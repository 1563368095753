import React, { useEffect, useState } from "react";
import "./css/style.css";
import { CSOAPI, VisitorTypeAPI, orgLocation } from "../../apis/apisServies";
import { setCSOData } from "../../Store/actions/csoReport/csoReportReducer";
import { useDispatch, useSelector } from "react-redux";
import { selectCSOData } from "../../Store/actions/csoReport/csoReportSelector";
import { CSROTable } from "../CSROReportTable/CSROReportTable";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import {
  // findCountryCodeById,
  findPortNameById,
  isView,
} from "../../../utils/commonFunctionsAndStatices";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";
import { selectOrgTable } from "../../Store/actions/Organizationadmin/OrganizationSelector";
import { setAllLocation } from "../../Store/actions/allLocationdata/getAllLocationReducer";
import { selectAllLocation } from "../../Store/actions/allLocationdata/selectallLocation";
import { selectLocationArr } from "../../Store/actions/locationData/locationSelectors";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import DataNotFound from "../DataNotFound/DataNotFound";
import { setVisitorTable } from "../../Store/actions/VisitorTypesTable/VisitorTypesTableReducer";
import { selectVisitorType } from "../../Store/actions/VisitorTypesTable/VisitorTypesTableSelector";
import { selectVisitorArr } from "../../Store/actions/visitorType/visitorTypeSelectors";
import parse from "html-react-parser";
import { selectOrgID } from "../../Store/actions/organizationId/organizationIdSeelct";
import { setOrganuzationID } from "../../Store/actions/organizationId/organizationIdReducer";
import { setOrganization } from "../../Store/actions/getorganization/organizationSelector";
import xlIcon from "../../../Asset/Icon/XLIcon.svg";

export const Csofilter = () => {
  const dispatch = useDispatch();
  const loginData = useSelector(selectToken);
  const [callingon, setCallingon] = useState("All");
  const selector = useSelector(selectCSOData);
  const [visitors, setVisitors] = useState(selector);

  const [loading, setLoading] = useState(true);
  const VisitorData = useSelector(selectVisitorType);
  const [locationName, setLocationName] = useState(
    findDisplayName("visitorLogAllVesselNameSelectOption", "All")
  );
  const visitorArr = useSelector(selectVisitorArr);
  const { showError } = useNotification();
  const languageId = useSelector(languageid);
  const selectOrgId = useSelector(selectOrgID);
  const orgName = useSelector(setOrganization);
  const [checkedItems, setCheckedItems] = useState({
    [findDisplayName("visitorsLogExportAllDetailsSelectOption", "All")]: true,
  });
  const orgData = useSelector(selectOrgTable);
  const superUser =
    isView("isDashboardVisitorLog") || isView("isListOrganization");

  const sortVisitorType = (
    visitorArr && visitorArr?.length > 0
      ? [...visitorArr]
      : [...(VisitorData || [])]
  ).sort((a, b) => a.name.localeCompare(b.name));

  const sortOrgData =
    orgData && [...orgData].sort((a, b) => a.name.localeCompare(b.name));

  const [OrgId, setOrganizationId] = useState(
    isView("isListOrganization") &&
      (selectOrgId || sortOrgData[0]?.organizationid || "")
  );
  const [filter, setFilter] = useState({
    category1: "All",
    category2: "All",
    visitorTypeId: "All",
  });
  const fetchData = async (organizationId) => {
    const id = {
      userid: loginData?.login_id,
      organizationid: organizationId,
    };
    setVisitors([]);
    try {
      const response = await orgLocation(id);
      if (response?.data.statusCode === 200) {
        const locationData = response?.data?.body?.data;
        dispatch(setAllLocation(locationData));
      } else {
        showError(response?.data?.body?.message);
        dispatch(setAllLocation([]));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCsochange = (e) => {
    const organizationId = e.target.value;
    setFilter((prevFilter) => {
      const filterData = {
        ...prevFilter,
        [e.target.name]: organizationId,
      };
      return filterData;
    });
  };

  const handleFilter = async (organizationId) => {
    if (navigator.onLine) {
      const changes = {};
      if (isView("isListOrganization")) {
        changes.organizationid = organizationId || OrgId;
      }

      // setShowTable(show)
      var search = {
        category1: filter.category1 ? filter.category1 : "All",
        category2: filter.category2 ? filter.category2 : "All",
        category3: callingon ? callingon : "All",
        visitor_type_id: filter.visitorTypeId ? filter.visitorTypeId : "All",
        userid: loginData?.login_id,
        ...changes,
        text_message: {
          language_id: languageId,
          ...(loginData?.organization_id
            ? { organization_id: loginData?.organization_id }
            : {}),
        },
      };
      const response = await CSOAPI(search);
      if (response?.data?.statusCode === 500) {
        dispatch(setCSOData([]));
        setVisitors([]);
        setLoading(false);
      } else if (response?.data?.statusCode === 404) {
        setLoading(false);
        setVisitors([]);
        dispatch(setCSOData([]));
      } else if (response?.data?.statusCode === 200) {
        if (superUser || organizationId) {
          const organizationID = organizationId || OrgId;
          fetchVisitorData(organizationID);
          fetchData(organizationID);
        }
        const csoResult = response?.data?.body;
        if (!csoResult || csoResult?.length === 0) {
          setLoading(false);
        }
        dispatch(setCSOData(csoResult));
        setVisitors(csoResult);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } else {
      setLoading(false);
      var root = JSON.parse(sessionStorage.getItem("persist:root"));
      var filterList = JSON.parse(root.csoReport).csodata;
      var csoReport = filterData(
        filterList,
        filter.category1,
        filter.category2,
        filter.visitorTypeId,
        callingon
      );
      setVisitors(csoReport);
    }
  };

  function filterData(data, category1, category2, category3, visitorType) {
    const now = new Date();
    const today = new Date(now);
    const sevenDaysAgo = new Date(now);
    sevenDaysAgo.setDate(now.getDate() - 7);
    const firstDayOfMonth = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      1
    );

    return data?.filter((item) => {
      let passCategory1 = false;
      let passCategory2 = false;
      let passCategory3 = false;
      let passVisitorType = false;

      if (category1 === "All") {
        passCategory1 = true; // Return all data
      } else if (category1 === "Check-In") {
        passCategory1 = item.CheckOut === null; // Return data where CheckOut is null
      } else if (category1 === "Check-Out") {
        passCategory1 = item.CheckOut !== null; // Return data where CheckOut is not null
      }

      if (category2 === "All") {
        passCategory2 = true;
      } else if (category2 === "Today") {
        const checkInDate = new Date(item.CheckIn);
        passCategory2 =
          checkInDate.getUTCFullYear() === today.getUTCFullYear() &&
          checkInDate.getUTCMonth() === today.getUTCMonth() &&
          checkInDate.getUTCDate() === today.getUTCDate();
      } else if (category2 === "This Week") {
        const checkInDate = new Date(item.CheckIn);

        passCategory2 = checkInDate >= sevenDaysAgo && checkInDate <= today;
      } else if (category2 === "This Month") {
        const checkInDate = new Date(item.CheckIn);

        passCategory2 = checkInDate >= firstDayOfMonth && checkInDate <= today;
      }

      if (category3 === "All") {
        passCategory3 = true;
      } else if (category3 === category3) {
        passCategory3 = item.locationid === category3;
      }

      if (visitorType === "All") {
        passVisitorType = true;
      } else if (visitorType === visitorType) {
        passVisitorType = item.visitortypeid === visitorType;
      }

      return passCategory1 && passCategory2 && passCategory3 && passVisitorType;
    });
  }

  // Visitor Id based find the visitor name
  function findVisitorTypeById(id) {
    var root = JSON.parse(sessionStorage.getItem("persist:root"));
    var visitorTypeList = JSON.parse(root.visitorTypeTable).visitortable;
    if (visitorTypeList?.length > 0) {
      const foundItem = visitorTypeList?.find(
        (item) => item.visitortypeid === id
      );
      return foundItem ? foundItem.name : null;
    } else {
      var visitorType = JSON.parse(root.visitorType).visitorArr;
      const foundItem = visitorType?.find((item) => item.visitortypeid === id);
      return foundItem ? foundItem.name : null;
    }
  }
  // Assuming listData contains the field names
  const listData = visitors?.flatMap((visitor) =>
    visitor.items && visitor.items?.length > 0
      ? visitor.items.map((subItem) => subItem.field_name)
      : []
  );

  // Use Set to remove duplicates
  const uniqueFieldNames = [...new Set(listData)].filter(
    (name) => name.trim() !== ""
  );

  const exportList = [
    findDisplayName("visitorsLogExportAllDetailsSelectOption", "All"),
    findDisplayName("visitorsLogCheckInDetailsSelectOption", "CheckIn Details"),
    findDisplayName("visitorsLogPortDetailsSelectOption", "Port Details"),
    findDisplayName("visitorsLogVesselNameSelectOption", "Vessel Name"),
    findDisplayName("visitorsLogVisitorTypeSelectOption", "Visitor Type"),
    ...uniqueFieldNames,
  ];

  // Sort the exportList based on the label property
  exportList.sort((a, b) => {
    const labelA = a.toUpperCase();
    const labelB = b.toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0; // Labels are equal
  });

  const handleChange = (fieldName) => {
    let all = findDisplayName("visitorsLogExportAllDetailsSelectOption", "All");
    if (fieldName === all) {
      const updatedItems = {
        [findDisplayName(
          "visitorsLogExportAllDetailsSelectOption",
          "All"
        )]: true,
      };
      setCheckedItems(updatedItems);
    } else {
      const updatedItems = {
        ...checkedItems,
        All: false,
        [fieldName]: !checkedItems[fieldName],
      };
      const filteredItems = Object.fromEntries(
        Object.entries(updatedItems).filter(([key, value]) => value === true)
      );
      setCheckedItems(filteredItems);
    }
  };

  const ExcelJS = require("exceljs");

  const handleDownload = async () => {
    var data = convertList(visitors);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    const heading = Object.keys(data[0]);
    const staticHeader1 = "VISITORS";
    const staticHeader2 = "LOG";

    // Set the first static header "VISITORS"
    const staticHeaderCell1 = worksheet.getCell(1, 1);
    staticHeaderCell1.value = staticHeader1.toUpperCase();
    worksheet.getRow(1).height = 60;
    staticHeaderCell1.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0271BE" },
    };
    staticHeaderCell1.font = {
      size: 40,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };

    const staticHeaderCell2 = worksheet.getCell(1, 2);
    staticHeaderCell2.value = staticHeader2.toUpperCase();
    staticHeaderCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0271BE" },
    };
    staticHeaderCell2.font = {
      size: 40,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };

    // Adding dynamic headers to the worksheet
    heading.forEach((key, index) => {
      const cell = worksheet.getCell(3, index + 1);
      cell.value = key.toUpperCase();
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF0271BE" },
      };
      cell.font = {
        color: { argb: "FFFFFFFF" },
      };
      const column = worksheet.getColumn(index + 1);
      column.width = 30;
    });
    for (let i = 1; i <= heading.length; i++) {
      const cell = worksheet.getCell(1, i);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF0271BE" },
      };
    }

    const additionalHeadings = [
      "Organization Name :",
      "Generated By :",
      "Role :",
      "Date :",
    ];
    worksheet.getRow(2).height = 30;
    additionalHeadings.forEach((heading, index) => {
      const startColumnIndex = index * 2 + 1;
      const endColumnIndex = startColumnIndex + 1;
      const cell = worksheet.getCell(2, startColumnIndex, 2, endColumnIndex);
      cell.value = heading.toUpperCase();
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF091B21" },
      };
      cell.font = {
        size: 16,
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
      cell.numFmt = "@"; // Apply custom number format to align text to the right
      cell.style.alignment = {
        ...cell.style.alignment,
        horizontal: "right", // Move content from left to right
      };
      if (index < additionalHeadings.length - 1) {
        const separatorCell = worksheet.getCell(2, endColumnIndex + 1);
        separatorCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF091B21" },
        };
      }
    });

    const Organization = orgData?.find(
      (item) => item.organizationid === selectOrgId
    )?.name;
    const orgNameStartColumnIndex = 2;
    const orgNameEndColumnIndex = 3;
    const orgNameCell = worksheet.getCell(
      2,
      orgNameStartColumnIndex,
      2,
      orgNameEndColumnIndex
    );
    orgNameCell.value = Organization ? Organization : orgName?.name;
    orgNameCell.alignment = { vertical: "middle", horizontal: "start" };
    orgNameCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF091B21" },
    };
    orgNameCell.font = {
      size: 16,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };

    const GeneratedNameStartColumnIndex = 4;
    const GeneratedNameEndColumnIndex = 5;
    const GeneratedNameCell = worksheet.getCell(
      2,
      GeneratedNameStartColumnIndex,
      2,
      GeneratedNameEndColumnIndex
    );
    GeneratedNameCell.value = loginData?.Name;
    GeneratedNameCell.alignment = { vertical: "middle", horizontal: "start" };
    GeneratedNameCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF091B21" },
    };
    GeneratedNameCell.font = {
      size: 16,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getColumn(GeneratedNameStartColumnIndex).width = 30;

    const RoleStartColumnIndex = 6;
    const RoleEndColumnIndex = 7;
    const RoleCell = worksheet.getCell(
      2,
      RoleStartColumnIndex,
      2,
      RoleEndColumnIndex
    );
    RoleCell.value = loginData?.role_name;
    RoleCell.alignment = { vertical: "middle", horizontal: "start" };
    RoleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF091B21" },
    };
    RoleCell.font = {
      size: 16,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getColumn(RoleStartColumnIndex).width = 30;

    const DateStartColumnIndex = 8;
    const DateEndColumnIndex = 9;
    const DateCell = worksheet.getCell(
      2,
      DateStartColumnIndex,
      2,
      DateEndColumnIndex
    );
    const currentDate = new Date();
    const day = currentDate.getUTCDate().toString().padStart(2, "0");
    const month = currentDate.toLocaleString("default", { month: "short" });
    const year = currentDate.getUTCFullYear();
    const formattedDate = `${day} ${month} ${year}`;
    DateCell.value = formattedDate;
    DateCell.alignment = { vertical: "middle", horizontal: "start" };
    DateCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF091B21" }, // Background color changed to #091B21
    };
    DateCell.font = {
      size: 16,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getColumn(DateStartColumnIndex).width = 30;

    const totalDynamicHeaders = heading.length + additionalHeadings.length;
    staticHeaderCell1.width = totalDynamicHeaders;
    staticHeaderCell2.width = 1;
    // Calculate the number of dynamic header columns occupied
    const occupiedDynamicHeaderColumns = heading.length;

    // Set the width of static header cell to match the occupied dynamic header columns
    staticHeaderCell1.width = occupiedDynamicHeaderColumns;
    staticHeaderCell2.width = 1; // Set width for the second static header cell

    // Convert data to an array of arrays (2D array)
    const dataArray = data.map((item) => heading.map((key) => item[key]));

    // Add the data to the worksheet starting from the fourth row (after the static header and blank row)
    worksheet.addRows(dataArray, { origin: "A4" });

    // Fill background color for cells in the second row where there is no data
    for (let index = 0; index < heading.length; index++) {
      const key = heading[index];
      const cell = worksheet.getCell(2, index + 1);
      const columnData = data.map((item) => item[key]); // Extract column data from the data array

      if (columnData.every((value) => !value)) {
        // If every value in the column data is falsy
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF091B21" }, // Background color changed to #091B21
        };
      } else {
        // If there is data, clear any existing fill
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF091B21" },
        };
      }
    }

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "Visitor.xlsx"
    );
  };

  // Helper function to mimic the Blob saving mechanism
  function saveAs(blob, fileName) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  const convertList = (list) => {
    const keys = Object.keys(checkedItems);
    // const keys = exportData.map((option) => option.value);
    const exportAll = keys.includes(
      findDisplayName("visitorsLogExportAllDetailsSelectOption", "All")
    );

    return list.map((item) => {
      const mappedItem = {
        Badge: item.Badge,
        "First Name": item.firstname,
        "Last Name": item.lastname,
      };

      if (
        exportAll ||
        keys.includes(
          findDisplayName("visitorsLogVesselNameSelectOption", "Vessel Name")
        )
      ) {
        mappedItem["Vessel Name"] = findLocationNameById(item.locationid);
      }
      if (
        exportAll ||
        keys.includes(
          findDisplayName("visitorsLogVisitorTypeSelectOption", "Visitor Type")
        )
      ) {
        mappedItem["Visitor Type"] = findVisitorTypeById(item.visitortypeid);
      }
      if (
        exportAll ||
        keys.includes(
          findDisplayName(
            "visitorsLogCheckInDetailsSelectOption",
            "CheckIn Details"
          )
        )
      ) {
        mappedItem["Check In"] = item.CheckIn;
        mappedItem["Check Out"] = item.CheckOut;
      }
      if (
        exportAll ||
        keys.includes(
          findDisplayName("visitorsLogPortDetailsSelectOption", "Port Details")
        )
      ) {
        mappedItem["Check in Port"] = item.checkinport
          ? findPortNameById(item.checkinport)
          : "";
        mappedItem["Check Out Port"] = item.checkoutport
          ? findPortNameById(item.checkoutport)
          : "";
      }

      // If "all" is not in the keys, only export matched keys
      if (!exportAll) {
        keys.forEach((key) => {
          if (
            key !== "Port Details" &&
            key !== "CheckIn Details" &&
            key !== "Visitor Type" &&
            key !== "Vessel Name" &&
            item.items &&
            item.items.length > 0
          ) {
            const matchingSubItem = item.items.find(
              (subItem) => subItem.field_name === key
            );
            if (matchingSubItem) {
              mappedItem[key] = matchingSubItem.field_result;
            }
          }
        });
      }
      if (exportAll) {
        if (item.items && item.items.length > 0) {
          item.items.forEach((subItem) => {
            const subItemKey = subItem.field_name;
            const subItemValue =
              subItem.field_result === "true"
                ? "Yes"
                : subItem.field_result === "false"
                ? "No"
                : subItem.field_result === ""
                ? "None"
                : subItem.field_result.charAt(0).toUpperCase() +
                  subItem.field_result.slice(1);
            mappedItem[subItemKey] = subItemValue;
          });
        }
      }

      return mappedItem;
    });
  };

  function findLocationNameById(id) {
    var root = JSON.parse(sessionStorage.getItem("persist:root"));
    var locationList = JSON.parse(root.locationDataArr).locationArr;
    if (locationList.length > 0) {
      const foundItem = locationList?.find((item) => item.locationid === id);
      return foundItem ? foundItem.locationname : null;
    } else {
      var locationList = JSON.parse(root.allLocationData).allLocationData;
      const foundItem = locationList.find((item) => item.locationid === id);
      return foundItem ? foundItem.locationname : null;
    }
  }

  const handleLocationChange = (event) => {
    setCallingon(event.target.value);
  };

  useEffect(() => {
    if (isView("isListOrganization") === true) {
      handleFilter(
        selectOrgId !== "" ? selectOrgId : sortOrgData[0]?.organizationid
      );
    } else {
      handleFilter("");
    }
  }, []);

  const locationData = useSelector((state) => {
    if (superUser === true) {
      return selectAllLocation(state);
    } else {
      return selectLocationArr(state);
    }
  });

  const fetchVisitorData = async (organizationId) => {
    const datobj = {
      userid: loginData?.login_id,
      organization_id: organizationId,
      isactive: true,
      text_message: {
        language_id: languageId,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    try {
      const response = await VisitorTypeAPI(datobj);
      if (response.data.statusCode === 200) {
        const setVisitorData = response?.data?.body?.data;
        dispatch(setVisitorTable(setVisitorData));
      } else if (response.data.statusCode === 404) {
        const setVisitorData = response?.data?.body?.message;
        showError(setVisitorData);
        dispatch(setVisitorTable([]));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortLocationList = [...locationData].sort((a, b) =>
    a.locationname.localeCompare(b.locationname)
  );

  const handleOrganizationChange = (id) => {
    setOrganizationId(id);
    setFilter({
      category1: "",
      category2: "",
      visitorTypeId: "All",
    });
    setCheckedItems({
      [findDisplayName("visitorsLogExportAllDetailsSelectOption", "All")]: true,
    });
    setCallingon("");
    dispatch(setOrganuzationID(id));
    handleFilter(id);
    setLoading(true);
  };

  const orgID =
    isView("isListOrganization") && selectOrgId !== ""
      ? selectOrgId
      : sortOrgData && sortOrgData.length > 0
      ? sortOrgData[0]?.organizationid
      : null;

  const name =
    isView("isListOrganization") && orgID
      ? orgData?.find((item) => item.organizationid === orgID)?.name
      : null;

  return (
    <div className="container ">
      <div className="container d-flex justify-content-center align-items-center mt-5">
        <div className="row d-flex gx-4 gy-2 ">
          <div className="d-flex justify-content-between">
            <div>
              {(isView("isDashboardStartOrganization") ||
                isView("isDashboardVisitorLog")) && (
                <strong className=" text-white">
                  {" "}
                  {findDisplayName("visitorlogHeading", "Visitor log")}
                </strong>
              )}
            </div>
            <div>
              {isView("isListOrganization") && (
                <div className="dropdown ">
                  <button
                    className="bg-white w-100 form-select text-start text-truncate"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {name}
                  </button>
                  <ul className="dropdown-menu w-100 pb-2">
                    {sortOrgData?.map((orgData) => (
                      <li key={orgData.organizationid}>
                        <a
                          className="dropdown-item text-truncate"
                          onClick={() =>
                            handleOrganizationChange(orgData.organizationid)
                          }
                        >
                          {orgData.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
            <label className="mt-2 text-white">
              {parse(
                findDisplayName(
                  "visitorsLogInstructionDetails",
                  "List of visitor check-in checkout details"
                )
              )}
            </label>
          </div>
          <div className="row d-flex me-3 pe-4 pt-4">
            <div className="col-lg-2 col-md-4 col-sm-12">
              <label htmlFor="statusDropdown" className="form-label text-white">
                {findDisplayName("visitorsLogStatusDropdown", "Status")}
              </label>
              <div className="dropdown ">
                <button
                  className="bg-white w-100 form-select text-start text-truncate"
                  id="statusDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {filter.category1 ||
                    findDisplayName("visitorLogAllstatusSelect", "All")}
                </button>
                <ul
                  className="dropdown-menu w-100 pb-2"
                  aria-labelledby="statusDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleCsochange({
                          target: { name: "category1", value: "" },
                        })
                      }
                    >
                      {findDisplayName("visitorLogAllstatusSelect", "All")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleCsochange({
                          target: { name: "category1", value: "Check-In" },
                        })
                      }
                    >
                      {findDisplayName(
                        "visitorLogCheckinStatusSelect",
                        "Check - IN"
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleCsochange({
                          target: { name: "category1", value: "Check-Out" },
                        })
                      }
                    >
                      {findDisplayName(
                        "visitorLogCheckoutSatusSelect",
                        "Check Out"
                      )}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <label htmlFor="timeDropdown" className="form-label text-white">
                {findDisplayName("visitorsLogTimeDropdown", "Time")}
              </label>
              <div className="dropdown pb-2">
                <button
                  className="bg-white w-100 form-select text-start text-truncate"
                  id="timeDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {filter.category2 ||
                    findDisplayName("visitorLogAllTimeSelectOption", "All")}
                </button>
                <ul
                  className="dropdown-menu pb-2"
                  aria-labelledby="timeDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleCsochange({
                          target: { name: "category2", value: "All" },
                        })
                      }
                    >
                      {findDisplayName("visitorLogAllTimeSelectOption", "All")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleCsochange({
                          target: { name: "category2", value: "Today" },
                        })
                      }
                    >
                      {findDisplayName(
                        "visitorLogTodayTimeSelectOption",
                        "Today"
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleCsochange({
                          target: { name: "category2", value: "This Week" },
                        })
                      }
                    >
                      {findDisplayName(
                        "visitorLogThisWeekTimeSelectOption",
                        "This Week"
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleCsochange({
                          target: { name: "category2", value: "This Month" },
                        })
                      }
                    >
                      {findDisplayName(
                        "visitorLogThisMonthTimeSelectOption",
                        "This Month"
                      )}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-12">
              <label
                htmlFor="callingOnDropdown"
                className="form-label text-white"
              >
                {findDisplayName("visitorsLogCallingOnDropdown", "Vessel Name")}
              </label>
              <div className="dropdown">
                <button
                  className="bg-white w-100 form-select text-start text-truncate"
                  id="callingOnDropdown"
                  data-bs-toggle="dropdown"
                >
                  {locationName}
                </button>
                <ul
                  className="dropdown-menu pb-2"
                  aria-labelledby="callingOnDropdown"
                >
                  <li>
                    <button
                      className="dropdown-item text-truncate"
                      onClick={() => {
                        handleLocationChange({
                          target: { value: "All" },
                        });
                        setLocationName(
                          findDisplayName(
                            "visitorLogAllVesselNameSelectOption",
                            "All"
                          )
                        );
                      }}
                    >
                      {findDisplayName(
                        "visitorLogAllVesselNameSelectOption",
                        "All"
                      )}
                    </button>
                  </li>
                  {sortLocationList?.map((data, index) => (
                    <li key={index}>
                      <button
                        className="dropdown-item text-truncate"
                        onClick={() => {
                          handleLocationChange({
                            target: { value: data.locationid },
                          });
                          setLocationName(data.locationname);
                        }}
                      >
                        {data.locationname}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-12">
              <label htmlFor="VisitorType" className="form-label text-white">
                {findDisplayName(
                  "visitorsLogVisitorTypeDropdown",
                  "Visitor Type"
                )}
              </label>
              <div className="dropdown">
                <button
                  className="bg-white w-100 form-select text-start text-truncate "
                  id="visitorTypeDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {filter.visitorTypeId &&
                  sortVisitorType.find(
                    (item) => item.visitortypeid === filter.visitorTypeId
                  )?.name
                    ? sortVisitorType.find(
                        (item) => item.visitortypeid === filter.visitorTypeId
                      )?.name
                    : findDisplayName(
                        "visitorLogAllVisitorTypeSelectOption",
                        "All"
                      )}
                </button>
                <ul
                  className="dropdown-menu pb-2"
                  aria-labelledby="visitorTypeDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleCsochange({
                          target: { name: "visitorTypeId", value: "All" },
                        })
                      }
                    >
                      {findDisplayName(
                        "visitorLogAllVisitorTypeSelectOption",
                        "All"
                      )}
                    </a>
                  </li>
                  {sortVisitorType?.map((item, index) => (
                    <li key={index}>
                      <a
                        className="dropdown-item text-truncate"
                        onClick={() =>
                          handleCsochange({
                            target: {
                              name: "visitorTypeId",
                              value: item.visitortypeid,
                            },
                          })
                        }
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-12 pt-lg-4 pt-2 mt-2 pt-md-4">
              <label className="mt-4"> </label>
              <button
                className="btn btn-primary w-100 "
                onClick={() => handleFilter()}
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5857 5.46289H5.41411C4.52321 5.46289 4.07704 6.54003 4.70701 7.17L9.70701 12.17C9.89454 12.3575 9.9999 12.6119 9.9999 12.8771V16.9629C9.9999 17.2776 10.1481 17.574 10.3999 17.7629L13.1999 19.8629C13.5295 20.1101 13.9999 19.8749 13.9999 19.4629V12.8771C13.9999 12.6119 14.1053 12.3575 14.2928 12.17L19.2928 7.17C19.9228 6.54003 19.4766 5.46289 18.5857 5.46289Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                {findDisplayName("visitorsLogFilterButton", "Filter")}
              </button>
            </div>

            {visitors && visitors.length > 0 && (
              <div className="col-lg-2 col-md-4 col-sm-12 pt-lg-4 pt-2 mt-2 pt-md-4">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Button group with nested dropdown"
                >
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className="btn bg-white text-dark dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {findDisplayName(
                        "visitorsLogExportXLSXButton",
                        "Export By"
                      )}{" "}
                    </button>
                    <div className="dropdown-menu p-2 pe-1">
                      <div
                        className="p-2 "
                        style={{ maxHeight: "25vh", overflowY: "auto" }}
                      >
                        {exportList?.map((fieldName, index) => (
                          <div className="form-check" key={index}>
                            <input
                              className="form-check-input border border-secondary-subtle"
                              type="checkbox"
                              id={fieldName}
                              name={fieldName}
                              checked={checkedItems[fieldName] || false}
                              onChange={() => handleChange(fieldName)}
                            />
                            <label
                              htmlFor={fieldName}
                              className="form-check-label text-dark-emphasis"
                            >
                              {fieldName}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={() => handleDownload()}
                  >
                    <img src={xlIcon} alt="XL Icon" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center mt-5 pt-5">
          <div className="d-flex text-white h-100">
            <div className="spinner-container">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="ps-2">
                {findDisplayName(
                  "labelTabelScreenLoadingHeading",
                  "Loading..."
                )}
              </span>
            </div>
          </div>
        </div>
      ) : visitors && visitors.length > 0 ? (
        <CSROTable data={visitors} POB={false} />
      ) : (
        <div className={`${isView("isLandingCheckIn") ? "card mt-3" : ""} `}>
          <div style={{ height: "40vh" }}>
            <DataNotFound />
          </div>
        </div>
      )}
    </div>
  );
};
