import React, { useEffect } from "react";
import {
  capitalizeFirstLetter,
  // findCountryCodeById,
  findDisplayName,
  findPortNameById,
  isView,
  sortData,
} from "../../../../utils/commonFunctionsAndStatices";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { useSelector } from "react-redux";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { useState } from "react";
import DataNotFound from "../../DataNotFound/DataNotFound";
import xlIcon from "../../../../Asset/Icon/XLIcon.svg";
import ExcelJS from "exceljs";
import { setOrganization } from "../../../Store/actions/getorganization/organizationSelector";

const VisitorsReport = ({ data }) => {
  const [sortDirection, setSortDirection] = useState('asc');
  const [tableData, setTableData] = useState(data);
  const [sortField, setSortField] = useState('');
  const orgData = useSelector(selectOrgTable);
  const loginData = useSelector(selectToken);
  const orgName = useSelector(setOrganization);
  const [selectedOption, setSelectedOption] = useState(
    isView("isListOrganization")
      ? orgData[0]?.organizationid
      : loginData?.organization_id
  );

  //sort the data
  const shortOrgData =
    orgData && [...orgData].sort((a, b) => a.name.localeCompare(b.name));
  const [checkedItems, setCheckedItems] = useState({
    [findDisplayName("visitorsLogExportAllDetailsSelectOption", "All")]: true,
  });
  const handleOptionClick = (organizationId, organizationName) => {
    setSelectedOption(organizationId);
  };
  const formatDateTime = (timestamp) => {
    if (timestamp === "null") {
      return "No date";
    } else {
      const date = new Date(timestamp);
      const options = { timeZone: "UTC" };
      return date.toLocaleString("en-US", options);
    }
  };


  function findCallingnameById(id) {
    var root = JSON.parse(sessionStorage.getItem("persist:root"));
    var locationList = JSON.parse(root.locationDataArr).locationArr;
    if (locationList?.length > 0) {
      const foundItem = locationList?.find((item) => item.locationid === id);
      return foundItem ? foundItem.locationname : null;
    } else {
      var locationList = JSON.parse(root.allLocationData).allLocationData;
      const foundItem = locationList?.find((item) => item.locationid === id);
      return foundItem ? foundItem.locationname : null;
    }
  }

  const filteredData = data?.filter((item) => {
    // Assuming each data item has an 'organizationId' property
    return item.organizationid === selectedOption;
  });

  useEffect(() => {
    if (tableData.length !== filteredData.length) {
      setTableData(filteredData);
    }
  }, [filteredData]);

  const uniqueFieldNames = new Set();
  filteredData?.forEach((item) => {
    item.items?.forEach((subItem) => {
      const trimmedFieldName = subItem.field_name.trim();
      if (trimmedFieldName) {
        // Check if the field name is not empty after trimming
        uniqueFieldNames.add(trimmedFieldName);
      }
    });
  });

  // Visitor Id based find the visitor name
  function findVisitorTypeById(id) {
    var root = JSON.parse(sessionStorage.getItem("persist:root"));
    var visitorTypeList = JSON.parse(root.visitorTypeTable).visitortable;
    if (visitorTypeList?.length > 0) {
      const foundItem = visitorTypeList?.find(
        (item) => item.visitortypeid === id
      );
      return foundItem ? capitalizeFirstLetter(foundItem.name) : null;
    } else {
      var visitorType = JSON.parse(root.visitorType).visitorArr;
      const foundItem = visitorType?.find((item) => item.visitortypeid === id);
      return foundItem ? capitalizeFirstLetter(foundItem.name) : null;
    }
  }
  const convertList = (list) => {
    const keys = Object.keys(checkedItems);
    // const keys = exportData.map((option) => option.value);
    const exportAll = keys.includes(
      findDisplayName("visitorsLogExportAllDetailsSelectOption", "All")
    );

    return list.map((item) => {
      const mappedItem = {
        Badge: item.Badge,
        "First Name": item.firstname,
        "Last Name": item.lastname,
      };

      if (
        exportAll ||
        keys.includes(
          findDisplayName("visitorsLogVesselNameSelectOption", "Vessel Name")
        )
      ) {
        mappedItem["Vessel Name"] = findCallingnameById(item.locationid);
      }
      if (
        exportAll ||
        keys.includes(
          findDisplayName("visitorsLogVisitorTypeSelectOption", "Visitor Type")
        )
      ) {
        mappedItem["Visitor Type"] = findVisitorTypeById(item.visitortypeid);
      }
      if (
        exportAll ||
        keys.includes(
          findDisplayName(
            "visitorsLogCheckInDetailsSelectOption",
            "CheckIn Details"
          )
        )
      ) {
        mappedItem["Check In"] = item.CheckIn;
        mappedItem["Check Out"] = item.CheckOut;
      }
      if (
        exportAll ||
        keys.includes(
          findDisplayName("visitorsLogPortDetailsSelectOption", "Port Details")
        )
      ) {
        mappedItem["Check in Port"] = item.checkinport
          ? findPortNameById(item.checkinport)
          : "";
        mappedItem["Check Out Port"] = item.checkoutport
          ? findPortNameById(item.checkoutport)
          : "";
      }

      // If "all" is not in the keys, only export matched keys
      if (!exportAll) {
        keys.forEach((key) => {
          if (
            key !== "Port Details" &&
            key !== "CheckIn Details" &&
            key !== "Visitor Type" &&
            key !== "Vessel Name" &&
            item.items &&
            item.items.length > 0
          ) {
            const matchingSubItem = item.items?.find(
              (subItem) => subItem.field_name === key
            );
            if (matchingSubItem) {
              mappedItem[key] = matchingSubItem.field_result;
            }
          }
        });
      }
      if (exportAll) {
        if (item.items && item.items.length > 0) {
          item.items.forEach((subItem) => {
            const subItemKey = subItem.field_name;
            const subItemValue = subItem.field_result;
            mappedItem[subItemKey] = subItemValue;
          });
        }
      }

      return mappedItem;
    });
  };

  const handleDownload = async () => {
    var data = convertList(filteredData);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    const heading = Object.keys(data[0]);
    const staticHeader1 = "VISITORS";
    const staticHeader2 = "LOG";

    // Set the first static header "VISITORS"
    const staticHeaderCell1 = worksheet.getCell(1, 1);
    staticHeaderCell1.value = staticHeader1.toUpperCase();
    worksheet.getRow(1).height = 60;
    staticHeaderCell1.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0271BE" },
    };
    staticHeaderCell1.font = {
      size: 40,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };

    const staticHeaderCell2 = worksheet.getCell(1, 2);
    staticHeaderCell2.value = staticHeader2.toUpperCase();
    staticHeaderCell2.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0271BE" },
    };
    staticHeaderCell2.font = {
      size: 40,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };

    // Adding dynamic headers to the worksheet
    heading.forEach((key, index) => {
      const cell = worksheet.getCell(3, index + 1);
      cell.value = key.toUpperCase();
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF0271BE" },
      };
      cell.font = {
        color: { argb: "FFFFFFFF" },
      };
      const column = worksheet.getColumn(index + 1);
      column.width = 30;
    });
    for (let i = 1; i <= heading.length; i++) {
      const cell = worksheet.getCell(1, i);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF0271BE" },
      };
    }

    const additionalHeadings = [
      "Organization Name :",
      "Generated By :",
      "Role :",
      "Date :",
    ];
    worksheet.getRow(2).height = 30;
    additionalHeadings.forEach((heading, index) => {
      const startColumnIndex = index * 2 + 1;
      const endColumnIndex = startColumnIndex + 1;
      const cell = worksheet.getCell(2, startColumnIndex, 2, endColumnIndex);
      cell.value = heading.toUpperCase();
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF091B21" },
      };
      cell.font = {
        size: 16,
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
      cell.numFmt = "@"; // Apply custom number format to align text to the right
      cell.style.alignment = {
        ...cell.style.alignment,
        horizontal: "right", // Move content from left to right
      };
      if (index < additionalHeadings.length - 1) {
        const separatorCell = worksheet.getCell(2, endColumnIndex + 1);
        separatorCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF091B21" },
        };
      }
    });

    const Organization = orgData?.find(
      (org) => org.organizationid === selectedOption
    )?.name;
    const orgNameStartColumnIndex = 2;
    const orgNameEndColumnIndex = 3;
    const orgNameCell = worksheet.getCell(
      2,
      orgNameStartColumnIndex,
      2,
      orgNameEndColumnIndex
    );
    orgNameCell.value = isView("isListOrganization")
      ? Organization
      : orgName?.name;
    orgNameCell.alignment = { vertical: "middle", horizontal: "start" };
    orgNameCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF091B21" },
    };
    orgNameCell.font = {
      size: 16,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };

    const GeneratedNameStartColumnIndex = 4;
    const GeneratedNameEndColumnIndex = 5;
    const GeneratedNameCell = worksheet.getCell(
      2,
      GeneratedNameStartColumnIndex,
      2,
      GeneratedNameEndColumnIndex
    );
    GeneratedNameCell.value = loginData?.Name;
    GeneratedNameCell.alignment = { vertical: "middle", horizontal: "start" };
    GeneratedNameCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF091B21" },
    };
    GeneratedNameCell.font = {
      size: 16,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getColumn(GeneratedNameStartColumnIndex).width = 30;

    const RoleStartColumnIndex = 6;
    const RoleEndColumnIndex = 7;
    const RoleCell = worksheet.getCell(
      2,
      RoleStartColumnIndex,
      2,
      RoleEndColumnIndex
    );
    RoleCell.value = loginData?.role_name;
    RoleCell.alignment = { vertical: "middle", horizontal: "start" };
    RoleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF091B21" },
    };
    RoleCell.font = {
      size: 16,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getColumn(RoleStartColumnIndex).width = 30;

    const DateStartColumnIndex = 8;
    const DateEndColumnIndex = 9;
    const DateCell = worksheet.getCell(
      2,
      DateStartColumnIndex,
      2,
      DateEndColumnIndex
    );
    const currentDate = new Date();
    const day = currentDate.getUTCDate().toString().padStart(2, "0");
    const month = currentDate.toLocaleString("default", { month: "short" });
    const year = currentDate.getUTCFullYear();
    const formattedDate = `${day} ${month} ${year}`;
    DateCell.value = formattedDate;
    DateCell.alignment = { vertical: "middle", horizontal: "start" };
    DateCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF091B21" }, // Background color changed to #091B21
    };
    DateCell.font = {
      size: 16,
      bold: true,
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getColumn(DateStartColumnIndex).width = 30;

    const totalDynamicHeaders = heading.length + additionalHeadings.length;
    staticHeaderCell1.width = totalDynamicHeaders;
    staticHeaderCell2.width = 1;
    // Calculate the number of dynamic header columns occupied
    const occupiedDynamicHeaderColumns = heading.length;

    // Set the width of static header cell to match the occupied dynamic header columns
    staticHeaderCell1.width = occupiedDynamicHeaderColumns;
    staticHeaderCell2.width = 1; // Set width for the second static header cell

    // Convert data to an array of arrays (2D array)
    const dataArray = data.map((item) => heading.map((key) => item[key]));

    // Add the data to the worksheet starting from the fourth row (after the static header and blank row)
    worksheet.addRows(dataArray, { origin: "A4" });

    // Fill background color for cells in the second row where there is no data
    for (let index = 0; index < heading.length; index++) {
      const key = heading[index];
      const cell = worksheet.getCell(2, index + 1);
      const columnData = data.map((item) => item[key]); // Extract column data from the data array

      if (columnData.every((value) => !value)) {
        // If every value in the column data is falsy
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF091B21" }, // Background color changed to #091B21
        };
      } else {
        // If there is data, clear any existing fill
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF091B21" },
        };
      }
    }

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "Visitor.xlsx"
    );
  };

  // Helper function to mimic the Blob saving mechanism
  function saveAs(blob, fileName) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  const exportList = [
    findDisplayName("visitorsLogExportAllDetailsSelectOption", "All"),
    findDisplayName("visitorsLogCheckInDetailsSelectOption", "CheckIn Details"),
    findDisplayName("visitorsLogPortDetailsSelectOption", "Port Details"),
    findDisplayName("visitorsLogVesselNameSelectOption", "Vessel Name"),
    findDisplayName("visitorsLogVisitorTypeSelectOption", "Visitor Type"),
    ...uniqueFieldNames,
  ];

  const handleChange = (fieldName) => {
    let all = findDisplayName("visitorsLogExportAllDetailsSelectOption", "All");
    if (fieldName === all) {
      const updatedItems = {
        [findDisplayName(
          "visitorsLogExportAllDetailsSelectOption",
          "All"
        )]: true,
      };
      setCheckedItems(updatedItems);
    } else {
      const updatedItems = {
        ...checkedItems,
        All: false,
        [fieldName]: !checkedItems[fieldName],
      };
      const filteredItems = Object.fromEntries(
        Object.entries(updatedItems).filter(([key, value]) => value === true)
      );
      setCheckedItems(filteredItems);
    }
  };


  const handleSort = (field) => {
    // Toggle sort direction if the same field is clicked again
    const newSortDirection = sortField === field ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';

    // Update sort direction and field state
    setSortDirection(newSortDirection);
    setSortField(field);

    // Sort the table data based on the selected field and direction
    const sortedData = sortData(tableData, field, newSortDirection);

    setTableData(sortedData);
  };

  return (
    <>
      <div className="row">
        <div className="d-flex justify-content-between">
          <div className="mb-3">
            {isView("isListOrganization") && (
              <>
                <label className="me-2 mt-1 text-white ">
                  {findDisplayName(
                    "visitorsReportSelectOragnization",
                    "Select Organization :"
                  )}
                </label>
                <div className="dropdown">
                  <button
                    className="btn btn-dark dropdown-toggle mt-2 text-truncate"
                    type="button"
                    id="orgDropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {
                      orgData?.find((org) => org.organizationid === selectedOption)
                        ?.name
                    }
                  </button>
                  <div className="dropdown-menu" aria-labelledby="orgDropdown">
                    {shortOrgData.map((organization) => (
                      <button
                        key={organization.organizationid}
                        className={`dropdown-item text-truncate ${organization.organizationid === selectedOption
                          ? "active"
                          : ""
                          }`}
                        type="button"
                        onClick={() =>
                          handleOptionClick(organization.organizationid)
                        }
                      >
                        {organization.name}
                      </button>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
          {filteredData.length !== 0 && (
            <div
              className="btn-group pt-4 mb-4"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn bg-white text-dark dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {findDisplayName("visitorsLogExportByDropdown", "Export By")}{" "}
                </button>
                <div className="dropdown-menu p-2 pe-1">
                  <div
                    className="p-2"
                    style={{ maxHeight: "25vh", overflowY: "auto" }}
                  >
                    {exportList?.map((fieldName, index) => (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input border border-secondary-subtle"
                          type="checkbox"
                          id={fieldName}
                          name={fieldName}
                          checked={checkedItems[fieldName] || false}
                          onChange={() => handleChange(fieldName)}
                        />
                        <label
                          htmlFor={fieldName}
                          className="form-check-label text-dark-emphasis"
                        >
                          {fieldName}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => handleDownload()}
              >
                <img src={xlIcon} alt="XL Icon" />
              </button>
            </div>
          )}
        </div>

        {filteredData.length === 0 && (
          <div className=" ">
            <DataNotFound />{" "}
          </div>
        )}
        {filteredData.length !== 0 && (
          <div className="p-2">
            <div className="visitors-table-scrol">
              <table className="table table-striped table-hover">
                <thead className="bg-transparent ">
                  <tr>
                    <th scope="col" className="bg-transparent th-color"
                      onClick={() => { handleSort('Badge') }}>
                      {findDisplayName("visitorsReportBadgeHeading", "Badge")}
                      {sortField === 'Badge'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    <th scope="col" className="bg-transparent th-color"
                      onClick={() => { handleSort('firstname') }}>
                      {findDisplayName("visitorsReportFirstNameHeading", "First Name")}
                      {sortField === 'Name'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    <th scope="col" className="bg-transparent th-color"
                      onClick={() => { handleSort('Name') }}>
                      {findDisplayName("visitorsReportLastNameHeading", "Last Name")}
                      {sortField === 'Name'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    <th
                      scope="col"
                      style={{ whiteSpace: "nowrap" }}
                      className="bg-transparent th-color"
                      onClick={() => { handleSort('locationid') }}
                    >
                      {findDisplayName(
                        "visitorsReportCallingOnHeading",
                        "Location Name"
                      )}
                      {sortField === 'locationid'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    <th scope="col" className="bg-transparent th-color"
                      onClick={() => { handleSort('visitortypeid') }}>
                      {findDisplayName(
                        "visitorsReportVisitorTypeHeading",
                        "Visitor Type"
                      )} {sortField === 'visitortypeid'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    <th scope="col" className="bg-transparent th-color"
                      onClick={() => { handleSort('CheckIn') }}>
                      {findDisplayName(
                        "visitorsReportCheckInHeading",
                        "Check-In"
                      )} {sortField === 'CheckIn'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    <th scope="col" className="bg-transparent th-color"
                      onClick={() => { handleSort('CheckOut') }}>
                      {findDisplayName(
                        "visitorsReportCheckOutHeading",
                        "Check-Out"
                      )} {sortField === 'CheckOut'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    <th scope="col" className="bg-transparent th-color"
                      onClick={() => { handleSort('checkinport') }}>
                      {findDisplayName(
                        "visitorsReportCheckInPortHeading",
                        "Checkin port"
                      )} {sortField === 'checkinport'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    <th scope="col" className="bg-transparent th-color"
                      onClick={() => { handleSort('checkoutport') }}>
                      {findDisplayName(
                        "visitorsReportCheckOutPortHeading",
                        "Check Out port"
                      )} {sortField === 'checkoutport'
                        ? sortDirection === 'asc'
                          ? '↑'
                          : '↓'
                        : '↑↓'}
                    </th>
                    {[...uniqueFieldNames].map((fieldName) => (
                      <th
                        key={fieldName}
                        scope="col"
                        className="bg-transparent th-color"
                      >
                        {fieldName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((item, index) => (
                    <tr key={index}>
                      <td className="ps-2 bg-transparent text-white">
                        {item.Badge}
                      </td>
                      <td className="bg-transparent text-white">{item?.firstname?.charAt(0).toUpperCase() + item?.firstname?.slice(1)}</td>
                      <td className='bg-transparent text-white'>{item?.lastname?.charAt(0).toUpperCase() + item?.lastname?.slice(1)}</td>
                      <td className="bg-transparent text-white">
                        {item.locationid
                          ? findCallingnameById(item.locationid)
                          : ""}
                      </td>
                      <td className="bg-transparent text-white">
                        {item.locationid
                          ? findVisitorTypeById(item.visitortypeid)
                          : ""}
                      </td>
                      <td className="bg-transparent text-white">
                        {item.CheckIn ? formatDateTime(item?.CheckIn) : ""}
                      </td>
                      <td className="bg-transparent text-white">
                        {item.CheckOut ? formatDateTime(item?.CheckOut) : ""}
                      </td>
                      <td className="bg-transparent text-white">
                        {item.checkinport
                          ? findPortNameById(item?.checkinport)
                          : ""}
                      </td>
                      <td className="bg-transparent text-white">
                        {item.checkoutport
                          ? findPortNameById(item?.checkoutport)
                          : ""}
                      </td>
                      {[...uniqueFieldNames].map((fieldName) => (
                        <td
                          key={fieldName}
                          className="bg-transparent text-white"
                        >
                          {
                            item.items?.find(
                              (subItem) => subItem.field_name === fieldName
                            )?.field_result
                          }
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VisitorsReport;