import React from "react";
import { findDisplayName } from "../../../../utils/commonFunctionsAndStatices";
import { useForm } from "react-hook-form";
import { UpdateFeature } from "../../../apis/apisServies";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { useSelector } from "react-redux";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";

const FeaturesUpdate = ({
  setActiveComponent,
  feature,
  featurelistdata,
  selectedRoleId,
}) => {
  const { handleSubmit, register } = useForm();
  const loginData = useSelector(selectToken);
  const { showSuccess, showError } = useNotification();
  const languageId = useSelector(languageid);
  const initialModule = feature?.module;
  const initialType = feature?.type;
  const initialModuleDetails = feature?.moduledetails;
  

  const onSubmit = async (data) => {
    var added = [];
    var removed = [];
    let changes = {};
    if (data.module?.trim() !== initialModule) {
      changes.module = data.module?.trim();
    }

    if (data.type?.trim() !== initialType) {
      changes.type = data.type?.trim();
    }
    if (!data.module.trim()) {
      showError("Kindly Enter a visitor module");
      return;
    }
    if (data.module_details?.trim() !== initialModuleDetails) {
      changes.module_details = data.module_details?.trim();
    }
    if (
      Object.keys(changes).length === 0 &&
      added.length === 0 &&
      removed.length === 0
    ) {
      showError("No changes were made");
      return;
    }
    if (!data.type.trim()) {
      showError("Kindly Enter a Type");
      return;
    }
    const dataObject = {
      userid: loginData?.login_id,
      module: data.module.trim(),
      featureid: feature?.featureid,
      type: data.type.trim(),
      module_details: data.module_details, 
      text_message: {
        language_id: languageId,
      },
    };

    const response = await UpdateFeature(dataObject);
    if (response.data.statusCode === 200) {
      showSuccess(response?.data?.body?.message);
      featurelistdata(selectedRoleId);
      setActiveComponent("table");
    } else if (response?.data?.statusCode === 400) {
      showError(response?.data?.body?.message);
    } else if (response?.data?.statusCode === 404) {
      showError(response?.data?.body?.message);
    }
  };

  return (
    <div className="container  text-white  from-scrol">
      <form
        className="row mt-lg-5  d-flex flex-column align-items-center mt-5 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <label htmlFor="module" className="mt-2">
            {findDisplayName("featuresUpdateModuleHeading", "Module")}
          </label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="module"
            onFocus={(event) => {
              event.target.setAttribute("autoComplete", "off");
            }}
            {...register("module", {})}
            defaultValue={initialModule}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <label htmlFor="type">
            {findDisplayName("featuresUpdateTypeHeading", "Type ")}
          </label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="type"
            onFocus={(event) => {
              event.target.setAttribute("autoComplete", "off");
            }}
            {...register("type", {})}
            defaultValue={initialType}
          />
        </div>
        <div className="form-group mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8">
          <label htmlFor="type ">{findDisplayName("featuresUpdateModuleDetailsHeading","Module Details")}</label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="type"
            placeholder={findDisplayName("featuresUpdateModuleDetailsInputBox","Module Details")}
            autoComplete="off"
            defaultValue={initialModuleDetails}
            {...register("module_details", {})}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex gap-2 justify-content-center mb-3">
          <button
            type="submit"
            className="btn btn-primary"
          >
            {findDisplayName("featuresUpdateUpdateButton ", "Update")}
          </button>
          <button
            type="submit"
            className="btn bg-secondary text-light"
            onClick={() => {
              setActiveComponent("table");
            }}
          >
            {findDisplayName("featuresUpdateCancelButton", "Cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FeaturesUpdate;
