import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import OrganizationList from "../OrganizationList/OrganizationList";
import { orgLocation } from "../../apis/apisServies";
import { setOrgLocation } from "../../Store/actions/orgLocation/orgLocationReducer";
import LocationTable from "./LocationTable/LocationTable";
import { selectOrgLocation } from "../../Store/actions/orgLocation/selectOrgLocation";
import LocationUpdate from "./LocationUpdate/LocationUpdate";
import LocationCreate from "./LocationCreate/LocationCreate";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import { selectOrgTable } from "../../Store/actions/Organizationadmin/OrganizationSelector";
import { findDisplayName, handleCommonSearch, isCreate, isUpdate, isView } from "../../../utils/commonFunctionsAndStatices";
import { setOrganuzationID } from "../../Store/actions/organizationId/organizationIdReducer";
import { selectOrgID } from "../../Store/actions/organizationId/organizationIdSeelct";

const Location = () => {
  const dispatch = useDispatch();
  const orgData = useSelector(selectOrgTable);
  const loginData = useSelector(selectToken);
  const selectOrgId = useSelector(selectOrgID);
  const sortOrgData = orgData && [...orgData].sort((a,b)=>a.name.localeCompare(b.name));
  const organizationid = isView("isListOrganization") ? selectOrgId !== "" ? selectOrgId : sortOrgData[0]?.organizationid : loginData?.organization_id;
  const [orgId, setOrgid] = useState(organizationid);
  const [location, setLocation] = useState(null);
  const [activeComponent, setActiveComponent] = useState("table");
  const locationData = useSelector(selectOrgLocation);
  const [searchList, setSearchList] = useState(locationData);
  const [searchBox, setSearchBox] = useState("");
  const { showError } = useNotification();
  const [activeButton, setActiveButton] = useState("active");

  const handleButtonClick = (status) => {
    setSearchBox("");
    setActiveButton(status);
    fetchLocation(orgId, status);
  };

  const fetchLocation = async (orgValue, active) => {
    setSearchBox("");
    dispatch(setOrganuzationID(orgValue));
    setOrgid(orgValue);
    const datobj = {
      userid: loginData?.login_id,
      isactive: active === "active" ? true : false,
      ...(orgValue !== "" && { organizationid: orgValue }),
    };

    try {
      const response = await orgLocation(datobj);
      if (response?.data.statusCode === 200) {
        const setUserData = response?.data?.body?.data;
        setSearchList(setUserData);
        dispatch(setOrgLocation(setUserData));
      } else if (response?.data.statusCode === 400){
        setSearchList([]);
        dispatch(setOrgLocation([]));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOrganizationChange = (organizationid) => {
    fetchLocation(organizationid,activeButton);
  };

  const handleSearch = (search) => {
    setSearchBox(search);
    const filterData = handleCommonSearch(search, locationData, "locationname");
    setSearchList(filterData);
  };

  useEffect(() => {
    fetchLocation(orgId, "active");
  }, []);

  return (
    <>
      <nav className="nav-dashboard">
        <strong className="links">
          {" "}
          {findDisplayName("locationHeading", "Location")}
        </strong>
        {activeComponent === "table" && isCreate("isDashboardLocationName") && (
          <button
            type="button"
            className="btn btn-primary"
            id="create-location-button"
            onClick={() => setActiveComponent("create")}
            disabled={orgId === "" || orgId === null}
          >
            {findDisplayName("locationCreateUserButton", "Create")}
          </button>
        )}
      </nav>
      {activeComponent === "table" && isView("isListOrganization") && (
        <div className="form-group col-lg-4 col-md-5 col-sm-10 col-xs-10 ps-3 pe-5 mt-md-0 mt-2">
          <OrganizationList
            handleChange={handleOrganizationChange}
            initialvalue={orgId}
            organizatonInUser={false}
          />
        </div>
      )}
      {activeComponent === "table" && (
        <>
          <div className="d-flex justify-content-between ">
          
            <div
                  className="btn-group ms-3 mt-3"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <button
                    className={`btn toggle-button ${
                      activeButton === "active" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("active")}
                  >
                    {findDisplayName("locationActiveButton", "Active")}
                  </button>
                  <button
                    className={`btn toggle-button ${
                      activeButton === "inactive" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("inactive")}
                  >
                    {findDisplayName("locationInactiveButton", "Inactive")}
                  </button>
            </div>
            <div className="mt-3 ms-2 me-2 ms-auto">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={findDisplayName(
                        "locationSearchInputBox",
                        "Vessel Name"
                      )}
                      value={searchBox}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <span className="input-group-text">
                      <svg
                        className="bi bi-search"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                          clipRule="evenodd"
                        />

                        <path
                          fillRule="evenodd"
                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
            </div>
          </div>
          <hr className="text-white mt-0 mb-0 ms-3 me-3" />         
          <LocationTable
            locationData={searchList}
            setActiveComponent={setActiveComponent}
            setLocation={setLocation}
            fetchLocation={fetchLocation}
            activeButton={activeButton}
          />
        </>
      )}
      {activeComponent === "create" &&  isCreate("isDashboardLocationName") && (
        <LocationCreate
          setActiveComponent={setActiveComponent}
          fetchLocation={fetchLocation}
          organizationId={orgId}
          activeButton={activeButton}
        />
      )}
      {activeComponent === "update" && isUpdate("isDashboardLocationName") && (
        <LocationUpdate
          location={location}
          setActiveComponent={setActiveComponent}
          fetchLocation={fetchLocation}
          activeButton={activeButton}
        />
      )}
    </>
  );
};

export default Location;
