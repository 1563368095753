import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ordId: "",
};

const createOrganizationSlice = createSlice({
  name: "OrgID",
  initialState,
  reducers: {
    setOrganuzationID: (state, action) => {
      state.ordId = action.payload;
    },
    clearOrganixationID: (state) => {
      state.ordId = "";
    },
  },
});

export const { setOrganuzationID, clearOrganixationID } =
  createOrganizationSlice.actions;
export default createOrganizationSlice.reducer;
