import React from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Webcam from "react-webcam";
import { createWorker } from "tesseract.js";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { findDisplayName } from "../../../../utils/commonFunctionsAndStatices";

const OCRProcess = ({ closeModal, isModalOpen }) => {
  const { showSuccess, showError } = useNotification();
  const webCamRef = useRef();
  const [image, setImage] = useState(null);
  const [text, setText] = useState("");
  const [isProcessing, setIsProcessing] = useState(true);
  const [webcamAvailable, setWebcamAvailable] = useState(true);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setWebcamAvailable(true);
      })
      .catch((error) => {
        setWebcamAvailable(false);
        showError("Webcam not available");
      });
  }, [webcamAvailable]);

  const capture = useCallback(async () => {
    setText("");
    if (webCamRef.current) {
      const captureImg = await webCamRef.current.getScreenshot();
      //   setImage(captureImg);
      //   doOCR(captureImg);
      const processedImage = await preprocessImage(captureImg);
      setImage(processedImage);
      doOCR(processedImage);
    }
  }, [webCamRef]);

  const preprocessImage = (imageData) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    return new Promise((resolve) => {
      img.onload = () => {
        // Higher quality resize
        const targetWidth = 800;
        const targetHeight = (img.height / img.width) * targetWidth;

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        ctx.imageSmoothingQuality = "high"; // Set high-quality resizing
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

        // Convert to grayscale
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          const brightness = (data[i] + data[i + 1] + data[i + 2]) / 3;
          data[i] = brightness;
          data[i + 1] = brightness;
          data[i + 2] = brightness;
        }
        ctx.putImageData(imageData, 0, 0);

        // Get the processed image as data URL
        const processedImage = canvas.toDataURL("image/jpeg");

        resolve(processedImage);
      };

      img.src = imageData;
    });
  };

  const doOCR = async (image) => {
    setIsProcessing(false);

    const worker = await createWorker({
      logger: (m) => console.log(m),
    });

    (async () => {
      await worker.loadLanguage("eng");
      await worker.initialize("eng");
        // const {
        //   data: { text },
        // } = await worker.recognize(image);
      const {
        data: { text },
      } = await worker.recognize(image, {
        oem: 1,
        psm: 3,
        config: {
          tessedit_char_whitelist:
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
          tessedit_minimal_confidence: 70,
        },
      });
      setIsProcessing(true);
      setText(text);
      await worker.terminate();
    })();
  };

  return (
    <>
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        role="dialog"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{findDisplayName("ocrCaptureIDHeading", "Capture ID")}</h5>
            </div>
            <div className="modal-body ">
              <div className="row d-flex">
                <div className="col-md-6 col-sm-6 mt-4 col-xs-6">
                  <div>
                    <Webcam
                      className="left-card"
                      audio={false}
                      screenshotFormat="image/jpeg"
                      ref={webCamRef}
                    />
                  </div>
                  <div className="d-flex justify-content-center capture-btn-margin">
                    <button className="capture-btn" onClick={() => capture()}>
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M6.26245 4.56738V20.5674M13.8872 12.1923H14.6372M13.8872 12.9423H14.6372M5.46245 20.5674H19.0625C20.1826 20.5674 20.7426 20.5674 21.1704 20.3494C21.5468 20.1576 21.8527 19.8517 22.0445 19.4754C22.2625 19.0475 22.2625 18.4875 22.2625 17.3674V7.76738C22.2625 6.64728 22.2625 6.08723 22.0445 5.6594C21.8527 5.28308 21.5468 4.97712 21.1704 4.78537C20.7426 4.56738 20.1826 4.56738 19.0625 4.56738H5.46245C4.34235 4.56738 3.78229 4.56738 3.35447 4.78537C2.97815 4.97712 2.67218 5.28308 2.48044 5.6594C2.26245 6.08723 2.26245 6.64728 2.26245 7.76738V17.3674C2.26245 18.4875 2.26245 19.0475 2.48044 19.4754C2.67218 19.8517 2.97815 20.1576 3.35447 20.3494C3.78229 20.5674 4.34235 20.5674 5.46245 20.5674ZM19.2625 12.5674C19.2625 15.3288 17.0239 17.5674 14.2625 17.5674C11.501 17.5674 9.26245 15.3288 9.26245 12.5674C9.26245 9.80596 11.501 7.56738 14.2625 7.56738C17.0239 7.56738 19.2625 9.80596 19.2625 12.5674ZM15.2625 12.5674C15.2625 13.1197 14.8147 13.5674 14.2625 13.5674C13.7102 13.5674 13.2625 13.1197 13.2625 12.5674C13.2625 12.0151 13.7102 11.5674 14.2625 11.5674C14.8147 11.5674 15.2625 12.0151 15.2625 12.5674Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                      {findDisplayName("ocrCaptureButton", "Capture")}
                      <span></span>
                    </button>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 mt-4 col-xs-6">
                  {isProcessing ? (
                    ""
                  ) : (
                      <div className="ms-1">{findDisplayName("ocrWaitingText", "Loading please wait. . .")}</div>
                  )}
                  {text && <div>{text}</div>}
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 pt-2">
                <button
                  className="btn btn-primary"
                  onClick={() => closeModal()}
                >
                  {findDisplayName("ocrUpdateButton", "Update")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OCRProcess;
