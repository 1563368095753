import React from 'react'
import { useState } from 'react';
import { selectVisitorType } from '../../Store/actions/VisitorTypesTable/VisitorTypesTableSelector';
import { useDispatch, useSelector } from 'react-redux';
import OrganizationList from '../OrganizationList/OrganizationList';
import VisitorTable from './VisitorTable/VisitorTable';
import { selectToken } from '../../Store/actions/auth/authSelectors';
import VisitorEdit from './VisitorEdit/VisitorEdit';
import { VisitorTypeAPI } from '../../apis/apisServies';
import { setVisitorTable } from '../../Store/actions/VisitorTypesTable/VisitorTypesTableReducer';
import VisitorCreate from './VisitorCreate/VisitorCreate';
import { useEffect } from 'react';
import { selectOrgTable } from '../../Store/actions/Organizationadmin/OrganizationSelector';
import { findDisplayName, handleCommonSearch, isCreate, isUpdate, isView } from '../../../utils/commonFunctionsAndStatices';
import { languageid } from '../../Store/actions/languageId/selectLanguageId';
import { useNotification } from '../../NotificationProvider/NotificationProvider';
import { selectOrgID } from '../../Store/actions/organizationId/organizationIdSeelct';
import { setOrganuzationID } from '../../Store/actions/organizationId/organizationIdReducer';

const Visitor = () => {

    const orgData = useSelector(selectOrgTable);
    const loginData = useSelector(selectToken);
    const selectOrgId = useSelector(selectOrgID);
    const sortOrgData = orgData && [...orgData].sort((a, b) => a.name.localeCompare(b.name));
    const organizationid = isView("isListOrganization") ? selectOrgId !== "" ? selectOrgId : sortOrgData[0]?.organizationid : loginData?.organization_id;
    const [orgid, setOrgid] = useState(organizationid);
    const dispatch = useDispatch()
    const [Visitor, setVisitor] = useState(null)
    const VisitorData = useSelector(selectVisitorType);
    const [activeComponent, setActiveComponent] = useState('table');
    const [ searchList, setSearchList]= useState(VisitorData);
    const [searchBox, setSearchBox] = useState("");
    const languageId = useSelector(languageid);
    const [activeButton, setActiveButton] = useState("active");

    const handleButtonClick = (status) => {
        setSearchBox("");
        setActiveButton(status);
        fetchdata(orgid, status);
    };

    const fetchdata = async (orgValue, status) => {
        setSearchBox("");
        dispatch(setOrganuzationID(orgValue));
        setOrgid(orgValue);
        if (orgid !== "") {
            const datobj = {
                userid: loginData?.login_id,
                organization_id: orgValue,
                isactive: status === "active" ? true : false,
                text_message: {
                    language_id: languageId,
                    ...(loginData?.organization_id ? { organization_id: loginData?.organization_id } : {}),
                }
            };

            try {
                const response = await VisitorTypeAPI(datobj);
                if (response.data.statusCode === 200) {
                    const setVisitorData = response?.data?.body?.data;
                    setSearchList(setVisitorData);
                    dispatch(setVisitorTable(setVisitorData));
                } else if (response.data.statusCode === 404) {
                    setSearchList([]);
                    dispatch(setVisitorTable([]));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const handleOrganizationChange = (organizationid) => {
        fetchdata(organizationid, activeButton);
    }

    const handleSearch = (search) => {
        setSearchBox(search);
        const filterData = handleCommonSearch(search, VisitorData, "name");
        setSearchList(filterData);
    };

    useEffect(() => {
        fetchdata(orgid, "active");
    }, [])

    return (
      <>
        <nav className="nav-dashboard">
          <strong className="links">
            {findDisplayName("visitorTypeHeading", "Visitor Type")}
          </strong>
          {activeComponent === "table" && isCreate("isDashboardVisitorType") && (
            <button
              type="button"
              className="btn btn-primary"
              id="create-user-button"
              onClick={() => setActiveComponent("create")}
              disabled={isView("isListOrganization") && orgid === ""}
            >
              {findDisplayName(
                "visitorTypeCreateVisitorButton",
                "Create Visitor"
              )}
            </button>
          )}
        </nav>
        {activeComponent === "table" && isView("isListOrganization") && (
          <div className="form-group col-lg-4 col-md-5 col-sm-10 col-xs-10 ps-3 pe-5 mt-md-0 mt-2">
            <OrganizationList
              handleChange={handleOrganizationChange}
              initialvalue={orgid}
              organizatonInUser={false}
            />
          </div>
        )}

        {activeComponent === "table" && isView("isDashboardVisitorType") && (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="btn-group ms-3 mt-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <button
                  className={`btn toggle-button ${
                    activeButton === "active" ? "active" : ""
                  }`}
                  onClick={() => handleButtonClick("active")}
                >
                  {findDisplayName("visitorTypeScreenActiveButton", "Active")}
                </button>
                <button
                  className={`btn toggle-button ${
                    activeButton === "inactive" ? "active" : ""
                  }`}
                  onClick={() => handleButtonClick("inactive")}
                >
                  {findDisplayName(
                    "visitorTypeScreenInactiveButton",
                    "Inactive"
                  )}
                </button>
              </div>
              <div className="mt-3 ms-2 me-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={findDisplayName(
                      "visitorTypeScreeSearchInputBox",
                      "Visitor Type"
                    )}
                    value={searchBox}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <span className="input-group-text">
                    <svg
                      className="bi bi-search"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                        clipRule="evenodd"
                      />

                      <path
                        fillRule="evenodd"
                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <hr className="text-white mt-0 mb-0 ms-3 me-3" />
            <VisitorTable
              VisitorData={searchList}
              setActiveComponent={setActiveComponent}
              setVisitor={setVisitor}
              fetchdata={fetchdata}
              orgid={orgid}
              activeButton={activeButton}
            />
          </>
        )}

        {activeComponent === "create" && isCreate("isDashboardVisitorType") && (
          <VisitorCreate
            setActiveComponent={setActiveComponent}
            fetchdata={fetchdata}
            organizationId={orgid}
            activeButton={activeButton}
          />
        )}

        {activeComponent === "update" && isUpdate("isDashboardVisitorType") && (
          <VisitorEdit
            Visitor={Visitor}
            setActiveComponent={setActiveComponent}
            fetchdata={fetchdata}
            activeButton={activeButton}
          />
        )}
      </>
    );
}

export default Visitor