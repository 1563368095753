import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { CreateFeature } from "../../../apis/apisServies";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import { findDisplayName } from "../../../../utils/commonFunctionsAndStatices";

const FeaturesCreate = ({ setActiveComponent ,featurelistdata ,selectedRoleId}) => {

  const { handleSubmit, register } = useForm();
  const loginData = useSelector(selectToken);
  const { showSuccess, showError } = useNotification();
  const languageId = useSelector(languageid);


  const onSubmit = async (data) => {
    if (!data.module.trim()) {
      showError("Kindly Enter a visitor module");
      return;
    }
    if (!data.type.trim()) {
      showError("Kindly Enter a Type");
      return;
    }
    const dataObject = {
      userid: loginData?.login_id,
      module: data.module.trim(),
      type: data.type.trim(),
      module_details:data.module_details.trim(),
      text_message: {
        language_id: languageId,
      },
    };
    const response = await CreateFeature(dataObject);
    if (response.data.statusCode === 201) {
      showSuccess(response?.data?.body?.message);
      featurelistdata(selectedRoleId);
      setActiveComponent("table");
    } else if (response?.data?.statusCode === 400) {
      showError(response?.data?.body?.message);
    } else if (response?.data?.statusCode === 404) {
      showError(response?.data?.body?.message);
    }
  };

  return (
    <div className="container text-white from-scrol">
      <form
        className="row mt-lg-5  d-flex flex-column align-items-center mt-5 "
        onSubmit={handleSubmit(onSubmit)}
      >         
        <div className="form-group mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8">
          <label htmlFor="moduleName ">{findDisplayName("featuresCreateModuleHeading","Module ")}</label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="moduleName"
            placeholder={findDisplayName("featuresCreateModuleInputBox","Module ")}
            autoComplete="off"
            {...register("module", {})}
          />
        </div>
        <div className="form-group mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8">
          <label htmlFor="type ">{findDisplayName("featuresCreateTypeHeading","Type")}</label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="type"
            placeholder={findDisplayName("featuresCreateTypeInputBox","Type")}
            autoComplete="off"
            {...register("type", {})}
          />
        </div>
        <div className="form-group mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8">
          <label htmlFor="type ">{findDisplayName("featuresCreateModuleDetailsHeading","Module Details")}</label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="type"
            placeholder={findDisplayName("featuresCreateModuleDetailsInputBox","Module Details")}
            autoComplete="off"
            {...register("module_details", {})}
          />
        </div>
        <div className="mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8 d-flex justify-content-center gap-2">
          <button className="btn btn-primary mt-3" type="submit">
          {findDisplayName("featuresCreateCreateButton","Create")}
          </button>
          <button
            className="btn btn-secondary mt-3"
            onClick={() => setActiveComponent("table")}
          >
            {findDisplayName("featuresCreateCancelButton","Cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FeaturesCreate;
