import React, { useEffect, useState } from "react";
import "./OrganizationDetails.css";
import { Outlet } from "react-router-dom";
import HeaderBar from "../Sidebar4/HeaderBar";
import Sidebar from "../Sidebar4/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  MasterLabelAPI,
  organizationApi,
  OrganizationFeaturesList,
} from "../../apis/apisServies";
import { setLanguageId } from "../../Store/actions/languageId/languageIdReducer";
import {
  setFeaturesList,
  setOrganizationData,
} from "../../Store/actions/getorganization/organizationReducer";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { setMasterLabel } from "../../Store/actions/masterLabel/masterLabelReducer";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";

const OrganizationDetails = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const loginData = useSelector(selectToken);
  const LanguageID = useSelector(languageid);
  const dispatch = useDispatch();
  const [labelLanguageID, setLabelLanguageID] = useState("");
  const localLanguageId = localStorage.getItem("language_id");
  const [loading, setLoading] = useState(true);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const masterLabelData = async () => {
    if (!localLanguageId) {
      setLoading(true);
      const dataObj = {
        LanguageId: labelLanguageID,
        OrganizationId: loginData?.organization_id,
      };
      const masterLabel = await MasterLabelAPI(dataObj);
      const data = masterLabel?.data?.body?.data;
      dispatch(setMasterLabel(data));
    }
  };

  useEffect(() => {
    const fetchOrganizationData = async () => {
      const dataObj = {
        organizationid: loginData?.organization_id,
      };
      try {
        const response = await organizationApi(dataObj);
        const organizationData = response?.data?.body;
        const Id_language = response?.data?.body?.default_language;
        setLabelLanguageID(Id_language);
        dispatch(setLanguageId(Id_language));
        dispatch(setOrganizationData(organizationData));
      } catch (error) {}
    };
    if (loginData?.organization_id) {
      fetchOrganizationData();
    }
    const fetchOrganizationFeatures = async () => {
      const dataObj = {
        userid: loginData?.login_id,
        organizationid: loginData?.organization_id,
        text_message: {
          language_id: LanguageID,
          organization_id: loginData?.organization_id,
        },
      };
      try {
        const response = await OrganizationFeaturesList(dataObj);
        if (response?.data?.statusCode === 200) {
          const data = response?.data?.body?.data;
          dispatch(setFeaturesList(data));
        } else {
          setFeaturesList([]);
        }
      } catch (error) {
        setFeaturesList([]);
        // Handle errors here
        console.error("Error fetching organization features:", error);
      }
    };
    fetchOrganizationFeatures();
  }, []);

  useEffect(() => {
    masterLabelData();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [labelLanguageID]);
  return (
    <>
      {loading ? (
        <div
          className="container card d-flex justify-content-center gap-2 align-items-center text-primary text-white"
          style={{
            height: "50vh",
            width: "50vw",
          }}
        >
          <h5>
            {" "}
            <span
              className="spinner-grow spinner-grow-sm text-white pe-1 me-2"
              role="status"
              aria-hidden="true"
            ></span>
            {findDisplayName(
              "VisitorsByOrganizationChartLoading",
              "Loading ..."
            )}
          </h5>
        </div>
      ) : (
        <div className="container dashboard-height">
          <div className="card card-height">
            <div className="row grid-container">
              <div className=" col-xl-3  col-lg-4 col-sm-0 col-xs-0 sidepanel">
                <HeaderBar OpenSidebar={OpenSidebar} />
                <Sidebar
                  openSidebarToggle={openSidebarToggle}
                  OpenSidebar={OpenSidebar}
                />
              </div>

              <div className=" col-xl-9 col-lg-8 col-sm-12 col-xs-12 outlet-height outlet-scrollbar">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrganizationDetails;
