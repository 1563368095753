import axios from "axios";

//* api base url
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_APP = process.env.REACT_APP_BASE_V01_URL

//* apis calling methods using here api like GET, PUT, POST,

export const userLogin = (authRequest) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}login`,
    headers: {
      "content-type": "application/json",
    },
    data: authRequest,
  });
};

//* visitor api call
export const visitorTypeApi = (orgIdData) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}visitor_types`,
    headers: {
      "content-type": "application/json",
    },
    data: orgIdData,
  });
};

//* get country api
export const getCountryData = () => {
  return axios({
    method: "GET",
    url: `${BASE_URL}country`,
    headers: {
      "content-type": "application/json",
    },
  });
};

//* location api call
export const getLocationApi = (orgIdData) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}locations`,
    headers: {
      "content-type": "application/json",
    },
    data: orgIdData,
  });
};
//*checkin api call
export const checkInApi = (formData) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}checkin`,
    headers: {
      "content-type": "application/json",
    },
    data: formData,
  });
};

// user not complete the check in process delete the visitor id 
export const checkInRefuse = (id) =>{
  return axios ({
    method:"DELETE",
    url:`${BASE_URL}checkin/refuse`,
    headers:{
      "Content-Type":"application/json",
    },
    data:id
  })
  }

//*term & condition api call
export const postTermConditionApi = (dataApi) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}terms`,
    headers: {
      "content-type": "application/json",
    },
    data: dataApi,
  });
};

// Check Out Screen Search API
export const checkOutList = (checkOutData) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}checkout/search`,
    headers: {
      "Content-Type": "application/json",
    },
    data: checkOutData,
  });
};

//Profile Screen Data in CheckOut page

export const getProfileData = (id) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}checkout/search/id/${id}`,
    headers: {
      "content-type": "application/json",
    },
  });
};

//Profile checkout confirmation

export const checkOutConfirmation = (finalData) => {
  const visitorId = finalData?.visitorid;
  return axios({
    method: "POST",
    url: `${BASE_URL}checkout/search/id/${visitorId}/confirmation`,
    headers: {
      "Content-Type": "application/json",
    },
    data: finalData,
  });
};


// Organization Api call
export const organizationApi = (id) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}organizations`,
    headers: {
      "content-type": "application/json",
    },
    data: id,
  });
};

// Capture image upload API
export const captureAPI = (formData) => {
  return fetch(`${BASE_URL}object-upload`,{
    method: "POST",
    body: formData,
  });
};

// CSO API
export const CSOAPI = (value) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}get_report`,
    headers: {
      "Content-Type": "application/json",
    },
    data:value,
  })
}

// Location Edit API
export const LocationEditAPI = (value) =>{
  return fetch(`${BASE_URL_APP}locations`,{
    method:"PUT",
    body: value,
  })
}
// Location Create API
export const LocationCreateAPI = (value) =>{
  return fetch(`${BASE_URL_APP}locations`,{
    method:"POST",
    body: value,
  })
}


// Organization get UserTable  API
export const OrganizationUserTable = (userid) =>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}users/list`,
    headers:{
      "Content-Type":"application/json"
    },
    data:userid,
  })
}


// Organization location API 
export const orgLocation = (value) => {
  return axios({
    method: "POST",
    url: `${BASE_URL_APP}locations/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: value,
  });
};

// Organization UserUpdateAPI  
export const UserUpdateAPI = (value) =>{
  return axios({
    method:"PUT",
    url:`${BASE_URL_APP}users`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// Role API get roles Name
export const roleData = (id)=>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}roles/list`,
    headers:{
      "Content-Type":"application/json"
    },
    data:id
  })
}

//Create User API
export const createUser = (dataObj)=>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}users`,
    headers:{
      "Content-Type":"application/json"
    },
    data:dataObj
  })
}

//user location add 
export const userLocationAPI = (value) => {
  return axios({
    method: "POST",
    url: `${BASE_URL_APP}userlocations`,
    headers: {
      "Content-Type": "application/json",
    },
    data:value,
  })
}

// Delete user location Edit 
export const deleteUserLocation = (value)=>{
  return axios({
    method:"PUT",
    url:`${BASE_URL_APP}userlocations`,
    headers: {
      "Content-Type": "application/json",
    },
    data:value,
  })
}

// VisitorTypes API
export const VisitorTypeAPI = (value) =>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}visitortypes/list`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// VisitorTypesEdit API
export const VisitorTypeEditAPI = (value) =>{
  return axios({
    method:"PUT",
    url:`${BASE_URL_APP}visitortypes`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// VisitorTypesCreate API
export const VisitorTypeCreateAPI = (value) =>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}visitortypes`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}
// Language details Get  API

export const LanguageAPI = (value) =>{
  return axios({
    method:"get", 
    url:`${BASE_URL_APP}languages`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// Organization API 
export const OrganizationAPI = (value) =>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}organization/list`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}


// Master label post API
export const MasterLabelAPI = (value) =>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}masterlabels/list`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// Organization Create API
export const OrganizationCreateAPI = (value) =>{
  return fetch(`${BASE_URL_APP}organization`,{
    method:"POST",
    body: value,
  })
}
// Organization Update API
export const OrganizationUpdateAPI = (value) =>{
  return fetch(`${BASE_URL_APP}organization`,{
    method:"PUT",
    body: value,
  })
}
// Master label edit API 
export const MasterLabelEditAPI = (value) =>{
  return axios({
    method:"PUT",
    url:`${BASE_URL_APP}masterlabels`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// Master label edit API 
export const MasterLabelCreateAPI = (value) =>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}masterlabels`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// Field Create API 
export const FieldCreateAPI = (value) =>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}organizationfields`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// Field Data in Table API
export const FieldTableAPI = (value) =>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}organizationfields/list`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

// Field Data  Edit API
export const FieldTableEditAPI = (value) =>{
  return axios({
    method:"PUT",
    url:`${BASE_URL_APP}organizationfields`,
    headers:{
      "Content-Type":"application/json"
    },
    data:value,
  })
}

//Language create API
export const LanguageCreateAPI = (value)=>{
  return axios({
    method: "POST",
    url:`${BASE_URL_APP}languages`,
    headers:{
      "Content-Type":"application/json"
    },
    data: value,
  })
}

//Language create API
export const LanguageEditAPI = (value)=>{
  return axios({
    method: "PUT",
    url:`${BASE_URL_APP}languages`,
    headers:{
      "Content-Type":"application/json"
    },
    data: value,
  })
}

export const Visitor_GETAPI = (value) =>{
  return fetch(`${BASE_URL_APP}get_report`,{
    method:"POST",
    body: value,
  })
}

export const userLogOut = (value)=>{
  return axios({
    method: "POST",
    url:`${BASE_URL_APP}logout`,
    headers:{
      "Content-Type": "application/json"
    },
    data: value,
    })
  }

  //send email api request
  export const passwordRestMailAPI = (value)=>{
    return axios({
      method: "POST",
      url:`${BASE_URL_APP}sendmail`,
      headers:{
        "Content-Type": "application/json"
      },
      data: value,
      })
    }


// Get port Details
export const PortDetails = (dataObj) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}port`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}


//List in Features Table
export const FeaturesList = (dataObj) => {
  return axios({
    method: "POST",
    url: `${BASE_URL_APP}privileges/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

//Permissions
export const Permissions = (dataObj) => {
  return axios({
    method: "PUT",
    url: `${BASE_URL_APP}privileges`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// create Feature details
export const CreateFeature = (dataObj) => {
  return axios({
    method: "POST",
    url:`${BASE_URL_APP}features`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// Update Feature details
export const UpdateFeature = (dataObj) => {
  return axios({
    method: "PUT",
    url:`${BASE_URL_APP}features`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// features list
export const featuresList = (dataObj)=>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}features/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// create features 
export const CreateRole = (dataObj)=>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}roles`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// create features 
export const UpdateRole = (dataObj)=>{
  return axios({
    method:"PUT",
    url:`${BASE_URL_APP}roles`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

//  features List
export const RolesList = (dataObj)=>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}roles/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// Organization features List API
export const OrganizationFeaturesList = (dataObj)=>{
  return axios({
    method:"POST",
    url:`${BASE_URL_APP}organizationprivileges/list`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// Organization Features Edit API

export const OrganizationFeaturesEdit = (dataObj)=>{
  return axios({
    method:"PUT",
    url:`${BASE_URL_APP}organizationprivileges`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// Get auto generate batch number

export const GetAutoBatchNumber = (dataObj)=>{
  return axios({
    method:"POST",
    url:`${BASE_URL}badgenumber`,
    headers: {
      "Content-Type": "application/json",
    },
    data:dataObj,
  })
}

// Visitor log archival API
export const VisitorLogArchiveAPI = (value) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}get-archive-visitor-log`,
    headers: {
      "Content-Type": "application/json",
    },
    data:value,
  })
}