import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Organizationtable:null
}

const OrganizationTableReducer = createSlice({
    name:"OrgTableData",
    initialState,
    reducers:{
        setOrgTable : (state,action)=>{
            state.Organizationtable = action.payload;
        },
        clearOrgTable:(state)=>{
            state.Organizationtable = null
        }
    }
})

export const { setOrgTable, clearOrgTable } = OrganizationTableReducer.actions;
export default OrganizationTableReducer.reducer;