import React from "react";
import formLogo from "../../../Asset/Img/Form-logo.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../Store/actions/auth/authReducer"; // Import the setToken action
import {
  FeaturesList,
  LanguageAPI,
  MasterLabelAPI,
  PortDetails,
  // getCountryData,
  userLogin,
} from "../../apis/apisServies";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import Layout from "../../Layout/Layout";
import { setLanguageArray } from "../../Store/actions/language/LanguageReducer";
import { setMasterLabel } from "../../Store/actions/masterLabel/masterLabelReducer";
import {
  findDisplayName,
  isView,
} from "../../../utils/commonFunctionsAndStatices";
import { useState } from "react";
import { useEffect } from "react";
// import { setgetCountryArray } from "../../Store/actions/getCountry/getCountryReducer";
import { selectlanguageArr } from "../../Store/actions/language/LanguageSelector";
import { setLanguageId } from "../../Store/actions/languageId/languageIdReducer";
import "bootstrap-icons/font/bootstrap-icons.css";

import { Link } from "react-router-dom";
import { setPortData } from "../../Store/actions/portData/portDataReducer";
import { setPrivileges } from "../../Store/actions/privilege/previliegeReducer";
import { setPortId } from "../../Store/actions/portId/portIdReducer";
export const SignInPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { showSuccess, showError } = useNotification();
  const [dataFetched, setDataFetched] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const languageid = useSelector(selectlanguageArr);

  const onSubmit = async (data) => {
    if (!data.email) {
      showError(
        findDisplayName(
          "signInpageEmailValidation",
          "Kindly Enter your Email address"
        )
      );
      return;
    } else if (!data.password) {
      showError(
        findDisplayName(
          "signInPagePasswordValidation",
          "Kindly Enter your Password"
        )
      );
      return;
    } else {
      setLoading(true);
      try {
        const response = await userLogin({
          username: data.email,
          password: data.password,
          text_message: {
            language_id: languageid[0]?.language_id,
          },
        });

        // Assuming your API returns a success property indicating login success
        if (response.data.statusCode === 200) {
          setLoading(true);
          const loginData = response?.data?.body?.data;
          if (loginData) {
            getPrivilege(
              loginData?.login_id,
              loginData?.role_id,
              loginData?.default_port
            );
          }

          if (loginData?.default_port) {
            dispatch(setPortId(loginData?.default_port));
          }
          //* Dispatch the setToken action to store the data in Redux
          dispatch(setToken(loginData));

          // * show popup msg
          showSuccess(response?.data?.body?.message);
          getportdetails();
        } else {
          // Handle login failure (display error message, clear form, etc.)
          setLoading(false);
          showError(response?.data?.body?.message);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error during login:", error);
        // Handle other errors (network issues, server errors, etc.)
        showError("An error occurred during login. Please try again later.");
      }
    }
  };

  const getPrivilege = async (loginId, roleId, port) => {
    const dataObj = {
      userid: loginId,
      roleid: roleId,
      text_message: {
        language_id: languageid?.find(item => item.language_name == "English")?.language_id,
      },
    };
    const response = await FeaturesList(dataObj);
    if (response?.data?.statusCode === 200) {
      const data = response?.data?.body?.data;
      dispatch(setPrivileges(data));
      setTimeout(() => {
        const checkIn = isView("isLandingCheckIn");
        const landingDashboard = isView("isLandingDashboard");
        const landingAnalytical = isView("isLandingAnalytical");

        if (checkIn) {
          if (isView("isPortDetailsForUser") && (!port || port === "")) {
            navigate("/PortDetail");
          } else {
            navigate("/CheckInPage");
          }
        }

      else  if (landingDashboard) {
          navigate("/Organization_details");
        }
       else if (landingAnalytical) {
          navigate("/Dashboard");
        }
        else {
          navigate("/permission");
        }
      }, 400);
    } else {
      setLoading(false);
      navigate("/permission")
      // showError(
      //   findDisplayName(
      //     "userPrivilegeDenied",
      //     "You don't have permission. Please contact your admin for further assistance."
      //   )
      // );
    }
  };

  const getportdetails = async () => {
    const dataObj = {
      display_all_port: "All",
    };
    try {
      const response = await PortDetails(dataObj);
      if (response.data.statusCode === 200) {
        const data = response?.data?.body?.data;
        dispatch(setPortData(data));
      }
    } catch (error) {
      dispatch(setPortData([]));
      console.error("Error fetching port details:", error);
      // You can handle the error in a way that makes sense for your application
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const language = await LanguageAPI();
        const languageId = language?.data?.body?.data;
        dispatch(setLanguageArray(languageId));
        // const languageID = languageId[0]?.language_id;
        // dispatch(setLanguageId(languageID));

        if (language.data.statusCode === 200) {
          const dataObj = {
            LanguageId: languageId?.find(item => item.language_name == "English")?.language_id,
          };
          const masterLabel = await MasterLabelAPI(dataObj);
          const data = masterLabel?.data?.body?.data;
          dispatch(setMasterLabel(data));
          setTimeout(() => {
            setDataFetched(false);
          }, 1000);
        }
      } catch (error) {
        console.error(error);
        setDataFetched(false); // Mark data as fetched even in case of an error
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <div className="card text-white p-3 col-xxl-5 col-xl-5 col-md-7 col-sm-10 col-xs-10">
        <div className="d-flex justify-content-center align-items-center">
          <img src={formLogo} alt="logo" height={65} width={371} />
        </div>

        {dataFetched ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm text-white pe-1"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </div>
        ) : (
          <>
            <span className="h4 d-flex justify-content-center mt-1 mb-1">
              {findDisplayName("signInPageTitle", "Let's Sign In")}
            </span>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-feild p-4">
                <div className="mb-3">
                  <label className="form-label">
                    {findDisplayName(
                      "signInPageEmailAddressHeading",
                      "Email address"
                    )}
                  </label>

                  <input
                    {...register("email")}
                    type="email"
                    className={`form-control ${
                      errors?.email ? "is-invalid" : ""
                    }`}
                    placeholder="Insert your email"
                    onFocus={(event) => {
                      event.target.setAttribute("autoComplete", "off");
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    {findDisplayName("UserPassword", "Password ")}
                  </label>
                  <div className="input-group">
                    <input
                      {...register("password")}
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${
                        errors?.password ? "is-invalid" : ""
                      }`}
                      placeholder="Insert your Password"
                      onFocus={(event) => {
                        event.target.setAttribute("autoComplete", "off");
                      }}
                    />
                    <span className="input-group-text">
                      <i
                        className={`bi bi-eye${showPassword ? "" : "-slash"}`}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 d-flex justify-content-between align-items-center col"></div>
                  <Link
                    to="/forgotPassword"
                    className="text-end text-color col text-decoration-none"
                  >
                    {findDisplayName(
                      "signInPageForgotPassowrdHeading",
                      "Forgot Password ?"
                    )}
                  </Link>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-3 btn-lg"
                  disabled={loading}
                >
                  <b>
                    {" "}
                    {loading ? (
                      "Kindly wait for logging in..."
                    ) : (
                      <>{findDisplayName("ButtonSignIn", "Sign-in")}</>
                    )}
                  </b>
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </Layout>
  );
};
