import React, { createContext, useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";
const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-center", // Set the position to 'top-center'
    });
  };

  const showError = (message) => {
    toast.error(message, {
      position: "top-center", // Set the position to 'top-center'
    });
  };

  return (
    <NotificationContext.Provider value={{ showSuccess, showError }}>
      {children}
      <Toaster />
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
