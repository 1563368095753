import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { useSelector } from "react-redux";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";
import { selectVisitorId } from "../../Store/actions/visitorId/visitorIdSelectors";

const VideoScreen = () => {
  const navigate = useNavigate();
  const [showNextButton, setShowNextButton] = useState(false);
  const videoRef = useRef(null);
  const timerRef = useRef(null);
  const loginData = useSelector(selectToken);
  const visitorId = useSelector(selectVisitorId);
  const startTimeRef = useRef(null);

  const handleChange = () => {
    if(visitorId){
      navigate("/AgreeScreen");
    }
    else{
      navigate("/CheckInPage");
    }
  };

  useEffect(() => {
    const playVideoFromIndexedDB = async () => {
      try {
        const vidoeId = loginData?.organization_id
        const openRequest = indexedDB.open(vidoeId, 1);

        openRequest.onsuccess = (event) => {
          const db = event.target.result;
          const transaction = db.transaction(["videoChunks"], "readonly");
          const chunkStore = transaction.objectStore("videoChunks");

          const chunks = [];

          transaction.oncomplete = () => {
            const blob = new Blob(chunks, { type: "video/mp4" });
            const videoUrl = URL.createObjectURL(blob);

            if (videoRef.current) {
              videoRef.current.src = videoUrl; // Set the video source
            }
          };

          chunkStore.openCursor().onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
              chunks.push(cursor.value.data);
              cursor.continue();
            }
          };
        };
      } catch (error) {
        console.error("Error playing video from IndexedDB:", error);
      }
    };

    // Call the function to play the video from IndexedDB when the component mounts
    playVideoFromIndexedDB();
  }, []);

  useEffect(() => {

    if(visitorId === null){
      setShowNextButton(true);
    }
    if (videoRef.current) {
      videoRef.current.addEventListener("play", () => {
        // Capture the start time when the video is played
        startTimeRef.current = Date.now();

        // Start a timer to check the video's current time
        timerRef.current = setInterval(() => {
          const currentTime = Date.now() - startTimeRef.current;
          if (currentTime >= 30000) {
            setShowNextButton(true);
            clearInterval(timerRef.current);
          }
        }, 1000);
      });
    }

    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  return (
    <Layout>
      <div className="card p-3 col-xxl-6 col-xl-6 col-md-8 col-sm-10 col-xs-10">
        <div className="d-flex justify-content-center">
          <h3 className="text-white watchvideo">{findDisplayName("videoScreenTitle", "Watch Video")} </h3>
        </div>
        <div className="col-12 pt-3">
          <video ref={videoRef} controls className="video-view rounded w-100"></video>
        </div>
        <div className="d-flex justify-content-center mt-4 pt-2">
          {showNextButton && (
            <button className="videocontentbutton" onClick={handleChange}>
             {visitorId === null ?  findDisplayName("videoScreenBackButton", "Back"):findDisplayName("videoScreenNextButton", "Next")}
              {visitorId === null ?
                "" :
                <svg
                  className="ms-1"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.16675 10H15.0001M10.8334 5L15.2442 9.41074C15.5696 9.73618 15.5696 10.2638 15.2442 10.5893L10.8334 15"
                    stroke="black"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                  />
                </svg>
              }

            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default VideoScreen;