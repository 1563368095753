import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organizationData: {},
  features: [],
};

const organizationReducer = createSlice({

  name: "organization",
  initialState,
  reducers: {
    setOrganizationData: (state, action) => {
      state.organizationData = action.payload;
    },
    clearOrganizationData: (state) => {
      state.organizationData = null; 
    },
    setFeaturesList: (state, action) => {
      state.features = action.payload;
    }
  },
});


export const { setOrganizationData , clearOrganizationData, setFeaturesList } = organizationReducer.actions;
export default organizationReducer.reducer;
