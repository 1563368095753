import React, { useEffect, useState } from "react";
import PortSearch from "./PortSearch/PortSearch";
import PortTable from "./PortTable/PortTable";
import { useDispatch } from "react-redux";
import { setFilterPortData } from "../../Store/actions/portData/portDataReducer";
import { PortDetails } from "../../apis/apisServies";

const PortDetail = () => {
const dispatch = useDispatch();
const [loading, setLoading] = useState(true);
const [pageNo, setPageNo] = useState(1);
const [count, setCount] = useState("");
const [currentPage, setCurrentPage] = useState(1);

  const getportdetails = async () => {
    const itemsPerPage = 100;
    const paginationNumber = (pageNo - 1) * itemsPerPage;
    
    let dataObj = {};
    
    if (pageNo === 1) {
      dataObj = {
        pagination_number: "0",
      };
    } else if (pageNo > 1) {
      dataObj = {
        pagination_number: String(paginationNumber),
      };
    }
        
    try {
      const response = await PortDetails(dataObj);
      if(response.data.statusCode === 200){
        setLoading(false);
        const data = response?.data?.body?.data;
        const count = response?.data?.body?.total_count;
        setCount(count);
        dispatch(setFilterPortData(data));
      } else if(response.data.statusCode === 404){
        setCount(1);
        dispatch(setFilterPortData([]));
      }
    } catch (error) {
      setLoading(false);
      dispatch(setFilterPortData([]));
      console.error("Error fetching port details:", error);
      // You can handle the error in a way that makes sense for your application
    }
  };

  useEffect(()=>{
    getportdetails();  
  },[]);
  
  return (
    <div className="container ">
      <PortSearch pageNo={pageNo} setCount={setCount} setLoading={setLoading} setPageNo={setPageNo} setCurrentPage={setCurrentPage}/>
      <PortTable loading={loading} totalCount ={count && count} setPageNo={setPageNo} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
    </div>
  );
};

export default PortDetail;
