import React, { useEffect, useState } from "react";
import {
  findDisplayName,
  isView,
} from "../../../../utils/commonFunctionsAndStatices";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { CreateFeature, CreateRole, RolesList } from "../../../apis/apisServies";

const RoleCreate = ({ setActiveComponent, roleslist, orgid }) => {
  const { handleSubmit, register, setValue, watch } = useForm();

  const loginData = useSelector(selectToken);
  const orgData = useSelector(selectOrgTable);
  const languageId = useSelector(languageid);
  const { showSuccess, showError } = useNotification();
  const [rolelistdata , setRoleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roledata , setRoledata] = useState([]);
  const initialOrganization = orgData?.find(
    (item) => item.organizationid === orgid
  )?.name;

  // let  roleList = [...rolelistdata];
  // setValue("parentrole", roleList[0]?.org_roleid);

  const name = watch("parentrole");

  const handleConfirmClick = async (data) => {
    if (!data.role.trim()) {
      showError("Kindly Enter a Role");
      return;
    }
    const dataObject = {
      userid: loginData?.login_id,
      rolename: data.role.trim(),
      organizationid: isView("isListOrganization")
        ? orgData?.find((item) => item.name === initialOrganization)
            ?.organizationid
        : loginData?.organization_id,
      text_message: {
        language_id: languageId,
      },
    };
    if (data?.parentrole && orgid) {
      dataObject.parentroleid = data?.parentrole;
    }
    setLoading(true);
    const response = await CreateRole(dataObject);
    if (response?.data?.statusCode === 201) {
      showSuccess(response?.data?.body?.message);
      roleslist();
      setActiveComponent("table");
      setLoading(false);
    } else if (response?.data?.statusCode === 400) {
      showError(response?.data?.body?.message);
      setLoading(false);
    } else if (response?.data?.statusCode === 404) {
      showError(response?.data?.body?.message);
      setLoading(false);
    } else if (response?.data?.statusCode === 409) {
      showError(response?.data?.body?.message);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setActiveComponent("table");
  };

  const rolesDatalist = async (orgid) => {
    try {
      const dataObj = {
        organizationid: orgid,
        userid: loginData?.login_id,
        display_all:"all",
        text_message: {
          language_id: languageId,
        }
      };
      if(!orgid && !orgid.trim()){
        dataObj.independent_user = "independent_user";
      }
      const response = await RolesList(dataObj);
      if (response?.data?.statusCode === 200) {
        const datavalue = response?.data?.body?.data;
        setRoleData(datavalue);
      } else{
        setRoleData([]);
      }
    } catch (error) {
      console.error("Error in featurelist:", error);
    }
  };


  useEffect(() => {
    rolesDatalist(orgid);
  }, [orgid]);

  useEffect(() => {
    let roleList = [...rolelistdata]; // Copy the state into a local variable
  
    if (roleList.length > 1) {
      roleList.sort((a, b) => {
        const roleA = a.org_rolename.toLowerCase();
        const roleB = b.org_rolename.toLowerCase();
        return roleA.localeCompare(roleB);
      });
    }
    setRoledata(roleList);
    setValue("parentrole", roleList[0]?.org_roleid); // Update the form value
  }, [rolelistdata]); // Execute this effect when rolelistdata changes
  
  const roleName =
  roledata &&
  roledata?.find((item) => item.org_roleid === name)?.org_rolename;


  return (
    <div className="container  text-white from-scrol">
      <div className="row d-flex flex-column align-items-center mt-5 ">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-8 "></div>
        {initialOrganization && (
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8">
            {isView("isListOrganization") ? (
              <div>
                <label htmlFor="organization">
                  {findDisplayName(
                    "createRoleOrganizationHeading",
                    "Organization"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control  mt-2 "
                  id="Organization"
                  placeholder={findDisplayName(
                    "createRoleOrganizationInputBox",
                    "Organization"
                  )}
                  defaultValue={initialOrganization}
                  {...register("Organization", {})}
                  disabled
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {initialOrganization && roledata.length > 0 && (
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
            <label htmlFor="parentrole">
              {" "}
              {findDisplayName("createRoleParentRoleHeading", "Parent Role")}
            </label>
            <div className="dropdown mt-2">
              <button
                className="bg-white w-100 form-select text-start"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {roleName}
              </button>

              <ul className="dropdown-menu w-100 pb-2">
                {roledata?.map((data) => (
                  <li key={data?.org_roleid}>
                    <a
                      className="dropdown-item"
                      onClick={() => setValue("parentrole", data?.org_roleid)}
                    >
                      {data?.org_rolename}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <label htmlFor="role">
            {findDisplayName("createRoleRoleHeading ", "Role")}
          </label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="role"
            placeholder={findDisplayName("createRoleRoleInputBox", "Role")}
            onFocus={(event) => {
              event.target.setAttribute("autoComplete", "off");
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
            }}
            {...register("role", {})}
          />
        </div>
        <div className="col-lg-6 gap-2 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit((data) => handleConfirmClick(data))}
            disabled={loading}
          >
            {findDisplayName("createRoleCreateButtonHeading ", "Create")}
          </button>
          <button
            className="btn bg-secondary text-light"
            onClick={() => {
              handleCancel();
            }}
          >
            {findDisplayName("createRoleCancelButtonHeading", "Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoleCreate;
