import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { FieldCreateAPI } from "../../../apis/apisServies";
import DynamicFields from "./DynamicFields";
import {
  findDisplayName,
  isView,
} from "../../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";

const Fieldcreate = ({
  setActiveComponent,
  organizationId,
  fetchdata,
  activeButton,
  checkInCount,
  checkOutCount,
  organizationFieldCount
}) => {
  const { handleSubmit, register, setValue, watch } = useForm();
  const [defaultstatus, setDefaultstatus] = useState(false);
  const [selectedFieldType, setSelectedFieldType] = useState("Text");
  const loginData = useSelector(selectToken);
  const orgData = useSelector(selectOrgTable);
  const { showSuccess, showError } = useNotification();
  const languageId = useSelector(languageid);
  const childFormRef = useRef();
  const isBoolean =
    selectedFieldType === "Boolean" ||
    selectedFieldType === "Date" ||
    selectedFieldType === "Time" ||
    selectedFieldType === "Radio" ||
    selectedFieldType === "Select";
  let handleFormSubmit = () => { };
  if (selectedFieldType === "Select" || selectedFieldType === "Radio") {
    handleFormSubmit = () => {
      childFormRef.current.submit();
    };
  }

  const displaygroupValue = watch("displaygroup");
  const formattedValue = displaygroupValue?.charAt(0).toUpperCase() + displaygroupValue?.slice(1).replace(/-/g, ' ');

  const handleChildFormSubmit = (formData) => { };

  const initialOrganization =
    organizationId === ""
      ? ""
      : orgData?.find((item) => item.organizationid === organizationId).name;

  const handleCheckboxChange = (e) => {
    setDefaultstatus(e.target.checked);
  };

  const handleConfirmClick = async (data) => {
    handleFormSubmit();
    const changes = {};
    let childFormData = [];
    if (selectedFieldType === "Select" || selectedFieldType === "Radio") {
      childFormData = childFormRef.current.getFormData();
      changes.items = childFormData.fields;
      if (selectedFieldType === "Radio") {
        changes.items.forEach((item) => {
          item.key = item.value.trim();
        });
      }
      if (selectedFieldType === "Select") {
        const hasMissingKey = changes.items.some((item) => !item.key.trim());
        if (hasMissingKey) {
          showError(
            findDisplayName(
              "fieldCreateKeyValidation",
              "Kindly enter a Key for all items"
            )
          );
          return;
        }
      }
      const hasMissingValue = changes.items.some((item) => !item.value.trim());
      // const hasMissingSortOrder = changes.items.some(
      //   (item) => !item.sort_order
      // );

      if (hasMissingValue) {
        showError(
          findDisplayName(
            "fieldCreateValueValidation",
            "Kindly enter a Value for all items"
          )
        );
        return;
      }
    }

    if (isView("isListOrganization") && activeButton !== "organization-field") {
      changes.organization_id = organizationId;
    } else if (!isView("isListOrganization")) {
      changes.organization_id = loginData?.organization_id;
    } else if (activeButton === "organization-field") {
      changes.organization_detail = "organization_detail"
    }
    if (!data.fieldname.trim()) {
      showError(
        findDisplayName(
          "fieldCreateFieldNameValidation",
          "Kindly Enter a Field Name."
        )
      );
    } else if (!isBoolean && !data.fieldlength.trim()) {
      showError(
        findDisplayName(
          "fieldCreateFieldLengthValidation",
          "Kindly Enter a Field Length"
        )
      );
    } else {
      if (selectedFieldType === "Select" || selectedFieldType === "Radio") {
        childFormData = childFormRef.current.getFormData();
      }
      const dataObj = {
        user_id: loginData?.login_id,
        ...changes,
        display_group: data.displaygroup?.trim(),
        field_name: data.fieldname?.trim(),
        field_type: data.fieldtype?.trim(),
        ...(!isBoolean && { field_length: data.fieldlength?.trim() }),
        sort_order:
        data.displaygroup === "Check-in"
        ? ++checkInCount
        : checkOutCount !== "" 
          ? ++checkOutCount 
          : ++organizationFieldCount,
              text_message: {
          language_id: languageId,
          ...(loginData?.organization_id
            ? { organization_id: loginData?.organization_id }
            : {}),
        },
      };

      if (data.defaultstatus) {
        dataObj.is_default = data.defaultstatus;
      }

      const response = await FieldCreateAPI(dataObj);
      if (response?.data?.statusCode === 201) {
        showSuccess(response?.data?.body?.message);
        fetchdata(organizationId, activeButton);
        setActiveComponent("table");
      } else if (response?.data?.statusCode === 409 || response?.data?.statusCode === 403) {
        showError(response?.data?.body?.message);
      } else if (response?.data?.statusCode === 404) {
        showError(response?.data?.body?.message);
      }
    }
  };
  const shouldRenderAdditionalFields =
    selectedFieldType === "Select" || selectedFieldType === "Radio";

  const handleFieldTypeChange = (e) => {
    setSelectedFieldType(e);
  };

  const handleCancel = () => {
    setActiveComponent("table");
  };

  useEffect(() => {
    if (activeButton !== "organization-field") {
      setValue("displaygroup", "Check-in");
    }
    else {
      setValue("displaygroup", "organization-field");
    }

    setValue("fieldtype", "Text");
  }, []);

  return (
    <>
      <div className="container ">
        <div className="text-white  from-scrol">
          <form className="row field">
            {(isView("isListOrganization") && activeButton !== "organization-field") && (
              <div className="col-lg-4 col-md-8 col-sm-12 col-xs-8 mt-3">
                <label htmlFor="organization">
                  {" "}
                  {findDisplayName(
                    "fieldCreateOraganizationHeading",
                    "Oraganization"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control  mt-2 "
                  id="Organization"
                  defaultValue={initialOrganization}
                  {...register("Organization", {})}
                  disabled
                />
              </div>
            )}

            <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3">
              <label htmlFor="displaygroup " className="">
                {findDisplayName(
                  "fieldCreateDisplayGroupHeading",
                  "Display Group"
                )}
              </label>
              <div className="dropdown mt-2">
                <button
                  className="bg-white form-control form-select text-start"
                  data-bs-toggle="dropdown"
                >
                  {formattedValue}
                </button>

                <ul className="dropdown-menu w-100 pb-2">
                  {
                    activeButton !== "organization-field" && (<>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => setValue("displaygroup", "Check-in")}
                        >
                          {findDisplayName("fieldCreateCheckInSelectOption", "Check-In")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => setValue("displaygroup", "Check-out")}
                        >
                          {findDisplayName("fieldCreateCheckOutSelectOption", "Check Out")}
                        </a>
                      </li>
                    </>)
                  }

                  {activeButton === "organization-field" && (
                    <>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => setValue("displaygroup", "organization-field")}
                        >
                          {findDisplayName("fieldCreateOrganizationFieldSelectOption", "Organization Field")}
                        </a>
                      </li>
                    </>
                  )
                  }
                </ul>
              </div>
            </div>

            <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3">
              <label htmlFor="fieldname">
                {" "}
                {findDisplayName("fieldCreateFieldNameHeading", "Field Name")}
              </label>
              <input
                type="text"
                className="form-control mt-2"
                id="fieldname"
                placeholder={findDisplayName(
                  "fieldCreateFieldNameInputBox",
                  "Field Name"
                )}
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                }}
                {...register("fieldname", {})}
              />
            </div>
            <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3">
              <label htmlFor="Formgroup">
                {" "}
                {findDisplayName("fieldCreateFieldtypeHeading", "Field Type")}
              </label>
              <div className="dropdown mt-2">
                <button
                  className="bg-white form-control form-select text-start"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedFieldType}
                </button>
                <ul
                  className="dropdown-menu w-100 pb-2"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleFieldTypeChange("Text");
                        setValue("fieldtype", "Text");
                      }}
                    >
                      {findDisplayName("fieldCreateTextSelectOption", "Text")}

                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleFieldTypeChange("Number");
                        setValue("fieldtype", "Number");
                      }}
                    >
                      {findDisplayName("fieldCreateNumberSelectOption", "Number")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleFieldTypeChange("Boolean");
                        setValue("fieldtype", "Boolean");
                      }}
                    >
                      {findDisplayName("fieldCreateBooleanSelectOption", "Boolean")}

                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleFieldTypeChange("Date");
                        setValue("fieldtype", "Date");
                      }}
                    >
                      {findDisplayName("fieldCreateDateSelectOption", "Date")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleFieldTypeChange("Select");
                        setValue("fieldtype", "Select");
                      }}
                    >
                      {findDisplayName("fieldCreateSelectSelectOption", "Select")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleFieldTypeChange("Time");
                        setValue("fieldtype", "Time");
                      }}
                    >
                      {findDisplayName("fieldCreateTimeSelectOption", "Time")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleFieldTypeChange("Radio");
                        setValue("fieldtype", "Radio");
                      }}
                    >
                      {findDisplayName("fieldCreateRadioSelectOption", "Ratio")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {!isBoolean && (
              <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3">
                <label htmlFor="fieldlength">
                  {" "}
                  {findDisplayName(
                    "fieldCreateFieldLengthHeading",
                    "Field Length"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  id="fieldlength"
                  placeholder={findDisplayName(
                    "fieldCreateFieldLengthInputBox",
                    "Field Length"
                  )}
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  {...register("fieldlength", {})}
                />
              </div>
            )}
            <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3 mt-lg-5">
              <input
                className="form-check-input"
                type="checkbox"
                id="active"
                defaultChecked={defaultstatus}
                onChange={handleCheckboxChange}
                {...register("defaultstatus", {})}
              />
              <label className="form-check-label ps-2" htmlFor="active">
                {findDisplayName("fieldCreateRepuiredHeading", "Required")}
              </label>
            </div>

            {shouldRenderAdditionalFields && (
              <DynamicFields
                ref={childFormRef}
                onSubmitFromParent={handleChildFormSubmit}
                selectedFieldType={selectedFieldType}
              />
            )}

            <div className="col-lg-12 col-md-12 col-sm-12 mt-4 mt-lg-5 d-flex justify-content-center mb-3">
              <button
                type="submit"
                className="btn btn-primary "
                onClick={handleSubmit((data) => handleConfirmClick(data))}
              >
                {findDisplayName("fieldCreateCreateButton", "Create")}
              </button>
              <button
                className="btn btn-secondary text-light ms-2"
                onClick={() => {
                  handleCancel();
                }}
              >
                {findDisplayName("fieldCreateCancelButton", "Cancel")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Fieldcreate;
