import { Navigate, Outlet } from "react-router-dom";
import { selectToken } from "../Component/Store/actions/auth/authSelectors";
import { useSelector } from "react-redux";
const PrivateRoutes = () => {
  const USER_KEY = useSelector(selectToken);
  let auth = { token: USER_KEY?.login_id };
  return auth.token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
