import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import { captureAPI } from "../../apis/apisServies";
import {  useSelector } from "react-redux";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import Layout from "../../Layout/Layout";
import "./css/style.css";
import { selectVisitorId } from "../../Store/actions/visitorId/visitorIdSelectors";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";
import { selectBypass } from "../../Store/actions/visitorType/visitorTypeSelectors";

const Capture = () => {
  const navigate = useNavigate();
  const webCamRef = useRef();
  const [image, setImage] = useState(null);
  const [webcamAvailable, setWebcamAvailable] = useState(true);
  const { showError } = useNotification();
  const visitorId = useSelector(selectVisitorId);
  const { showSuccess } = useNotification();
  const byPass = useSelector(selectBypass);
  const [loading, setLoading] = useState(false);
  //If visitor id not present navigate to Check in page
  useEffect(() => {
    if (visitorId === null) {
      navigate("/CheckInPage");
    }
  }, [null]);

  useEffect(() => {
    // Check if the webcam is available
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setWebcamAvailable(true);
      })
      .catch((error) => {
        setWebcamAvailable(false);
        showError(
          findDisplayName(
            "CaptureScreenWebCamRequiredErrorMessage",
            "There is no webcam available."
          )
        );
      });
  }, [showError]);
  const storeImageInLocalStorage = (imageData) => {
    localStorage.setItem("capturedImage", JSON.stringify(imageData));
  };
  const capture = useCallback(async () => {
    if (webCamRef.current) {
      const captureImg = await webCamRef.current.getScreenshot();
      setImage(captureImg);
    }
  }, [webCamRef]);

  const handleChange = async (image) => {
    if (image) {
      const blob = dataURItoBlob(image);
      const formData = new FormData();
      // Append the blob data with the key 'file'
      formData.append("file", blob, "image.png");
      // Append the visitor ID
      formData.append("visitorid", visitorId?.trim());
      if (image) {
        // Set loading state to true
        setLoading(true);

        try {
          if (navigator.onLine) {
            const response = await captureAPI(formData);
            if (response?.ok) {
              showSuccess(
                findDisplayName(
                  "CaptureScreenImageSuccessValidation",
                  "Image Captured Successfully."
                )
              );
              if(byPass !== ""){
                const page = byPass  === true ? "/AgreeScreen" : "/VideoScreen";
                navigate(page);
              }
            } else {
              const localimage = storeImageInLocalStorage(image);
              const blob = dataURItoBlob(image);
              const formData2 = new FormData();
              // Append the blob data with the key 'file'
              formData2.append("file", blob, "image.png");
              // Append the visitor ID
              formData2.append("visitorid", visitorId?.trim());
              const secondApiResponse = await captureAPI(formData2);
              if (secondApiResponse?.ok) {
                showSuccess(
                  findDisplayName(
                    "CaptureScreenImageSuccessValidation",
                    "Image Captured Successfully."
                  )
                );
              }
              if(byPass !== ""){
                const page = byPass  === true ? "/AgreeScreen" : "/VideoScreen";
                navigate(page);
              }
            localStorage.removeItem("capturedImage");
            }
          } else {
            var visitor_list = JSON.parse(sessionStorage.getItem("visitor"));
            if (visitor_list) {
              visitor_list[visitorId]["img"] = image;
              sessionStorage.setItem("visitor", JSON.stringify(visitor_list));

              if(byPass !== ""){
                const page = byPass  === true ? "/AgreeScreen" : "/VideoScreen";
                navigate(page);
              }
            }
          }
        } catch (error) {
          const localimage = storeImageInLocalStorage(image);
          const blob = dataURItoBlob(image);
          const formData2 = new FormData();
          // Append the blob data with the key 'file'
          formData2.append("file", blob, "image.png");
          // Append the visitor ID
          formData2.append("visitorid", visitorId?.trim());
          const secondApiResponse = await captureAPI(formData2);
          if (secondApiResponse?.ok) {
            showSuccess(
              findDisplayName(
                "CaptureScreenImageSuccessValidation",
                "Image Captured Successfully."
              )
            );
          }
          if(byPass !== ""){
            const page = byPass  === true ? "/AgreeScreen" : "/VideoScreen";
            navigate(page);
          }
        localStorage.removeItem("capturedImage");
        } finally {
          if(navigator.onLine){
            // Set loading state back to false after the operation is complete
            const localimage = storeImageInLocalStorage(image);
            const blob = dataURItoBlob(image);
            const formData2 = new FormData();
            // Append the blob data with the key 'file'
            formData2.append("file", blob, "image.png");
            // Append the visitor ID
            formData2.append("visitorid", visitorId?.trim());
            const secondApiResponse = await captureAPI(formData2);
            if(byPass !== ""){
              const page = byPass  === true ? "/AgreeScreen" : "/VideoScreen";
              navigate(page);
            }
            localStorage.removeItem("capturedImage");
            // Set loading state back to false after the operation is complete
            setLoading(false);
          }
          setLoading(false);
        }
      }
    } else {
      showError(
        findDisplayName(
          "CaptureScreenImageRequiredErrorMessage",
          "Kindly take a snapshot."
        )
      );
    }
  };

  const dataURItoBlob = (dataURI) => {
    if (!dataURI) {
      return null;
    }
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const byteArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  };
  return (
    <Layout>
      <div className="card p-3 justify-content-center align-items-center  mb-2">
        <span className="h4 d-flex justify-content-center mt-1 mb-1 text-white text-center">
          {findDisplayName(
            "captureScreenTitle",
            "Please take a picture to identify you"
          )}
          <br />
        </span>

        <div className="row mt-4 camSec">
          <div className="col-md-6 col-sm-6 mt-4 col-xs-6">
            <div>
              <Webcam
                className="left-card"
                audio={false}
                screenshotFormat="image/jpeg"
                ref={webCamRef}
              />
            </div>
            <div className="d-flex justify-content-center capture-btn-margin">
              <button className="capture-btn" onClick={capture}>
                {" "}
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-2"
                >
                  <path
                    d="M6.26245 4.56738V20.5674M13.8872 12.1923H14.6372M13.8872 12.9423H14.6372M5.46245 20.5674H19.0625C20.1826 20.5674 20.7426 20.5674 21.1704 20.3494C21.5468 20.1576 21.8527 19.8517 22.0445 19.4754C22.2625 19.0475 22.2625 18.4875 22.2625 17.3674V7.76738C22.2625 6.64728 22.2625 6.08723 22.0445 5.6594C21.8527 5.28308 21.5468 4.97712 21.1704 4.78537C20.7426 4.56738 20.1826 4.56738 19.0625 4.56738H5.46245C4.34235 4.56738 3.78229 4.56738 3.35447 4.78537C2.97815 4.97712 2.67218 5.28308 2.48044 5.6594C2.26245 6.08723 2.26245 6.64728 2.26245 7.76738V17.3674C2.26245 18.4875 2.26245 19.0475 2.48044 19.4754C2.67218 19.8517 2.97815 20.1576 3.35447 20.3494C3.78229 20.5674 4.34235 20.5674 5.46245 20.5674ZM19.2625 12.5674C19.2625 15.3288 17.0239 17.5674 14.2625 17.5674C11.501 17.5674 9.26245 15.3288 9.26245 12.5674C9.26245 9.80596 11.501 7.56738 14.2625 7.56738C17.0239 7.56738 19.2625 9.80596 19.2625 12.5674ZM15.2625 12.5674C15.2625 13.1197 14.8147 13.5674 14.2625 13.5674C13.7102 13.5674 13.2625 13.1197 13.2625 12.5674C13.2625 12.0151 13.7102 11.5674 14.2625 11.5674C14.8147 11.5674 15.2625 12.0151 15.2625 12.5674Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <span>
                  {" "}
                  {findDisplayName(
                    "captureScreenTakePhotoButton",
                    "Take Photo"
                  )}
                </span>
              </button>
            </div>
          </div>

          <div className="col-md-6 col-sm-6  mt-4 col-xs-6">
            {image ? (
              <img src={image} className="left-card" alt="webCam" />
            ) : (
              <div className="left-card before-capture"> </div>
            )}
          </div>
          <div className="d-flex justify-content-center mt-4 pt-2">
            <button
              className="btn btn-primary"
              onClick={() => handleChange(image)}
              disabled={loading}
            >
              {" "}
              {loading ? (
                "Loading..."
              ) : (
                <>
                  {findDisplayName("captureScreenNextButton", "Next")}
                  <svg
                    className="ms-1"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16675 10H15.0001M10.8334 5L15.2442 9.41074C15.5696 9.73618 15.5696 10.2638 15.2442 10.5893L10.8334 15"
                      stroke="black"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Capture;
