import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { useSelector } from "react-redux";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import DataNotFound from "../../DataNotFound/DataNotFound";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";
const EmptyLegend = () => null;
const DashboardChart5 = ({ data, locationList, loading }) => {
  const loginData = useSelector(selectToken);
  const orgData = useSelector(selectOrgTable);
  const [showSelectIcon, setShowSelectIcon] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    isView("isListOrganization") ? orgData[0]?.organizationid : ""
  );
  const [displayName, setDisplayName] = useState(
    isView("isListOrganization") ? orgData[0]?.name : ""
  );

const sortOrgData = orgData && [...orgData].sort((a,b)=> a.name.localeCompare(b.name));

  const handleSvgClick = () => {
    setShowSelectIcon((prev) => !prev);
  };
  const handleOptionClick = (organizationId, organizationName) => {
    setDisplayName(organizationName);
    setSelectedOption(organizationId);
    setShowSelectIcon(false);
  };

  const orgLocationData = data.filter(
    (item) => item.organizationid === selectedOption
  );

  const locationData =
    isView("isListOrganization") ? orgLocationData : data;

  const separateByLocation = (locationData) => {
    const separatedData = {};
    locationData.forEach((item) => {
      const locationId = item.locationid;
      if (!separatedData[locationId]) {
        separatedData[locationId] = [];
      }
      separatedData[locationId].push(item);
    });
    return separatedData;
  };

  function findLocationById(id) {
    const foundItem = locationList?.find((item) => item.locationid === id);
    return foundItem ? foundItem.locationname : null;
  }

  function convertLocationIdsToNames(separatedData) {
    const convertedData = {};
    for (const [locationId, data] of Object.entries(separatedData)) {
      const locactionName = findLocationById(locationId);
      convertedData[locactionName || locationId] = data.map((entry) => ({
        ...entry,
        locationId: locactionName || locationId,
      }));
    }
    return convertedData;
  }

  const dataSet = separateByLocation(locationData);

  const convertedVisitorTypeData = convertLocationIdsToNames(dataSet);
  const chartData = Object.entries(convertedVisitorTypeData).map(
    ([Name, Data]) => ({
      name: Name,
      "Visitor Type": Data.length,
    })
  );
  chartData.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="row mt-4 ">
      <div className="bg-light rounded col-12 p-3">
        <div className="row">
          <div
            className={`  ${
              isView("isListOrganization")
                ? "col-lg-8 col-md-7 col-xxl-8 col-7"
                : "col-lg-8 col-md-7 col-xxl-8 col-7 "
            }`}
          >
            <strong className="text-dark ms-3 mt-xxl-4 mt-lg-2 d-xxl-flex align-items-xxl-center">
              {findDisplayName("visitorsByVesselHeading", "Visitors By Vessel")}
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ms-2 d-none d-xxl-block d-lg-block d-xl-block"
              >
                <title>
                  {findDisplayName(
                    "visitorsByVesselToolTip",
                    "To get insight on distribution of Visitors in each vessel"
                  )}
                </title>

                <circle cx="12.5508" cy="12.5" r="12" fill="#BDBDBD" />
                <rect
                  x="10.5508"
                  y="9.83331"
                  width="3.99999"
                  height="10.6666"
                  rx="2"
                  fill="white"
                />
                <rect
                  x="10.5508"
                  y="4.5"
                  width="3.99999"
                  height="3.99999"
                  rx="2"
                  fill="white"
                />
              </svg>
            </strong>
          </div>
          <div className="col-lg-4 col-md-5 col-xxl-4 col-5">
            {isView("isListOrganization") && (
              <>
                <div className="d-flex justify-content-end">
                  <span className="bg-dark text-white p-2 rounded selected-text-size text-wrap">
                    {displayName}
                  </span>

                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-2"
                    onClick={handleSvgClick}
                  >
                    <path
                      d="M3.07031 16.5705H17.2589H3.07031Z"
                      fill="#BDBDBD"
                    />
                    <path
                      d="M3.07031 5.33984H17.2589M3.07031 10.9552H17.2589M3.07031 16.5705H17.2589"
                      stroke="#BDBDBD"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>

                {showSelectIcon && (
                  <div className=" row mt-1 position-relative ">
                    <div
                      className=" position-absolute col-12 selected-icon "
                      aria-label="Default select example"
                      style={{ zIndex: 4 }}
                    >
                      <div
                        className="list-group position-relative row"
                        id="list-tab"
                        role="tablist"
                      >
                        {sortOrgData.map((organization) => (
                          <a
                            key={organization.organizationid}
                            className={`list-group-item list-group-item-action col-10 ${
                              organization.organizationid === selectedOption
                                ? "active"
                                : ""
                            }`}
                            data-bs-toggle="list"
                            href={`#${organization.organizationid}`}
                            role="tab"
                            aria-controls={organization.organizationid}
                            onClick={() =>
                              handleOptionClick(
                                organization.organizationid,
                                organization.name
                              )
                            }
                          >
                            {organization.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <hr
          className={` text-secondary ${
            isView("isListOrganization")
              ? "mt-xxl-5 mt-lg-4 mt-md-5 pb-md-4"
              : "mt-4  mt-md-5 pt-md-0 pb-md-0 pt-lg-3 pb-lg-0 pb-lg-0 mt-xxl-5 pt-xxl-0 pb-xxl-0 mt-lg-5"
          }`}
        />
        <div className="row">
          <div className="col-12 d-flex justify-content-center pb-xxl-3 ">
            {loading ? (
              <div
                className="d-flex justify-content-center gap-2 text-primary align-items-center"
                style={{
                  height: "400px",
                }}
              >
                <span
                  className="spinner-grow spinner-grow-sm text-primary pe-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                {findDisplayName(
                  "VisitorsByOrganizationChartLoading",
                  "Loading ..."
                )}
              </div>
            ) : chartData.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
              <BarChart width="95%" height={400} data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" angle={-25} textAnchor="end" fontSize={10} />
                <YAxis />
                <Tooltip />
                <Legend content={<EmptyLegend />} />
                <Bar dataKey="Visitor Type" fill="#165BAA" barSize={7} />
              </BarChart>
              </ResponsiveContainer>
            ) : (
              <div
                className={` ${
                  showSelectIcon
                    ? "d-flex justify-content-center align-items-center mt-0"
                    : ""
                }`}
                style={{
                  height: "400px",
                 
                }}
              >
                {chartData.length === 0 && !showSelectIcon ? (
                  <div className="text-center ">
                    <DataNotFound />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardChart5;
