import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DataNotFound from "../../DataNotFound/DataNotFound";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { useSelector } from "react-redux";

const DashboardChart7 = ({ data, loading }) => {
  const [showSelectIcon, setShowSelectIcon] = useState(false);
  const [selectedOption, setSelectedOption] = useState("This Year");
  const [activeOption, setActiveOption] = useState("This Year");  
  const loginData = useSelector(selectToken);
  
  const handleSvgClick = () => {
    setShowSelectIcon((prev) => !prev);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setActiveOption(option);
    setShowSelectIcon(false);
  };
  function filterDataByTimeInterval(dataList, timeInterval) {
    const currentDate = new Date();
    let startDate;
  
    switch (timeInterval) {
      case "This Week":
        startDate = new Date(currentDate);
        startDate.setUTCDate(
          currentDate.getUTCDate() - currentDate.getUTCDay()
        );
        break;
  
      case "This Month":
        startDate = new Date(currentDate);
        startDate.setUTCDate(1);
        break;
  
      case "This Year":
        startDate = new Date(currentDate);
        startDate.setUTCMonth(0, 1);
        break;
  
      default:
        return { dataList: [], label: "Invalid Interval" };
    }
  
    const aggregatedData = dataList.reduce((result, item) => {
      const checkInDate = new Date(item.CheckIn);
      let label;
  
      if (checkInDate >= startDate && checkInDate <= currentDate) {
        if (timeInterval === "This Week") {
          label = new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(
            checkInDate
          );
        } else if (timeInterval === "This Month") {
          const dayName = new Intl.DateTimeFormat("en-US", {
            weekday: "short",
          }).format(checkInDate);
          const dayOfMonth = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
          }).format(checkInDate);
          label = `${dayName} ${dayOfMonth}`;
        } else if (timeInterval === "This Year") {
          label = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
            checkInDate
          );
        }
  
        if (!result[label]) {
          result[label] = {
            Visitors: 0,
          };
        }
  
        result[label].Visitors += 1;
      }
  
      return result;
    }, {});
  
    const monthOrder = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    
    const aggregatedArray = Object.keys(aggregatedData)
      .map((label) => ({
        label,
        Visitors: aggregatedData[label].Visitors,
        monthIndex: aggregatedData[label].monthIndex,
      }))
      .sort((a, b) => monthOrder.indexOf(a.label) - monthOrder.indexOf(b.label));
  
    return { dataList: aggregatedArray };
  }
  

  const filterData = filterDataByTimeInterval(data, selectedOption);

  return (
    <div className="row mt-4">
      <div className= {`bg-light rounded col-12   p-3 ${isView("isListOrganization") ? 'pb-xxl-3 pb-lg-0' : 'p-3 pb-xxl-3 pb-md-1 pb-lg-3 '}`}>
        <div className="row">
          <div className="col-lg-8 col-md-6 col-xxl-8 col-7 ">
            <strong className="text-dark ms-3 mt-xxl-2 d-xxl-flex align-items-xxl-center">
              {findDisplayName("VisitorsByDateHeading", "Visitors by Date")}
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ms-2 d-none d-xxl-block d-lg-block d-xl-block"
              >
                <title>
                  {findDisplayName(
                    "VisitorsByDateToolTipHeading",
                    " To get insights on Number of visitors on each date."
                  )}
                </title>
                <circle cx="12.5508" cy="12.5" r="12" fill="#BDBDBD" />
                <rect
                  x="10.5508"
                  y="9.83331"
                  width="3.99999"
                  height="10.6666"
                  rx="2"
                  fill="white"
                />
                <rect
                  x="10.5508"
                  y="4.5"
                  width="3.99999"
                  height="3.99999"
                  rx="2"
                  fill="white"
                />
              </svg>
            </strong>
          </div>
          <div className={`col-lg-4 col-md-6 col-xxl-4 col-5 ${isView("isListOrganization") ? '' : 'mt-xxl-3  pb-md-2 p-lg-0 mt-lg-0'}`}>
            <>
              <div className="d-flex justify-content-end">
                <span className="bg-dark text-white p-2 rounded selected-text-size text-wrap">
                  {selectedOption}
                </span>

                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ms-2"
                  onClick={handleSvgClick}
                >
                  <path d="M3.07031 16.5705H17.2589H3.07031Z" fill="#BDBDBD" />
                  <path
                    d="M3.07031 5.33984H17.2589M3.07031 10.9552H17.2589M3.07031 16.5705H17.2589"
                    stroke="#BDBDBD"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              {showSelectIcon && (
                <div className=" row mt-1 position-relative ">
                  <div
                    className=" position-absolute col-12 selected-icon "
                    aria-label="Default select example"
                    style={{ zIndex: 4 }}
                  >
                    <div
                      className="list-group position-relative  row"
                      id="list-tab"
                      role="tablist"
                    >
                      <a
                        className={`list-group-item list-group-item-action col-10 ${
                          activeOption === "This Year" ? "active" : ""
                        }`}
                        id="list-settings-list"
                        data-bs-toggle="list"
                        href="#list-settings"
                        role="tab"
                        aria-controls="list-settings"
                        onClick={() => handleOptionClick("This Year")}
                      >
                        {findDisplayName(
                          "VisitorsByDateThisYearSelect",
                          " This Year"
                        )}
                      </a>
                      
                      <a
                        className={`list-group-item list-group-item-action col-10 ${
                          activeOption === "This Month" ? "active" : ""
                        }`}
                        id="list-messages-list"
                        data-bs-toggle="list"
                        href="#list-messages"
                        role="tab"
                        aria-controls="list-messages"
                        onClick={() => handleOptionClick("This Month")}
                      >
                        {findDisplayName(
                          "VisitorsByDateThisMonthSelect",
                          "This Month"
                        )}
                      </a>
                      <a
                        className={`list-group-item list-group-item-action col-10 ${
                          activeOption === "This Week" ? "active" : ""
                        }`}
                        id="list-profile-list"
                        data-bs-toggle="list"
                        href="#list-profile"
                        role="tab"
                        aria-controls="list-profile"
                        onClick={() => handleOptionClick("This Week")}
                      >
                        {findDisplayName(
                          "VisitorsByDateThisWeekSelect",
                          "This Week"
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>

        <hr  className={`text-secondary  ${isView("isListOrganization") ? ' mt-lg-5 pb-md-0 mt-xxl-4 pt-xxl-3 pb-lg-3 ' : ' mt-lg-5 pt-lg-3 mt-md-4'}`}/>
        <div className="row">
          <div className="col-12 d-flex justify-content-center mt-lg-0 pb-lg-0 pt-xxl-0 pb-md-3 mt-md-4">
            {
              loading ? (
                <div
                  className="d-flex justify-content-center gap-2 text-primary align-items-center"
                  style={{
                    height: "400px",
                  }}
                >
                  <span
                    className="spinner-grow spinner-grow-sm text-primary pe-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {findDisplayName(
                    "VisitorsByOrganizationChartLoading",
                    "Loading ..."
                  )}
                </div>
              ) : 
            filterData.dataList.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  width="95%"
                  height={400}
                  data={filterData.dataList}
                  layout="vertical"
                >
                  <XAxis type="number" fontSize={12}/>
                  <YAxis type="category" dataKey="label" barSize={7} />
                  <Tooltip 
                   contentStyle={{
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    // Add mobile-specific content styles here if needed
                  }}
                  />
                  <Legend />
                  <Bar dataKey="Visitors" fill="#091B21" barSize={7} />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div
                className={` ${
                  showSelectIcon
                    ? "d-flex justify-content-center align-items-center"
                    : ""
                }`}
                style={{
                  height: "400px",
                 
                }}
              >
                {filterData.dataList.length === 0 && !showSelectIcon ? (
                  <div className="text-center ">
                    <DataNotFound />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardChart7;
