import React from "react";
import { useForm } from "react-hook-form";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { LanguageEditAPI } from "../../../apis/apisServies";
import { useState } from "react";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import { findDisplayName } from "../../../../utils/commonFunctionsAndStatices";

const MasterLanguageEdit = ({
  setActiveComponent,
  fetchLanguage,
  languageData,
}) => {
  const loginData = useSelector(selectToken);
  const { showSuccess, showError } = useNotification();
  const { handleSubmit, register } = useForm();
  const languageId = useSelector(languageid);
  const [disabled, setDisabled] = useState(false);

  const initialName = languageData?.language_name?.trim();
  const initialIsDefault = languageData?.is_default;

  const handleConfirmClick = async (data) => {
    if (data.language === "" || !data.language.trim()) {
      showError(
        findDisplayName(
          "languageEditScreenEmptyValidation",
          "Language field is not filled up"
        )
      );
      return;
    }
    const changes = {};
    if (data.language?.trim() !== initialName) {
      changes.languagename = data.language?.trim();
    }
    if (data.is_default!== languageData?.is_default) {
      changes.is_default = data.is_default;
    }

    if (Object.keys(changes).length === 0) {
      showSuccess(
        findDisplayName("languageCreateNoChangeValidation", "No changes made")
      );
    } else {
      if (Object.keys(changes).length > 0) {
        const dataObj = {
          hostuserid: loginData?.login_id,
          languageid: languageData?.language_id,
          text_message: {
            language_id: languageId,
          },
          ...changes,
        };
        setDisabled(true);
        const response = await LanguageEditAPI(dataObj);
        if (response.data.statusCode === 200) {
          showSuccess(response?.data?.body?.message);
          fetchLanguage();
          setActiveComponent("table");
          setDisabled(false);
        } else {
          showError(response?.data?.body?.message);
          setDisabled(false);
        }
      }
    }
  };

  const handleCancel = () => {
    fetchLanguage();
    setActiveComponent("table");
  };

  return (
    <div className="container  text-white from-scrol">
      <form className="row d-flex flex-column align-items-center mt-5 ">
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <label htmlFor="language">
            {findDisplayName("languageEditScreenLanguageHeading", "Language")}
          </label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="language"
            onFocus={(event) => {
              event.target.setAttribute("autoComplete", "off");
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
            }}
            {...register("language", {})}
            defaultValue={initialName}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <input
            type="checkbox"
            id="is_default"
            className="form-check-input me-2"
            {...register("is_default")}
            defaultChecked={initialIsDefault}
          />
          <label htmlFor="is_default">
            {findDisplayName(
              "languageEditScreenDefaultHeading",
              "Default"
            )}
          </label>
        </div>
        <div className="col-lg-6 gap-2 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={disabled}
            onClick={handleSubmit((data) => handleConfirmClick(data))}
          >
            {findDisplayName("languageEditScreenUpdateButton", "Update")}
          </button>
          <button
            className="btn bg-secondary text-light"
            onClick={() => {
              handleCancel();
            }}
          >
            {findDisplayName("languageEditScreenCancelButton", "Cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MasterLanguageEdit;
