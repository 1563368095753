import React, { useEffect, useState } from "react";
import logo from "../../../Asset/Img/Logo.svg";
import "./css/style.css";
import {
  findDisplayName,
  getCurrentDateAndTime,
  isView,
} from "../../../utils/commonFunctionsAndStatices";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { setOrganization } from "../../Store/actions/getorganization/organizationSelector";
import { clearOrganizationData } from "../../Store/actions/getorganization/organizationReducer";
import { clearToken } from "../../Store/actions/auth/authReducer";
import { clearLocationArray } from "../../Store/actions/locationData/locationReducer";
import { clearCSOData } from "../../Store/actions/csoReport/csoReportReducer";
import { clearArray } from "../../Store/actions/visitorType/visitorTypeReducer";
import { clearCountryArray } from "../../Store/actions/getCountry/getCountryReducer";
import { clearAllLocation } from "../../Store/actions/allLocationdata/getAllLocationReducer";
import { clearLanguageArray } from "../../Store/actions/language/LanguageReducer";
import { clearOrgTable } from "../../Store/actions/Organizationadmin/OrganizationReducer";
import { clearOrgLocation } from "../../Store/actions/orgLocation/orgLocationReducer";
import { clearRoleData } from "../../Store/actions/role/roleReducer";
import { clearVisitorTable } from "../../Store/actions/VisitorTypesTable/VisitorTypesTableReducer";
import { clearUserTable } from "../../Store/actions/userTableData/userTableDataReducer";
import { clearFieldData } from "../../Store/actions/FieldTableData/FieldTableReducer";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { MasterLabelAPI, userLogOut } from "../../apis/apisServies";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import { selectlanguageArr } from "../../Store/actions/language/LanguageSelector";
import {
  clearLanguageId,
  setLanguageId,
} from "../../Store/actions/languageId/languageIdReducer";
import { setMasterLabel } from "../../Store/actions/masterLabel/masterLabelReducer";
import { FaLanguage } from "react-icons/fa";
import { clearPortid } from "../../Store/actions/portId/portIdReducer";
import { selecetPortID } from "../../Store/actions/portId/portSelect";
import { clearPortData } from "../../Store/actions/portData/portDataReducer";
import { clearOrganixationID } from "../../Store/actions/organizationId/organizationIdReducer";
import { selectPrivilege } from "../../Store/actions/privilege/previlegeSelectoe";

export const Header = () => {
  const [currentTime, setCurrentTime] = useState({
    dayOfWeek: "",
    month: "",
    year: "",
    time: "",
    date: "",
  });

  const privilege = useSelector(selectPrivilege);
  const loginData = useSelector(selectToken);
  const navigator = useNavigate();
  const [image, setImage] = useState(null);
  const updateLogo = useSelector(setOrganization);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const languageId = useSelector(languageid);
  const { showSuccess, showError } = useNotification();
  const languageData = useSelector(selectlanguageArr);
  const portID = useSelector(selecetPortID);
  const sortLanguage =
    languageData &&
    [...languageData]?.sort((a, b) =>
      a.language_name.localeCompare(b.language_name)
    );
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const lgId = languageId?.length > 0 ? languageId : null;
  const [id, setId] = useState(lgId);

  let selectedLanguageName = id
    ? languageData?.find((language) => language.language_id === id)
    : languageData?.find((language) => language.language_id === lgId);

  const handleLanguageSelect = (language) => {
    let id = language.language_id;
    dispatch(setLanguageId(id));
    localStorage.setItem("language_id", id);
    setId(id);
    setTimeout(() => {
      setSelectedLanguage(language);
    }, 400);
  };

  useEffect(() => {
    const fetchLanguage = async () => {
      const dataObj = {
        LanguageId: id,
        ...(isView("isListOrganization") === false
          ? { OrganizationId: loginData?.organization_id }
          : {}),
      };
      try {
        const masterLabel = await MasterLabelAPI(dataObj);
        const data = masterLabel?.data?.body?.data;
        dispatch(setMasterLabel(data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchLanguage();
  }, [selectedLanguage]);

  const handleNavigate = () => {
    if (isView("isLandingCheckIn")) {
      navigator("/CheckInPage");
    } else if (isView("isLandingDashboard")) {
      navigator("/Organization_details");
    } else {
      navigator("/");
    }
  };

  useEffect(() => {
    if (updateLogo) {
      const newLogo = updateLogo.logo;
      setImage(newLogo);
    }
  }, [updateLogo]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const instance = await getCurrentDateAndTime();
      setCurrentTime({
        dayOfWeek: instance.dayOfWeek,
        month: instance.month,
        year: instance.year,
        time: instance.time,
        date: instance.date,
      });
    }, 1000);

    return () => clearTimeout(interval);
  }, []);

  const handlechangePOB = () => {
    navigator("/CheckoutScreen");
  };

  const Profile = () => {
    if (loginData) {
      navigator("/Organization_details");
    }
  };
  const Chart = () => {
    navigator("/Dashboard");
  };

  var pathName = window.location.pathname;
  const logOutDetails = async () => {
    const dataObj = {
      user_id: loginData?.login_id,
      text_message: {
        language_id: languageId,
        ...(isView("isListOrganization") === false
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };
    const response = await userLogOut(dataObj);
    const message = response?.data?.body?.message;
    showSuccess(message);
  };

  useEffect(() => {
    if (selectedLanguage) {
      // Reload the page after a short delay only when selectedLanguage changes
      const reloadTimeout = setTimeout(() => {
        window.location.reload();
      }, 1000);

      return () => clearTimeout(reloadTimeout);
    }
  }, [selectedLanguage]);

  // Log Out the user
  const logOutUser = () => {
    logOutDetails();
    sessionStorage.removeItem("persist:root");
    localStorage.removeItem("language_id");
    setShow(false);
    setImage(null);
    dispatch(clearToken());
    dispatch(clearLocationArray());
    dispatch(clearCSOData());
    dispatch(clearArray());
    dispatch(clearCountryArray());
    dispatch(clearOrganizationData());
    dispatch(clearAllLocation());
    dispatch(clearLanguageArray());
    dispatch(clearOrgTable());
    dispatch(clearOrgLocation());
    dispatch(clearRoleData());
    dispatch(clearVisitorTable());
    dispatch(clearUserTable());
    dispatch(clearFieldData());
    dispatch(clearPortid());
    dispatch(clearPortData());
    dispatch(clearOrganixationID());
    dispatch(clearLanguageId());
    navigator("/");
  };
  let location = window.location.pathname;
  return (
    <header className="header-background">
      <div className="container">
        <div className="row">
          <div className="headerContentDiv d-flex justify-content-between align-items-center">
            <div className="logo-container">
              {!image ? (
                <img
                  src={logo}
                  className="image"
                  alt="Logo"
                  height="25px"
                  onClick={() => handleNavigate()}
                />
              ) : (
                <img
                  src={image}
                  className="image"
                  alt="Image"
                  onClick={() => handleNavigate()}
                />
              )}
            </div>
            <div className="content d-flex flex-column align-items-end text-white">
              <span className="d-flex align-items-baseline gap-1">
                <span className="timeText">{currentTime.time}</span>
                <span className="dayText"> {currentTime.dayOfWeek},</span>
              </span>
              <span className="yearText ">
                {currentTime.date} {currentTime.month}, {currentTime.year}{" "}
                {findDisplayName("headerTimeFormat", "UTC")}
              </span>
            </div>
            <div className="row d-flex ">
              {isView("isMultiLanguage") &&
                location !== "/" &&
                location !== "/PortDetail" &&
                location !== "/permission" && (
                  <div className={`col-${isView("isPOB") ? "5" : "7"}`}>
                    <a
                      className="btn text-white d-flex"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FaLanguage className="icon mx-2" />
                      {selectedLanguageName
                        ? selectedLanguageName?.language_name
                            .slice(0, 2)
                            .toUpperCase()
                        : "EN"}
                    </a>

                    <ul className="dropdown-menu pb-2">
                      {sortLanguage?.map((language) => (
                        <li key={language.language_id}>
                          <a
                            className="dropdown-item"
                            onClick={() => handleLanguageSelect(language)}
                          >
                            {language.language_name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              {loginData?.login_id && (
                <>
                  {pathName !== "/PortDetail" &&
                    pathName !== "/" &&
                    isView("isPOB") && (
                      <button
                        className="btn btn-dark col-3"
                        onClick={() => handlechangePOB()}
                      >
                        {findDisplayName("headerPOBButton", "POB")}
                      </button>
                    )}

                  {privilege?.length > 0 && pathName !== "/" && (
                    <div className="btn-group col-4">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {findDisplayName("menuButtonMenuHeading", "Menu")}
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end pb-2">
                        <>
                          {pathName !== "/PortDetail" && (
                            <>
                              {isView("isMenuDashboard") && (
                                <li
                                  onClick={() => Profile()}
                                  className="dropdown-item"
                                >
                                  {findDisplayName(
                                    "menuButtonDashboardHeading",
                                    "Dashboard"
                                  )}
                                </li>
                              )}
                              {(isView("isLandingAnalytical") ||
                                isView("isMenuAnalytics")) && (
                                <li
                                  onClick={() => Chart()}
                                  className="dropdown-item"
                                >
                                  {findDisplayName(
                                    "menuButtonBIChartHeading",
                                    "BI-Chart"
                                  )}
                                </li>
                              )}
                              {isView("isMenuCheckin") && (
                                <li
                                  onClick={() => navigator("/Formscreen")}
                                  className="dropdown-item"
                                >
                                  {findDisplayName(
                                    "menuButtonCheckInHeading",
                                    "Check In"
                                  )}
                                </li>
                              )}
                              {isView("isMenuCheckout") && (
                                <li
                                  onClick={() => navigator("/CheckoutScreen")}
                                  className="dropdown-item"
                                >
                                  {findDisplayName(
                                    "menuButtonCheckOutHeading",
                                    "Check Out"
                                  )}
                                </li>
                              )}
                              {isView("isMenuVisitorLog") && (
                                <li
                                  onClick={() => navigator("/CsoReport")}
                                  className="dropdown-item"
                                >
                                  {findDisplayName(
                                    "menuButtonVisitorLogHeading",
                                    "Visitor Log"
                                  )}
                                </li>
                              )}
                              {isView("isMenuVisitorLogArchival") && (
                                <li
                                  onClick={() =>
                                    isView("isDashboardVisitorLogArchival")
                                      ? navigator(
                                          "/Organization_details/visitor-log-archival"
                                        )
                                      : navigator("/visitor-log-archival")
                                  }
                                  className="dropdown-item"
                                >
                                  {findDisplayName(
                                    "menuButtonVisitorLogArchivalHeading",
                                    "Visitor Log Archive"
                                  )}
                                </li>
                              )}
                            </>
                          )}
                        </>
                        <li onClick={() => logOutUser()}>
                          <span className="dropdown-item">
                            {findDisplayName(
                              "menuButtonLogoutHeading",
                              "Logout"
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
