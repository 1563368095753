import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  portId: [],
};

const portIdReducer = createSlice({
  name: "portID",
  initialState,
  reducers: {
    setPortId: (state, action) => {
      state.portId = action.payload;
    },
    clearPortid: (state) => {
      state.portId = [];
    },
  },
});

export const { setPortId, clearPortid } = portIdReducer.actions;
export default portIdReducer.reducer;
