import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { MasterLabelAPI, MasterLabelEditAPI } from "../../../apis/apisServies";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import { setMasterLabel } from "../../../Store/actions/masterLabel/masterLabelReducer";

const LanguageEdit = ({ labelEdit, languageId, setActiveComponent , handleTableUpdate, organizationId}) => {
  const { handleSubmit, register } = useForm();
  const { showSuccess, showError } = useNotification();
  const loginData = useSelector(selectToken);
  const initialLabelName = labelEdit.LabelName;
  const initialLabelText = labelEdit.LabelText;
  const langugaeid = languageId;
  const [disabled, setDisabled] = useState(false);
  const languageID = useSelector(languageid);
  const dispatch = useDispatch();
  const orgData = useSelector(selectOrgTable);
  const initialOrganization = organizationId === "" ? "" : orgData?.find(item => item.organizationid === organizationId).name

const handleCancel = () =>{
  handleTableUpdate(languageId, organizationId);
  setActiveComponent( "table"); 
}
  const handleUpdateClick = async (data) => {

    const changes = {};

    if (data.DispalyName !== initialLabelText) {
      changes.LabelText = data.DispalyName?.trim();
    }

    if (organizationId !== "") {
      changes.OrganizationId = organizationId;
    }

    if (Object.keys(changes).length === 0) {
      showSuccess("No changes made.");
    } else {
      if (Object.keys(changes).length > 0) {
        const dataObj = {
          userid: loginData?.login_id,
          MasterLabelId: labelEdit.MasterLabelId,
          LanguageId: langugaeid,
          LabelText: initialLabelText?.trim(),
          LabelName: initialLabelName?.trim(),
          ...changes,
          text_message: {
            language_id: languageID,
            ...(loginData?.organization_id ? { organization_id: loginData?.organization_id } : {}),
          }
        };
        setDisabled(true)
        const response = await MasterLabelEditAPI(dataObj);
        if (response.data.statusCode === 200) {
          showSuccess(response?.data?.body?.message);
          const organizationid = isView("isListOrganization") ? organizationId : loginData?.organization_id;
          handleTableUpdate(languageId, organizationid);
          setActiveComponent("table");
          if (languageID === languageId) {
            const dataObj = {
              LanguageId: languageId,
            };
            const masterLabel = await MasterLabelAPI(dataObj);
            const data = masterLabel?.data?.body?.data;
            dispatch(setMasterLabel(data));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          setDisabled(false);
        } else {
          setDisabled(false);
          showError(response?.data?.body?.message);
        }
      }
    }
  };

  return (
    <div className="container  text-white from-scrol ">
      <div className="row d-flex flex-column align-items-center">
        {isView("isListOrganization") && organizationId !== "" && (
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8">
            <label htmlFor="LabelName">{findDisplayName("labelEditScreenOrganizationHeading", "Organization")}</label>
            <input
            type="text"
            className="form-control  mt-2 "
            id="Organization"
            {...register("Organization", {})}
            defaultValue={initialOrganization}
            disabled
          />
          </div>
        )}

        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <label htmlFor="LabelName">{findDisplayName("labelEditScreenLabelNameHeading", "Label Name")}</label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="LabelName"
            {...register("LabelName", {})}
            defaultValue={initialLabelName}
            disabled
          />
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <label htmlFor="DispalyName">{findDisplayName("labelEditScreenDisplayNameHeading", "Display Name")}</label>
          <input
            type="text"
            className="form-control  mt-2 "
            id="DispalyName"
            placeholder={findDisplayName("labelEditScreenDisplayNameInputBox", "Display Name")}
            {...register("DispalyName", {})}
            defaultValue={initialLabelText}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex gap-2 justify-content-center">
          <button
            type="submit"
            className="btn btn-primary text-black "
            disabled ={disabled}
            onClick={handleSubmit(handleUpdateClick)}>
            {findDisplayName("labelEditScreenUpdateButton", "Update")}
          </button>
          <button
            className="btn bg-secondary text-light"
            onClick={() =>handleCancel()}
          >
            {findDisplayName("labelEditScreenClancelButton", "Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LanguageEdit;
