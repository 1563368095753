import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visitorID: "",
  visitorProfile: [],
  navigationPath: "",
};

const profileReducer = createSlice({
  name: "profileData",
  initialState,
  reducers: {
    setVisitorID: (state, action) => {
      state.visitorID = action.payload;
    },
    setVisitorProfile: (state, action) => {
      state.visitorProfile = action.payload;
    },
    setNavigation: (state, action) => {
      state.navigationPath = action.payload;
    }
  },
});

export const { setVisitorID, setVisitorProfile, setNavigation } = profileReducer.actions;
export default profileReducer.reducer;
