import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userTable:null
}

const userTableReducer = createSlice({
    name:"userTableData",
    initialState,
    reducers:{
        setUserTable : (state,action)=>{
            state.userTable = action.payload;
        },
        clearUserTable:(state)=>{
            state.userTable = null
        }
    }
})

export const { setUserTable, clearUserTable } = userTableReducer.actions;
export default userTableReducer.reducer;