import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  CheckOutList: [],
};

const checkOutSlice = createSlice({
  name: "checkOut",
  initialState,
  reducers: {
    setUserDatails: (state, action) => {
      state.CheckOutList = action.payload;
    },
  },
});

export const { setUserDatails } = checkOutSlice.actions;
export default checkOutSlice.reducer;
