import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgLocation: null,
};

const orgLocationReducer = createSlice({
  name: "orgLocationData",
  initialState,
  reducers: {
    setOrgLocation: (state, action) => {
      state.orgLocation = action.payload;
    },
    clearOrgLocation: (state) => {
      state.orgLocation = [];
    },
  },
});

export const { setOrgLocation, clearOrgLocation } = orgLocationReducer.actions;
export default orgLocationReducer.reducer;
