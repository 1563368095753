import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const privilegesSclice = createSlice({
  name: "privileges",
  initialState,
  reducers: {
    setPrivileges: (state, action) => {
      state.data = action.payload;
    },
    clearPrivileges: (state) =>{
        state.data = [];
    }
  },
});


export const {setPrivileges, clearPrivileges} = privilegesSclice.actions;
export default privilegesSclice.reducer;
