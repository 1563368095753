import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalizeFirstLetter,
  findDisplayName,
  formatNumberWithMask,
  isView,
  sortData,
} from '../../../utils/commonFunctionsAndStatices';
import { Link, useNavigate } from 'react-router-dom';
import { setNavigation, setVisitorID } from '../../Store/actions/profile/profileReducer';
import DataNotFound from '../DataNotFound/DataNotFound';
import { setOrganization } from '../../Store/actions/getorganization/organizationSelector';
import { selectOrgTable } from '../../Store/actions/Organizationadmin/OrganizationSelector';
import { selectOrgID } from '../../Store/actions/organizationId/organizationIdSeelct';

export const CSROTable = ({ data, POB }) => {
  const [sortDirection, setSortDirection] = useState('asc');
  const [tableData, setTableData] = useState(data);
  const [sortField, setSortField] = useState('');
  const organization = useSelector(setOrganization);
  const organizationDetails = useSelector(selectOrgTable);
  const sortOrgData =
    organizationDetails &&
    [...organizationDetails].sort((a, b) => a.name.localeCompare(b.name));

  const selectOrgId = useSelector(selectOrgID);
  const [batchID, setBatchID] = useState(
    organization ? organization?.badge_number_mask : ''
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const visitor = isView("isLandingCheckIn") || isView("isDashboardVisitorLog") === false;

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    if (selectOrgId) {
      const batchNumber =
        organizationDetails &&
        organizationDetails?.find(
          (item) => item.organizationid === selectOrgId
        );
      setBatchID(batchNumber?.badgenumbermask);
    }

    if (organizationDetails) {
      const batchNumber = sortOrgData && sortOrgData[0];
      setBatchID(batchNumber?.badgenumbermask);
    }
  }, [organizationDetails]);

  useEffect(() => {

    if (batchID === "") {
      setBatchID(organization?.badge_number_mask)
    }

  }, [organization])

  const formatDateTime = (timestamp) => {
    if (timestamp === 'null' || timestamp === '') {
      return '';
    } else {
      const date = new Date(timestamp);
      const options = { timeZone: 'UTC' };
      return date.toLocaleString('en-US', options);
    }
  };
  let offlineLogOut = sessionStorage.getItem('checkout');

  let formetData = JSON.parse(offlineLogOut);
  if (!tableData || tableData?.length === 0) {
    return (
      <div className={`${visitor ? 'card' : ''}`}>
        <DataNotFound />
      </div>
    );
  }

  function getTimeByVisitorId(visitorId) {
    for (let i = 0; i < formetData?.length; i++) {
      const entry = formetData[i];
      const keys = Object.keys(entry);
      if (keys.includes(visitorId)) {
        return entry[visitorId].time;
      }
    }
    return '';
  }

  function findCallingnameById(id) {
    var root = JSON.parse(sessionStorage.getItem('persist:root'));
    var locationList = JSON.parse(root.locationDataArr).locationArr;
    if (locationList?.length > 0) {
      const foundItem = locationList?.find((item) => item.locationid === id);
      return foundItem ? foundItem.locationname : null;
    } else {
      var locationList = JSON.parse(root.allLocationData).allLocationData;
      const foundItem = locationList.find((item) => item.locationid === id);
      return foundItem ? foundItem.locationname : null;
    }
  }
  const location = window.location.pathname;
  const handleSubmit = (visitorid) => {
    dispatch(setNavigation(location))
    dispatch(setVisitorID(visitorid));
    navigate("/VisitorDetails")
  };

  // Visitor Id based find the visitor name
  function findVisitorTypeById(id) {
    var root = JSON.parse(sessionStorage.getItem('persist:root'));
    var visitorTypeList = JSON.parse(root.visitorTypeTable).visitortable;
    if (visitorTypeList) {
      const foundItem = visitorTypeList?.find(
        (item) => item.visitortypeid === id
      );
      return foundItem ? capitalizeFirstLetter(foundItem.name) : null;
    } else if (!visitorTypeList) {
      var visitorType = JSON.parse(root.visitorType).visitorArr;
      const foundItem = visitorType?.find((item) => item.visitortypeid === id);
      return foundItem ? capitalizeFirstLetter(foundItem.name) : null;
    }
  }


  const handleSort = (field) => {
    // Toggle sort direction if the same field is clicked again
    const newSortDirection = sortField === field ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';

    // Update sort direction and field state
    setSortDirection(newSortDirection);
    setSortField(field);

    // Sort the table data based on the selected field and direction
    const sortedData = sortData(tableData, field, newSortDirection);

    setTableData(sortedData);
  };

  return (
    <div className='container '>
      <div className='row pt-4'>
        <div className={` ${visitor ? 'p-2' : ''}`}>
          <div
            className={` ${visitor ? 'table-wrapper' : 'visitorlog-table-scrol'}`}>
            <table
              className={` ${visitor ? 'checkouttable table table-hover' : 'table table-striped table-hover'}`}>
              <thead className='bg-transparent'>
                <tr>
                  <th scope='col' className='bg-transparent th-color'
                    onClick={() => { handleSort('Badge') }}>
                    {findDisplayName('visitorsLogBadgeHeading', 'Badge')}
                    {sortField === 'Badge'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th scope='col' className='bg-transparent th-color'
                    onClick={() => { handleSort('firstname') }}>
                    {findDisplayName('visitorsLogFirstNameHeading', 'First Name')}
                    {sortField === 'firstname'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th scope='col' className='bg-transparent th-color'
                    onClick={() => { handleSort('lastname') }}>
                    {findDisplayName('visitorsLogLastNameHeading', 'Last Name')}
                    {sortField === 'lastname'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th
                    scope='col'
                    style={{ whiteSpace: 'nowrap' }}
                    className='bg-transparent th-color'
                    onClick={() => { handleSort('locationid') }}
                  >
                    {findDisplayName(
                      'visitorsLogCallingOnHeading',
                      'Location Name'
                    )}
                    {sortField === 'locationid'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th
                    scope='col'
                    style={{ whiteSpace: 'nowrap' }}
                    className='bg-transparent th-color'
                    onClick={() => { handleSort('visitortypeid') }}
                  >
                    {findDisplayName(
                      'visitorsLogVisitorTypeHeading',
                      'Visitor Type'
                    )}
                    {sortField === 'visitortypeid'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th scope='col' className='bg-transparent th-color'
                    onClick={() => { handleSort('CheckIn') }}>
                    {findDisplayName('visitorsLogCheckInHeading', 'Check-In')}
                    {sortField === 'CheckIn'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th scope='col' className='bg-transparent th-color'
                    onClick={() => { handleSort('CheckOut') }}>

                    {findDisplayName(
                      'visitorsLogCheckOutHeading',
                      'Check-Out '
                    )}
                    {sortField === 'CheckOut'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, index) => (
                  <tr key={index}
                    onClick={() => isView('isVisitorLogProfile') && handleSubmit(item.visitorid)}
                    style={isView('isVisitorLogProfile') ? { cursor: "pointer" } : {}}
                  >
                    <td className='ps-2 bg-transparent text-white' >
                      {formatNumberWithMask(item.Badge, batchID) ? formatNumberWithMask(item.Badge, batchID) : item.Badge}
                    </td>
                    <td className='bg-transparent text-white'>{item?.firstname?.charAt(0).toUpperCase() + item?.firstname?.slice(1)}</td>
                    <td className='bg-transparent text-white'>{item?.lastname?.charAt(0).toUpperCase() + item?.lastname?.slice(1)}</td>
                    <td className='bg-transparent text-white'>
                      {item.locationid
                        ? findCallingnameById(item.locationid)
                        : ''}
                    </td>
                    <td className='bg-transparent text-white'>
                      {item.locationid
                        ? findVisitorTypeById(item.visitortypeid)
                        : ''}
                    </td>
                    <td className='bg-transparent text-white'>
                      {item.CheckIn ? formatDateTime(item?.CheckIn) : ''}
                    </td>
                    <td className='bg-transparent text-white'>
                      {item.CheckOut
                        ? formatDateTime(item?.CheckOut)
                        : formatDateTime(getTimeByVisitorId(item.visitorid))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
