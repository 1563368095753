// visitorTypeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offline_visitor_id: "", // Initialize the location type to []
};

const offlineVisitorSlice = createSlice({
  name: "offlineVisitor",
  initialState,
  reducers: {
    setOfflineVisitorId: (state, action) => {
      state.offline_visitor_id = action.payload;
    },
    clearOfflineVisitiorId: (state) => {
      state.offline_visitor_id = "";
    },
  },
});

export const { setOfflineVisitorId, clearOfflineVisitiorId } = offlineVisitorSlice.actions;

export default offlineVisitorSlice.reducer;