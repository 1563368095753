import React, { useState } from "react";

const PortDropdown = ({ items, keys, placeholder, valueChange, showList, setShowList, childRef, defaultValue,style }) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const handleInputChange = (e) => {
        const filter = e.target.value;
        setInputValue(filter);

        if (filter.length > 0) {
            setShowList(true);
        } else {
            setShowList(false);
        }
        valueChange("");
    };

    const handleItemClick = (value, return_val) => {
        setInputValue(value);
        setShowList(false);
        valueChange(value);
    };

    return (
        <div ref={childRef} className="form-group">
            <input
                type="text"
                className="form-select form-control mt-1"
                value={inputValue}
                placeholder={placeholder}
                onChange={handleInputChange}
                onFocus={() => setShowList(true)}
            />

            {showList && (
                <ul className="list-group" style={{ position: "absolute", width: style, cursor: "default", zIndex: "2", maxHeight: "15vh", overflowY: "auto" }}>
                    {items
                        .filter(item =>
                            item[keys[0]] && typeof item[keys[0]] === "string" && item[keys[0]]?.toLowerCase()?.includes(inputValue?.toLowerCase())
                        )
                        .map((item, index) => (
                            <li
                                className="list-group-item"
                                key={index}
                                onClick={() => handleItemClick(item[keys[0]], item[keys[1]])}
                            >
                                {item[keys[0]]}
                            </li>
                        ))}
                </ul>
            )}
        </div>
    );
};

export default PortDropdown;
