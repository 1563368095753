import React from "react";
import { useState } from "react";
import LanguagesList from "./LanguagesList/LanguagesList";
import OrganizationList from "../OrganizationList/OrganizationList";
import LanguagesTable from "./LanguagesTable/LanguagesTable";
import { useDispatch, useSelector } from "react-redux";
import { selectlanguageArr } from "../../Store/actions/language/LanguageSelector";
import { MasterLabelAPI } from "../../apis/apisServies";
import { useEffect } from "react";
import LanguageCreate from "./LanguageCreate/LanguageCreate";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import LanguageEdit from "./LanguageEdit/LanguageEdit";
import LanguageSearch from "./LanguageSearch/LanguageSearch";
import { findDisplayName, isCreate, isUpdate, isView } from "../../../utils/commonFunctionsAndStatices";
import { setLanguageData } from "../../Store/actions/languageData/LanguageDataReducer";
import { selectLanguageData } from "../../Store/actions/languageData/LanguageDataSelector";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { useNotification } from "../../NotificationProvider/NotificationProvider";

const Languages = () => {
  const loginData = useSelector(selectToken);
  const languages = useSelector(selectlanguageArr);
  const enLanguage = languages.find(
    (language) => language.language_name === "English"
  ).language_id;
  const [activeComponent, setActiveComponent] = useState("table");
  const [language, setLanguage] = useState(enLanguage);
  const [organization, setOrganization] = useState("");
  const dispatch = useDispatch();
  const selectLabel = useSelector(selectLanguageData);
  const [labelEdit, setLabelEdit] = useState(null);
  const [labels, setLabels] = useState(selectLabel);
  const languageID = useSelector(languageid);
  const { showError } = useNotification();
  const [loading , setLoading] = useState(false);

  const getLabels = async () => {
    const dataObj = {
      LanguageId: language,
      OrganizationId:
      isView("isListOrganization") 
          ? organization
          : loginData?.organization_id,
      text_message: {
        language_id: languageID,
        ...(loginData?.organization_id 
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    try {
      setLoading(true);
      const res = await MasterLabelAPI(dataObj);
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.body?.data;
        dispatch(setLanguageData(data));
        setLabels(data);
        setLoading(false);
      } else if (res?.data?.statusCode === 404) {
        setLoading(false);
        const data = res?.data?.body?.message;
        showError(data);
        dispatch(setLanguageData([]));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getLabelsWithLanguage = async (id) => {
    const dataObj = {
      LanguageId: id,
      OrganizationId: organization,
      text_message: {
        language_id: languageID,
        ...(loginData?.organization_id 
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    try {
      setLoading(true);
      const res = await MasterLabelAPI(dataObj);
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.body?.data;
        dispatch(setLanguageData(data));
        setLabels(data);
        setLoading(false);
      } else if (res?.data?.statusCode === 404) {
        const data = res?.data?.body?.message;
        showError(data);
        setLoading(false);
        dispatch(setLanguageData([]));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getLabelsWithOrganization = async (id) => {
    const dataObj = {
      LanguageId: language,
      OrganizationId: id,
      text_message: {
        language_id: languageID,
        ...(loginData?.organization_id 
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    try {
      setLoading(true);
      const res = await MasterLabelAPI(dataObj);
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.body?.data;
        dispatch(setLanguageData(data));
        setLabels(data);
        setLoading(false);
      } else if (res?.data?.statusCode === 404) {
        const data = res?.data?.body?.message;
        showError(data);
        setLoading(false);
        dispatch(setLanguageData([]));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getLabelsWithOrganizationAndLanguage = async (
    languageId,
    organizationId
  ) => {
    const dataObj = {
      LanguageId: languageId,
      OrganizationId: organizationId,
      text_message: {
        language_id: languageID,
        ...(loginData?.organization_id 
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    try {
      setLoading(true);
      const res = await MasterLabelAPI(dataObj);
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.body?.data;
        dispatch(setLanguageData(data));
        setLabels(data);
        setLoading(false);
      } else if (res?.data?.statusCode === 404) {
        const data = res?.data?.body?.message;
        showError(data);
        setLoading(false);
        dispatch(setLanguageData([]));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLanguageChange = (id) => {
    setLanguage(id);
    setLabels([]);
    getLabelsWithLanguage(id);
  };

  const handleOrganizationChange = (id) => {
    setOrganization(id);
    setLabels([]);
    getLabelsWithOrganization(id);
  };

  const handleTableUpdate = (languageId, organizationId) => {
    setLanguage(languageId);
    setOrganization(organizationId);
    setLabels([]);
    getLabelsWithOrganizationAndLanguage(languageId, organizationId);
  };

  const handleSearch = (search) => {
    const searchValue = search?.toLowerCase();
    const data = selectLabel?.filter((item) =>
      item.LabelName && item.LabelName.toLowerCase().includes(searchValue)
    );        
    setLabels(data);
    if (data?.length === 0) {
      setLabels([]);
    }
  };
  const handleSearch2 = (search) => {
    const searchValue = search?.toLowerCase();
    const data = selectLabel?.filter((item) =>
    item.LabelText && item.LabelText.toLowerCase().includes(searchValue)
    );
    setLabels(data);
    if (data?.length === 0) {
      setLabels([]);
    }
  };

  useEffect(() => {
    getLabels();
  }, []);

  return (
    <>
      <nav className="nav-dashboard">
        <strong className="links">
          {findDisplayName("labelHeading", "Labels")}
        </strong>
        {activeComponent === "table" && isCreate("isDashboardLabels") &&  (
          <button
            type="button"
            className="btn btn-primary"
            id="create-user-button"
            onClick={() => setActiveComponent("create")}
            disabled={language === ""}
          >
            {findDisplayName("labelScreenCreateLabelButton", "Create Label")}
          </button>
        )}
      </nav>
      <div className="row">
        <div className="d-flex gap-3 flex-lg-row flex-column mt-3 small-screen ">
          {activeComponent === "table" && (
            <div className="form-group col-lg-3 col-md-8 col-sm-12 col-xs-10 ps-3 pe-5 pe-xxl-0  pe-lg-0 ">
              <LanguagesList
                initialvalue={language}
                handleChange={handleLanguageChange}
              />
            </div>
          )}
          {activeComponent === "table" && isView("isListOrganization") && (
            <div className="form-group col-lg-3 col-md-8 col-sm-12 col-xs-12 ps-3 pe-5  pe-lg-0  ps-lg-1 ps-xxl-1 pe-xxl-0">
              <OrganizationList
                initialvalue={organization}
                handleChange={handleOrganizationChange}
                organizatonInUser={true}
              />
            </div>
          )}
          {activeComponent === "table" && (
            <div className="form-group col-lg-6 col-md-8 col-sm-12 col-xs-12 ps-3  ps-lg-1 ps-xxl-1 pe-5 ">
              <LanguageSearch
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                handleChange={handleSearch}
                handleChange2={handleSearch2}
              />
            </div>
          )}
        </div>
      </div>

   
      {activeComponent === "table" && isView("isDashboardLabels") && (
        <LanguagesTable
          selectLabel={labels}
          setLabelEdit={setLabelEdit}
          setActiveComponent={setActiveComponent}
          loading={loading}
          organizationId={organization}
          handleTableUpdate={handleTableUpdate}
          langId={language}
        />
      )}
      {activeComponent === "create" && isCreate("isDashboardLabels") && (
        <LanguageCreate
          languageId={language}
          organizationId={organization}
          handleTableUpdate={handleTableUpdate}
          setActiveComponent={setActiveComponent}
        />
      )}
      {activeComponent === "update" && isUpdate("isDashboardLabels") && (
        <LanguageEdit
          labelEdit={labelEdit}
          setActiveComponent={setActiveComponent}
          languageId={language}
          organizationId={organization}
          handleTableUpdate={handleTableUpdate}
        />
      )}
    </>
  );
};

export default Languages;
