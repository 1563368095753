import React, { useEffect, useReducer, useState } from "react";
import RoleTable from "./RoleTable/RoleTable";
import RoleCreate from "./RoleCreate/RoleCreate";
import RoleUpdate from "./RoleUpdate/RoleUpdate";
import OrganizationList from "../OrganizationList/OrganizationList";
import { findDisplayName, isCreate, isUpdate, isView } from "../../../utils/commonFunctionsAndStatices";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { selectOrgID } from "../../Store/actions/organizationId/organizationIdSeelct";
import { useDispatch, useSelector } from "react-redux";
import { setOrganuzationID } from "../../Store/actions/organizationId/organizationIdReducer";
import { selectOrgTable } from "../../Store/actions/Organizationadmin/OrganizationSelector";
import { RolesList } from "../../apis/apisServies";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";

const Role = () => {

  const [activeComponent, setActiveComponent] = useState("table");
  const [rolelistdata, setRolelistdata] = useState([]);
  const [rolevalue, setRolevalue] = useState(null);
  const selectOrgId = useSelector(selectOrgID);
  const languageId = useSelector(languageid);
  const orgData = useSelector(selectOrgTable);
  const { showError } = useNotification();
  const dispatch = useDispatch();
  const loginData = useSelector(selectToken);
  const sortOrgData = orgData && [...orgData].sort((a, b) => a.name.localeCompare(b.name));
  const organizationid = isView("isListOrganization") ? selectOrgId !== "" ? selectOrgId : sortOrgData[0]?.organizationid : loginData?.organization_id;
  const [orgid, setOrgId] = useState(organizationid);
  const handleOrganizationChange = (organizationid) => {
    setOrgId(organizationid); 
    dispatch(setOrganuzationID(organizationid));
    // fetchdata(organizationid, activeButton);
  };

  const roleslist = async () => {
    try {
      const dataObj = {
        userid: loginData?.login_id,
        text_message: {
          language_id: languageId,
        }
      };
      if(orgid && orgid.trim()){
        dataObj.organizationid = orgid;
      }
      if(!orgid && !orgid.trim()){
        dataObj.independent_user = "independent_user";
      }
      const response = await RolesList(dataObj);
      if (response?.data?.statusCode === 200) {
        const datavalue = response?.data?.body?.data;
        setActiveComponent("table");
        setRolelistdata(datavalue);

      } else{
        setRolelistdata([]);
      }
    } catch (error) {
      console.error("Error in featurelist:", error);
    }
  };

  useEffect(() => {
    roleslist();
  }, [orgid])

  return (
    <>
      <nav className="nav-dashboard">
        <strong className="links">
          {findDisplayName("roleHeading", "Role")}
        </strong>
        {activeComponent === "table" &&  isCreate("isDashboardRole") && (
          <button
            type="button"
            className="btn btn-primary mb-2"
            id="create-user-button"
            onClick={() => setActiveComponent("create")}
          >
            {findDisplayName("createRolebutton", "Create Role")}
          </button>
        )}
      </nav>
      {activeComponent === "table"  && isView("isListOrganization") && (
        <div className="form-group col-lg-4 col-md-5 col-sm-10 col-xs-10 ps-3 pe-5">
          <OrganizationList
            handleChange={handleOrganizationChange}
            initialvalue={orgid}
            organizatonInUser={true}
          />
        </div>
      )}
      {activeComponent === "table" &&  isView("isDashboardRole") && (
        <>
          <RoleTable
            setRolevalue={setRolevalue}
            rolelistdata={rolelistdata}
            setActiveComponent={setActiveComponent}
            orgid={orgid}
            roleslist={roleslist}
          />
        </>
      )}
      {activeComponent === "create" && isCreate("isDashboardRole") && (
        <RoleCreate
          roleslist={roleslist}
          setRolevalue={setRolevalue}
          orgid={orgid}
          rolelistdata={rolelistdata}
          setActiveComponent={setActiveComponent}
        />
      )}
      {activeComponent === "update" && isUpdate("isDashboardRole") && (
        <RoleUpdate
          roleslist={roleslist}
          rolevalue={rolevalue}
          orgid={orgid}
          rolelistdata={rolelistdata}
          setActiveComponent={setActiveComponent}
        />
      )}
    </>
  );
};

export default Role;
