import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languageArr: [], 
};

const languageSlice = createSlice({
    name: "languageDataArr",
    initialState,
    reducers: {
        setLanguageArray: (state, action) => {
            state.languageArr = action.payload;
        },
        clearLanguageArray: (state) => {
            state.languageArr = [];
          },
    },
});

export const { setLanguageArray, clearLanguageArray } = languageSlice.actions;
export default languageSlice.reducer;