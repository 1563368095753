import { useState, useEffect } from "react";

// Custom hook to filter an array by ID
const useFilterArrayById = (inputArray, idToRemove) => {
  const [filteredArray, setFilteredArray] = useState(inputArray);

  useEffect(() => {
    // Check if inputArray or idToRemove has changed
    if (inputArray && idToRemove !== undefined) {
      // Use filter() to create a new array without the object with the specified ID
      const updatedArray = inputArray?.filter(
        (item) => item?.visitor_id !== idToRemove
      );
      setFilteredArray(updatedArray);
    }
  }, [inputArray, idToRemove]);

  return filteredArray;
};

export default useFilterArrayById;
