import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";
import DataNotFound from "../../DataNotFound/DataNotFound";

const DashboardChart6 = ({ data, locationList, visitorTypeList, loading }) => {
  const sortLocationList = locationList && [...locationList].sort((a,b)=> a.locationname.localeCompare(b.locationname));
  const loginData = useSelector(selectToken);
  const orgData = useSelector(selectOrgTable);
  const [showSelectIcon, setShowSelectIcon] = useState(false);
  const ltnID =
    isView("isListOrganization") ? null : sortLocationList[0]?.locationid;
  const [selectedOption, setSelectedOption] = useState(
    isView("isListOrganization") ? orgData[0]?.organizationid : ""
  );
  const [displayName, setDisplayName] = useState(
    isView("isListOrganization") ? orgData[0]?.name : ""
  );
  const [locationId, setLocationId] = useState("");
  const handleSvgClick = () => {
    setShowSelectIcon((prev) => !prev);
  };

  const sortOrgData = orgData && [...orgData].sort((a,b)=> a.name.localeCompare(b.name));
  const handleOptionClick = (organizationId, organizationName) => {
    setDisplayName(organizationName);
    setSelectedOption(organizationId);
    setShowSelectIcon(false);
    setLocationId('');
  };

  const handleChangeLocation = (id) => {
    setLocationId(id);
  };

  const orgLocationData = data.filter(
    (item) => item.organizationid === selectedOption
  );

  const locationData =
    isView("isListOrganization") ? orgLocationData : data;

  const orgLocationList = locationList.filter(
    (item) => item.organizationid === selectedOption
  );
  const sortLocation = [...orgLocationList].sort((a, b) => a.locationname.localeCompare(b.locationname));

  if (  isView("isListOrganization") && orgLocationList.length > 0 && locationId === "" ) {
    setLocationId(sortLocation[0].locationid);
  }

  const unicLocationList = locationId
    ? locationData?.filter((item) => item.locationid === locationId)
    : locationData?.filter((item) => item.locationid === ltnID);

  const separateByVisitorType = (visitorData) => {
    const separatedData = {};

    visitorData.forEach((item) => {
      const visistorId = item.visitortypeid;
      if (!separatedData[visistorId]) {
        separatedData[visistorId] = [];
      }
      separatedData[visistorId].push(item);
    });
    return separatedData;
  };

  const dataSet = separateByVisitorType(unicLocationList);

  // Function to convert visitor type IDs to names in the separated data
  function convertVisitorTypeIdsToNames(separatedData) {
    const convertedData = {};
    for (const [visitorTypeId, data] of Object.entries(separatedData)) {
      const visitorTypeName = findVisitorTypeById(visitorTypeId);
      convertedData[visitorTypeName || visitorTypeId] = data.map((entry) => ({
        ...entry,
        visitortypeid: visitorTypeName || visitorTypeId,
      }));
    }
    return convertedData;
  }

  function findVisitorTypeById(id) {
    const foundItem = visitorTypeList?.find(
      (item) => item.visitortypeid === id
    );
    return foundItem ? foundItem.name : null;
  }

  const convertedVisitorTypeData = convertVisitorTypeIdsToNames(dataSet);
  const chartData = Object.entries(convertedVisitorTypeData).map(
    ([Name, Data]) => ({
      name: Name,
      vistors: Data.length,
    })
  );
  const chartDataSorted = chartData.slice().sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="row mt-4 ">
      <div
        className={` bg-light rounded col-12 p-3 ${
           (isView("isListOrganization") === false) && "pb-xxl-2 "
        }`}
      >
        <div className="row">
          <div className="col-lg-8 col-md-8 col-xxl-9 col-7 ">
            <strong className="text-dark ms-3 d-xxl-flex align-items-xxl-center">
           
                  {findDisplayName(
                    "VisitorsByTypeByVesselHeading",
                    "Visitors by Type by Vessel"
                  )}
              
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ms-2 d-none d-xxl-block d-lg-block d-xl-block"
              >
                <title>
                  {findDisplayName(
                    "VisitorsByTypeByVesselTooltip",
                    "To get insight on distribution of Visitor Types in each vessel"
                  )}
                </title>
                <circle cx="12.5508" cy="12.5" r="12" fill="#BDBDBD" />
                <rect
                  x="10.5508"
                  y="9.83331"
                  width="3.99999"
                  height="10.6666"
                  rx="2"
                  fill="white"
                />
                <rect
                  x="10.5508"
                  y="4.5"
                  width="3.99999"
                  height="3.99999"
                  rx="2"
                  fill="white"
                />
              </svg>
            </strong>
          </div>
          <div className="col-lg-4 col-md-4 col-xxl-3 col-5 p-0">
            <div>
              {isView("isListOrganization") && (
                <div className="d-flex justify-content-end">
                  <span className="bg-dark text-white p-2 rounded selected-text-size text-wrap">
                    {displayName}
                  </span>

                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-2"
                    onClick={handleSvgClick}
                  >
                    <path
                      d="M3.07031 16.5705H17.2589H3.07031Z"
                      fill="#BDBDBD"
                    />
                    <path
                      d="M3.07031 5.33984H17.2589M3.07031 10.9552H17.2589M3.07031 16.5705H17.2589"
                      stroke="#BDBDBD"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              )}
              {showSelectIcon && isView("isListOrganization") && (
                <div className=" row mt-1 position-relative ">
                  <div
                    className=" position-absolute col-12 selected-icon "
                    aria-label="Default select example"
                    style={{ zIndex: 4 }}
                  >
                    <div
                      className="list-group position-relative row"
                      id="list-tab"
                      role="tablist"
                    >
                      {sortOrgData.map((organization) => (
                        <a
                          key={organization.organizationid}
                          className={`list-group-item list-group-item-action col-10 ${
                            organization.organizationid === selectedOption
                              ? "active"
                              : ""
                          }`}
                          data-bs-toggle="list"
                          href={`#${organization.organizationid}`}
                          role="tab"
                          aria-controls={organization.organizationid}
                          onClick={() =>
                            handleOptionClick(
                              organization.organizationid,
                              organization.name
                            )
                          }
                        >
                          {organization.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12 mt-1 pe-3">
                <select
                  className="form-select"
                  value={locationId}
                  onChange={(e) => handleChangeLocation(e.target.value)}
                >
                  {isView("isListOrganization")
                    ? sortLocation.map((item) => (
                        <option key={item?.locationid} value={item?.locationid}>
                          {item?.locationname}
                        </option>
                      ))
                    : sortLocationList.map((item) => (
                        <option key={item.locationid} value={item?.locationid}>
                          {item?.locationname}
                        </option>
                      ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr
          className={` text-secondary pb-xxl-4 ${
            isView("isListOrganization")
              ? "mt-xxl-4 pt-xxl-1 pt-lg-0 mt-lg-0"
              : "mt-xxl-5 mb-xxl-5"
          }`}
        />
        <div className="row">
          <div className="col-12 d-flex justify-content-center pb-md-2">
            {loading ? (
              <div
                className="d-flex justify-content-center gap-2 text-primary align-items-center"
                style={{
                  height: "400px",
                }}
              >
                <span
                  className="spinner-grow spinner-grow-sm text-primary pe-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                {findDisplayName(
                  "VisitorsByOrganizationChartLoading",
                  "Loading ..."
                )}
              </div>
            ) : chartData.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                  width="95%"
                  height={350}
                  data={chartDataSorted}
                  syncId="anyId"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" fontSize={10}/>
                  <YAxis />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      // Add mobile-specific content styles here if needed
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey="vistors"
                    stroke="#0271BE"
                    fill="#5FBDE7"
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <div
                className={` ${
                  showSelectIcon
                    ? "d-flex justify-content-center align-items-center"
                    : ""
                }`}
                style={{
                  height: "400px",
                 
                }}
              >
                {chartData.length === 0 && !showSelectIcon ? (
                  <div className="text-center ">
                    <DataNotFound />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardChart6;
