import React, { useEffect, useState } from "react";
import {
  FieldTableAPI,
  checkOutList,
  visitorTypeApi,
} from "../../apis/apisServies";
import { useDispatch, useSelector } from "react-redux";
import CheckoutTable from "../CheckoutTable/CheckoutTable";
import { setUserDatails } from "../../Store/actions/checkoutSearch/CheckoutScreenReducer";
import { searchList } from "../../Store/actions/checkoutSearch/checkoutSearchSelector";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import "./css/style.css";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { setOrganization } from "../../Store/actions/getorganization/organizationSelector";
import { findDisplayName, isView } from "../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { selectVisitorType } from "../../Store/actions/VisitorTypesTable/VisitorTypesTableSelector";
import { selectVisitorArr } from "../../Store/actions/visitorType/visitorTypeSelectors";
import { selectFieldTable } from "../../Store/actions/FieldTableData/FieldTableSelector";
import { setFieldData } from "../../Store/actions/FieldTableData/FieldTableReducer";
import { setVisitorArray } from "../../Store/actions/visitorType/visitorTypeReducer";
import DataNotFound from "../DataNotFound/DataNotFound";

export const CheckoutScreen = () => {
  const dispatch = useDispatch();
  const selecter = useSelector(searchList);
  const { showError } = useNotification();
  const [checkOutDetail, setCheckOutDetails] = useState({
    BadgeNumber: "",
    FirstName: "",
    LastName: "",
    visitorTypeId: "",
  });
  const [badgenumbers, setBadgenumbers] = useState("");
  const [visitors, setVisitors] = useState(selecter);
  const [badgenumbermask, setBadgenumbermask] = useState("");
  const loginData = useSelector(selectToken);
  const organization = useSelector(setOrganization);
  const languageId = useSelector(languageid);
  const fieldList = useSelector(selectFieldTable);

  const VisitorData = useSelector((state) => {
    const visitorArr = selectVisitorArr(state);
    if (visitorArr?.length > 0) {
      return visitorArr;
    } else {
      return selectVisitorType(state);
    }
  });

  const FieldData = async () => {
    const obj = {
      userid: loginData?.login_id,
      organization_id: loginData?.organization_id,
      text_message: {
        language_id: languageId,
        organization_id: loginData?.organization_id,
      },
    };
    const response = await FieldTableAPI(obj);
    if (response?.data?.statusCode === 200) {
      const FieldData = response?.data?.body?.data;
      dispatch(setFieldData(FieldData));
    } else if (response?.data?.statusCode === 409) {
      showError(response?.data?.body?.message);
    }
  };

  const sortVisitorData =
    VisitorData &&
    [...VisitorData].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    organization && setBadgenumbermask(organization?.badge_number_mask);
  }, [loginData]);

  const checkoutExist = (key) => {
    var checkout = JSON.parse(sessionStorage.getItem("checkout"));
    var isExist = false;
    if (checkout) {
      checkout.map((item) => {
        var keys = Object.keys(item);
        if (keys.includes(key)) {
          isExist = true;
        }
      });
    }

    return isExist;
  };

  const onUpdateCheckOut = (e) => {
    const formData = {
      ...checkOutDetail,
      [e.target.name]: e.target.value,
    };
    setCheckOutDetails(formData);
  };

  const handleSearch = async () => {
    var search = {
      badgenumber: checkOutDetail.BadgeNumber ? checkOutDetail.BadgeNumber.replace(/\s/g, '') : "",
      firstname: checkOutDetail.FirstName?.trim()
        ? checkOutDetail.FirstName?.trim()
        : "",
      lastname: checkOutDetail.LastName?.trim()
        ? checkOutDetail.LastName?.trim()
        : "",
      userid: loginData?.login_id,
      visitor_type_id: checkOutDetail.visitorTypeId
        ? checkOutDetail.visitorTypeId
        : "",
      text_message: {
        language_id: languageId,
        organization_id: loginData?.organization_id,
      },
    };

    if (navigator.onLine) {
      const response = await checkOutList(search);

      if (response?.data?.statusCode === 404) {
        showError(response?.data?.body?.message);
        setVisitors([]);
        dispatch(setUserDatails([]));
      } else if (response?.data?.statusCode === 200) {
        const userDetails = response?.data?.body;
        if (!userDetails || userDetails?.length === 0) {
          showError("No details for this search, try again");
        }
        dispatch(setUserDatails(userDetails));
        setVisitors(userDetails);
      }
    } else {
      var visitorlog = JSON.parse(
        sessionStorage.getItem("persist:root")
      ).checkOut;
      if (visitorlog) var data = JSON.parse(visitorlog).CheckOutList;

      //offline visitor also not checked
      var offline_visitors = [];
      var offline = JSON.parse(sessionStorage.getItem("visitor"));
      for (const key in offline) {
        if (offline.hasOwnProperty(key)) {
          const visitor_obj = offline[key];
          const convertedData = {
            badge_number: visitor_obj.checkin.badgenumber,
            name:
              visitor_obj.checkin.FirstName +
              " " +
              visitor_obj.checkin.LastName,
            checkin: visitor_obj.terms.time,
            visitor_id: key,
            visitor_type_id: visitor_obj.checkin.visitortypeid,
          };
          if (!checkoutExist(key)) {
            offline_visitors.push(convertedData);
          }
        }
      }
      if (!data) return;
      if (offline_visitors.length > 0) {
        data = [...data, ...offline_visitors];
      }

      data = data.filter((item) => {
        if (!checkoutExist(item.visitor_id)) {
          return true;
        }
      });

      data = data.sort((a, b) => new Date(b.checkin) - new Date(a.checkin));

      let filteredList = data.filter((visitor) => {
        if (search.badgenumber != "") {
          let badgenumberPartialMatch = visitor.badge_number.includes(
            search.badgenumber
          );
          if (!badgenumberPartialMatch) return false;
        }

        if (search.firstname) {
          let firstnamePartialMatch = visitor.firstname.includes(search.firstname);
          if (!firstnamePartialMatch) return false;
        }

        if (search.lastname) {
          let lastnamePartialMatch = visitor.lastname.includes(search.lastname);
          if (!lastnamePartialMatch) return false;
        }

        if (search.visitor_type_id) {
          let visitorTypeFilter = visitor.visitor_type_id.includes(search.visitor_type_id);
          if (!visitorTypeFilter) return false;
        }

        return true;
      });

      setVisitors(filteredList);
    }
  };

  // * api call for get visitorType
  const getVistiorTypeHandler = async () => {
    const dataObj = {
      organizationid: loginData?.organization_id,
    };

    const response = await visitorTypeApi(dataObj);
    try {
      if (response.data.statusCode === 200) {
        dispatch(setVisitorArray(response?.data?.body));
      }
    } catch (error) {
      showError(error?.response?.data?.body?.message);
    }
  };
  useEffect(() => {
    handleSearch();
    if (!fieldList || fieldList.length === 0) {
      FieldData();
    }
  }, []);

  function findBadgemaskpattern(inputString) {
    const regex = /[^#]/g;
    let result = [];
    let match;
    while ((match = regex.exec(inputString))) {
      result.push({ symbol: match[0], pos: match.index });
    }
    return result;
  }

  const handleBadgenumber = (e) => {
    const formData = {
      ...checkOutDetail,
      [e.target.name]: e.target.value,
    };
    setCheckOutDetails(formData);

    let badgeNumber = e.target.value.replace(/\D/g, "");
    let space = findBadgemaskpattern(badgenumbermask);
    space.map((s) => {
      if (badgeNumber.length >= s.pos) {
        badgeNumber =
          badgeNumber.substring(0, s.pos) +
          s.symbol +
          badgeNumber.substring(s.pos);
      }
      return s;
    });
    setBadgenumbers(badgeNumber);
  };
  const badgenumberBackspace = (event) => {
    if (event.key === "Backspace") {
      // Remove the last character from the Badge number
      setBadgenumbers((prevBadgeNumber) =>
        prevBadgeNumber.replace(/[^\d]/g, "")
      );
    }
  };

  useEffect(() => {
    if (!VisitorData) {
      getVistiorTypeHandler();
    }
  }, [VisitorData]);
  return (
    <div className="container Searchmovetop">
      <div className="container d-flex justify-content-center align-items-center  ">
        <div className="row w-100 gx-4 gy-2 d-flex justify-content-center align-items-center ">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <input
              autoComplete="off"
              type="text"
              value={badgenumbers}
              className="form-control"
              id="organization_id"
              name="BadgeNumber"
              placeholder={findDisplayName(
                "checkOutPageBadgeInputBox",
                "Badge Number"
              )}
              onKeyDown={badgenumberBackspace}
              maxLength={badgenumbermask?.length}
              onChange={(e) => handleBadgenumber(e)}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12">
            <input
              type="text"
              autoComplete="off"
              name="FirstName"
              placeholder={findDisplayName(
                "checkOutPageFirstNameInputBox",
                "First Name"
              )}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
              }}
              className="form-control"
              onChange={(e) => onUpdateCheckOut(e)}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12">
            <input
              type="text"
              name="LastName"
              autoComplete="off"
              placeholder={findDisplayName(
                "checkOutPageLastNameInputBox",
                "Last Name"
              )}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
              }}
              className="form-control"
              onChange={(e) => onUpdateCheckOut(e)}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12">
            <div className="dropdown">
              <button
                className="bg-white w-100 form-select text-start text-truncate "
                id="visitorTypeDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {checkOutDetail.visitorTypeId === "" ? findDisplayName(
                  "checkOutPageVisitorTypeDropdown",
                  "Visitor Type"
                ) : sortVisitorData.find(
                  (item) => item.visitortypeid === checkOutDetail.visitorTypeId
                )?.name}
              </button>
              <ul
                className="dropdown-menu pb-2"
                aria-labelledby="visitorTypeDropdown"
              >
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      onUpdateCheckOut({
                        target: { name: "visitorTypeId", value: "" },
                      })
                    }
                  >
                    {findDisplayName(
                      "checkOutPageVisitorTypeDropdown",
                      "All Visitot"
                    )}
                  </a>
                </li>
                {sortVisitorData?.map((item, index) => (
                  <li key={index}>
                    <a
                      className="dropdown-item text-truncate"
                      onClick={() =>
                        onUpdateCheckOut({
                          target: {
                            name: "visitorTypeId",
                            value: item.visitortypeid,
                          },
                        })
                      }
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <button
              onClick={() => handleSearch()}
              className="btn btn-primary btn-sign-in btn-bloc mt-0 w-100 h-100"
            >
              <b>
                {" "}
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  className="me-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.243 18.9236C17.5691 19.2497 18.0978 19.2497 18.4238 18.9236C18.7499 18.5975 18.7499 18.0688 18.4238 17.7427L17.243 18.9236ZM14.0572 8.52925C14.0572 11.8583 11.3585 14.557 8.02949 14.557V16.227C12.2808 16.227 15.7272 12.7806 15.7272 8.52925H14.0572ZM8.02949 14.557C4.70046 14.557 2.00175 11.8583 2.00175 8.52925H0.331748C0.331748 12.7806 3.77815 16.227 8.02949 16.227V14.557ZM2.00175 8.52925C2.00175 5.20022 4.70046 2.5015 8.02949 2.5015V0.831504C3.77815 0.831504 0.331748 4.2779 0.331748 8.52925H2.00175ZM8.02949 2.5015C11.3585 2.5015 14.0572 5.20022 14.0572 8.52925H15.7272C15.7272 4.2779 12.2808 0.831504 8.02949 0.831504V2.5015ZM12.341 14.0216L17.243 18.9236L18.4238 17.7427L13.5219 12.8408L12.341 14.0216Z"
                    fill="black"
                  />
                </svg>
                {findDisplayName("checkOutPageSearchButton", "Search")}
              </b>
            </button>
          </div>
        </div>
      </div>
      {visitors.length > 0 ? <CheckoutTable data={visitors} /> : <div className={`${isView('isLandingCheckIn') ? 'card mt-2' : ''}`}>
        <div className="d-flex align-items-center justify-content-center text-white" style={{ height: "50vh", }}>
          <h4>{findDisplayName("visitorLogNoDataValidation")}</h4>
        </div>
      </div>}
    </div>
  );
};
