// store.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./actions/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session"; // Use session storage  as the storage engine

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: [
    "auth",
    "visitorType",
    "getCountryArray",
    "locationDataArr",
    "visitorIdCurrent",
    "profileData",
    "checkOut",
    "organization",
    "offlineVisitor",
    "csoReport",
    "orgLocationData",
    "userTableData",
    "orgLocationData",
    "role",
    "visitorTypeTable",
    "OrgTableData",
    "languageDataArr",
    "masterLabel",
    "orgEditData",
    "allLocationData",
    "fieldArrData",
    "languageId",
    "portID",
    "portData",
    "OrgID",
    "privileges"
  ], // Specify the reducers you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
