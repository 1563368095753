import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import "../css/style.css";
import { scaleOrdinal } from "d3-scale";
import '../css/style.css'
import { findDisplayName } from "../../../../utils/commonFunctionsAndStatices";
import DataNotFound from "../../DataNotFound/DataNotFound";
const findOrganizationNameById = (id) => {
  var root = JSON.parse(sessionStorage.getItem("persist:root"));
  var OrganizationList = JSON.parse(root.OrgTableData).Organizationtable;
  const foundItem = OrganizationList?.find(
    (item) => item.organizationid === id
  );
  return foundItem ? foundItem.name : null;
};

export default function DashboardChart2({ convertedData, loading }) {
  const [showSelectIcon, setShowSelectIcon] = useState(false);
  const [selectedOption, setSelectedOption] = useState("This Year");
  const [activeOption, setActiveOption] = useState("This Year");
  const [chartData, setChartData] = useState([]);
  const [organizationNames, setOrganizationNames] = useState({});
  const [convertedChartData, setConvertedChartData] = useState([]);

  const colorScale = scaleOrdinal().range([
    "#5FBDE7",    
    "#244955",
    "#8E9CA6",
    "#9E9C9C",
    "#217895", 
    "#4A7988",
    "#158ED0",
    "#21A1DB",
    "#091B21",
    "#165BAA",
    "#0271BE"
  ]);

  const handleSvgClick = () => {
    setShowSelectIcon((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowSelectIcon(false);
    setActiveOption(option);
  };

  function filterDataByYearUTC(data, yearOption) {
    const currentUTCYear = new Date().getUTCFullYear();
    let yearToFilter;

    if (yearOption === "This Year") {
      yearToFilter = currentUTCYear;
    } else if (yearOption === "Last Year") {
      yearToFilter = currentUTCYear - 1;
    } else {
      // If the yearOption is a specific year, use that year
      yearToFilter = parseInt(yearOption, 10);
    }

    return data.filter((entry) => {
      const entryYear = new Date(entry.CheckIn).getUTCFullYear();
      return entryYear === yearToFilter;
    });
  }

  // Example usage: Filter data for the selected year option
  const filteredData = filterDataByYearUTC(convertedData, selectedOption);

  useEffect(() => {
    const processChartData = (data) => {
      // Convert date strings to Date objects
      const parsedData = data.map((item) => ({
        ...item,
        CheckIn: new Date(item.CheckIn),
      }));

      // Group data by month and organization
      const groupedData = parsedData.reduce((result, item) => {
        const monthKey = item.CheckIn.toLocaleString("default", {
          month: "long",
        });

        if (!result[monthKey]) {
          result[monthKey] = {};
        }
        if (!result[monthKey][item.organizationid]) {
          result[monthKey][item.organizationid] = 0;
        }
        result[monthKey][item.organizationid]++;
        return result;
      }, {});

      // Get an array of month names and sort them
      const months = Object.keys(groupedData).sort(
        (a, b) =>
          new Date(Date.parse("01 " + a + " 2000")) -
          new Date(Date.parse("01 " + b + " 2000"))
      );

      // Convert the sorted month names to the required format for the chart
      const chartData = months.map((monthKey) => {
        const monthData = groupedData[monthKey];
        return {
          name: monthKey,
          ...monthData,
        };
      });

      return { chartData, months };
    };

    const convertChartDataToNames = (chartData) => {
      const convertedData = [];

      // Iterate over each entry in the chartData
      for (const entry of chartData) {
        const convertedEntry = { name: entry.name };
        // Iterate over each property in the entry (excluding 'name')
        for (const [orgId, value] of Object.entries(entry).filter(
          ([key]) => key !== "name"
        )) {
          // Convert organization ID to name
          const organizationName = findOrganizationNameById(orgId);
          // Add the converted property to the converted entry
          convertedEntry[organizationName || orgId] = value;
        }
        // Add the converted entry to the final convertedData array
        convertedData.push(convertedEntry);
      }
      return convertedData;
    };

    const { chartData } = processChartData(filteredData);

    setChartData(chartData);

    // Extract unique organization IDs and names
    const uniqueOrganizations = Array.from(
      new Set(convertedData.map((item) => item.organizationid))
    );

    // Replace organization IDs with names
    const orgNames = {};
    uniqueOrganizations.forEach((orgId) => {
      const orgData = convertedData.find(
        (item) => item.organizationid === orgId
      );
      if (orgData) {
        orgNames[orgId] = orgData.organization_name;
      }
    });

    // Set the organizationNames state
    setOrganizationNames(orgNames);

    // Set the convertedChartData state
    setConvertedChartData(convertChartDataToNames(chartData));

    // ... rest of your useEffect code
  }, [convertedData, selectedOption]);
  const sortedOrganizationNames = Object.keys(organizationNames)
  .sort((a, b) => findOrganizationNameById(a).localeCompare(findOrganizationNameById(b)));

  useEffect(() => {
    // Ensure the default option is set initially
    setSelectedOption("This Year");
  }, []);

  return (
    <>
      <div className=" row mt-4">
        <div className="bg-light rounded col-12 p-3 ">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-xxl-8 col-7" >
              <strong className="text-dark ms-3  t-wrap d-xxl-flex align-items-xxl-center"  >
           
                {findDisplayName(
                  "VisitorsByOrganizationByMonthChartHeading",
                  "Visitors by Organization by Month"
                )}
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ms-1 d-none d-xxl-block d-lg-block d-xl-block "
                >
                  <title>
                    {findDisplayName(
                      "VisitorsByOrganizationByMonthChartToolTip",
                      "To get insight on the total number of visitors by organization each month."
                    )}
                  </title>
                  <circle cx="12.5508" cy="12.5" r="12" fill="#BDBDBD" />
                  <rect
                    x="10.5508"
                    y="9.83331"
                    width="3.99999"
                    height="10.6666"
                    rx="2"
                    fill="white"
                  />
                  <rect
                    x="10.5508"
                    y="4.5"
                    width="3.99999"
                    height="3.99999"
                    rx="2"
                    fill="white"
                  />
                </svg>
              </strong>
            </div>
            <div className="col-lg-4 col-md-4 col-xxl-4 col-5">
              <>
                <div className="d-flex justify-content-end">
                  <span className="selected-text-size bg-dark p-2 text-white rounded">
                    {selectedOption}
                  </span>
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-2"
                    onClick={handleSvgClick}
                  >
                    <path
                      d="M3.07031 16.5705H17.2589H3.07031Z"
                      fill="#BDBDBD"
                    />
                    <path
                      d="M3.07031 5.33984H17.2589M3.07031 10.9552H17.2589M3.07031 16.5705H17.2589"
                      stroke="#BDBDBD"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>

                {showSelectIcon && (
                  <div className=" row mt-1 position-relative ">
                    <div
                      className=" position-absolute col-12 selected-icon"
                      aria-label="Default select example"
                      style={{ zIndex: 4 }}
                    >
                      <div
                        className="list-group position-relative row"
                        id="list-tab"
                        role="tablist"
                      >
                        <a
                          className={`list-group-item list-group-item-action col-10 ${
                            activeOption === "This Year" ? "active" : ""
                          }`}
                          id="list-home-list"
                          data-bs-toggle="list"
                          href="#list-home"
                          role="tab"
                          aria-controls="list-home"
                          onClick={() => handleOptionClick("This Year")}
                        >
                          {findDisplayName(
                            "VisitorsByOrganizationByMonthChartThisYearSelect",
                            "This Year"
                          )}
                        </a>
                        <a
                          className={`list-group-item list-group-item-action col-10 ${
                            activeOption === "Last Year" ? "active" : ""
                          }`}
                          id="list-profile-list"
                          data-bs-toggle="list"
                          href="#list-profile"
                          role="tab"
                          aria-controls="list-profile"
                          onClick={() => handleOptionClick("Last Year")}
                        >
                          {findDisplayName(
                            "VisitorsByOrganizationChartLastYearSelect",
                            "Last Year"
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
          <hr className="text-secondary mt-xxl-5 pb-xxl-1 pb-md-1 mt-lg-3  pt-lg-1 pt-md-0 pt-xxl-0 pb-lg-3 mt-md-4" />
          <div className="row">
            {loading ? (
              <div
                className=" d-flex justify-content-center gap-2 text-primary align-items-center"
                style={{
                  height: "400px",
                }}
              >
                <span
                  className="spinner-grow spinner-grow-sm text-primary pe-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                {findDisplayName(
                  "VisitorsByOrganizationChartLoading",
                  "Loading ..."
                )}
              </div>
            ) : chartData.length > 0 ? (
              <div className="col-12 d-flex pb-md-1 ">
                <ResponsiveContainer width="100%" height={400}>
                <BarChart width="95%" height={400} data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    angle={-25}
                    textAnchor="end"
                    order={chartData.map((data) => data.name)}
                    fontSize={10}
                  />
                  <YAxis domain={[0, "dataMax + 10"]} />
                  <RechartsTooltip
                    contentStyle={{
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      // Add mobile-specific content styles here if needed
                    }}
                  />
                  <Legend />
                  {sortedOrganizationNames?.map((orgName, index) => (
                    <Bar
                      key={index}
                      dataKey={orgName}
                      stackId="a"
                      fill={colorScale(orgName)}
                      name={findOrganizationNameById(orgName)}
                    />
                  ))}
                </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div
                className={` ${
                  showSelectIcon
                    ? "d-flex justify-content-center align-items-center"
                    : ""
                }`}
                style={{
                  height: "405px",
                 
                }}
              >
                {chartData.length === 0 && !showSelectIcon ? (
                  <div className="text-center ">
                    <DataNotFound />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
