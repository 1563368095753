// visitorTypeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visitortable: null, // Initialize the visitor type to null
};

const visitorTypeSlice = createSlice({
  name: "visitorTypeTable",
  initialState,
  reducers: {
    setVisitorTable: (state, action) => {
      state.visitortable = action.payload;
    },
    clearVisitorTable: (state) => {
      state.visitortable = null;
    },
  },
});

export const { setVisitorTable, clearVisitorTable } = visitorTypeSlice.actions;

export default visitorTypeSlice.reducer;
