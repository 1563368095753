import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectRoleData } from "../../../Store/actions/role/selectRole";
import { selectOrgLocation } from "../../../Store/actions/orgLocation/selectOrgLocation";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import {
  clearOrgLocation,
  setOrgLocation,
} from "../../../Store/actions/orgLocation/orgLocationReducer";
import {
  FeaturesList,
  createUser,
  orgLocation,
  userLocationAPI,
} from "../../../apis/apisServies";
import { setRoleData } from "../../../Store/actions/role/roleReducer";
import { roleData } from "../../../apis/apisServies";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import {
  findDisplayName,
  isView,
  isViewUser,
} from "../../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import PortDropdown from "../../PortDropdown/PortDropdown";
import { selectPortData } from "../../../Store/actions/portData/selectPortData";

const UserCreate = ({
  setActiveComponent,
  fetchdata,
  organizationId,
  activeButton,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
    watch,
    setValue
  } = useForm();
  const roles = useSelector(selectRoleData);
  const [privilegeList, setPrivileLsit] = useState([]);
  const { showSuccess, showError } = useNotification();
  const locationData = useSelector(selectOrgLocation);
  const loginData = useSelector(selectToken);
  const dispatch = useDispatch();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const languageId = useSelector(languageid);
  const [loading, isLoading] = useState(false);
  const [isPort, setisPort] = useState(false);
  const [showList, setShowList] = useState(false);
  const [selectedPortData, setSelectedPortData] = useState(null);
  const [orgValue, SetOrgValue] = useState(loginData.organization_id);
  const childRef = useRef(null);
  const orgData = useSelector(selectOrgTable);
  const portData = useSelector(selectPortData);
  const [roleName, setRoleName] = useState(findDisplayName("userCreateScreenRoleHeading", "Role"));
  const role = watch("userType");
  const initialOrganization =
    organizationId === ""
      ? ""
      : orgData?.find((item) => item.organizationid === organizationId)?.name;

  // sort by role name
  let sortFilteredRoles;

  if (roles && roles.length > 1) {
    sortFilteredRoles = [...roles].sort((a, b) => {
      return a.org_rolename.localeCompare(b.org_rolename);
    });
  }
  if (roles && roles.length === 1) {
    sortFilteredRoles = roles;
  }
  // get the url and set to payload
  var fullURL = window.location.href;
  var url = new URL(fullURL);
  var baseURL = url.origin + "/UpdatePassword";

  // generathe random password
  function generateRandomPassword(length) {
    var charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^*()_+";
    var password = "";

    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }

    return password;
  }

  // Generate a random password with a length of 10
  var randomPassword = generateRandomPassword(10);

  const handleLocationChange = (selectedOptions) => {
    setSelectedLocations(selectedOptions);
  };
  const handleCancel = () => {
    dispatch(clearOrgLocation());
    setActiveComponent("table");
    fetchdata(orgValue, activeButton);
  };
  const locationOptions = Array.isArray(locationData)
    ? locationData.map((item) => ({
      value: item.locationid,
      label: item.locationname,
    }))
    : [];

  const handleOrganizationChange = async (id) => {
    const Orgid = id;
    SetOrgValue(id);
    if (Orgid === "") {
      dispatch(clearOrgLocation());
      return;
    } else {
      const id = {
        userid: loginData?.login_id,
        organizationid: Orgid,
      };

      const response = await orgLocation(id);
      const locationData = response?.data?.body?.data;
      dispatch(setOrgLocation(locationData));
    }
  };

  const getLocation = async () => {
    const dataobj = {
      userid: loginData?.login_id,
    };
    try {
      const response = await orgLocation(dataobj);
      const setUserData = response?.data?.body?.data;
      dispatch(setOrgLocation(setUserData));
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const getRoleData = async (roleId) => {
    const id = {
      userid: loginData?.login_id,
      text_message: {
        language_id: languageId,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };
    if (isView("isAdminUser") === false || loginData?.organization_id) {
      id.parent_user = loginData?.role_id;
    }
    if (roleId !== "") {
      id.organizationid = roleId;
    } else {
      id.independent_user = "independent_user";
    }
    const response = await roleData(id);
    if (response?.data?.statusCode === 200) {
      const data = response?.data?.body?.data;
      dispatch(setRoleData(data));
    } else {
      setActiveComponent("table");
      showError(
        findDisplayName("userChildRoleNotAvailable", "Make sure that before creating a new user, you ensure that there's a child role.")
      );
      console.log("error");
    }
  };

  const handlePortChange = (selectedPort) => {
    if (portData) {
      const selectedPortData = portData?.find(
        (port) => port.port_name === selectedPort
      );
      if (selectedPortData) {
        const selectPort = selectedPortData.port;
        setSelectedPortData(selectPort);
      } else {
        console.error("Port not found:", selectedPort);
      }
    } else {
      console.error("Port data is undefined.");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (childRef.current && !childRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (loginData && !isView("isListOrganization")) {
      getLocation();
    }
    getRoleData(organizationId);
    if (organizationId !== "" && organizationId !== null) {
      handleOrganizationChange(organizationId);
    }
  }, []);

  const onSubmit = async (data) => {
    if (!data.userType) {
      showError(
        findDisplayName("userCreateRoleValidation", "Kindly choose a role.")
      );
    } else if (!data.firstName.trim()) {
      showError(
        findDisplayName(
          "userCreateFirstNameValidation",
          "Kindly Enter a First Name"
        )
      );
      return;
    } else if (!data.lastName.trim()) {
      showError(
        findDisplayName(
          "userCreateLastNameValidation",
          "Kindly Enter a Last Name"
        )
      );
      return;
    } else if (!data.email.trim()) {
      showError(
        findDisplayName(
          "userCreateLoginIdValidation",
          "Kindly Enter a Login ID"
        )
      );
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      showError(
        findDisplayName(
          "userCreateLoginId1Validation",
          "Kindly Enter a valid Login ID"
        )
      );
      return;
    } else {
      const dataObj = {
        hostuserid: loginData?.login_id,
        roleid: data.userType,
        firstname: data.firstName?.trim(),
        lastname: data.lastName?.trim(),
        loginid: data.email?.trim(),
        loginpassword: randomPassword?.trim(),
        confirm_url: baseURL,
        organizationid: isView("isListOrganization")
          ? orgValue
          : loginData?.organization_id,
        text_message: {
          language_id: languageId,
          ...(loginData?.organization_id
            ? { organization_id: loginData?.organization_id }
            : {}),
        },
        ...(privilegeList &&
          isViewUser(privilegeList, "isPortDetailsForUser") && {
          allowportselection: isPort,
          defaultport: selectedPortData,
        }),
      };

      isLoading(true);
      try {
        const response = await createUser(dataObj);
        if (
          response?.data?.statusCode === 201 ||
          response?.data?.statusCode === 200
        ) {
          showSuccess(response?.data?.body?.message);
          const newId = response.data.body.userid;
          const selectedLocationIds = selectedLocations?.map(
            (location) => location.value
          );
          const userObj = {
            host_userid: loginData?.login_id,
            client_userid: newId,
            client_locationid: selectedLocationIds,
            text_message: {
              language_id: languageId,
              ...(loginData?.organization_id
                ? { organization_id: loginData?.organization_id }
                : {}),
            },
          };

          if (selectedLocationIds.length > 0) {
            const update = await userLocationAPI(userObj);
            showSuccess(update?.data?.body?.message);
          }
          setActiveComponent("table");
          fetchdata(orgValue, activeButton);
          reset();
        } else if (response.data.statusCode === 404) {
          showError(response?.data?.body?.message);
          isLoading(false);
        } else if (response.data.statusCode === 504) {
          showError(response?.data?.body?.message);
          isLoading(false);
        } else {
          showError(response?.data?.body?.message);
          isLoading(false);
        }
      } catch {
        showError("something went wrong, please try again");
        isLoading(false);
      }
    }
  };

  useEffect(() => {
    const getPrivilege = async (roleId) => {
      const dataObj = {
        userid: loginData?.login_id,
        roleid: roleId,
        text_message: {
          language_id: languageId,
        },
      };

      const response = await FeaturesList(dataObj);
      if (response?.data?.statusCode === 200) {
        const data = response?.data?.body?.data;
        setPrivileLsit(data);
      } else {
        setPrivileLsit([]);
        console.log("error");
      }
    };
    if (role) {
      getPrivilege(role);
    }
  }, [role]);

  return (
    <div className="container">
      <form>
        <div className=" col-lg-12 col-md-12 col-sm-12 p-5 text-white from-scrol">
          <div className="row">
            {isView("isListOrganization") &&
              isView("isAdminUser") === false && (
                <div className="d-flex gap-3 flex-column flex-lg-row flex-md-column flex-sm-column flex-xs-column small-screen align-items-md-center mt-4">
                  <div className="form-group col-lg-4 col-md-8 col-sm-12">
                    <label htmlFor="LabelName">
                      {findDisplayName(
                        "userCreateScreenOrganization",
                        "Organization"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control  mt-2 "
                      id="Organization"
                      {...register("Organization", {})}
                      defaultValue={initialOrganization}
                      disabled
                    />
                  </div>
                </div>
              )}
            <div className="d-flex gap-3 flex-column flex-lg-row flex-md-column flex-sm-column mt-3 small-screen align-items-md-center">
              <div className="form-group col-lg-4 col-md-8 col-sm-12">
                <label htmlFor="userType">
                  {findDisplayName("userCreateScreenRoleHeading", "Role")}
                </label>
                {sortFilteredRoles?.length > 1 ? (
                  <div className="dropdown mt-2 ">
                    <button
                      className="bg-white w-100 form-select text-start "
                      id="userTypeDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {roleName}
                    </button>
                    <ul
                      className="dropdown-menu w-100 pb-2"
                      aria-labelledby="userTypeDropdown"
                    >
                      {sortFilteredRoles?.map((item, index) => (
                        <li key={index}>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setValue("userType", item.org_roleid);
                              setRoleName(item.org_rolename);
                            }
                            }
                          >
                            {item.org_rolename}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <>
                    <input
                      className="form-control mt-2"
                      type="text"
                      value={
                        sortFilteredRoles?.length === 1
                          ? sortFilteredRoles[0]?.org_rolename
                          : ""
                      }
                      readOnly
                    />
                    <input
                      name="userType"
                      type="hidden"
                      value={
                        sortFilteredRoles?.length === 1
                          ? sortFilteredRoles[0]?.org_roleid
                          : ""
                      }
                      {...register("userType")}
                    />
                  </>
                )}
                {errors.userType && showError(errors.userType.message)}
              </div>
              <div className="form-group col-lg-4 col-md-8 col-sm-12">
                <label htmlFor="firstName">
                  {findDisplayName(
                    "userCreateScreenFirstNameHeading",
                    "First Name"
                  )}
                </label>
                <input
                  type="text"
                  className={`form-control mt-2 ${errors.firstName ? "is-invalid" : ""
                    }`}
                  placeholder={findDisplayName(
                    "userCreateScreenFirstNameInputBox",
                    "First Name"
                  )}
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                  }}
                  {...register("firstName", {})}
                />
              </div>
              <div className="form-group col-lg-4 col-md-8 col-sm-12">
                <label htmlFor="lastName">
                  {findDisplayName(
                    "userCreateScreenLastNameHeading",
                    "Last Name"
                  )}
                </label>
                <input
                  type="text"
                  className={`form-control mt-2 ${errors.lastName ? "is-invalid" : ""
                    }`}
                  placeholder={findDisplayName(
                    "userCreateScreenLastNameInputBox",
                    "Last Name"
                  )}
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                  }}
                  {...register("lastName", {})}
                />
              </div>
            </div>
            <div className="d-flex gap-3 flex-column  flex-lg-row flex-md-column flex-sm-column small-screen mt-4 align-items-md-center align-items-lg-start">
              <div className="form-group col-lg-4 col-md-8 col-sm-12">
                <label htmlFor="email">
                  {findDisplayName(
                    "userCreateScreenLoginIDHeading",
                    "Login ID"
                  )}
                </label>
                <input
                  type="email"
                  id="email"
                  className={`form-control mt-2 ${errors.email ? "is-invalid" : ""
                    }`}
                  placeholder={findDisplayName(
                    "userCreateScreenLoginIDInputBox",
                    "E-Mail"
                  )}
                  onInput={(event) => {
                    // Remove spaces from the input value
                    event.target.value = event.target.value.replace(/\s/g, "");
                  }}
                  autoComplete="off"
                  {...register("email", {})}
                />
              </div>
              {privilegeList &&
                isViewUser(privilegeList, "isLocationRequiredForUser") && (
                  <div className="form-group   col-xxl-9  col-lg-10 col-md-8 col-sm-12 ">
                    <div className="row">
                      <div className="col-xxl-11  col-lg-10 col-md-12 ">
                        <label htmlFor="userLocation">
                          {findDisplayName(
                            "userCreateScreenLocationHeading",
                            "Location"
                          )}
                        </label>
                        <Select
                          className="form-control mt-2"
                          options={locationOptions}
                          isMulti
                          value={selectedLocations}
                          onChange={handleLocationChange}
                          closeMenuOnSelect={false}
                          placeholder={findDisplayName(
                            "userCreateScreenLocationInputBox",
                            "Location"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {privilegeList &&
              isViewUser(privilegeList, "isPortDetailsForUser") && (
                <div className="d-flex gap-3 flex-column  flex-lg-row flex-md-column flex-sm-column small-screen mt-4 align-items-md-center align-items-lg-start">
                  <div className=" col-lg-4 col-md-8 col-sm-12 mt-lg-5 mt-3">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      id="isPort"
                      checked={isPort}
                      onChange={(e) => setisPort(e.target.checked)}
                    />
                    <label htmlFor="isPort ">
                      {findDisplayName(
                        "userCreateScreenAllowPortCheckBox",
                        "Allow Port"
                      )}
                    </label>
                  </div>
                  <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-lg-3 mt-3 position-relative">
                    <label htmlFor="customInput">
                      {findDisplayName(
                        "userCreateScreenDefaultPortHeading",
                        "Default Port"
                      )}
                    </label>
                    <PortDropdown
                      items={portData}
                      keys={["port_name", "port_id"]}
                      placeholder={findDisplayName(
                        "userCreateScreenDefaultPortInputBox",
                        "Default Port"
                      )}
                      {...register("Portname", {})}
                      valueChange={handlePortChange}
                      showList={showList}
                      setShowList={setShowList}
                      childRef={childRef}
                      style={"100%"}
                      defaultValue={""}
                    />
                  </div>
                </div>
              )}

            <div className="d-flex row gap-3 flex-column flex-lg-row flex-md-column flex-sm-column flex-xs-column small-screen align-items-center mt-4">
              <div className="d-flex justify-content-lg-end justify-content-center  gap-2">
                <button
                  className="btn btn-primary me-2"
                  onClick={handleSubmit(onSubmit)}
                  disabled={loading}
                >
                  {loading ? (
                    "Creating..."
                  ) : (
                    <>
                      {findDisplayName(
                        "userCreateScreenCreateButton",
                        "Create"
                      )}
                    </>
                  )}
                </button>
                <button
                  className="btn bg-secondary text-light"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  {findDisplayName("userCreateScreenCancelButton", "Cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserCreate;
