import React, { useEffect } from "react";
import FieldTable from "./FieldTable/FieldTable";
import Fieldcreate from "./FieldCreate/Fieldcreate";
import FieldUpdate from "./FieldUpdate/FieldUpdate";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import OrganizationList from "../OrganizationList/OrganizationList";
import { useState } from "react";
import { selectOrgTable } from "../../Store/actions/Organizationadmin/OrganizationSelector";
import { selectFieldTable } from "../../Store/actions/FieldTableData/FieldTableSelector";
import { FieldTableAPI } from "../../apis/apisServies";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import { setFieldData } from "../../Store/actions/FieldTableData/FieldTableReducer";
import {
  findDisplayName,
  handleCommonSearch,
  isCreate,
  isUpdate,
  isView,
} from "../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { selectOrgID } from "../../Store/actions/organizationId/organizationIdSeelct";
import { setOrganuzationID } from "../../Store/actions/organizationId/organizationIdReducer";

const Field = () => {
  const orgData = useSelector(selectOrgTable);
  const selectOrgId = useSelector(selectOrgID);
  const sortOrgData =
    orgData && [...orgData].sort((a, b) => a.name.localeCompare(b.name));
  const [activeComponent, setActiveComponent] = useState("table");
  const [activeButton, setActiveButton] = useState("check-In");
  const { showSuccess, showError } = useNotification();
  const [Fieldvalue, setField] = useState(null);
  const [loading, setLoading] = useState(false);
  const FieldData = useSelector(selectFieldTable);
  const [searchList, setSearchList] = useState(FieldData);
  const [searchBox, setSearchBox] = useState("");
  const [checkInCount, setCheckInCount] = useState("");
  const [checkOutCount, setCheckOutCount] = useState("");
  const [organizationFieldCount, setOrganizationFieldCount] = useState("");
  const loginData = useSelector(selectToken);
  const organizationid = isView("isListOrganization")
    ? selectOrgId !== ""
      ? selectOrgId
      : sortOrgData[0]?.organizationid
    : loginData?.organization_id;
  const [orgid, setOrgid] = useState(organizationid);
  const dispatch = useDispatch();
  const languageID = useSelector(languageid);

  const handleButtonClick = (status) => {
    setSearchList([]);
    dispatch(setFieldData([]));
    setSearchBox("");
    setActiveButton(status);
    fetchdata(orgid, status);
  };

  const fetchdata = async (orgValue, status) => {
    setCheckInCount("");
    setCheckOutCount("");
    setOrganizationFieldCount("");
    setSearchBox("");
    dispatch(setOrganuzationID(orgValue));
    setOrgid(orgValue);
    const datobj = {
      userid: loginData?.login_id,
      ...(orgValue !== "" ? { organization_id: orgValue } : {}),
      isactive: status === "inactive" ? false : true,
      ...(status === "check-In" || status === "check-Out" || status === "organization-field"
        ? { display_group: status }
        : {}),
      text_message: {
        language_id: languageID,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };
    if (orgid === "" || status === "organization-field") {
      datobj.organization_detail = "organization_detail"
    }

    try {
      const response = await FieldTableAPI(datobj);
      if (response?.data?.statusCode === 200) {
        setLoading(true);
        const FieldData = response?.data?.body?.data;
        const checkIn = response?.data?.body?.count?.checkin_count;
        const checkOut = response?.data?.body?.count?.checkout_count;
        const org_field = response?.data?.body?.count?.organization_field_count;
        {
          checkIn && setCheckInCount(checkIn);
        }
        {
          checkOut && setCheckOutCount(checkOut);
        }
        { org_field && setOrganizationFieldCount(org_field)}
        setSearchList(FieldData);
        dispatch(setFieldData(FieldData));
      } else if (response.data.statusCode === 404) {
        setLoading(true);
        setCheckInCount(0);
        setCheckOutCount(0);
        setSearchList([]);
        dispatch(setFieldData([]));
      }
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };

  const handleOrganizationChange = (organizationid) => {
    setSearchList([]);
    dispatch(setFieldData([]));
    organizationid !== "" ? fetchdata(organizationid, "check-In") : fetchdata(organizationid, "organization-field");
    organizationid !== "" ? setActiveButton("check-In") : setActiveButton("organization-field");
  };

  const handleSearch = (search) => {
    setSearchBox(search);
    const filterData = handleCommonSearch(search, FieldData, "field_name");
    setSearchList(filterData);
  };

  useEffect(() => {
    fetchdata(orgid, "check-In");
  }, []);

  return (
    <div>
      <nav className="nav-dashboard">
        <strong className="links">
          {" "}
          {findDisplayName("fieldHeading", "Field")}
        </strong>
        {activeComponent === "table" && isCreate("isDashboardFields") && (
          <button
            type="button"
            className="btn btn-primary mb-2"
            id="create-user-button"
            onClick={() => setActiveComponent("create")}
          >
            {" "}
            {findDisplayName("fieldCreateFieldButton", "Create Field")}
          </button>
        )}
      </nav>
      {activeComponent === "table" && isView("isListOrganization") && (
        <div className="form-group col-lg-4 col-md-5 col-sm-10 col-xs-10 ps-3 pe-5">
          <OrganizationList
            handleChange={handleOrganizationChange}
            initialvalue={orgid}
            organizatonInUser={"common"}
          />
        </div>
      )}
      {activeComponent === "table" && isView("isDashboardFields") && (
        <>
          <div className="d-flex justify-content-between">
            <div
              className="btn-group ms-3 mt-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              {orgid === "" && (
                <button
                  className={`btn toggle-button ${activeButton === "organization-field" ? "active" : ""
                    }`}
                  onClick={() => handleButtonClick("organization-field")}
                >{findDisplayName("fieldScreenOrganizationButton", "Organization")}</button>
              )}

              {orgid !== "" && (
                <>
                  <button
                    className={`btn toggle-button ${activeButton === "check-In" ? "active" : ""
                      }`}
                    onClick={() => handleButtonClick("check-In")}
                  >
                    {findDisplayName("fieldScreenCheckInButton", "Check In")}
                  </button>
                  <button
                    className={`btn toggle-button ${activeButton === "check-Out" ? "active" : ""
                      }`}
                    onClick={() => handleButtonClick("check-Out")}
                  >
                    {findDisplayName("fieldScreenCheckOutButton", "Check Out")}
                  </button>
                </>
              )
              }
              <button
                className={`btn toggle-button ${activeButton === "inactive" ? "active" : ""
                  }`}
                onClick={() => handleButtonClick("inactive")}
              >
                {findDisplayName("fieldScreenInactiveButton", "Inactive")}
              </button>
            </div>
            <div className="mt-3 ms-2 me-2">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={findDisplayName(
                    "fieldScreenSearchInputBox",
                    "Field Name"
                  )}
                  value={searchBox}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <span className="input-group-text">
                  <svg
                    className="bi bi-search"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                      clipRule="evenodd"
                    />

                    <path
                      fillRule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <hr className="text-white mt-0 mb-0 ms-3 me-3" />

          <FieldTable
            FieldData={searchList}
            setActiveComponent={setActiveComponent}
            setField={setField}
            loading={loading}
            fetchdata={fetchdata}
            organizationId={orgid}
            activeButton={activeButton}
            checkInCount={checkInCount}
            checkOutCount={checkOutCount}
            organizationFieldCount={organizationFieldCount}
            handleButtonClick={handleButtonClick}
          />
        </>
      )}
      {activeComponent === "create" && isCreate("isDashboardFields") && (
        <Fieldcreate
          setActiveComponent={setActiveComponent}
          fetchdata={fetchdata}
          organizationId={orgid}
          activeButton={activeButton}
          checkInCount={checkInCount}
          checkOutCount={checkOutCount}
          organizationFieldCount={organizationFieldCount}
        />
      )}
      {activeComponent === "update" && isUpdate("isDashboardFields") && (
        <FieldUpdate
          Fieldvalue={Fieldvalue}
          setActiveComponent={setActiveComponent}
          fetchdata={fetchdata}
          organizationId={orgid}
          activeButton={activeButton}
        />
      )}
    </div>
  );
};

export default Field;
