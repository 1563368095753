import React, { useEffect, useState } from "react";
import UserTable from "./UserTable/UserTable";
import { useDispatch, useSelector } from "react-redux";
import { setUserTable } from "../../Store/actions/userTableData/userTableDataReducer";
import {
  OrganizationUserTable,
  orgLocation,
  roleData,
} from "../../apis/apisServies";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { selectUserTable } from "../../Store/actions/userTableData/userTableDataSelector";
import UserCreate from "./UserCreate/UserCreate";
import OrganizationList from "../OrganizationList/OrganizationList";
import UserEdit from "./UserEdit/UserEdit";
import { setOrgLocation } from "../../Store/actions/orgLocation/orgLocationReducer";
import { setRoleData } from "../../Store/actions/role/roleReducer";
import "./CSS/style.css";
import {
  findDisplayName,
  handleCommonSearch,
  isCreate,
  isUpdate,
  isView,
} from "../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { selectOrgID } from "../../Store/actions/organizationId/organizationIdSeelct";
import { selectOrgTable } from "../../Store/actions/Organizationadmin/OrganizationSelector";
import { setOrganuzationID } from "../../Store/actions/organizationId/organizationIdReducer";
import { useNotification } from "../../NotificationProvider/NotificationProvider";

const Users = () => {
  const userData = useSelector(selectUserTable);
  const selectOrgId = useSelector(selectOrgID);
  const orgData = useSelector(selectOrgTable);
  const dispatch = useDispatch();
  const loginData = useSelector(selectToken);
  const sortOrgData =
    orgData && [...orgData].sort((a, b) => a.name.localeCompare(b.name));
  const organizationid = isView("isListOrganization")
    ? selectOrgId !== ""
      ? selectOrgId
      : sortOrgData[0]?.organizationid
    : loginData?.organization_id;
  const [orgid, setOrgid] = useState(organizationid);
  const [user, setUser] = useState(null);
  const languageID = useSelector(languageid);
  const [searchList, setSearchList] = useState(userData);
  const [searchBox, setSearchBox] = useState("");
  const [activeButton, setActiveButton] = useState("active");
  const [activeComponent, setActiveComponent] = useState("table");
  const [searchNameBox, setSearchNameBox] = useState("");
  const [childRole, setChildRole] = useState(false);
  const { showError } = useNotification();

  const handleButtonClick = (status) => {
    setSearchBox("");
    setSearchNameBox("");
    setActiveButton(status);
    fetchdata(orgid, status);
  };

  const fetchdata = async (orgValue, status) => {
    setSearchBox("");
    setSearchNameBox("");
    setOrgid(orgValue);
    const datobj = {
      userid: loginData?.login_id,
      isactive: status === "active" ? true : false,
      ...(orgValue !== "" ? { organizationid: orgValue } : {}),
      text_message: {
        language_id: languageID,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };
    try {
      const response = await OrganizationUserTable(datobj);
      if (response?.data?.statusCode === 200) {
        const setUserData = response?.data?.body?.data;
        setSearchList(setUserData);
        dispatch(setUserTable(setUserData));
      } else if (response.data.statusCode === 404) {
        setSearchList([]);
        dispatch(setUserTable([]));
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleOrganizationChange = (organizationid) => {
    dispatch(setOrganuzationID(organizationid));
    fetchdata(organizationid, activeButton);
    getRoleData(organizationid);
  };

  const getLocation = async () => {
    const dataobj = {
      userid: loginData?.login_id,
    };
    try {
      const response = await orgLocation(dataobj);
      const setUserData = response?.data?.body?.data;
      dispatch(setOrgLocation(setUserData));
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const getRoleData = async (roleId) => {
    const id = {
      userid: loginData?.login_id,
      // organizationid: roleId,
      text_message: {
        language_id: languageID,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    if (roleId !== "") {
      id.organizationid = roleId;
    } 
    if (loginData?.organization_id) {
      id.parent_user = loginData?.role_id;
    }
    else {
      id.independent_user = "independent_user";
    }
    const response = await roleData(id);
    if (response?.data?.statusCode === 200) {
    const data = response?.data?.body?.data;
    dispatch(setRoleData(data));
    } else{
      setChildRole(true);
    }
  };

  const handleEmailSearch = (search) => {
    setSearchBox(search);
    const filterData = handleCommonSearch(search, userData, "login_id");
    setSearchList(filterData);
  };

  const handleNameSearch = (search) => {
    setSearchNameBox(search);
    const searchValue = search?.toLowerCase();
    const filterData =
      Array.isArray(userData) &&
      userData?.filter((item) => {
        const name = `${item.first_name} ${item.last_name}`;
        return name && name.toLowerCase().includes(searchValue);
      });
    setSearchList(filterData);
    if (filterData?.length === 0) {
      setSearchList([]);
    }
  };

  useEffect(() => {
    fetchdata(
      isView("isListOrganization")
        ? selectOrgId !== ""
          ? selectOrgId
          : sortOrgData[0]?.organizationid
        : loginData?.organization_id,
      "active"
    );
    if (loginData && !isView("isListOrganization")) {
      getLocation();
    }
    getRoleData(
      isView("isListOrganization")
        ? selectOrgId !== ""
          ? selectOrgId
          : sortOrgData[0]?.organizationid
        : loginData?.organization_id
    );
  }, []);

  return (
    <>
      <nav className="nav-dashboard">
        <strong className="links">
          {findDisplayName("userHeading", "User")}
        </strong>
        {activeComponent === "table" && isCreate("isDashboardUser") && (
          <button
            type="button"
            className="btn btn-primary mb-2"
            id="create-user-button"
            onClick={() => {childRole ? showError(
              findDisplayName("userChildRoleNotAvailable","Make sure that before creating a new user, you ensure that there's a child role.")
              ) : setActiveComponent("create")}}
          >
            {findDisplayName("userScreenCreateUserButton", "Create User")}
          </button>
        )}
      </nav>
      {activeComponent === "table" && isView("isListOrganization") && (
        <div className="form-group col-lg-4 col-md-5 col-sm-10 col-xs-10 ps-3 pe-5">
          <OrganizationList
            handleChange={handleOrganizationChange}
            initialvalue={orgid}
            organizatonInUser={true}
          />
        </div>
      )}
      {activeComponent === "table" && isView("isDashboardUser") && (
        <>
          <div className="d-flex justify-content-between">
            <div
              className="btn-group ms-3 mt-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <button
                className={`btn toggle-button ${
                  activeButton === "active" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("active")}
              >
                {findDisplayName("userScreenActiveButton", "Active")}
              </button>
              <button
                className={`btn toggle-button ${
                  activeButton === "inactive" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("inactive")}
              >
                {findDisplayName("userScreenInactiveButton", "Inactive")}
              </button>
            </div>
            <div className="ms-2 me-2 mt-2">
              <div className="row justify-content-end">
                <div className="col-5">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={searchNameBox}
                      placeholder={findDisplayName(
                        "userScreenSearchNameInputBox",
                        "Name"
                      )}
                      onChange={(e) => handleNameSearch(e.target.value)}
                    />
                    <span className="input-group-text">
                      <svg
                        className="bi bi-search"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                          clipRule="evenodd"
                        />

                        <path
                          fillRule="evenodd"
                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="col-5">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={findDisplayName(
                        "userScreenSearchInputBox",
                        "Login ID"
                      )}
                      value={searchBox}
                      onChange={(e) => handleEmailSearch(e.target.value)}
                    />
                    <span className="input-group-text">
                      <svg
                        className="bi bi-search"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                          clipRule="evenodd"
                        />

                        <path
                          fillRule="evenodd"
                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="text-white mt-0 mb-0 ms-3 me-3" />
          <UserTable
            userData={searchList}
            setActiveComponent={setActiveComponent}
            setUser={setUser}
            organizationid={orgid}
            fetchdata={fetchdata}
            activeButton={activeButton}
            childRole={childRole}
          />
        </>
      )}
      {activeComponent === "create" && isCreate("isDashboardUser") && (
        <UserCreate
          setActiveComponent={setActiveComponent}
          fetchdata={fetchdata}
          organizationId={orgid}
          activeButton={activeButton}
        ></UserCreate>
      )}
      {activeComponent === "update" && isUpdate("isDashboardUser") && (
        <UserEdit
          user={user}
          setActiveComponent={setActiveComponent}
          organizationid={orgid}
          fetchdata={fetchdata}
          activeButton={activeButton}
        />
      )}
    </>
  );
};

export default Users;
