import "./App.css";
import React from "react";
import { CheckInPage } from "./Component/Pages/CheckIn/CheckInPage";
import AgreeScreen from "./Component/Pages/AgreeScreen/AgreeScreen";
import { Header } from "./Component/Pages/Header/Header";
import { SignInPage } from "./Component/Pages/SignIn/SignInPage";
import VideoScreen from "./Component/Pages/VideoScreen/VideoScreen";
import { Routes, Route, useNavigate } from "react-router-dom";
import CheckoutTable from "./Component/Pages/CheckoutTable/CheckoutTable";
import { FormScreen } from "./Component/Pages/Formpage/Formscreen";
import Capture from "./Component/Pages/Capture/Capture";
import { ProfileScreen } from "./Component/Pages/ProfileScreen/ProfileScreen";
import { CheckoutScreen } from "./Component/Pages/CheckoutScreen/CheckoutScreen";
import PrivateRoutes from "./ProtectRoutes/ProtectedRoute";
import PageNotFound from "./Component/Pages/PageNotFound/PageNotFound";
import { useEffect, useState } from "react";
import { checkInApi } from "./Component/apis/apisServies";
import { useDispatch } from "react-redux";
import { setVisitorId } from "./Component/Store/actions/visitorId/visitorIdReducer";
import { useNotification } from "./Component/NotificationProvider/NotificationProvider";
import { postTermConditionApi } from "./Component/apis/apisServies";
import { useSelector } from "react-redux";
import { captureAPI } from "./Component/apis/apisServies";
import { checkOutConfirmation } from "./Component/apis/apisServies";
import { setOrganization } from "./Component/Store/actions/getorganization/organizationSelector";
import background from "./Asset/Img/bg-img.png";
import { Csofilter } from "./Component/Pages/Csofilter/Csofilter";
import OrganizationDetails from "./Component/Pages/OrganizationDetails/OrganizationDetails";
import { selectToken } from "./Component/Store/actions/auth/authSelectors";
import OrganizationScreen from "./Component/Pages/Organization/OrganizationScreen";
import OrganizationCreate from "./Component/Pages/OrganizationCreate/OrganizationCreate";
import OrganizationEdit from "./Component/Pages/OrganizationEdit/OrganizationEdit";
import Users from "./Component/Pages/Users/Users";
import Location from "./Component/Pages/Location/Location";
import Languages from "./Component/Pages/Languages/Languages";
import Visitor from "./Component/Pages/VisitorTypes/Visitor";
import Field from "./Component/Pages/Field/Field";
import MasterLanguage from "./Component/Pages/MasterLanguage/MasterLanguage";
import VisitorDetails from "./Component/Pages/VisitorDetails/VisitorDetails";
import ForgotPassword from "./Component/Pages/SignIn/ForgotPassword/ForgotPassword";
import UpdatePassword from "./Component/Pages/SignIn/UpdatePassword/UpdatePassword";
import Dashboard from "./Component/Pages/Dashboard/Dashboard";
import PortDetail from "./Component/Pages/Port/PortDetails";
import Features from "./Component/Pages/Features/Features";
import Role from "./Component/Pages/Role/Role";
import { isCreate, isUpdate, isView } from "./utils/commonFunctionsAndStatices";
import OrganizationFeatures from "./Component/Pages/OrganizationFeature/OrganizationFeatures";
import { VisitorLogArchival } from "./Component/Pages/VisitorLogArchive/VisitorLogScreen";
import { selectMasterLabel } from "./Component/Store/actions/masterLabel/masterLabelSelector";
import ErrorPage from "./Component/Pages/PageNotFound/ErrorPage";

function App() {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useNotification();
  const masterData = useSelector(selectMasterLabel);
  const navigate = useNavigate();
  const [newBG, setNewBG] = useState(background);
  const logo = useSelector(setOrganization);
  const loginData = useSelector(selectToken);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const byteArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  };

  const imageUpload = async (image, id) => {
    if (image) {
      const blob = dataURItoBlob(image);
      const formData = new FormData();
      // Append the blob data with the key 'file'
      formData.append("file", blob, "image.png");
      // Append the visitor ID
      formData.append("visitorid", id);
      try {
        const response = await captureAPI(formData);
        if (response) {
          return true;
        }
      } catch (error) {
        showError("An error occurred while uploading the image.");
        return false;
      }
    }
  };

  const updateVisitorid = (local_id, online_id) => {
    var data = JSON.parse(sessionStorage.getItem("checkout"));
    if (data) {
      data.map((item) => {
        const innerObject = item[local_id];
        if (innerObject) {
          innerObject.visitorid = online_id;
        }
      });
      sessionStorage.setItem("checkout", JSON.stringify(data));
    }
  };

  const checkoutAPI = async () => {
    //get the checkout count
    const checkout_list = JSON.parse(sessionStorage.getItem("checkout"));
    if (checkout_list) {
      checkout_list.map(async (item) => {
        for (const [key, value] of Object.entries(item)) {
          const response = await checkOutConfirmation(value);
          if (response.data.statusCode === 200) {
          }
        }
      });
      sessionStorage.removeItem("checkout");
      navigate("/CheckInPage");
    }
  };

  //get offline visitors
  const getVisitors = () => {
    var data = JSON.parse(sessionStorage.getItem("visitor"));
    return data;
  };

  const postTermsAndConditions = async (visitor_terms, current_id) => {
    if (visitor_terms) {
      visitor_terms.visitorid = current_id;
      const response = await postTermConditionApi(visitor_terms);
      if (response.data.statusCode === 200) {
        return true;
      }
      return false;
    }
  };

  // checkIn POST API call after user back to online
  useEffect(() => {
    const interval = setInterval(async () => {
      if (navigator.onLine) {
        var visitor_list = getVisitors();
        if (visitor_list) {
          for (const key in visitor_list) {
            if (visitor_list.hasOwnProperty(key)) {
              const visitor_obj = visitor_list[key];
              const response = await checkInApi(visitor_obj["checkin"]);
              if (response.data.statusCode === 201) {
                var current_id = response?.data?.body?.visitorid;
                showSuccess("success checkin: " + current_id);
                updateVisitorid(key, current_id);
                dispatch(setVisitorId(current_id));
                if (current_id != "") {
                  var image_upload = imageUpload(
                    visitor_obj["img"],
                    current_id
                  );
                  if (image_upload) {
                    var terms_upload = postTermsAndConditions(
                      visitor_obj["terms"],
                      current_id
                    );
                  }
                }
              }
            }
          }
          sessionStorage.removeItem("visitor");
          sessionStorage.removeItem("VisitorCounter");
        }
        checkoutAPI();
      }
      console.log("I am running every 10 seconds");
    }, 10000);

    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    if (logo && logo.background) {
      const bgImage = new Image();
      bgImage.src = logo.background;
      bgImage.onload = () => {
        setNewBG(logo.background);
      };
    }
    if (logo === null) {
      setNewBG(background);
    }
  }, [logo]);

  return (
    <div
      className="bg-image"
      style={{
        height: "100vh",
        background: `url("${newBG}") rgba(53, 145, 212, 0.75)`,
      }}
    >
      <div className="header-h" style={{ height: "10vh" }}>
        <Header />
      </div>
      <div className=" mainDiv ">
        <Routes>
          <Route index path="/" element={<SignInPage />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/UpdatePassword" element={<UpdatePassword />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/permission" element={<ErrorPage />} />
            {isView("isCheckIn") && (
              <>
                <Route path="/Capture" element={<Capture />} />
                <Route path="/CheckInPage" element={<CheckInPage />} />
                <Route path="/VideoScreen" element={<VideoScreen />} />
                <Route path="/AgreeScreen" element={<AgreeScreen />} />
                <Route path="/Formscreen" element={<FormScreen />} />
                <Route path="/PortDetail" element={<PortDetail />} />
              </>
            )}
            {(isView("isCheckOut") || isView("isPOB")) && (
              <>
                <Route path="/CheckoutScreen" element={<CheckoutScreen />} />
                <Route path="/CheckoutTable" element={<CheckoutTable />} />
                <Route path="/ProfileScreen" element={<ProfileScreen />} />
              </>
            )}

            {isView("isMenuVisitorLogArchival") && (
              <Route
                path="/visitor-log-archival"
                element={<VisitorLogArchival />}
              />
            )}
            {isView("isMenuVisitorLog") && (
              <Route path="/CsoReport" element={<Csofilter />} />
            )}
            {(isView("isLandingDashboard") || isView("isMenuDashboard")) && (
              <>
                <Route
                  path="/Organization_details"
                  element={<OrganizationDetails />}
                >
                  {isView("isDashboardStartOrganization") && (
                    <Route index element={<OrganizationScreen />} />
                  )}
                  {isView("isDashboardStartLocation") && (
                    <Route index element={<Location />} />
                  )}

                  {isView("isDashboardStartUser") && (
                    <Route index element={<Users />} />
                  )}

                  {isView("isDashboardStartVisitorLog") && (
                    <Route index element={<Csofilter />} />
                  )}

                  {isView("isDashboardLabels") && (
                    <Route
                      path="/Organization_details/Label"
                      element={<Languages />}
                    />
                  )}

                  {(isView("isDashboardUser") ||
                    isView("isDashboardStartUser")) && (
                    <Route
                      path={
                        isView("isDashboardStartUser")
                          ? "/Organization_details"
                          : "/Organization_details/User"
                      }
                      element={<Users />}
                    />
                  )}
                  {(isView("isDashboardLocationName") ||
                    isView("isDashboardStartLocation")) && (
                    <Route
                      path={
                        isView("isDashboardStartLocation")
                          ? "/Organization_details"
                          : "/Organization_details/Location"
                      }
                      element={<Location />}
                    />
                  )}
                  {(isView("isDashboardVisitorLog") ||
                    isView("isDashboardStartVisitorLog")) && (
                    <Route
                      path={
                        isView("isDashboardStartVisitorLog")
                          ? "/Organization_details"
                          : "/Organization_details/CsoReport"
                      }
                      element={<Csofilter />}
                    />
                  )}
                  {isView("isDashboardVisitorType") && (
                    <Route
                      path="/Organization_details/Visitor"
                      element={<Visitor />}
                    />
                  )}
                  {isView("isDashboardFields") && (
                    <Route
                      path="/Organization_details/field"
                      element={<Field />}
                    />
                  )}
                  {isCreate("isDashboardOrganization") && (
                    <Route
                      path="/Organization_details/OrganizationCreate"
                      element={<OrganizationCreate />}
                    />
                  )}
                  {isUpdate("isDashboardOrganization") && (
                    <Route
                      path="/Organization_details/OrganizationEdit"
                      element={<OrganizationEdit />}
                    />
                  )}

                  {isView("isDashboardLanguage") && (
                    <Route
                      path="/Organization_details/Language"
                      element={<MasterLanguage />}
                    />
                  )}

                  {isView("isDashboardFeatures") && (
                    <Route
                      path="/Organization_details/Features"
                      element={<Features />}
                    />
                  )}
                  {isView("isDashboardRole") && (
                    <Route
                      path="/Organization_details/Role"
                      element={<Role />}
                    />
                  )}
                  {isView("isDashboardOrganizationFeatures") && (
                    <Route
                      path="/Organization_details/Organization_features"
                      element={<OrganizationFeatures />}
                    />
                  )}
                  {isView("isDashboardVisitorLogArchival") && (
                    <Route
                      path="/Organization_details/visitor-log-archival"
                      element={<VisitorLogArchival />}
                    />
                  )}
                </Route>
                {(isView("isMenuAnalytics") ||
                  isView("isLandingAnalytical")) && (
                  <Route path="/Dashboard" element={<Dashboard />} />
                )}
              </>
            )}
            {isView("isVisitorLogProfile") && (
              <Route path="/VisitorDetails" element={<VisitorDetails />} />
            )}
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
