import React, { useEffect, useState } from 'react'

const DynamicFields = ({ fieldData, dynamicFieldsRef }) => {

    const [selectedFieldValues, setSelectedFieldValues] = useState({});
    const [numberFieldValues, setNumberFieldValues] = useState({});
    const [booleanFieldValues, setBooleanFieldValues] = useState({});
    const [textFieldValues, setTextFieldValues] = useState({});
    const [dateFieldValues, setDateFieldValues] = useState({});
    const [timeFieldValue, setTimeFieldValues] = useState({});
    const [radioFieldValues, setRadioFieldValues] = useState({});

    useEffect(() => {
        fieldData?.forEach(field => {
            switch (field.field_type) {
                case "Select":
                    setSelectedFieldValues(prevValues => ({
                        ...prevValues,
                        [field.organization_field_id]: field.field_value || ""
                    }));
                    break;
                case "Number":
                    setNumberFieldValues(prevValues => ({
                        ...prevValues,
                        [field.organization_field_id]: field.field_value || ""
                    }));
                    break;
                case "Boolean":
                    setBooleanFieldValues(prevValues => ({
                        ...prevValues,
                        [field.organization_field_id]: field.field_value === "true"
                    }));
                    break;
                case "Text":
                    setTextFieldValues(prevValues => ({
                        ...prevValues,
                        [field.organization_field_id]: field.field_value || ""
                    }));
                    break;
                case "Date":
                    setDateFieldValues(prevValues => ({
                        ...prevValues,
                        [field.organization_field_id]: field.field_value || ""
                    }));
                    break;
                case "Time":
                    setTimeFieldValues(prevValues => ({
                        ...prevValues,
                        [field.organization_field_id]: field.field_value || ""
                    }));
                    break;
                case "Radio":
                    setRadioFieldValues(prevValues => ({
                        ...prevValues,
                        [field.organization_field_id]: field.field_value === "true"
                    }));
                    break;
                default:
                    break;
            }
        });
    }, [fieldData]);

    const renderField = (field, index) => {
        let isFieldRequired = field.is_default;
        switch (field.field_type) {
            case "Select":
                return (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
                        <label >{field.field_name}</label>
                        <div className="dropdown ">
                            <button
                                className=" bg-white w-100 form-select text-start "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {selectedFieldValues[field.organization_field_id]
                                    ? field.items.find(
                                        (item) =>
                                            item.detail_key ===
                                            selectedFieldValues[field.organization_field_id]
                                    )?.detail_value
                                    : "Select"}
                            </button>
                            <ul className="dropdown-menu w-100 pb-2">
                                {
                                    field.items.map((item) => (
                                        <li key={item.organization_field_details_id}>
                                            <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                    handleSelectFieldChange(
                                                        { target: { value: item.detail_key } },
                                                        field.organization_field_id
                                                    )
                                                }
                                            >
                                                {item.detail_value}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                );

            case "Number":
                const numberFieldValue =
                    numberFieldValues[field.organization_field_id] || "";
                const inputWidth = field?.field_length < 4 ? field?.field_length * 25 : field?.field_length * 16;
                return (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
                        <label >{field.field_name}</label>
                        <br />
                        <input
                            type="text"
                            className="form-control "
                            id={field.organization_field_id}
                            // placeholder={field.field_name}
                            maxLength={field?.field_length}
                            autoComplete="off"
                            onChange={(e) =>
                                setNumberFieldValues((prevValues) => ({
                                    ...prevValues,
                                    [field.organization_field_id]: e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    ),
                                }))
                            }
                            value={numberFieldValue}
                            required={isFieldRequired ? true : false}
                            style={{ width: `${inputWidth}px` }}
                        />
                    </div>
                );

            case "Boolean":
                const booleanFieldValue =
                    booleanFieldValues[field.organization_field_id] || false;
                return (
                    <div
                        key={index}
                        className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4"
                    >
                        <div className='mt-3'>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={field.organization_field_id}
                                autoComplete="off"
                                onChange={() =>
                                    setBooleanFieldValues((prevValues) => ({
                                        ...prevValues,
                                        [field.organization_field_id]:
                                            !prevValues[field.organization_field_id],
                                    }))
                                }
                                checked={booleanFieldValue}
                                required={isFieldRequired ? true : false}
                            />
                            <label
                                className="form-check-label ms-2"
                                htmlFor={field.organization_field_id}
                            >
                                {field.field_name}
                            </label>
                        </div>
                    </div>
                );
            case "Date":
                const dateFieldValue =
                    dateFieldValues[field.organization_field_id] || "";
                return (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
                        <label htmlFor={field.organization_field_id}>
                            {field.field_name}
                        </label>
                        <input
                            type="date"
                            className="form-control "
                            id={field.organization_field_id}
                            // placeholder={field.field_name}
                            onChange={(e) =>
                                setDateFieldValues((prevValues) => ({
                                    ...prevValues,
                                    [field.organization_field_id]: e.target.value,
                                }))
                            }
                            value={dateFieldValue}
                            required={isFieldRequired ? true : false}
                        />
                    </div>
                );

            case "Time":
                const timeFieldValues =
                    timeFieldValue[field.organization_field_id] || "";
                return (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
                        <label htmlFor={field.organization_field_id}>
                            {field.field_name}
                        </label>
                        <input
                            type="time"
                            className="form-control "
                            id={field.organization_field_id}
                            // placeholder={field.field_name}
                            onChange={(e) =>
                                setTimeFieldValues((prevValues) => ({
                                    ...prevValues,
                                    [field.organization_field_id]: e.target.value,
                                }))
                            }
                            value={timeFieldValues}
                            required={isFieldRequired ? true : false}
                        />
                    </div>
                );

            case "Text":
                const textFieldValue =
                    textFieldValues[field.organization_field_id] || "";
                return (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
                        <label >{field.field_name}</label>
                        <input
                            type="text"
                            className="form-control "
                            maxLength={field?.field_length}
                            id={field.organization_field_id}
                            autoComplete="off"
                            onChange={(e) =>
                                setTextFieldValues((prevValues) => ({
                                    ...prevValues,
                                    [field.organization_field_id]: e.target.value,
                                }))
                            }
                            value={textFieldValue}
                            required={isFieldRequired ? true : false}
                        />
                    </div>
                );

            case "Radio":
                const radioFieldValue =
                    radioFieldValues[field.organization_field_id] || "";
                return (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
                        <label >{field.field_name}</label>
                        <div className="">
                            {field.items.map((item, itemIndex) => (
                                <div key={itemIndex} className="form-check form-check-inline">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name={field.organization_field_id}
                                        id={`${field.organization_field_id}-${itemIndex}`}
                                        value={item.detail_value}
                                        checked={radioFieldValue === item.detail_value}
                                        onChange={(e) =>
                                            setRadioFieldValues((prevValues) => ({
                                                ...prevValues,
                                                [field.organization_field_id]: e.target.value,
                                            }))
                                        }
                                        required={isFieldRequired ? true : false}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={`${field.organization_field_id}-${itemIndex}`}
                                    >
                                        {item.detail_value}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const handleSelectFieldChange = (e, organizationFieldId) => {
        const { value } = e.target;
        setSelectedFieldValues((prevValues) => ({
            ...prevValues,
            [organizationFieldId]: value,
        }));
    };

    dynamicFieldsRef.current = {
        selectedFieldValues,
        numberFieldValues,
        booleanFieldValues,
        textFieldValues,
        dateFieldValues,
        timeFieldValue,
        radioFieldValues,
    };



    return (
        <>
            {fieldData?.map(
                (field, index) =>
                    field.display_group === 'organization-field' &&
                    renderField(field, index)
            )}
        </>
    )
}

export default DynamicFields


// validate the error
export const fieldIsValid = (field, selectedFieldValues, numberFieldValues, booleanFieldValues, dateFieldValues, timeFieldValue, textFieldValues) => {
    switch (field.field_type) {
        case "Select":
            return (
                selectedFieldValues.hasOwnProperty(field.organization_field_id) &&
                selectedFieldValues[field.organization_field_id] !== ""
            );
        case "Number":
            return (
                numberFieldValues[field.organization_field_id] !== "" &&
                !isNaN(numberFieldValues[field.organization_field_id])
            );
        case "Boolean":
        case "Radio":
            return (
                booleanFieldValues[field.organization_field_id] !== undefined &&
                booleanFieldValues[field.organization_field_id] !== null &&
                booleanFieldValues[field.organization_field_id] !== false
            );
        case "Date":
            return (
                dateFieldValues[field.organization_field_id] !== undefined &&
                dateFieldValues[field.organization_field_id] !== ""
            );
        case "Time":
            return (
                timeFieldValue[field.organization_field_id] !== undefined &&
                timeFieldValue[field.organization_field_id] !== ""
            );
        case "Text":
            return (
                textFieldValues.hasOwnProperty(field.organization_field_id) &&
                textFieldValues[field.organization_field_id] !== ""
            );
        default:
            return false;
    }
};


// To retrieve field name and field values

export function mapFieldData(fieldData, {
    selectedFieldValues = {},
    numberFieldValues = {},
    booleanFieldValues = {},
    dateFieldValues = {},
    timeFieldValue = {},
    textFieldValues = {},
    radioFieldValues = {}
} = {}) {
    return Array.isArray(fieldData) ? fieldData.map((field) => {
        switch (field.field_type) {
            case "Select":
                return {
                    ...(field.organization_detail_id && { organization_detail_id: field.organization_detail_id }),
                    field_name: field.field_name,
                    field_value: selectedFieldValues[field.organization_field_id] || "",
                };
            case "Number":
                return {
                    ...(field.organization_detail_id && { organization_detail_id: field.organization_detail_id }),
                    field_name: field.field_name,
                    field_value: numberFieldValues[field.organization_field_id] || "",
                };
            case "Boolean":
                return {
                    ...(field.organization_detail_id && { organization_detail_id: field.organization_detail_id }),
                    field_name: field.field_name,
                    field_value: booleanFieldValues[field.organization_field_id] || false,
                };
            case "Date":
                return {
                    ...(field.organization_detail_id && { organization_detail_id: field.organization_detail_id }),
                    field_name: field.field_name,
                    field_value: dateFieldValues[field.organization_field_id] || "",
                };
            case "Time":
                return {
                    ...(field.organization_detail_id && { organization_detail_id: field.organization_detail_id }),
                    field_name: field.field_name,
                    field_value: timeFieldValue[field.organization_field_id] || "",
                };
            case "Text":
                return {
                    ...(field.organization_detail_id && { organization_detail_id: field.organization_detail_id }),
                    field_name: field.field_name,
                    field_value: (textFieldValues[field.organization_field_id] || "").trim(),
                };
            case "Radio":
                return {
                    ...(field.organization_detail_id && { organization_detail_id: field.organization_detail_id }),
                    field_name: field.field_name,
                    field_value: radioFieldValues[field.organization_field_id] || false,
                };
            default:
                return null;
        }
    }) : [];
}
