import React, { useEffect, useState } from 'react';
import FeaturesTable from './FeaturesTable/FeaturesTable';
import OrganizationList from '../OrganizationList/OrganizationList';
import { setOrganuzationID } from '../../Store/actions/organizationId/organizationIdReducer';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrgID } from '../../Store/actions/organizationId/organizationIdSeelct';
import {
  findDisplayName,
  isView,
} from '../../../utils/commonFunctionsAndStatices';
import { selectOrgTable } from '../../Store/actions/Organizationadmin/OrganizationSelector';
import { selectToken } from '../../Store/actions/auth/authSelectors';
import { languageid } from '../../Store/actions/languageId/selectLanguageId';
import { OrganizationFeaturesList } from '../../apis/apisServies';
import { t } from 'i18next';

const OrganizationFeatures = () => {
  const loginData = useSelector(selectToken);
  const orgData = useSelector(selectOrgTable);
  const selectOrgId = useSelector(selectOrgID);
  const languageId = useSelector(languageid);
  const [loading, setLoading] = useState(true);
  const [featuresList, serFeaturesList] = useState([]);
  const sortOrgData =
    orgData && [...orgData].sort((a, b) => a.name.localeCompare(b.name));
  const organizationid = isView('isListOrganization')
    ? selectOrgId !== ''
      ? selectOrgId
      : sortOrgData[0]?.organizationid
    : loginData?.organization_id;
  const dispatch = useDispatch();
  const [orgid, setOrgid] = useState(organizationid);

  const handleOrganizationChange = (organizationid) => {
    setOrgid(organizationid);
    dispatch(setOrganuzationID(organizationid));
    fetchOrganizationFeatures(organizationid);
  };

  const fetchOrganizationFeatures = async (org_id) => {
    const dataObj = {
      userid: loginData?.login_id,
      organizationid: org_id,
      text_message: {
        language_id: languageId,
      },
    };
    try {
      const response = await OrganizationFeaturesList(dataObj);
      if (response?.data?.statusCode === 200) {
        const data = response?.data?.body?.data;
        serFeaturesList(data);
        setLoading(false);
      } else {
        setLoading(false);
        serFeaturesList([]);
      }
    } catch (error) {
      serFeaturesList([]);
      setLoading(false);
      // Handle errors here
      console.error('Error fetching organization features:', error);
    }
  };

  useEffect(() => {
    fetchOrganizationFeatures(organizationid);
  }, []);
  return (
    <div>
      <nav className='nav-dashboard'>
        <strong className='links'>{findDisplayName("organizationFeaturesHeading","Organization Features")}</strong>
      </nav>
      <div className='row p-2 p-lg-0 ms-lg-2'>
        {
          <div className='form-group col-lg-3 col-md-8 col-sm-10 col-xs-10 mt-lg-3 '>
            <label htmlFor='role' className='text-white mb-2'>
              {findDisplayName(
                'organizationFeaturesSelectOrganizationHeading',
                'Organization'
              )}
            </label>
            <OrganizationList
              handleChange={handleOrganizationChange}
              initialvalue={orgid}
              organizatonInUser={false}
            />
          </div>
        }
      </div>
      <hr className='text-white mt-0 mb-0 ms-4 me-1' />

      <FeaturesTable featuresList={featuresList} loading={loading} fetchOrganizationFeatures={fetchOrganizationFeatures} orgid={orgid} />
    </div>
  );
};

export default OrganizationFeatures;
