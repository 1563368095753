import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/style.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setVisitorID,
  setVisitorProfile,
} from '../../Store/actions/profile/profileReducer';
import {
  findDisplayName,
  findVisitorTypeById,
  formatNumberWithMask,
  sortData,
} from '../../../utils/commonFunctionsAndStatices';
import { setOrganization } from '../../Store/actions/getorganization/organizationSelector';

const CheckoutTable = (props) => {
  const organization = useSelector(setOrganization);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortDirection, setSortDirection] = useState('asc');
  const [tableData, setTableData] = useState([]);
  const [sortTable, setSortTable] = useState([]);
  const [sortField, setSortField] = useState('');

  const handleSubmit = (id) => {
    dispatch(setVisitorID(id));
    navigate('/ProfileScreen');
  };

  const formatDateTime = (timestamp) => {
    if (timestamp === 'null') {
      return '';
    } else {
      const date = new Date(timestamp);
      const options = { timeZone: 'UTC' };
      return date.toLocaleString('en-US', options);
    }
  };

  let data = props.data;

  var dataCopy = data
    .slice()
    .sort((a, b) => new Date(b.checkin) - new Date(a.checkin));

  useEffect(() => {
    setTableData(dataCopy);
    setSortTable(dataCopy);
  }, [props.data]);

  if (!data || data?.length === 0) {
    return null; // Don't render anything if there's no data
  }



  const handleSort = (field) => {
    // Toggle sort direction if the same field is clicked again
    const newSortDirection =
      sortField === field ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';

    // Update sort direction and field state
    setSortDirection(newSortDirection);
    setSortField(field);

    // Sort the table data based on the selected field and direction
    const sortedData = sortData(sortTable, field, newSortDirection);

    setSortTable(sortedData);

    // Update the table data with the sorted data
    setTableData(sortedData);
  };

  return (
    <div className='container'>
      <div className='row pt-4'>
        <div className='p-2'>
          <div className='table-wrapper'>
            <table className='checkouttable table-hover'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='ps-4'
                    onClick={() => {
                      handleSort('badge_number');
                    }}
                  >
                    {findDisplayName('checkOutPageBadgeHeading', 'Badge')}{' '}
                    {sortField === 'badge_number'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th
                    scope='col'
                    onClick={() => {
                      handleSort('firstname');
                    }}
                  >
                    {findDisplayName('checkOutPageFirstNameHeading', 'First Name')}
                    {sortField === 'firstname'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th
                    scope='col'
                    onClick={() => {
                      handleSort('lastname');
                    }}
                  >
                    {findDisplayName('checkOutPageLastNameHeading', 'Last Name')}
                    {sortField === 'lastname'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th scope='col'
                    onClick={() => {
                      handleSort('visitor_type_id');
                    }}>
                    {findDisplayName(
                      'checkOutPageVisitorTypeHeading',
                      'Visitor Type'
                    )}
                    {sortField === 'visitor_type_id'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th
                    scope='col'
                    onClick={() => {
                      handleSort('checkin');
                    }}
                  >
                    {findDisplayName('checkOutPageCheckinHeading', 'Check-in')}
                    {sortField === 'checkin'
                      ? sortDirection === 'asc'
                        ? '↑'
                        : '↓'
                      : '↑↓'}
                  </th>
                  <th scope='col' className='ps-4'>
                    {findDisplayName('checkOutPageActionHeading', 'Action')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, index) => (
                  <tr key={index}>
                    <td className='ps-3'>
                      {' '}
                      {formatNumberWithMask(
                        item.badge_number,
                        organization.badge_number_mask
                      )}
                    </td>
                    <td className='ps-2'>
                      {item.firstname?.charAt(0).toUpperCase() + item.firstname?.slice(1)}
                    </td>
                    <td className='ps-2'>
                      {item?.lastname?.charAt(0).toUpperCase() + item?.lastname?.slice(1)}
                    </td>
                    <td className='ps-2'>
                      {findVisitorTypeById(item.visitor_type_id)}
                    </td>
                    <td className='ps-2'>
                      {item.checkin ? formatDateTime(item?.checkin) : ''}
                    </td>
                    <td>
                      <button
                        className='checkoutbutton '
                        onClick={() => handleSubmit(item.visitor_id)}
                      >
                        <span className=''>
                          {findDisplayName(
                            'checkOutPageCheckOutButton',
                            'Check Out'
                          )}
                        </span>
                        <svg
                          className='ms-1'
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.16675 10H15.0001M10.8334 5L15.2442 9.41074C15.5696 9.73618 15.5696 10.2638 15.2442 10.5893L10.8334 15'
                            stroke='white'
                            strokeWidth='1.67'
                            strokeLinecap='round'
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutTable;
