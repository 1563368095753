// visitorIdSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visitorId: null, // Initialize the token to null
};

const visitorIdSlice = createSlice({
  name: "visitorIdCurrent",
  initialState,
  reducers: {
    setVisitorId: (state, action) => {
      state.visitorId = action.payload;
    },
    clearVisitorId: (state) => {
      state.visitorId = null;
    },
  },
});

export const { setVisitorId, clearVisitorId } = visitorIdSlice.actions;

export default visitorIdSlice.reducer;
