import React, { useEffect } from "react";
import {
  FaLanguage,
  FaGlobe,
  FaUser,
  FaUsers,
  FaFile,
  FaSitemap,
  FaFileArchive
} from "react-icons/fa";
import { AiFillSafetyCertificate } from "react-icons/ai";

import "./Sidebar.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { TbMessageLanguage } from "react-icons/tb";
import { BsShieldFillPlus } from "react-icons/bs";
import {
  findDisplayName,
  isNonEmptyString,
  isView,
} from "../../../utils/commonFunctionsAndStatices";

const Sidebar = ({ openSidebarToggle, OpenSidebar }) => {
  const loginData = useSelector(selectToken);
  const navigate = useNavigate();

  const sidebarClose = () => {
    var windowWidth = window.innerWidth;

    if (windowWidth < 990) {
      OpenSidebar();
    } else {
      return;
    }
  };


  useEffect(() => {
    // Add the event listener to the window
    window.addEventListener("popstate", handlePopstate);
  }, []);

  //* handler router
  const handlePopstate = (event) => {
    if (navigator.onLine) {
      if (!isNonEmptyString(loginData?.login_id)) {
        // Navigate back
        navigate(-1);
      } else {
        // Prevent the default behavior of the Back button and stay on the current page
        event.preventDefault();
        navigate("/Organization_details");
      }
    }
  };

  return (
    <>
      <aside
        id="sidebar"
        className={openSidebarToggle ? "sidebar-responsive" : ""}
      >
        <div className="sidebar-title">
          <div className="sidebar-brand">{loginData?.role_name}</div>

          <span className="icon close_icon" onClick={OpenSidebar}>
            X
          </span>
        </div>

        <ul className="sidebar-list scrol-sidebar">
          {isView("isDashboardStartOrganization") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
                end
              >
                <FaSitemap className="icon" />{" "}
                {findDisplayName(
                  "sideBarOrganizationsHeading",
                  "Organizations"
                )}
              </NavLink>
            </li>
          )}
          {isView("isDashboardOrganizationFeatures") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details/Organization_features"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
                end
              >
                <BsShieldFillPlus className="icon" />
                {findDisplayName("sideBarOrganizationFeaturesHeading", "Organization Features")}
              </NavLink>
            </li>
          )}
          {(isView("isDashboardStartLocation") ||
            isView("isDashboardLocationName")) && (
              <li onClick={() => sidebarClose()}>
                <NavLink
                  to={
                    isView("isDashboardStartLocation")
                      ? "/Organization_details"
                      : "/Organization_details/Location"
                  }
                  className={({ isActive }) =>
                    isActive
                      ? "active-link sidebar-list-item active-width d-flex bg-link"
                      : "sidebar-list-item w-100 d-flex bg-link "
                  }
                  end
                >
                  <FaGlobe className="icon" />
                  {findDisplayName("sideBarLocationsHeading", "Locations")}
                </NavLink>
              </li>
            )}
          {isView("isDashboardRole") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details/Role"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
              >
                <FaUsers className="icon" />
                {findDisplayName("sideBarRoleHeading", "Role")}
              </NavLink>
            </li>
          )}
          {(isView("isDashboardUser") || isView("isDashboardStartUser")) && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to={
                  isView("isDashboardStartUser")
                    ? "/Organization_details"
                    : "/Organization_details/User"
                }
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
                end
              >
                <FaUser className="icon" />{" "}
                {findDisplayName("sideBarUserHeading", "User")}
              </NavLink>
            </li>
          )}
          {isView("isDashboardLanguage") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details/Language"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
              >
                <TbMessageLanguage className="icon" />{" "}
                {findDisplayName("sideBarLanguageHeading", "Language")}
              </NavLink>
            </li>
          )}
          {isView("isDashboardLabels") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details/Label"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
              >
                <FaLanguage className="icon" />{" "}
                {findDisplayName("sideBarLabelsHeading", "Labels")}
              </NavLink>
            </li>
          )}
          {isView("isDashboardVisitorType") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details/Visitor"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
              >
                <FaUsers className="icon" />{" "}
                {findDisplayName("sideBarVisitorTypeHeading", "Visitor Type")}
              </NavLink>
            </li>
          )}
          {(isView("isDashboardVisitorLog") ||
            isView("isDashboardStartVisitorLog")) && (
              <li onClick={() => sidebarClose()}>
                <NavLink
                  to={
                    isView("isDashboardStartVisitorLog")
                      ? "/Organization_details"
                      : "/Organization_details/CsoReport"
                  }
                  className={({ isActive }) =>
                    isActive
                      ? "active-link sidebar-list-item active-width d-flex bg-link"
                      : "sidebar-list-item w-100 d-flex bg-link "
                  }
                  end
                >
                  <FaFile className="icon" />
                  {findDisplayName("sideBarVisitorLogHeading", "Visitor Log")}
                </NavLink>
              </li>
            )}
          {isView("isDashboardVisitorLogArchival") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details/visitor-log-archival"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
                end
              >
                <FaFileArchive className="icon" />
                {findDisplayName("sideBarVisitorLogArchivalHeading", "Visitor Log Archive")}
              </NavLink>
            </li>
          )}
          {isView("isDashboardFields") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details/field"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
              >
                <MdOutlineDashboardCustomize className="icon" />{" "}
                {findDisplayName("sideBarFieldsHeading", "Fields")}
              </NavLink>
            </li>
          )}
          {isView("isDashboardFeatures") && (
            <li onClick={() => sidebarClose()}>
              <NavLink
                to="/Organization_details/Features"
                className={({ isActive }) =>
                  isActive
                    ? "active-link sidebar-list-item active-width d-flex bg-link"
                    : "sidebar-list-item w-100 d-flex bg-link "
                }
              >
                <AiFillSafetyCertificate className="icon text-white " />{" "}
                {findDisplayName("sideBarFeaturesHeading", "Features")}
              </NavLink>
            </li>
          )}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
