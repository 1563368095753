import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  csodata: null,
};

const csoReducer = createSlice({
  name: "csodata",
  initialState,
  reducers: {
    setCSOData: (state, action) => {
      state.csodata = action.payload;
    },
    clearCSOData: (state) => {
      state.csodata = null;
    },
  },
});
export const { setCSOData, clearCSOData } = csoReducer.actions;
export default csoReducer.reducer;
