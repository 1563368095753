import React from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
const visiblePages = 6;

  const handleClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const generatePages = () => {
    const pages = [];

    if (totalPages <= visiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <span
            key={i}
            onClick={() => handleClick(i)}
            className={`page-link page-item ${
              currentPage === i ? "active" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      const half = Math.floor(visiblePages / 2);
      let startPage = currentPage - half;

      if (currentPage + half > totalPages) {
        startPage = totalPages - visiblePages + 1;
      }

      if (startPage < 1) {
        startPage = 1;
      }

      const endPage = Math.min(startPage + visiblePages - 1, totalPages);

      if (startPage > 1) {
        pages.push(
          <span
            key={1}
            onClick={() => handleClick(1)}
            className="page-link page-item"
          >
            1
          </span>
        );
        if (startPage > 2) {
          pages.push(
            <span key="ellipsis-start" className="page-item page-link ">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <span
            key={i}
            onClick={() => handleClick(i)}
            className={`page-link page-item ${
              currentPage === i ? "active" : ""
            }`}
          >
            {i}
          </span>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push(
            <span key="ellipsis-end" className="page-item page-link ">
              ...
            </span>
          );
        }
        pages.push(
          <span
            key={totalPages}
            onClick={() => handleClick(totalPages)}
            className="page-link page-item"
          >
            {totalPages}
          </span>
        );
      }
    }

    return pages;
  };

  return (
    <div className="pagination justify-content-end mt-3 mb-3 me-3">
      <span
        onClick={() => handleClick(currentPage - 1)}
        className={`page-link page-item ${currentPage === 1 ? "disabled" : ""}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.975 1.69046C9.00992 1.7253 9.03763 1.76668 9.05654 1.81224C9.07544 1.8578 9.08517 1.90664 9.08517 1.95596C9.08517 2.00529 9.07544 2.05413 9.05654 2.09969C9.03763 2.14525 9.00992 2.18663 8.975 2.22146L4.73975 6.45596L8.975 10.6905C9.04542 10.7609 9.08498 10.8564 9.08498 10.956C9.08498 11.0555 9.04542 11.151 8.975 11.2215C8.90459 11.2919 8.80908 11.3314 8.7095 11.3314C8.60992 11.3314 8.51442 11.2919 8.444 11.2215L3.944 6.72146C3.90908 6.68663 3.88137 6.64525 3.86247 6.59969C3.84356 6.55413 3.83383 6.50529 3.83383 6.45596C3.83383 6.40664 3.84356 6.3578 3.86247 6.31224C3.88137 6.26668 3.90908 6.2253 3.944 6.19046L8.444 1.69046C8.47884 1.65554 8.52022 1.62783 8.56578 1.60893C8.61134 1.59002 8.66018 1.58029 8.7095 1.58029C8.75883 1.58029 8.80767 1.59002 8.85323 1.60893C8.89879 1.62783 8.94017 1.65554 8.975 1.69046Z"
            fill="#0D6EFD"
          />
        </svg>{" "}
      </span>
      {generatePages()}
      <span
        onClick={() => handleClick(currentPage + 1)}
        className={`page-link page-item ${
          currentPage === totalPages ? "disabled" : ""
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.94397 1.69046C3.97881 1.65554 4.02019 1.62783 4.06575 1.60893C4.11131 1.59002 4.16015 1.58029 4.20947 1.58029C4.2588 1.58029 4.30764 1.59002 4.3532 1.60893C4.39876 1.62783 4.44014 1.65554 4.47497 1.69046L8.97497 6.19046C9.00989 6.2253 9.0376 6.26668 9.05651 6.31224C9.07541 6.3578 9.08514 6.40664 9.08514 6.45596C9.08514 6.50529 9.07541 6.55413 9.05651 6.59969C9.0376 6.64525 9.00989 6.68663 8.97497 6.72146L4.47497 11.2215C4.40456 11.2919 4.30905 11.3314 4.20947 11.3314C4.10989 11.3314 4.01439 11.2919 3.94397 11.2215C3.87356 11.151 3.834 11.0555 3.834 10.956C3.834 10.8564 3.87356 10.7609 3.94397 10.6905L8.17922 6.45596L3.94397 2.22146C3.90905 2.18663 3.88134 2.14525 3.86244 2.09969C3.84353 2.05413 3.8338 2.00529 3.8338 1.95596C3.8338 1.90664 3.84353 1.8578 3.86244 1.81224C3.88134 1.76668 3.90905 1.7253 3.94397 1.69046Z"
            fill="#0D6EFD"
          />
        </svg>
      </span>
    </div>
  );
};

export default Pagination;
