// actions/rootReducer.js
import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import visitorTypeReducer from "./visitorType/visitorTypeReducer";
import getCountryReducer from "./getCountry/getCountryReducer";
import locationReducer from "./locationData/locationReducer";
import visitorIdReducer from "./visitorId/visitorIdReducer";
import CheckoutScreenReducer from "./checkoutSearch/CheckoutScreenReducer";
import profileReducer from "./profile/profileReducer";
import organizationReducer from "./getorganization/organizationReducer";
import offlineVisitorReducer from "./offlineVisitors/offlineVisitorReducer";
import csoReportReducer from "./csoReport/csoReportReducer";
import orgLocationReducer from "./orgLocation/orgLocationReducer";
import userTableDataReducer from "./userTableData/userTableDataReducer";
import roleReducer from "./role/roleReducer";
import visitorTypeTableReducer from "./VisitorTypesTable/VisitorTypesTableReducer";
import OrganizationReducer from "./Organizationadmin/OrganizationReducer";
import LanguageReducer from "./language/LanguageReducer";
import masterLabelReducer from "./masterLabel/masterLabelReducer";
import OrgUpdateReducer from "./OrgUpdate/OrgUpdateReducer";
import getAllLocationReducer from "./allLocationdata/getAllLocationReducer";
import FieldTableReducer from "./FieldTableData/FieldTableReducer";
import LanguageDataReducer from "./languageData/LanguageDataReducer";
import languageIdReducer from "./languageId/languageIdReducer";
import portIdReducer from "./portId/portIdReducer";
import portDataReducer from "./portData/portDataReducer";
import organizationIdReducer from "./organizationId/organizationIdReducer";
import previliegeReducer from "./privilege/previliegeReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  visitorType: visitorTypeReducer,
  getCountryArray: getCountryReducer,
  locationDataArr: locationReducer,
  visitorIdCurrent: visitorIdReducer,
  checkOut: CheckoutScreenReducer,
  profileData: profileReducer,
  organization: organizationReducer,
  offlineVisitor: offlineVisitorReducer,
  csoReport:csoReportReducer,
  orgLocationData: orgLocationReducer,
  role:roleReducer,
  userTableData:userTableDataReducer,
  visitorTypeTable:visitorTypeTableReducer,
  OrgTableData:OrganizationReducer,
  languageDataArr:LanguageReducer,
  masterLabel:masterLabelReducer,
  orgEditData:OrgUpdateReducer,
  allLocationData:getAllLocationReducer,
  fieldArrData:FieldTableReducer,
  languageLabelData:LanguageDataReducer,
  languageId:languageIdReducer,
  portID:portIdReducer,
  portData:portDataReducer,
  OrgID:organizationIdReducer,
  privileges:previliegeReducer
  // Add other reducers if you have them
});

export default rootReducer;
