import React from 'react';
import 
 { BsJustify}
 from 'react-icons/bs'
import { useSelector } from 'react-redux';
import { selectToken } from '../../Store/actions/auth/authSelectors';
import {findDisplayName} from '../../../utils/commonFunctionsAndStatices';

const HeaderBar = ({OpenSidebar}) => {
  const loginData = useSelector(selectToken);
  return (
    <header className='header'>
    <div className='menu-icon d-flex '>
        <BsJustify className='icon' onClick={OpenSidebar}/> 
        {loginData?.role_name}
    </div>
  
</header>
  )
}

export default HeaderBar;