import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getFieldData: [],
};

const getFieldTable = createSlice({
  name: "fieldArrData",
  initialState,
  reducers: {
    setFieldData: (state, action) => {
      state.getFieldData = action.payload;
    },
    clearFieldData: (state) => {
      state.getFieldData = [];
    },
  },
});

export const { setFieldData, clearFieldData } = getFieldTable.actions;

export default getFieldTable.reducer;
