// DashboardChart1.jsx

import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  FilteredDataByOrganization,
  filterDataByCheckInForAll,
  findDisplayName,
} from "../../../../utils/commonFunctionsAndStatices";
import DataNotFound from "../../DataNotFound/DataNotFound";
import "../css/style.css";

const DashboardChart1 = ({ convertedData, loading }) => {
  const [showSelectIcon, setShowSelectIcon] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Today");
  const [activeOption, setActiveOption] = useState("Today");
  const handleSvgClick = () => {
    setShowSelectIcon((prev) => !prev);
  };

  
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setActiveOption(option);
    setShowSelectIcon(false);
  };

  //filtering based on  Date
  const filteredDataList = filterDataByCheckInForAll(
    convertedData,
    selectedOption
  );

  //Separating filtered data by organization name
  const FilteredData = FilteredDataByOrganization(filteredDataList);
  const sortedFilteredData = Object.fromEntries(
    Object.entries(FilteredData).sort((a, b) => a[0].localeCompare(b[0]))
  );
  const chartData = Object.entries(sortedFilteredData).map(
    ([organizationName, organizationData]) => ({
      name: organizationName,
      Visitors: organizationData.length,
    })
  );

  return (
    <div className="row mt-4 ">
      <div className="bg-light rounded col-12 p-3">
        <div className="row">
          <div className="col-lg-8 col-md-7 col-xxl-8 col-7">
            <strong className="text-dark ms-3 d-xxl-flex align-items-xxl-center">
              {findDisplayName(
                "VisitorsByOrganizationChartHeading",
                "Visitors by Organization"
              )}

              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                title="Your Tooltip Text Here"
                className="ms-2 d-none d-xxl-block d-lg-block d-xl-block"
              >
                <title>
                  {findDisplayName(
                    "VisitorsByOrganizationChartToolTip",
                    "To get insight on the total number of visitors by organization."
                  )}
                </title>
                <circle cx="12.5508" cy="12.5" r="12" fill="#BDBDBD" />
                <rect
                  x="10.5508"
                  y="9.83331"
                  width="3.99999"
                  height="10.6666"
                  rx="2"
                  fill="white"
                />
                <rect
                  x="10.5508"
                  y="4.5"
                  width="3.99999"
                  height="3.99999"
                  rx="2"
                  fill="white"
                />
              </svg>
            </strong>
          </div>
          <div className="col-lg-4 col-md-5 col-xxl-4 col-5">
            <div className="d-flex justify-content-end">
              <span className="bg-dark text-white p-2 rounded selected-text-size">
                {selectedOption}
              </span>

              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ms-2"
                onClick={handleSvgClick}
              >
                <path d="M3.07031 16.5705H17.2589H3.07031Z" fill="#BDBDBD" />
                <path
                  d="M3.07031 5.33984H17.2589M3.07031 10.9552H17.2589M3.07031 16.5705H17.2589"
                  stroke="#BDBDBD"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            {showSelectIcon && (
              <div className=" row mt-1 position-relative ">
                <div
                  className=" position-absolute col-12 selected-icon "
                  aria-label="Default select example"
                  style={{ zIndex: 4 }}
                >
                  <div
                    className="list-group position-relative  row"
                    id="list-tab"
                    role="tablist"
                  >
                    <a
                      className={`list-group-item list-group-item-action col-10 ${
                        activeOption === "Today" ? "active" : ""
                      }`}
                      id="list-home-list"
                      data-bs-toggle="list"
                      href="#list-home"
                      role="tab"
                      aria-controls="list-home"
                      onClick={() => handleOptionClick("Today")}
                    >
                      {findDisplayName(
                        "VisitorsByOrganizationChartTodaySelect",
                        "Today"
                      )}
                    </a>
                    <a
                      className={`list-group-item list-group-item-action col-10 ${
                        activeOption === "This Week" ? "active" : ""
                      }`}
                      id="list-profile-list"
                      data-bs-toggle="list"
                      href="#list-profile"
                      role="tab"
                      aria-controls="list-profile"
                      onClick={() => handleOptionClick("This Week")}
                    >
                      {findDisplayName(
                        "VisitorsByOrganizationChartThisWeekSelect",
                        "This Week"
                      )}
                    </a>
                    <a
                      className={`list-group-item list-group-item-action col-10 ${
                        activeOption === "This Month" ? "active" : ""
                      }`}
                      id="list-messages-list"
                      data-bs-toggle="list"
                      href="#list-messages"
                      role="tab"
                      aria-controls="list-messages"
                      onClick={() => handleOptionClick("This Month")}
                    >
                      {findDisplayName(
                        "VisitorsByOrganizationChartThisMonthSelect",
                        "This Month"
                      )}
                    </a>
                    <a
                      className={`list-group-item list-group-item-action col-10 ${
                        activeOption === "This Year" ? "active" : ""
                      }`}
                      id="list-settings-list"
                      data-bs-toggle="list"
                      href="#list-settings"
                      role="tab"
                      aria-controls="list-settings"
                      onClick={() => handleOptionClick("This Year")}
                    >
                      {findDisplayName(
                        "VisitorsByOrganizationChartThisYearSelect",
                        "This Year"
                      )}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className="text-secondary mt-lg-4 mt-xxl-4 pb-xxl-3 chart-margin-align " />
        <div className="col-12 d-flex justify-content-center pb-md-3">
          {loading ? (
            <div
              className="d-flex justify-content-center gap-2 align-items-center text-primary"
              style={{
                height: "400px",
              }}
            >
              <span
                className="spinner-grow spinner-grow-sm text-primary pe-1"
                role="status"
                aria-hidden="true"
              ></span>
              {findDisplayName(
                "VisitorsByOrganizationChartLoading",
                "Loading ..."
              )}
            </div>
          ) : chartData.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart width="95%" height={400} data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" angle={-25} textAnchor="end" fontSize={10}/>
                <YAxis />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    // Add mobile-specific content styles here if needed
                  }}
                />
                <Legend />
                <Bar dataKey="Visitors" fill="#165BAA" />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div
              className={` ${
                showSelectIcon
                  ? "d-flex justify-content-center align-items-center"
                  : ""
              }`}
              style={{
                height: "400px",
               
              }}
            >
              {chartData.length === 0 && !showSelectIcon ? (
                <div className="text-center ">
                  <DataNotFound />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardChart1;
