import React from 'react'
import { useForm } from "react-hook-form";
import { findDisplayName } from '../../../../utils/commonFunctionsAndStatices';

const LanguageSearch = ({handleChange,handleChange2}) => {

    const form = useForm();
    return (
        <div className=' d-flex gap-3  flex-xxl-row flex-lg-row  flex-column '>
            <div >
                <input type="text" className='rounded border-0  form-control p-2'  placeholder={findDisplayName('labelScreenSearchInputBox', 'Search Label Name')}
                 onChange={(e)=>handleChange(e.target.value)}
                />
               
            </div>
            <div >
                <input type="text" className='rounded border-0  form-control p-2'  placeholder={findDisplayName( 'labelScreenSearchDisplayNameInputBox','Search Display Name')}
                 onChange={(e)=>handleChange2(e.target.value)}
                />
               
            </div>
        </div>
    )
}

export default LanguageSearch