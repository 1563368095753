import React, { useEffect, useState } from "react";
import MasterLanguageTable from "./MasterLanguageTable/MasterLanguageTable";
import MasterLanguageCreate from "./MasterLanguageCreate/MasterLanguageCreate";
import MasterLanguageEdit from "./MasterLanguageEdit/MasterLanguageEdit";
import { useDispatch, useSelector } from "react-redux";
import { selectlanguageArr } from "../../Store/actions/language/LanguageSelector";
import { setLanguageArray } from "../../Store/actions/language/LanguageReducer";
import { LanguageAPI } from "../../apis/apisServies";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";

const MasterLanguage = () => {
  const dispatch = useDispatch();
  const [languageData, setLanguageData] = useState("");
  const [activeComponent, setActiveComponent] = useState("table");
  const language = useSelector(selectlanguageArr);

  const fetchLanguage = async () => {
    const language = await LanguageAPI();
    const languageId = language?.data?.body?.data;
    dispatch(setLanguageArray(languageId));
  };
  useEffect(() => {
    fetchLanguage();
  }, []);
  
  return (
    <>
      <nav className="nav-dashboard">
        <strong className="links">
          {findDisplayName("languageHeading", "Language")}
        </strong>
        <button
          type="button"
          className="btn btn-primary"
          id="create-user-button"
          onClick={() => setActiveComponent("create")}
        >
          {findDisplayName("languageCreateLanguageHeading", "Create Language")}{" "}
        </button>
      </nav>
      {activeComponent === "table" && (
        <MasterLanguageTable
          setActiveComponent={setActiveComponent}
          language={language}
          setLanguageData={setLanguageData}
          fetchLanguage={fetchLanguage}
        />
      )}
      {activeComponent === "create" && (
        <MasterLanguageCreate
          setActiveComponent={setActiveComponent}
          fetchLanguage={fetchLanguage}
        />
      )}
      {activeComponent === "update" && (
        <MasterLanguageEdit
          setActiveComponent={setActiveComponent}
          fetchLanguage={fetchLanguage}
          languageData={languageData}
        />
      )}
    </>
  );
};

export default MasterLanguage;
