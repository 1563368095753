import React from "react";
import { useNavigate } from "react-router-dom";
import { findDisplayName, isView } from "../../../utils/commonFunctionsAndStatices";
const PageNotFound = () => {
  const navigate = useNavigate();
 
  const handleNavigate = () => {
    const checkIn = isView("isLandingCheckIn");
    const landingDashboard = isView("isLandingDashboard");
    const landingAnalytical = isView("isLandingAnalytical");
 
    if (checkIn) {
      navigate("/CheckInPage");
    }
    if (landingDashboard) {
      navigate("/Organization_details");
    }
    if (landingAnalytical) {
      navigate("/Dashboard");
    }
  };
  
  return (
    <>
      <div className="container ">
        <div className="card d-flex justify-content-center align-items-center ">
          <div className=" text-white col-lg-8 col-md-7 col-sm-8 col-xs-8">
 
            <div className="col-12 d-flex justify-content-center align-items-center" >
              <svg xmlns="http://www.w3.org/2000/svg" width="131" height="124" viewBox="0 0 131 124" fill="none">
                <path d="M91.1061 83.2018L115.639 103.696C117.482 105.266 120.273 105.004 121.843 103.161C123.412 101.318 123.15 98.5262 121.307 96.9568L96.9857 76.6654L91.1061 83.2018Z" fill="#1775AF" />
                <path d="M120.974 103.748C119.405 105.591 116.665 105.852 114.77 104.283L66.7046 64.1036L72.3213 57.3653L120.44 97.5957C122.282 99.1133 122.543 101.905 120.974 103.748Z" fill="#8BD0FF" />
                <path opacity="0.5" d="M64.8566 22.8608C52.8818 11.3447 33.8387 11.7166 22.3226 23.6914C10.8065 35.6662 11.1784 54.7094 23.1532 66.2254C35.128 77.7415 54.1712 77.3696 65.6872 65.3948C77.2033 53.42 76.8314 34.3769 64.8566 22.8608Z" fill="#CDEBF5" />
                <path d="M16.5203 63.9444C27.2256 79.1159 48.2172 82.6935 63.3886 71.9882C78.5601 61.2829 82.1895 40.2903 71.4852 25.1706C60.7798 9.99911 39.7873 6.36973 24.6676 17.074C9.44433 27.7804 5.81495 48.7729 16.5203 63.9444ZM66.9894 28.3138C75.9379 41.0338 72.9147 58.5966 60.2465 67.5441C47.5264 76.4926 29.9636 73.4695 20.9643 60.8022C12.0158 48.0822 15.039 30.5194 27.759 21.5709C40.478 12.5706 58.0408 15.5937 66.9894 28.3138Z" fill="#8BD0FF" />
                <path d="M56.7866 28.2534C56.7439 28.7203 56.4942 29.1913 56.0861 29.51C55.27 30.1474 54.0763 30.0153 53.4389 29.1992C52.2707 27.7202 50.7452 26.5071 49.1243 25.7102C47.2933 24.762 45.5789 24.4847 44.7496 24.4492C43.764 24.3648 41.6883 24.1464 39.5313 25.0689C36.5013 26.371 35.0537 29.1439 34.5583 30.2929C34.1623 31.2328 33.0316 31.6692 32.0917 31.2732C31.1518 30.8773 30.7154 29.7465 31.1113 28.8066C31.954 26.8741 33.9579 23.417 38.0659 21.6279C41.1476 20.3248 43.9491 20.5808 45.1427 20.7129C46.4917 20.8419 48.5705 21.2156 50.8197 22.3627C52.9633 23.4084 54.91 24.9757 56.3969 26.8628C56.7156 27.2709 56.8292 27.7865 56.7866 28.2534Z" fill="#8BD0FF" />
                <path d="M33.8013 73.7567C31.2993 72.9252 23.4747 70.0226 17.8247 61.7954C12.335 53.8239 12.2808 45.7463 12.3846 43.1032" stroke="#262626" stroke-width="0.4147" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
                <path d="M48.819 113.214C49.1296 111.557 49.5438 110.263 49.958 109.279C50.424 108.295 51.0971 107.467 51.9773 106.794C52.4951 106.38 53.0646 106.017 53.6342 105.758C54.2555 105.5 54.825 105.189 55.3428 104.878C55.8606 104.568 56.3266 104.153 56.7408 103.739C57.155 103.273 57.4139 102.652 57.5692 101.875C57.8281 100.529 57.621 99.4935 56.9996 98.7686C56.3266 98.0955 55.4981 97.6295 54.4626 97.4224C52.9611 97.1117 51.8738 97.3706 51.1489 98.0955C50.424 98.8204 49.8545 99.8559 49.4403 101.254L44.0038 100.218C44.2626 98.8204 44.7286 97.6295 45.4017 96.594C46.0748 95.5585 46.9032 94.73 47.887 94.1087C48.8707 93.4874 49.9581 93.0732 51.2007 92.8661C52.4433 92.659 53.7895 92.7107 55.1875 92.9696C58.1905 93.5392 60.4687 94.6265 61.9702 96.1798C63.4717 97.7331 63.9895 99.9077 63.4199 102.704C63.2646 103.584 63.0057 104.36 62.6951 104.982C62.3844 105.603 61.9702 106.173 61.5042 106.639C61.0382 107.105 60.4687 107.519 59.8474 107.933C59.226 108.295 58.5012 108.658 57.7763 109.072C57.0514 109.435 56.4819 109.797 56.0159 110.108C55.5499 110.418 55.1875 110.833 54.9286 111.247C54.6697 111.661 54.4108 112.127 54.2555 112.593C54.1002 113.059 53.9448 113.628 53.8413 114.25L48.819 113.214ZM47.0586 121.291L48.0941 115.803L53.7895 116.89L52.754 122.379L47.0586 121.291Z" fill="#8BD0FF" />
                <path d="M115.48 69.4516C115.698 68.1425 116.017 67.0714 116.342 66.3022C116.666 65.533 117.241 64.8484 117.924 64.2863C118.318 63.9155 118.775 63.6414 119.251 63.438C119.727 63.2347 120.184 62.9605 120.571 62.7054C120.957 62.4502 121.37 62.1502 121.647 61.7725C121.97 61.4208 122.21 60.9016 122.297 60.2341C122.5 59.1561 122.318 58.3333 121.794 57.7915C121.269 57.2497 120.571 56.9062 119.769 56.7421C118.575 56.5312 117.682 56.7328 117.114 57.3018C116.546 57.8708 116.099 58.7487 115.78 59.8198L111.442 59.0491C111.646 57.971 111.984 56.9707 112.521 56.1446C113.058 55.3186 113.677 54.6598 114.45 54.1495C115.223 53.6392 116.079 53.2962 117.088 53.1016C118.071 52.9519 119.137 52.9694 120.241 53.1285C122.673 53.576 124.48 54.3804 125.727 55.6383C126.929 56.8703 127.358 58.6126 126.969 60.8393C126.857 61.5517 126.681 62.1674 126.441 62.6866C126.201 63.2057 125.897 63.6282 125.549 64.0248C125.2 64.4214 124.762 64.7663 124.234 65.0593C123.751 65.3783 123.178 65.6455 122.579 65.9575C122.007 66.2247 121.523 66.5436 121.182 66.8247C120.84 67.1057 120.543 67.4127 120.292 67.7455C120.084 68.1042 119.903 68.4181 119.786 68.8286C119.668 69.2391 119.551 69.6496 119.497 70.1567L115.48 69.4516ZM114.212 75.8938L114.964 71.4852L119.507 72.3146L118.755 76.7232L114.212 75.8938Z" fill="#8BD0FF" />
              </svg>
            </div>
            <div className="mt-2 col-12">
              <h1 className="d-flex justify-content-center">{findDisplayName("pageNotFoundLabel", "404 Not Found")}</h1>
            </div>
            <div className="d-flex justify-content-center mt-5 col-12 mb-5">
              <button type="submit" onClick={() => handleNavigate()} className="btn btn-light">
                {findDisplayName("pageNotFoundVisitMainPageLabel", "Visit Main Website")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default PageNotFound;