// visitorTypeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visitorArr: [], // Initialize the visitor type to []
  bypass:"",
};

const visitorTypeSlice = createSlice({
  name: "visitorType",
  initialState,
  reducers: {
    setVisitorArray: (state, action) => {
      state.visitorArr = action.payload;
    },
    setVisitorBypass: (state , action) => {
      state.bypass = action.payload;
    },
    clearArray: (state) => {
      state.visitorArr = [];
    },
  },
});

export const { setVisitorArray, clearArray, setVisitorBypass } = visitorTypeSlice.actions;

export default visitorTypeSlice.reducer;
