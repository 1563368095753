import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { useForm } from "react-hook-form";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { FieldTableEditAPI } from "../../../apis/apisServies";
import { useState } from "react";
import DynamicFieldsForm from "./DynamicUpdateField";
import {
  findDisplayName,
  isView,
} from "../../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";

const FieldUpdate = ({
  Fieldvalue,
  setActiveComponent,
  organizationId,
  fetchdata,
  activeButton,
}) => {
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const { showSuccess, showError } = useNotification();
  const loginData = useSelector(selectToken);
  const orgData = useSelector(selectOrgTable);
  const childFormRef = useRef();
  const languageId = useSelector(languageid);

  const initialOrganization =
    organizationId === ""
      ? ""
      : orgData?.find((item) => item.organizationid === organizationId)?.name ||
      "";
  const displaygroupValue = Fieldvalue?.display_group;
  const initialDisplayGroup = displaygroupValue?.charAt(0).toUpperCase() + displaygroupValue?.slice(1).replace(/-/g, ' ');
  const initialFieldname = Fieldvalue?.field_name;
  const initialFieldtype = Fieldvalue?.field_type;
  const initialdefaultstatus = Fieldvalue?.is_default;
  const [selectedFieldType, setSelectedFieldType] = useState(initialFieldtype);
  const initialFieldlength = Fieldvalue?.field_length;
  const shouldRenderAdditionalFields = initialFieldtype === "Select";
  const isBoolean =
    selectedFieldType === "Boolean" ||
    selectedFieldType === "Date" ||
    selectedFieldType === "Time" ||
    selectedFieldType === "Radio" ||
    selectedFieldType === "Select";

  const handleConfirmClick = async (data) => {
    if (!data.fieldname.trim()) {
      showError(
        findDisplayName(
          "fieldUpdateFieldNameValidation",
          "Kindly Enter a Field Name."
        )
      );
      return;
    } else if (!isBoolean && !data.fieldlength.trim()) {
      showError(
        findDisplayName(
          "fieldUpdateFieldLengthValidation",
          "Kindly Enter a Field Length"
        )
      );
      return;
    }
    let childFormData = [];

    if (selectedFieldType === "Select" || selectedFieldType === "Radio") {
      childFormData = childFormRef.current.getFormData();
    }
    const changes = {};
    if (selectedFieldType === "Select" || selectedFieldType === "Radio") {
      changes.items = childFormData.fields;
      if (selectedFieldType === "Radio") {
        changes.items.forEach((item) => {
          item.key = item.value.trim();
        });
      }
      if (selectedFieldType === "Select") {
        const hasMissingKey = changes.items.some((item) => !item.key.trim());
        if (hasMissingKey) {
          showError(
            findDisplayName(
              "fieldCreateKeyValidation",
              "Kindly enter a Key for all items"
            )
          );
          return;
        }
      }
      const hasMissingValue = changes.items.some((item) => !item.value.trim());
      if (hasMissingValue) {
        showError(
          findDisplayName(
            "fieldCreateValueValidation",
            "Kindly enter a Value for all items"
          )
        );
        return;
      }
    }

    if (isView("isListOrganization") && activeButton !== "organization-field") {
      changes.organization_id = organizationId;
    } else if (!isView("isListOrganization")) {
      changes.organization_id = loginData?.organization_id;
    } else if (activeButton === "organization-field") {
      changes.organization_detail = "organization_detail"
    }
    const dataObj = {
      user_id: loginData?.login_id,
      ...changes,
      organization_field_id: Fieldvalue?.organization_field_id,
      display_group: data.displaygroup,

      field_name: data.fieldname?.trim(),
      field_type: data.fieldtype?.trim(),
      ...(!isBoolean && { field_length: data.fieldlength?.trim() }),
      is_default: data.defaultstatus,
      text_message: {
        language_id: languageId,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };
    if (data.defaultstatus) {
      dataObj.is_default = data.defaultstatus;
    }
    const response = await FieldTableEditAPI(dataObj);
    if (response.data.statusCode === 200) {
      showSuccess(response?.data?.body?.message);
      fetchdata(organizationId, activeButton);
      setActiveComponent("table");
    } else if (response?.data?.statusCode === 409 || response?.data?.statusCode === 403) {
      showError(response?.data?.body?.message);
    } else if (response?.data?.statusCode === 400) {
      showError(response?.data?.body?.message);
    } else {
      showError(response?.data?.body?.message);
    }
  };
  const handleFieldTypeChange = (e) => {
    setSelectedFieldType(e);
  };

  const handleChildFormSubmit = (formData) => {
    console.log("Form Data in Parent Component:", formData);
  };

  const handleCancel = () => {
    setActiveComponent("table");
  };

  return (
    <>
      <div className="container ">
        <form className="">
          <div className=" col-lg-12 col-md-12 col-sm-12 p-5 text-white from-scrol ">
            <div className="row field">
              {(isView("isListOrganization") && activeButton !== "organization-field") && (
                <div className="col-lg-4 col-md-8 col-sm-12 col-xs-8 mt-3">
                  <div>
                    <label htmlFor="organization">
                      {findDisplayName(
                        "fieldUpdateOraganizationHeading",
                        "Oraganization"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control  mt-2 "
                      id="Organization"
                      placeholder="Organization"
                      defaultValue={initialOrganization}
                      {...register("Organization", {})}
                      disabled
                    />
                  </div>
                </div>
              )}

              <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3">
                <label htmlFor="Formgroup " className="">
                  {findDisplayName(
                    "fieldUpdateDisplayGroupHeading",
                    "Display Group"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  defaultValue={initialDisplayGroup}
                  {...register("displaygroup")}
                  disabled
                ></input>
              </div>
              <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3">
                <label htmlFor="fieldname">
                  {findDisplayName("fieldUpdateFieldNameHeading", "Field Name")}
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  id="fieldname"
                  placeholder="Field Name"
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                  }}
                  {...register("fieldname", {})}
                  defaultValue={initialFieldname}
                />
              </div>
              <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3">
                <label htmlFor="Formgroup">
                  {findDisplayName("fieldUpdateFieldtypeHeading", "Field Type")}
                </label>
                <div className="dropdown mt-2">
                  <button
                    className="bg-white form-control form-select text-start"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                  >
                    {selectedFieldType}
                  </button>
                  <ul
                    className="dropdown-menu w-100 pb-2"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          handleFieldTypeChange("Text");
                          setValue("fieldtype", "Text");
                        }}
                      >
                        {findDisplayName("fieldUpdateTextSelectOption", "Text")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          handleFieldTypeChange("Number");
                          setValue("fieldtype", "Number");
                        }}
                      >
                        {findDisplayName("fieldUpdateNumberSelectOption", "Number")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          handleFieldTypeChange("Boolean");
                          setValue("fieldtype", "Boolean");
                        }}
                      >
                        {findDisplayName("fieldUpdateBooleanSelectOption", "Boolean")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          handleFieldTypeChange("Date");
                          setValue("fieldtype", "Date");
                        }}
                      >
                       {findDisplayName("fieldUpdateDateSelectOption", "Date")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          handleFieldTypeChange("Select");
                          setValue("fieldtype", "Select");
                        }}
                      >
                        {findDisplayName("fieldUpdateSelectSelectOption", "Select")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          handleFieldTypeChange("Time");
                          setValue("fieldtype", "Time");
                        }}
                      >
                        {findDisplayName("fieldUpdateTimeSelectOption", "Time")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          handleFieldTypeChange("Radio");
                          setValue("fieldtype", "Radio");
                        }}
                      >
                        {findDisplayName("fieldUpdateRadioSelectOption", "Ratio")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {!isBoolean && (
                <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3">
                  <label htmlFor="fieldlength">
                    {" "}
                    {findDisplayName(
                      "fieldUpdateFieldLengthHeading",
                      "Field Length"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="fieldlength"
                    placeholder="Field Length"
                    onFocus={(event) => {
                      event.target.setAttribute("autoComplete", "off");
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                    {...register("fieldlength", {})}
                    defaultValue={initialFieldlength}
                  />
                </div>
              )}

              <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-3 mt-lg-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="active"
                  {...register("defaultstatus", {})}
                  defaultChecked={initialdefaultstatus}
                />
                <label className="form-check-label ps-2" htmlFor="active">
                  {findDisplayName("fieldUpdateRepuiredHeading", "Required")}
                </label>
              </div>

              {(selectedFieldType === "Select" ||
                selectedFieldType === "Radio") && (
                  <DynamicFieldsForm
                    ref={childFormRef}
                    onSubmitFromParent={handleChildFormSubmit}
                    fieldvalue={Fieldvalue}
                    selectedFieldType={selectedFieldType}
                  />
                )}
              <div className="col-12 mt-4 mt-lg-5 d-flex justify-content-center mb-3 gap-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit((data) => handleConfirmClick(data))}
                >
                  {findDisplayName("fieldUpdateUpdateButton", "Update")}
                </button>
                <button
                  className="btn bg-secondary text-light"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  {findDisplayName("fieldUpdateCancelButton", "Cancel")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default FieldUpdate;
