import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  masterLabelData: [],
};

const masterLabelSlice = createSlice({
  name: "masterLabel",
  initialState,
  reducers: {
    setMasterLabel: (state, action) => {
      state.masterLabelData = action.payload;
    },
    clearMasterLabel: (state) => {
      state.masterLabelData = [];
    },
  },
});

export const { setMasterLabel, clearMasterLabel } = masterLabelSlice.actions;
export default masterLabelSlice.reducer;
