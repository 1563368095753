import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { VisitorTypeCreateAPI } from "../../../apis/apisServies";
import { clearOrgLocation } from "../../../Store/actions/orgLocation/orgLocationReducer";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";

const VisitorCreate = ({
  setActiveComponent,
  fetchdata,
  organizationId,
  activeButton,
}) => {
  const { handleSubmit, register } = useForm();

  const loginData = useSelector(selectToken);
  const orgData = useSelector(selectOrgTable);
  const languageId = useSelector(languageid);
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(false);
  const { showSuccess, showError } = useNotification();
  const initialOrganization =
    organizationId === ""
      ? ""
      : orgData?.find((item) => item.organizationid === organizationId).name;

  const handleConfirmClick = async (data) => {
    if (data.visitortype === "" || !data.visitortype.trim()) {
      showError(
        findDisplayName(
          "visitorTypeScreenCreateVisitorTypeValidation",
          "Kindly Enter a visitor Type"
        )
      );
      return;
    } 

    const changes = {};

    if (isView("isListOrganization")) {
      changes.organizationid = organizationId;
    } else if (!isView("isListOrganization")) {
      changes.organizationid = loginData?.organization_id;
    }
    const dataObj = {
      userid: loginData?.login_id,
      name: data.visitortype?.trim(),
      // sortorder: data.sortorder,
      bypassvideo: data.video,
      ...changes,
      text_message: {
        language_id: languageId,
        ...(loginData?.organization_id ? { organization_id: loginData?.organization_id } : {}),
      }
    };
    isLoading(true);
    const response = await VisitorTypeCreateAPI(dataObj);
    if (response.data.statusCode === 201) {
      showSuccess(response?.data?.body?.message);
      fetchdata(organizationId, activeButton);
      setActiveComponent("table");
      isLoading(false);
    } else if (response?.data?.statusCode === 400) {
      showError(response?.data?.body?.message);
      isLoading(false);
    } else if (response?.data?.statusCode === 409) {
      showError(response?.data?.body?.message);
      isLoading(false);
    }
  };
  const handleCancel = () => {
    dispatch(clearOrgLocation());
    setActiveComponent("table");
    fetchdata(organizationId, activeButton);
  };
  return (
    <div>
      <div className="container  text-white from-scrol">
        <div className="row d-flex flex-column align-items-center mt-5 ">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-8 "></div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8">
            {isView("isListOrganization") && (
              <div>
                <label htmlFor="organization">
                  {findDisplayName(
                    "visitorTypeCreateOrganizationHeading",
                    "Organization"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control  mt-2 "
                  id="Organization"
                  placeholder="Organization"
                  defaultValue={initialOrganization}
                  {...register("Organization", {})}
                />
              </div>
            )}
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
            <label htmlFor="visitortype">
              {findDisplayName(
                "visitorTypeCreateVisitorTypeHeading",
                "Visitor Type"
              )}
            </label>
            <input
              type="text"
              className="form-control  mt-2 "
              id="visitortype"
              placeholder={findDisplayName(
                "visitorTypeCreateVisitorTypeInputBox",
                "Visitor Type"
              )}
              onFocus={(event) => {
                event.target.setAttribute("autoComplete", "off");
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
              }}
              {...register("visitortype", {})}
            />
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex gap-2">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                id="bypassVideo"
                {...register("video")}
              />
              <label className="form-check-label ps-2" htmlFor="bypassVideo">
                {findDisplayName(
                  "visitorTypeCreateBypassVideoHeading",
                  "Bypass Video"
                )}
              </label>
            </div>
          </div>
          <div className="col-lg-6 gap-2 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit((data) => handleConfirmClick(data))}
              disabled={loading}
            >
              {findDisplayName("visitorTypeCreateCreateButton", "Create")}
            </button>
            <button
              className="btn bg-secondary text-light"
              onClick={() => {
                handleCancel();
              }}
            >
              {findDisplayName("visitorTypeCreateCancelButton", "Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorCreate;
