import React, { useEffect } from "react";
import Rectangle from "../../../Asset/Img/Rectangle.png";
import { useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FieldTableAPI, OrganizationCreateAPI } from "../../apis/apisServies";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import "./css/style.css";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";
import DynamicFields, { fieldIsValid, mapFieldData } from "./DynamicFields";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { selectlanguageArr } from "../../Store/actions/language/LanguageSelector";

const OrganizationCreate = () => {
  const form = useForm();
  const [uploadedImage1, setUploadedImage1] = useState(null);
  const [uploadedImage2, setUploadedImage2] = useState(null);
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const videoInputRef = useRef(null);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const { register, handleSubmit, setValue } = form;
  const loginData = useSelector(selectToken);
  const { showSuccess, showError } = useNotification();
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);
  const languageID = useSelector(languageid);
  const [fieldData, setFieldData] = useState([]);
  const dynamicFieldsRef = useRef(null);
  const languageList = useSelector(selectlanguageArr);
  const [languageName, setLanguageName] = useState(
    findDisplayName("organizationCreateLanguageSelect", "Select Language")
  );

  useEffect(() => {
    const languageId = languageList?.find(
      (item) => item.language_name === "English"
    )?.language_id;
    setLanguageName("English");
    setValue("defaultlanguage", languageId);
  }, [languageList]);

  const handleImageChange = (fieldNumber, e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const maxSize = 2 * 1024 * 1024; // 2 MB

      // Check file size first
      if (selectedImage.size > maxSize) {
        e.target.value = null;
        showError(
          findDisplayName(
            "organizationCreateImageExceedSizeErrorMessage",
            "File size exceeds 2 MB limit. Please choose a smaller file."
          )
        );
        return;
      }

      // Check file type
      if (!allowedTypes.includes(selectedImage.type)) {
        e.target.value = null;
        showError(
          findDisplayName(
            "organizationCreateLogoValidation",
            "The only kinds of files accepted are JPG, JPEG, and PNG."
          )
        );
        return;
      }

      // Set the uploaded image based on the field number
      if (fieldNumber === 1) {
        setUploadedImage1(selectedImage);
      } else if (fieldNumber === 2) {
        setUploadedImage2(selectedImage);
      }
    }
  };

  function videoToBlob(video) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const blob = new Blob([new Uint8Array(e.target.result)], {
          type: video.type,
        });
        resolve(blob);
      };
      reader.readAsArrayBuffer(video);
    });
  }

  const handleVideoChange = async (e) => {
    const selectedVideo = e.target.files[0];
    if (selectedVideo) {
      const allowedTypes = ["video/mp4", "video/webm", "video/ogg"];
      if (!allowedTypes.includes(selectedVideo.type)) {
        e.target.value = null;
        showError(
          findDisplayName(
            "organizationUpdateVideoValidation",
            "Only MP4, WebM, and Ogg video files are allowed."
          )
        );
        return;
      }
      setUploadedVideo(selectedVideo);
      const videoBlob = await videoToBlob(selectedVideo); // Convert video to Blob
      setVideoBlob(videoBlob);
    }
  };

  const handleConfirmClick = async (formData) => {
    const organizationFields = fieldData.filter(
      (field) => field.display_group === "organization-field"
    );

    // Access the state values from the DynamicFields component using the ref
    const stateValues = dynamicFieldsRef?.current;

    const data = mapFieldData(organizationFields, stateValues);
    const jsonData = JSON.stringify(data);
    const {
      name,
      displayname,
      phone,
      street,
      city,
      state,
      zip_code,
      badge_number,
      defaultlanguage,
    } = formData;

    const dataobj = new FormData();
    if (!uploadedImage1 || !uploadedImage2) {
      showError(
        findDisplayName(
          "organizationCreateLogoBgErrorMessage",
          "Kindly upload a logo and a background ."
        )
      );
      return;
    }

    dataobj.append("userid", loginData?.login_id);
    dataobj.append("name", name?.trim());
    dataobj.append("displayname", displayname?.trim());
    dataobj.append("phone", phone);
    dataobj.append("street", street?.trim());
    dataobj.append("city", city?.trim());
    dataobj.append("state", state?.trim());
    dataobj.append("zipcode", zip_code);
    dataobj.append("badgenumbermask", badge_number);
    dataobj.append("logo", uploadedImage1, "logo.jpg");
    dataobj.append("background", uploadedImage2, "bg.jpg");
    dataobj.append("organizationdetail", jsonData);
    dataobj.append("defaultlanguage", defaultlanguage);

    if (videoBlob) {
      dataobj.append("videolink", uploadedVideo, "video.mp4");
    }

    const missingRequiredField = organizationFields.find((field) => {
      const isValid = fieldIsValid(
        field,
        stateValues?.selectedFieldValues,
        stateValues?.numberFieldValues,
        stateValues?.booleanFieldValues,
        stateValues?.dateFieldValues,
        stateValues?.timeFieldValue,
        stateValues?.textFieldValues
      );
      return field.is_default && !isValid;
    });
    if (missingRequiredField) {
      let data = findDisplayName(
        "checkInPageDynamicFieldValidation",
        "Kindly provide details to this"
      );
      let fieldData = data + " " + missingRequiredField.field_name;
      showError(fieldData);
      return;
    }
    try {
      if (!name.trim()) {
        showError(
          findDisplayName(
            "organizationCreateNameErrorMessage",
            "Kindly Choose a Name"
          )
        );
      } else if (!displayname.trim()) {
        showError(
          findDisplayName(
            "organizationCreateDisplayNameErrorMessage",
            "Kindly Choose a Display Name"
          )
        );
      } else if (!phone.trim()) {
        showError(
          findDisplayName(
            "organizationCreatePhoneNumberErrorMessage",
            "Kindly Enter a Phone Number"
          )
        );
      } else if (!badge_number) {
        showError(
          findDisplayName(
            "organizationCreateBadgeNumberErrorMessage",
            "Kindly Enter a Badge Number"
          )
        );
      } else if (!street.trim()) {
        showError(
          findDisplayName(
            "organizationCreateStreetErrorMessage",
            "Kindly Enter a Street"
          )
        );
      } else if (!city.trim()) {
        showError(
          findDisplayName(
            "organizationCreateCityErrorMessage",
            "Kindly Enter a City"
          )
        );
      } else if (!state.trim()) {
        showError(
          findDisplayName(
            "organizationCreateStateErrorMessage",
            "Kindly Enter a State"
          )
        );
      } else if (!zip_code) {
        showError(
          findDisplayName(
            "organizationCreateZipCodeErrorMessage",
            "Kindly Enter a Zip Code"
          )
        );
      } else if (!defaultlanguage) {
        showError(
          findDisplayName(
            "organizationCreateDefaultLanguageErrorMessage",
            "Kindly Choose a Default Language"
          )
        );
      } else {
        isLoading(true);
        const response = await OrganizationCreateAPI(dataobj);
        if (response.status === 201) {
          showSuccess(
            findDisplayName(
              "organizationcreateaddedsuccessmessage",
              "Organization was successfully added"
            )
          );
          navigate("/Organization_details");
        } else if (response.status === 409) {
          showError(
            findDisplayName(
              "organizationcreateexisterrormessage",
              "organization was existing."
            )
          );
          isLoading(false);
        } else {
          showError(
            findDisplayName(
              "organizationcreateerrormessage",
              "During the organization's creation, something went wrong."
            )
          );
          isLoading(false);
        }
      }
    } catch (error) {
      showError(
        findDisplayName(
          "organizationcreateerrormessage",
          "During the organization's creation, something went wrong."
        )
      );
      isLoading(false);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleUploadClick2 = () => {
    fileInputRef2.current.click();
  };
  const handleVideoClick = () => {
    videoInputRef.current.click();
  };

  const fetchdata = async () => {
    const datobj = {
      userid: loginData?.login_id,
      isactive: true,
      display_group: "organization-field",
      organization_detail: "organization_detail",
      text_message: {
        language_id: languageID,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    try {
      const response = await FieldTableAPI(datobj);
      if (response?.data?.statusCode === 200) {
        const FieldData = response?.data?.body?.data;
        setFieldData(FieldData);
      } else if (response.data.statusCode === 404) {
        setFieldData([]);
      }
    } catch (error) {
      setFieldData([]);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <>
      <form className="container ">
        <div className="col-lg-12 col-md-12 col-sm-12 text-white">
          <p className="text-white mt-3">
            {findDisplayName("organizationScreenHeading", "Organizations")}
          </p>
          <div className="row  mt-2 from-scrol">
            {/* <div className='d-flex '> */}
            <div className="col-lg-4 col-md-6 d-flex gap-2 flex-column">
              <input
                type="file"
                className="d-none mb-2"
                id="upload_photo"
                onClick={(e) => setUploadedImage1(e.target.files[0])}
                onChange={(e) => handleImageChange(1, e)}
                ref={fileInputRef}
                accept="image/jpeg', 'image/jpg', 'image/png'"
              />
              <img
                src={
                  uploadedImage1
                    ? URL.createObjectURL(uploadedImage1)
                    : Rectangle
                }
                alt="Location"
                className="img-height w-100 mt-5 custom-image-size "
              />

              <span
                className=" btn bg-primary  btn-sm rounded-pill mt-2 text-white  d-flex gap-2 justify-content-center"
                onClick={() => {
                  handleUploadClick();
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.50092 4.68158V20.6816M14.1257 12.3065H14.8757M14.1257 13.0565H14.8757M5.70092 20.6816H19.3009C20.421 20.6816 20.9811 20.6816 21.4089 20.4636C21.7852 20.2718 22.0912 19.9659 22.2829 19.5896C22.5009 19.1617 22.5009 18.6017 22.5009 17.4816V7.88158C22.5009 6.76147 22.5009 6.20142 22.2829 5.7736C22.0912 5.39727 21.7852 5.09131 21.4089 4.89957C20.9811 4.68158 20.421 4.68158 19.3009 4.68158H5.70092C4.58081 4.68158 4.02076 4.68158 3.59293 4.89957C3.21661 5.09131 2.91065 5.39727 2.7189 5.7736C2.50092 6.20142 2.50092 6.76147 2.50092 7.88158V17.4816C2.50092 18.6017 2.50092 19.1617 2.7189 19.5896C2.91065 19.9659 3.21661 20.2718 3.59293 20.4636C4.02076 20.6816 4.58081 20.6816 5.70092 20.6816ZM19.5009 12.6816C19.5009 15.443 17.2623 17.6816 14.5009 17.6816C11.7395 17.6816 9.50092 15.443 9.50092 12.6816C9.50092 9.92016 11.7395 7.68158 14.5009 7.68158C17.2623 7.68158 19.5009 9.92016 19.5009 12.6816ZM15.5009 12.6816C15.5009 13.2339 15.0532 13.6816 14.5009 13.6816C13.9486 13.6816 13.5009 13.2339 13.5009 12.6816C13.5009 12.1293 13.9486 11.6816 14.5009 11.6816C15.0532 11.6816 15.5009 12.1293 15.5009 12.6816Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                {findDisplayName(
                  "organizationCreateUploadLogoButton",
                  "Upload Logo"
                )}
              </span>
            </div>
            <div className="col-lg-4 col-md-6  d-flex gap-2 flex-column">
              <input
                type="file"
                className="d-none"
                id="upload_photo"
                onClick={(e) => setUploadedImage2(e.target.files[0])}
                onChange={(e) => handleImageChange(2, e)}
                ref={fileInputRef2}
                accept="image/jpeg', 'image/jpg', 'image/png'"
              />
              <img
                src={
                  uploadedImage2
                    ? URL.createObjectURL(uploadedImage2)
                    : Rectangle
                }
                alt="Location"
                className="img-height w-100 mt-5 custom-image-size "
              />

              <span
                className=" btn bg-primary  btn-sm rounded-pill mt-2 text-white  d-flex gap-2 justify-content-center"
                onClick={() => {
                  handleUploadClick2();
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.50092 4.68158V20.6816M14.1257 12.3065H14.8757M14.1257 13.0565H14.8757M5.70092 20.6816H19.3009C20.421 20.6816 20.9811 20.6816 21.4089 20.4636C21.7852 20.2718 22.0912 19.9659 22.2829 19.5896C22.5009 19.1617 22.5009 18.6017 22.5009 17.4816V7.88158C22.5009 6.76147 22.5009 6.20142 22.2829 5.7736C22.0912 5.39727 21.7852 5.09131 21.4089 4.89957C20.9811 4.68158 20.421 4.68158 19.3009 4.68158H5.70092C4.58081 4.68158 4.02076 4.68158 3.59293 4.89957C3.21661 5.09131 2.91065 5.39727 2.7189 5.7736C2.50092 6.20142 2.50092 6.76147 2.50092 7.88158V17.4816C2.50092 18.6017 2.50092 19.1617 2.7189 19.5896C2.91065 19.9659 3.21661 20.2718 3.59293 20.4636C4.02076 20.6816 4.58081 20.6816 5.70092 20.6816ZM19.5009 12.6816C19.5009 15.443 17.2623 17.6816 14.5009 17.6816C11.7395 17.6816 9.50092 15.443 9.50092 12.6816C9.50092 9.92016 11.7395 7.68158 14.5009 7.68158C17.2623 7.68158 19.5009 9.92016 19.5009 12.6816ZM15.5009 12.6816C15.5009 13.2339 15.0532 13.6816 14.5009 13.6816C13.9486 13.6816 13.5009 13.2339 13.5009 12.6816C13.5009 12.1293 13.9486 11.6816 14.5009 11.6816C15.0532 11.6816 15.5009 12.1293 15.5009 12.6816Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                {findDisplayName(
                  "organizationCreateUploadBackgroundButton",
                  "Upload Background"
                )}
              </span>
            </div>
            <div className="col-lg-4 col-md-6  d-flex gap-2 flex-column mt-5">
              <video
                controls
                className="video-view rounded w-100 img-height custom-image-size"
                ref={(video) => {
                  if (video && uploadedVideo) {
                    video.src = URL.createObjectURL(uploadedVideo);
                  }
                }}
              ></video>
              <input
                type="file"
                accept="video/*" // This line restricts file selection to video files
                className="d-none"
                id="upload_video"
                {...register("video")}
                ref={videoInputRef}
                onChange={(e) => handleVideoChange(e)}
                disabled
              />

              <span
                className="btn bg-primary btn-sm rounded-pill mt-2 text-white d-flex gap-2 justify-content-center"
                onClick={handleVideoClick}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 58 59"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="29"
                    cy="29.8477"
                    r="27"
                    fill="#0E111D"
                    fillOpacity="0.3"
                    stroke="white"
                    strokeWidth="4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.3107 27.2806C41.0439 28.4727 41.0439 31.0321 39.3107 32.2242L25.7954 41.5198C23.8049 42.8888 21.0953 41.4638 21.0953 39.048L21.0953 20.4569C21.0953 18.0411 23.8049 16.6161 25.7954 17.9851L39.3107 27.2806Z"
                    fill="white"
                  />
                </svg>
                {findDisplayName(
                  "organizationCreateUploadVideoButton",
                  "Upload Video"
                )}
              </span>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
              <label htmlFor="name">
                {findDisplayName("organizationCreateNameHeading", "Name")}
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                }}
                placeholder={findDisplayName(
                  "organizationCreateNameInputBox",
                  "Name"
                )}
                {...register("name", {})}
                required
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
              <label htmlFor="display_name">
                {findDisplayName(
                  "organizationCreateDisplayNameHeading",
                  "Display Name"
                )}
              </label>
              <input
                type="text"
                className="form-control"
                id="display_name"
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                }}
                placeholder={findDisplayName(
                  "organizationCreateDisplayNameInputBox",
                  "Display Name"
                )}
                {...register("displayname", {})}
                required
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
              <label htmlFor="phone">
                {findDisplayName("organizationCreatePhoneHeading", "Phone")}
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                placeholder={findDisplayName(
                  "organizationCreatePhoneInputBox",
                  "Phone"
                )}
                {...register("phone", {})}
                required
              />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
              <label htmlFor="badge_number">
                {findDisplayName(
                  "organizationCreateBadgeNumberHeading",
                  "Badge Number"
                )}
              </label>
              <input
                type="text"
                className="form-control"
                id="badge_number"
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                placeholder={findDisplayName(
                  "organizationCreateBadgeNumberInputBox",
                  "Eg..### ###"
                )}
                {...register("badge_number")}
                onKeyDown={(event) => {
                  const allowedKeys = ["#", " ", "Backspace"];
                  if (!allowedKeys.includes(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
              <label htmlFor="street">
                {findDisplayName("organizationCreateStreetHeading", "Street")}
              </label>
              <input
                type="text"
                className="form-control"
                id="street"
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                placeholder={findDisplayName(
                  "organizationCreateStreetInputBox",
                  "Street"
                )}
                {...register("street", {})}
                required
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
              <label htmlFor="city">
                {findDisplayName("organizationCreateCityHeading", "City")}
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                placeholder={findDisplayName(
                  "organizationCreateCityInputBox",
                  "City"
                )}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                }}
                {...register("city", {})}
                required
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
              <label htmlFor="state">
                {findDisplayName("organizationCreateStateHeading", "State")}
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                placeholder={findDisplayName(
                  "organizationCreateStateInputBox",
                  "State"
                )}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                }}
                {...register("state", {})}
                required
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-4">
              <label htmlFor="zip_code">
                {findDisplayName(
                  "organizationCreateZipCodeHeading",
                  "Zip Code"
                )}
              </label>
              <input
                type="text"
                className="form-control"
                id="zip_code"
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                placeholder={findDisplayName(
                  "organizationCreateZipCodeInputBox",
                  "Zip Code"
                )}
                {...register("zip_code", {})}
                required
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
              <label>
                {findDisplayName(
                  "organizationCreateDefaultLanguageHeading",
                  "Default Language"
                )}
              </label>
              <div className="dropdown">
                <button
                  className="bg-white w-100 form-select text-start "
                  id="languageDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={!languageList || languageList.length === 0}
                >
                  {languageName}
                </button>
                <ul
                  className="dropdown-menu w-100 pb-2"
                  aria-labelledby="languageDropdown"
                >
                  {languageList?.map((item, index) => (
                    <li key={index}>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setValue("defaultlanguage", item.language_id);
                          setLanguageName(item.language_name);
                        }}
                      >
                        {item.language_name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {fieldData.length > 0 && fieldData && (
              <DynamicFields
                fieldData={fieldData}
                dynamicFieldsRef={dynamicFieldsRef}
              />
            )}
            <div className="col-12 mt-4 mt-lg-5 d-flex justify-content-center mb-3 gap-3">
              <button
                type="submit"
                className="btn btn-primary mt-4"
                onClick={handleSubmit((data) => handleConfirmClick(data))}
                disabled={loading}
              >
                {loading ? (
                  "Creating ..."
                ) : (
                  <>
                    {findDisplayName(
                      "organizationCreateCreateButton",
                      "Create"
                    )}
                  </>
                )}
              </button>
              <button
                className="btn bg-secondary text-light mt-4 "
                onClick={() => {
                  navigate("/Organization_details");
                }}
              >
                {findDisplayName("organizationCreateCancelButton", "Cancel")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default OrganizationCreate;
