import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import {
  findDisplayName,
  isNonEmptyString,
} from "../../../../utils/commonFunctionsAndStatices";
import { useNavigate } from "react-router-dom";
import { setFilterPortData } from "../../../Store/actions/portData/portDataReducer";
import { PortDetails } from "../../../apis/apisServies";

const PortSearch = ({setCount , setLoading, pageNo , setPageNo , setCurrentPage}) => {
  const dispatch = useDispatch();
  const loginData = useSelector(selectToken);
  const navigate = useNavigate();
  const prevSearchRef = useRef({});
  const [portDetail, setPortDetails] = useState({
    portName: "",
  });

  const onUpdatePort = (e) => {
    const formData = {
      ...portDetail,
      [e.target.name]: e.target.value,
    };
    setPortDetails(formData);
  };

  
  const compareSearchValues = (search1, search2) => {
    return Object.keys(search1).every(key => search1[key] === search2[key]);
  };
  
  
  const getportdetails = async () => {
    const itemsPerPage = 100;
    const paginationNumber = (pageNo - 1) * itemsPerPage;
    
    var search = {
      port_name: portDetail.portName?.trim() || "",
    };

    let dataObj = {
      pagination_number: pageNo === 1 ? "0" : !compareSearchValues(search, prevSearchRef.current) ? "0" : String(paginationNumber),
      ...(search && { ...search }), // Include search data only if available
    };

    prevSearchRef.current = { ...search };

    try {
      const response = await PortDetails(dataObj);
      if(response.data.statusCode === 200){
        setLoading(false);
        const data = response?.data?.body?.data;
        const count = response?.data?.body?.total_count;
        setCount(count);
        dispatch(setFilterPortData(data));
      } else if(response.data.statusCode === 404){
        setCount(1);
        dispatch(setFilterPortData([]));
      }
    } catch (error) {
      dispatch(setFilterPortData([]));
      console.error("Error fetching port details:", error);
    }
  };

  useEffect(() => {
      getportdetails();
  }, [pageNo]);
  

  const handleSearch = async () => {
    setPageNo(1);
    getportdetails();
    setCurrentPage(1);
  };
  
  useEffect(() => {
    // Add the event listener to the window
    window.addEventListener("popstate", handlePopstate);
  }, []);

  //* handler router
  const handlePopstate = (event) => {
    if (navigator.onLine) {
      if (!isNonEmptyString(loginData?.organization_id)) {
        // Navigate back
        navigate(-1);
      } else {
        // Prevent the default behavior of the Back button and stay on the current page
        event.preventDefault();
        navigate("/PortDetail");
      }
    }
  };

  return (
    <div className="container Searchmovetop">
      <div className="container ">
        <div className="row w-100 gx-4 gy-2 justify-content-center align-items-center">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <input
              type="text"
              autoComplete="off"
              name="portName"
              placeholder={findDisplayName("portSearchPortNameInputBox", "Port Name")}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
              }}
              className="form-control"
              onChange={(e) => onUpdatePort(e)}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <button
              onClick={() => handleSearch()}
              className="btn btn-primary btn-sign-in btn-bloc mt-0 w-100 h-100"
            >
              <b>
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  className="me-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.243 18.9236C17.5691 19.2497 18.0978 19.2497 18.4238 18.9236C18.7499 18.5975 18.7499 18.0688 18.4238 17.7427L17.243 18.9236ZM14.0572 8.52925C14.0572 11.8583 11.3585 14.557 8.02949 14.557V16.227C12.2808 16.227 15.7272 12.7806 15.7272 8.52925H14.0572ZM8.02949 14.557C4.70046 14.557 2.00175 11.8583 2.00175 8.52925H0.331748C0.331748 12.7806 3.77815 16.227 8.02949 16.227V14.557ZM2.00175 8.52925C2.00175 5.20022 4.70046 2.5015 8.02949 2.5015V0.831504C3.77815 0.831504 0.331748 4.2779 0.331748 8.52925H2.00175ZM8.02949 2.5015C11.3585 2.5015 14.0572 5.20022 14.0572 8.52925H15.7272C15.7272 4.2779 12.2808 0.831504 8.02949 0.831504V2.5015ZM12.341 14.0216L17.243 18.9236L18.4238 17.7427L13.5219 12.8408L12.341 14.0216Z"
                    fill="black"
                  />
                </svg>
                {findDisplayName("portSearchButton", "Search")}
              </b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortSearch;
