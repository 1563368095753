import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { selectRoleData } from "../../../Store/actions/role/selectRole";
import { selectOrgLocation } from "../../../Store/actions/orgLocation/selectOrgLocation";
import {
  FeaturesList,
  UserUpdateAPI,
  deleteUserLocation,
  orgLocation,
  roleData,
  userLocationAPI,
} from "../../../apis/apisServies";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { setRoleData } from "../../../Store/actions/role/roleReducer";
import { setOrgLocation } from "../../../Store/actions/orgLocation/orgLocationReducer";
import {
  findDisplayName,
  isView,
  isViewUser,
} from "../../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import "../CSS/style.css";
import PortDropdown from "../../PortDropdown/PortDropdown";
import { selectPortData } from "../../../Store/actions/portData/selectPortData";
const UserEdit = ({
  user,
  setActiveComponent,
  organizationid,
  fetchdata,
  activeButton,
}) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const { showSuccess, showError } = useNotification();
  const loginData = useSelector(selectToken);
  const [privilegeList, setPrivileLsit] = useState([]);
  const role = useSelector(selectRoleData);
  const locationData = useSelector(selectOrgLocation);
  const portData = useSelector(selectPortData);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const languageId = useSelector(languageid);
  const [loading, isLoading] = useState(false);
  const [isPort, setisPort] = useState(false);
  const [showList, setShowList] = useState(false);
  const childRef = useRef(null);
  const [selectedPortData, setSelectedPortData] = useState(user?.default_port);
  const initialFirstName = user?.first_name?.trim();
  const initialLastName = user?.last_name?.trim();
  const initialloginid = user?.login_id?.trim();
  const initialPassword = user?.password?.trim();
  let roleID = watch("userType");
  const initialrole = role?.find(
    (item) => item?.org_rolename === user?.role_name
  )?.org_roleid;
  const initialIsPort = user?.allow_port_selection;
  const initialPort =
    portData && portData.length > 0
      ? portData.find((port) => port.port === user?.default_port)?.port_name ||
        ""
      : "";

  const initialLocationId = user?.user_locations;
  const isSameUser = loginData?.login_id === user?.user_id;

  const initialLocationIds = initialLocationId
    .filter(
      (location) =>
        location.locationid !== null && location.locationname !== null
    )
    .map((location) => ({
      value: location.locationid,
      label: location.locationname,
    }));

  const [selectedLocations, setSelectedLocations] =
    useState(initialLocationIds);

  const handleLocationChange = (selectedOption) => {
    // Filter out the options that are already in selectedLocations
    const newLocations = selectedOption.filter(
      (option) =>
        !selectedLocations.some((location) => location.value === option.value)
    );
    // Update selectedLocations with the new locations
    setSelectedLocations([...selectedLocations, ...newLocations]);
  };

  const handleLocationRemove = (locationToRemove) => {
    const updatedLocations = selectedLocations.filter(
      (location) => location.value !== locationToRemove.value
    );

    setSelectedLocations(updatedLocations);
  };

  let locationOptions = [];

  if (Array.isArray(locationData)) {
    if (locationData.length === 0) {
      locationOptions = [];
    } else if (locationData.statusCode === 400) {
      locationOptions = [];
    } else {
      locationOptions = locationData.map((item) => ({
        value: item.locationid,
        label: item.locationname,
      }));
    }
  }

  const selectedLocationIds = selectedLocations.map(
    (location) => location.value
  );

  const handleUpdateButtonClick = async (data) => {
    const getinitialids = initialLocationIds.map((item) => {
      return item.value;
    });

    var added = [];
    var removed = [];

    selectedLocationIds.map((i) => {
      if (!getinitialids.includes(i)) {
        added.push(i);
      }
    });

    getinitialids.map((i) => {
      if (!selectedLocationIds.includes(i)) {
        removed.push(i);
      }
    });

    let changes = {};
    if (data.firstName?.trim() !== initialFirstName) {
      changes.firstname = data.firstName?.trim();
    }

    if (data.lastName?.trim() !== initialLastName) {
      changes.lastname = data.lastName?.trim();
    }

    if (data.email?.trim() !== initialloginid) {
      changes.loginid = data.email?.trim();
    }

    if (data.userType !== initialrole) {
      changes.roleid = data.userType;
    }

    if (isView("isPasswordViewForUser") && data.password !== initialPassword) {
      changes.loginpassword = data.password;
    }

    if (isView("isPortDetailsForUser") || data.isport !== initialIsPort) {
      changes.allowportselection = data.isport;
    }
    if (
      isView("isPortDetailsForUser") ||
      selectedPortData !== user?.default_port
    ) {
      changes.defaultport = selectedPortData;
    }
    if (
      Object.keys(changes).length === 0 &&
      added.length === 0 &&
      removed.length === 0
    ) {
      showError("No changes were made");
      return;
    }
    if (!data.userType) {
      showError(
        findDisplayName("userUpdateRoleValidation", "Kindly choose a role.")
      );
    } else if (!data.firstName.trim()) {
      showError(
        findDisplayName(
          "userUpdateFirstNameValidation",
          "Kindly Enter a First Name"
        )
      );
      return;
    } else if (!data.lastName.trim()) {
      showError(
        findDisplayName(
          "userUpdateLastNameValidation",
          "Kindly Enter a Last Name"
        )
      );
      return;
    } else if (!data.email.trim()) {
      showError(
        findDisplayName(
          "userUpdateLoginIdValidation",
          "Kindly Enter a Login ID"
        )
      );
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      showError(
        findDisplayName(
          "userUpdateLoginId1Validation",
          "Kindly Enter a valid Login ID"
        )
      );
      return;
    } else if (isView("isPasswordViewForUser") && !data.password) {
      showError(
        findDisplayName(
          "userUpdatePasswordValidation",
          "Kindly Enter a Password"
        )
      );
      return;
    }
    const passwordPattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,15}$/;

    if (
      isView("isPasswordViewForUser") &&
      data.password &&
      !passwordPattern.test(data.password)
    ) {
      showError(
        findDisplayName(
          "userUpdatePasswordCharactersValidation",
          "A minimum of one uppercase, one lowercase, one special character, and one number must be included in a password that is between 8 - 15 characters long."
        )
      );
      return;
    } else {
      if (Object.keys(changes).length > 0) {
        const dataObj = {
          hostuserid: loginData?.login_id,
          requestuserid: user.user_id,
          ...changes,
          text_message: {
            language_id: languageId,
            ...(loginData?.organization_id
              ? { organization_id: loginData?.organization_id }
              : {}),
          },
        };
        const response = await UserUpdateAPI(dataObj);

        if (response.data.statusCode === 200) {
          isLoading(true);
          const successMessage = response?.data?.body.message;
          showSuccess(successMessage);
          fetchdata(organizationid, activeButton);
          setActiveComponent("table");
        } else {
          const errorMessage = response?.data?.body?.message;
          showError(errorMessage);
          isLoading(false);
        }
      }
    }

    const updateObj = {
      host_userid: loginData?.login_id,
      client_userid: user.user_id,
      client_locationid: added,
      text_message: {
        language_id: languageId,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    const deleteObj = {
      host_userid: loginData?.login_id,
      client_userid: user.user_id,
      client_locationid: removed,
      text_message: {
        language_id: languageId,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };

    if (removed.length > 0) {
      const responseDelete = await deleteUserLocation(deleteObj);
      showSuccess(responseDelete?.data?.body?.message);
      fetchdata(organizationid, activeButton);
      setActiveComponent("table");
    }

    if (added.length > 0) {
      const responseUpdate = await userLocationAPI(updateObj);
      showSuccess(responseUpdate?.data?.body?.message);
      fetchdata(organizationid, activeButton);
      setActiveComponent("table");
    }
  };

  const getLocation = async (id) => {
    const dataobj = {
      userid: loginData?.login_id,
      ...(organizationid !== "" && { organizationid: organizationid }),
    };
    try {
      const response = await orgLocation(dataobj);
      const setUserData = response?.data?.body?.data;
      dispatch(setOrgLocation(setUserData));
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const getRoleData = async (roleId) => {
    const id = {
      userid: loginData?.login_id,
      text_message: {
        language_id: languageId,
        ...(loginData?.organization_id
          ? { organization_id: loginData?.organization_id }
          : {}),
      },
    };
    if (isView("isAdminUser") === false) {
      id.parent_user = loginData?.role_id;
    }
    if (roleId !== "") {
      id.organizationid = roleId;
    } else {
      id.independent_user = "independent_user";
    }
    const response = await roleData(id);
    if (response?.data?.statusCode === 200) {
      const data = response?.data?.body?.data;
      dispatch(setRoleData(data));
    } else {
      setActiveComponent("table");
      showError(
        findDisplayName(
          "userChildRoleNotAvailable",
          "Make sure that before creating a new user, you ensure that there's a child role."
        )
      );
      console.log("error: ");
    }
  };

  useEffect(() => {
    getLocation();
    getRoleData(organizationid);
    setTimeout(() => {
      if (initialrole) {
        setValue("userType", initialrole);
        roleID = watch("userType");
      }
    }, 1000);
  }, [initialrole]);

  // Import an icon or use an HTML element for the close icon
  const CloseIcon = () => <span>&times;</span>;

  const handlePortChange = (selectedPort) => {
    if (selectedPort) {
      const selectedPortItem = portData?.find(
        (port) => port.port_name === selectedPort
      )?.port;
      setSelectedPortData(selectedPortItem);
    } else {
      setSelectedPortData(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (childRef.current && !childRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const getPrivilege = async (roleId) => {
      const dataObj = {
        userid: loginData?.login_id,
        roleid: roleId,
        text_message: {
          language_id: languageId,
        },
      };

      const response = await FeaturesList(dataObj);
      if (response?.data?.statusCode === 200) {
        const data = response?.data?.body?.data;
        setPrivileLsit(data);
      } else {
        setPrivileLsit([]);
        console.log("error");
      }
    };
    if (roleID) {
      getPrivilege(roleID);
    }
  }, [roleID]);

  return (
    <div className="container">
      <form>
        <div className=" col-lg-12 col-md-12 col-sm-12  text-white from-scrol">
          <div className="row p-4 p-lg-5">
            <div className="d-flex flex-column gap-3 flex-lg-row flex-md-column flex-sm-column small-screen align-items-md-center ">
              <div className="form-group col-lg-4 col-md-8 col-sm-12">
                <label htmlFor="userType">
                  {findDisplayName("userUpdateScreenRoleHeading", "Role")}
                </label>
                {role?.length > 1 && (
                  <div className="dropdown mt-2">
                    <Controller
                      name="userType"
                      control={control}
                      defaultValue={initialrole}
                      render={({ field }) => (
                        <>
                          <button
                            className="bg-white w-100 form-select text-start "
                            id="userTypeDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            disabled={isSameUser}
                          >
                            {role?.find(
                              (item) => item.org_roleid === field.value
                            )?.org_rolename || "Select Role"}
                          </button>
                          <ul
                            className="dropdown-menu w-100"
                            aria-labelledby="userTypeDropdown"
                          >
                            {role.map((item, index) => (
                              <li key={index}>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    field.onChange(item.org_roleid);
                                  }}
                                >
                                  {item.org_rolename}
                                </a>
                              </li>
                            ))}
                          </ul>
                          <input type="hidden" {...field} />
                        </>
                      )}
                    />
                  </div>
                )}
                {role?.length === 1 && (
                  <>
                    <input
                      className="form-control mt-2"
                      type="text"
                      value={role[0]?.org_rolename}
                      readOnly
                    />
                    <input
                      name="userType"
                      type="hidden"
                      value={role[0]?.org_roleid}
                      {...register("userType")}
                    />
                  </>
                )}
              </div>

              <div className="form-group col-lg-4 col-md-8 col-sm-12">
                <label htmlFor="firstName">
                  {findDisplayName(
                    "userUpdateScreenFirstNameHeading",
                    "First Name"
                  )}
                </label>
                <input
                  type="text"
                  className={`form-control mt-2 ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                  placeholder="First Name"
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                  }}
                  {...register("firstName", {})}
                  defaultValue={initialFirstName}
                />
              </div>
              <div className="form-group col-lg-4 col-md-8 col-sm-12">
                <label htmlFor="lastName">
                  {findDisplayName(
                    "userUpdateScreenLastNameHeading",
                    "Last Name"
                  )}
                </label>
                <input
                  type="text"
                  className={`form-control mt-2 ${
                    errors.lastName ? "is-invalid" : ""
                  }`}
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                  }}
                  placeholder="Last Name"
                  {...register("lastName", {})}
                  defaultValue={initialLastName}
                />
              </div>
            </div>
            <div className="d-flex gap-3 flex-column flex-lg-row  flex-md-column flex-sm-column small-screen mt-4 align-items-md-center align-items-lg-start">
              <div className="form-group col-lg-4 col-md-8 col-sm-12 ">
                <label htmlFor="email">
                  {findDisplayName(
                    "userUpdateScreenLoginIDHeading",
                    "Login ID"
                  )}
                </label>
                <input
                  type="email"
                  className={`form-control mt-2 ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  placeholder="E-mail"
                  autoComplete="off"
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                  }}
                  {...register("email", {})}
                  onInput={(event) => {
                    // Remove spaces from the input value
                    event.target.value = event.target.value.replace(/\s/g, "");
                  }}
                  defaultValue={initialloginid}
                />
              </div>
              {isView("isPasswordViewForUser") && (
                <div className="form-group col-lg-4 col-md-8 col-sm-12 ">
                  <label htmlFor="password">
                    {findDisplayName(
                      "userUpdateScreenPasswordHeading",
                      "Password"
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control mt-2 ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      placeholder="Password"
                      autoComplete="off"
                      onFocus={(event) => {
                        event.target.setAttribute("autoComplete", "off");
                      }}
                      {...register("password", {})}
                      defaultValue={initialPassword}
                      onInput={(event) => {
                        // Remove spaces from the input value
                        event.target.value = event.target.value.replace(
                          /\s/g,
                          ""
                        );
                      }}
                    />
                    <span className="input-group-text mt-2">
                      <i
                        className={`bi bi-eye${showPassword ? "" : "-slash"}`}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </span>
                  </div>
                </div>
              )}
              {privilegeList &&
                isViewUser(privilegeList, "isLocationRequiredForUser") && (
                  <div
                    className={`form-group ${
                      isView("isListOrganization")
                        ? "col-lg-4 col-md-8 col-sm-12"
                        : "col-xxl-9 col-lg-10 col-md-8 col-sm-12"
                    } `}
                  >
                    <label htmlFor="userLocation">
                      {findDisplayName(
                        "userUpdateScreenLocationHeading",
                        "Location"
                      )}
                    </label>
                    <div className="row">
                      <div
                        className={` ${
                          isView("isListOrganization")
                            ? "col-xxl-12 col-lg-12 col-md-12"
                            : "col-xxl-11 col-lg-10 col-md-12"
                        } `}
                      >
                        <Select
                          className="form-control  close mt-2 "
                          options={locationOptions}
                          isMulti
                          value={selectedLocations}
                          onChange={handleLocationChange}
                          closeMenuOnSelect={false}
                          placeholder="Location"
                          components={{
                            MultiValueRemove: (props) =>
                              initialLocationIds.length > 0 ? (
                                <button
                                  className="location-remove-button"
                                  onClick={() =>
                                    handleLocationRemove(props.data)
                                  }
                                >
                                  <CloseIcon />
                                </button>
                              ) : null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {privilegeList &&
              isViewUser(privilegeList, "isPortDetailsForUser") && (
                <div className="d-flex gap-3 flex-column  flex-lg-row flex-md-column flex-sm-column small-screen mt-4 align-items-md-center align-items-lg-start">
                  <div className=" col-lg-4 col-md-8 col-sm-12 mt-lg-5 mt-3">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      id="isPort"
                      onChange={(e) => setisPort(e.target.checked)}
                      defaultChecked={initialIsPort}
                      {...register("isport", {})}
                    />
                    <label htmlFor="isPort ">
                      {findDisplayName(
                        "userUpdateScreenAllowPortCheckBox",
                        "Allow Port"
                      )}
                    </label>
                  </div>
                  <div className="form-group col-lg-4 col-md-8 col-sm-12 mt-lg-3 mt-3 position-relative">
                    <label htmlFor="customInput">
                      {findDisplayName(
                        "userUpdateScreenDefaultPortHeading",
                        "Default Port"
                      )}
                    </label>
                    <PortDropdown
                      items={portData}
                      keys={["port_name", "port_id"]}
                      placeholder={findDisplayName(
                        "userUpdateScreenDefaultPortInputBox",
                        "Default Port"
                      )}
                      valueChange={handlePortChange}
                      showList={showList}
                      setShowList={setShowList}
                      childRef={childRef}
                      defaultValue={initialPort}
                      style={"100%"}
                      {...register("port", {})}
                    />
                  </div>
                </div>
              )}
            <div className="d-flex row gap-3 flex-column flex-lg-row flex-md-column flex-sm-column flex-xs-column small-screen align-items-center mt-4">
              <div className="d-flex justify-content-lg-end justify-content-center  gap-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit(handleUpdateButtonClick)}
                  disabled={loading}
                >
                  {loading ? (
                    "Loading..."
                  ) : (
                    <>
                      {" "}
                      {findDisplayName(
                        "userUpdateScreenUpdateButton",
                        "Update"
                      )}{" "}
                    </>
                  )}
                </button>
                <button
                  className="btn bg-secondary text-light"
                  onClick={() => {
                    setActiveComponent("table");
                  }}
                >
                  {findDisplayName("userUpdateScreenCancelButton", "Cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserEdit;
