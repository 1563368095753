import React, { useEffect, useState } from "react";
import "./css/style.css";
import DashboardChart1 from "./DashboardChart1/DashboardChart1";
import DashboardChart2 from "./DashboardChart2/DashboardChart2";
import DashboardChart3 from "./DashboardChart3/DashboardChart3";
import DashboardChart5 from "./DashboardChart5/DashboardChart5";
import DashboardChart6 from "./DashboardChart6/DashboardChart6";
import DashboardChart7 from "./DashboardChart7/DashboardChart7";
import DashboardChart8 from "./DashboardChart8/DashboardChart8";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { useDispatch, useSelector } from "react-redux";
import {
  CSOAPI,
  MasterLabelAPI,
  PortDetails,
  VisitorTypeAPI,
  orgLocation,
  organizationApi,
} from "../../apis/apisServies";
import {
  findDisplayName,
  isNonEmptyString,
  isView,
} from "../../../utils/commonFunctionsAndStatices";
import VisitorsReport from "./VisitorsReport/VisitorsReport";
import { setAllLocation } from "../../Store/actions/allLocationdata/getAllLocationReducer";
import {
  setFilterPortData,
  setPortData,
} from "../../Store/actions/portData/portDataReducer";
import { setVisitorTable } from "../../Store/actions/VisitorTypesTable/VisitorTypesTableReducer";
import { setOrganizationData } from "../../Store/actions/getorganization/organizationReducer";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import { useNavigate } from "react-router-dom";
import { setMasterLabel } from "../../Store/actions/masterLabel/masterLabelReducer";
import { setLanguageId } from "../../Store/actions/languageId/languageIdReducer";

const Dashboard = () => {
  const loginData = useSelector(selectToken);
  const languageId = useSelector(languageid);
  const { showError } = useNotification();
  const dispatch = useDispatch();
  const [locationList, setLocationList] = useState([]);
  const [visitorTypeList, setVisitorTypeList] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [labelLanguageID, setLabelLanguageID] = useState("");
  const localLanguageId = localStorage.getItem("language_id");

  useEffect(() => {
    // Get all visitors list
    const dataFetch = async () => {
      var search = {
        category1: "All",
        category2: "All",
        category3: "All",
        organizationid: "",
        userid: loginData?.login_id,
        text_message: {
          language_id: languageId,
          ...(loginData?.organization_id
            ? { organization_id: loginData?.organization_id }
            : {}),
        },
      };
      const response = await CSOAPI(search);
      if (response?.data?.statusCode === 200) {
        const csoResult = response?.data?.body;
        setData(csoResult);
      } else {
        return;
      }
    };

    // Get Location lists
    const getLocationData = async () => {
      const dataObj = {
        userid: loginData?.login_id,
        organizationid: isView("isListOrganization")
          ? ""
          : loginData?.organizationid,
      };
      try {
        const response = await orgLocation(dataObj);
        if (response.data.statusCode === 200) {
          const locationData = response?.data?.body?.data;
          setLocationList(locationData);
          dispatch(setAllLocation(locationData));
        } else if (response.data.statusCode === 404) {
          setLocationList([]);
          dispatch(setAllLocation([]));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // get organization basic information
    const fetchOrganizationData = async () => {
      const dataObj = {
        organizationid: loginData?.organization_id,
      };
      try {
        const response = await organizationApi(dataObj);
        const organizationData = response?.data?.body;
        const Id_language = response?.data?.body?.default_language;
        setLabelLanguageID(Id_language);
        dispatch(setLanguageId(Id_language));
        dispatch(setOrganizationData(organizationData));
      } catch (error) {
        showError(error?.response?.data?.body?.message);
      }
    };

    // Get visitor Type list
    const getVisitorTypes = async () => {
      const dataObj = {
        userid: loginData?.login_id,
        organizationid: isView("isListOrganization")
          ? ""
          : loginData?.organizationid,
        text_message: {
          language_id: languageId,
          ...(loginData?.organization_id
            ? { organization_id: loginData?.organization_id }
            : {}),
        },
      };
      try {
        const response = await VisitorTypeAPI(dataObj);
        if (response.data.statusCode === 200) {
          const setVisitorData = response?.data?.body?.data;
          dispatch(setVisitorTable(setVisitorData));
          setVisitorTypeList(setVisitorData);
        } else if (response.data.statusCode === 404) {
          dispatch(setVisitorTable([]));
          setVisitorTypeList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadingCheck = () => {
      setTimeout(() => {
        setLoading(false);
      }, 3700);
    };
    loadingCheck();
    getVisitorTypes();
    dataFetch();
    getLocationData();
    if (!isView("isListOrganization")) {
      fetchOrganizationData();
    }
  }, []);

  const masterLabelData = async () => {
    if (!localLanguageId) {
      setLoading(true);
      const dataObj = {
        LanguageId: labelLanguageID,
        OrganizationId: loginData?.organization_id,
      };
      const masterLabel = await MasterLabelAPI(dataObj);
      const data = masterLabel?.data?.body?.data;
      dispatch(setMasterLabel(data));
    }
  };

  useEffect(() => {
    masterLabelData();
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [labelLanguageID]);

  function separateByOrganization(dictionaries) {
    const separatedData = {};

    dictionaries.forEach((dict) => {
      const organizationId = dict.organizationid;

      // If the organizationId key doesn't exist in separatedData, create an array for it
      if (!separatedData[organizationId]) {
        separatedData[organizationId] = [];
      }
      // Add the dictionary to the corresponding organizationId array
      separatedData[organizationId].push(dict);
    });

    return separatedData;
  }

  const separatedData = separateByOrganization(data);

  function findOrganizationNameById(id) {
    var root = JSON.parse(sessionStorage.getItem("persist:root"));
    var OrganizationList = JSON.parse(root.OrgTableData).Organizationtable;
    const foundItem = OrganizationList?.find(
      (item) => item.organizationid === id
    );
    return foundItem ? foundItem.name : null;
  }

  // Function to convert organization IDs to names in the separated data
  function convertOrganizationIdsToNames(separatedData) {
    const convertedData = {};
    for (const [organizationId, data] of Object.entries(separatedData)) {
      const organizationName = findOrganizationNameById(organizationId);
      convertedData[organizationName || organizationId] = data.map((entry) => ({
        ...entry,
        organizationid: organizationName || organizationId,
      }));
    }
    return convertedData;
  }
  const convertedData = convertOrganizationIdsToNames(separatedData);

  //* handler router
  const handlePopstate = (event) => {
    if (navigator.onLine) {
      if (!isNonEmptyString(loginData?.login_id)) {
        // Navigate back
        navigate(-1);
      } else {
        // Prevent the default behavior of the Back button and stay on the current page
        event.preventDefault();
        navigate("/Organization_details");
      }
    }
  };

  useEffect(() => {
    // Add the event listener to the window
    window.addEventListener("popstate", handlePopstate);
  }, []);

  return (
    <div className="container-fluid p-3 h-100 ">
      <div className="card col-lg-12 col-md-12 col-sm-12 padding-mobile">
        {/* <div className="from-scrol"> */}
        <nav className="nav-dashboard">
          <strong className="links">
            {findDisplayName("DashboardNameHeading", "Dashboard")}
          </strong>
        </nav>
        <hr className="line-color" />
        <div className=" from-scrol mb-5 ">
          <div className="row  gap-2  justify-content-center  p-2 ">
            {isView("isListOrganization") && (
              <div className="col-12 col-xxl-3 col-lg-5 col-md-5">
                <DashboardChart1
                  convertedData={convertedData}
                  loading={loading}
                />
              </div>
            )}
            {isView("isListOrganization") && (
              <div className="col-xxl-4 col-lg-5 col-md-6">
                <DashboardChart2
                  convertedData={data.length > 0 ? data : []}
                  loading={loading}
                />
              </div>
            )}
            {isView("isListOrganization") && (
              <div className=" col-xxl-4 col-lg-5 col-md-5">
                <DashboardChart3
                  data={data.length > 0 ? data : []}
                  visitorTypeList={
                    visitorTypeList.length > 0 ? visitorTypeList : []
                  }
                  loading={loading}
                />
              </div>
            )}
            {isView("isListOrganization") && (
              <div className="col-xxl-6 col-lg-5 col-md-6">
                <DashboardChart8
                  data={data.length > 0 ? data : []}
                  locationList={locationList.length > 0 ? locationList : []}
                  visitorTypeList={
                    visitorTypeList.length > 0 ? visitorTypeList : []
                  }
                  loading={loading}
                />
              </div>
            )}
            {isView("isListOrganization") && (
              <div className="col-xxl-5 col-lg-5  col-md-5">
                <DashboardChart7
                  data={data.length > 0 ? data : []}
                  loading={loading}
                />
              </div>
            )}
            {isView("isListOrganization") === false && (
              <div className="col-xxl-5 col-lg-6 col-md-6 ">
                <DashboardChart7
                  data={data.length > 0 ? data : []}
                  loading={loading}
                />
              </div>
            )}
            <div
              className={`${
                isView("isListOrganization")
                  ? "col-xxl-6 col-lg-5 col-md-6 "
                  : " col-md-5 "
              }`}
            >
              <DashboardChart5
                data={data.length > 0 ? data : []}
                locationList={locationList.length > 0 ? locationList : []}
                loading={loading}
              />
            </div>

            {isView("isListOrganization") === false && (
              <div className="col-xxl-5 col-lg-6 col-md-6 col-12">
                <DashboardChart8
                  data={data.length > 0 ? data : []}
                  locationList={locationList.length > 0 ? locationList : []}
                  visitorTypeList={
                    visitorTypeList.length > 0 ? visitorTypeList : []
                  }
                  loading={loading}
                />
              </div>
            )}
            {isView("isListOrganization") === false && (
              <div className=" col-xxl-5 col-lg-5 col-md-5 col-12">
                <DashboardChart3
                  data={data.length > 0 ? data : []}
                  visitorTypeList={
                    visitorTypeList.length > 0 ? visitorTypeList : []
                  }
                  loading={loading}
                />
              </div>
            )}
            <div
              className={`${
                isView("isListOrganization")
                  ? "col-xxl-5 col-lg-10 col-md-11  "
                  : "col-xxl-10 col-lg-11  col-md-11 "
              }`}
            >
              <DashboardChart6
                data={data.length > 0 ? data : []}
                locationList={locationList.length > 0 ? locationList : []}
                visitorTypeList={
                  visitorTypeList.length > 0 ? visitorTypeList : []
                }
                loading={loading}
              />
            </div>
          </div>
          <hr className="line-color" />
          <div className="col-12 p-2  ">
            <VisitorsReport data={data.length > 0 ? data : []} />
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Dashboard;
