import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { Permissions, RolesList } from "../../../apis/apisServies";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import { findDisplayName } from "../../../../utils/commonFunctionsAndStatices";

const RecreateFeatures = ({
  setActiveComponent,
  sortOrgData,
  organizationid,
  roledata,
  selectedRoleId,
}) => {
  const loginData = useSelector(selectToken);
  const [id, setRoleId] = useState("");
  const languageId = useSelector(languageid);
  const [orgValue, setOrgValue] = useState(organizationid);
  const { showSuccess, showError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const orgName =
    sortOrgData &&
    sortOrgData.find((item) => item.organizationid === organizationid).name;
  const roleName =
    roledata &&
    roledata.find((item) => item.org_roleid === selectedRoleId).org_rolename;

  const onSubmit = async (e) => {
    e.preventDefault();

    const dataObject = {
      userid: loginData?.login_id,
      privileges_clone: {
        from_role_id: selectedRoleId,
        clone_role_id: id,
      },
      text_message: {
        language_id: languageId,
      },
    };
    setLoading(true);
    const response = await Permissions(dataObject);
    if (response.data.statusCode === 200) {
      showSuccess(response?.data?.body?.message);
      setActiveComponent("table");
      setLoading(false);
    } else if (response?.data?.statusCode === 400) {
      showError(response?.data?.body?.message);
      setLoading(false);
    } else if (response?.data?.statusCode === 404) {
      showError(response?.data?.body?.message);
      setLoading(false);
    }
  };

  const handleOrganizationChange = (e) => {
    const data = e.target.value;
    setOrgValue(data);
    roleslist(data);
  };

  const roleslist = async (orgid) => {
    try {
      const dataObj = {
        userid: loginData?.login_id,
        text_message: {
          language_id: languageId,
        },
      };
      if (orgid !== "") {
        dataObj.organizationid = orgid;
      } else {
        dataObj.independent_user = "independent_user";
      }
      const response = await RolesList(dataObj);
      if (response?.data?.statusCode === 200) {
        const datavalue = response?.data?.body?.data;
        setData(datavalue);
        setRoleId(datavalue[0].org_roleid);
      } else if (response?.data?.statusCode === 400) {
        const errorMessage = response?.data?.body?.message;
        setData([]);
      }
    } catch (error) {
      console.error("Error in featurelist:", error);
      setData([]);
    }
  };

  useEffect(() => {
    if (organizationid) {
      roleslist(organizationid);
    }
  }, []);

  return (
    <div className="container text-white from-scrol">
      <form className="row d-flex align-items-center  justify-content-center">
        <div className="d-flex gap-3 small-screen align-items-md-center mt-4 justify-content-center">
          <div className="form-group mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8">
            <label htmlFor="moduleName">
              {findDisplayName(
                "featuresCloneOrgFromHeading",
                "Organization From"
              )}
            </label>
            <input
              className="form-control  mt-2 "
              type="text"
              name="FromOrg"
              value={roleName === "Admin" ? "Admin User" : orgName}
              readOnly
            />
          </div>

          <div className="form-group mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8 ">
            <label htmlFor="moduleName">
              {findDisplayName("featuresCloneRoleFromHeading", "Role From")}
            </label>
            <input
              className="form-control  mt-2 "
              type="text"
              name="roleName"
              value={roleName}
              readOnly
            />
          </div>
        </div>

        <div className="d-flex gap-3 small-screen align-items-md-center mt-4 justify-content-center">
          <div className="form-group mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8">
            <label htmlFor="organization" className="text-white">
              {findDisplayName("featuresCloneOrgToHeading", "Organization To")}
            </label>
            <div className="dropdown">
              <button
                className="bg-white w-100 form-select text-start text-truncate mt-2"
                id="organizationDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {orgValue
                  ? sortOrgData.find((item) => item.organizationid === orgValue)
                      ?.name
                  : findDisplayName("adminSelectionOption", "Admin")}
              </button>
              <ul
                className="dropdown-menu w-100 pb-2"
                aria-labelledby="organizationDropdown"
              >
                <li>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() =>
                      handleOrganizationChange({ target: { value: "" } })
                    }
                  >
                    {findDisplayName("adminSelectionOption", "Admin")}
                  </button>
                </li>
                {sortOrgData?.map((orgData, index) => (
                  <li key={index}>
                    <button
                      type="button"
                      className="dropdown-item text-truncate"
                      onClick={() =>
                        handleOrganizationChange({
                          target: { value: orgData?.organizationid },
                        })
                      }
                    >
                      {orgData?.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="form-group mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8">
            <label htmlFor="roleTo" className="text-white">
              {findDisplayName("featuresCloneRoleToHeading", "Role To")}
            </label>
            <div className="dropdown">
              <button
                className="bg-white w-100 form-select text-start text-truncate mt-2"
                id="roleToDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {id &&
                  data?.find((item) => item.org_roleid === id)?.org_rolename}
              </button>
              <ul
                className="dropdown-menu w-100 pb-2"
                aria-labelledby="roleToDropdown"
              >
                {data?.map((item, index) => (
                  <li key={index}>
                    <button
                      type="button"
                      className="dropdown-item text-truncate"
                      onClick={() => setRoleId(item.org_roleid)}
                    >
                      {item.org_rolename}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-3 col-lg-4 col-md-6 col-sm-12 col-xs-8 d-flex justify-content-center gap-2">
          <button
            className="btn btn-primary mt-3"
            onClick={(e) => onSubmit(e)}
            disabled={loading}
          >
            {findDisplayName("featuresCloneCreateButton", "Clone")}
          </button>
          <button
            className="btn btn-secondary mt-3"
            onClick={() => setActiveComponent("table")}
          >
            {findDisplayName("featuresCloneCancelButton", "Cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecreateFeatures;
