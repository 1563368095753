import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { MasterLabelCreateAPI } from "../../../apis/apisServies";
import { selectlanguageArr } from "../../../Store/actions/language/LanguageSelector";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";

const LanguageCreate = ({languageId , handleTableUpdate, organizationId, setActiveComponent}) => {
  const languages = useSelector(selectlanguageArr);
 const language_name= languages.find(
  (language) => language.language_id ===languageId
).language_name;
  const {
    handleSubmit,
    register,
  } = useForm();

  const navigate = useNavigate();
  const { showSuccess, showError } = useNotification();
  const loginData = useSelector(selectToken);
  const languageID = useSelector(languageid);
  const [disabled, setDisabled] = useState(false);

  const handleCreate = async (data) => {
    if (data.LabelName === "" || !data.LabelName.trim()) {
      showError(findDisplayName("labelCreateLabelNameValidation","Kindly Enter a Label name"));
    }
     else {
      const dataObj = {
        userid: loginData?.login_id,
        LanguageId: languageId,
        LabelName: data.LabelName?.trim(),
        LabelText: data.DisplayName?.trim(),
        ...(organizationId !== "" && { OrganizationId: organizationId }),
        text_message: {
          language_id: languageID,
          ...( (isView("isListOrganization") === false) ? { organization_id: loginData?.organization_id } : {}),
        }
      };
      setDisabled(true);
      const res = await MasterLabelCreateAPI(dataObj);
      if (res.data.statusCode === 201) {
        showSuccess(res?.data?.body?.message);
        handleTableUpdate(languageId, organizationId);
        setActiveComponent("table");
        navigate("/Organization_details/Label");
        setDisabled(false);
      } else if (res.data.statusCode === 409) {
        showError(res?.data?.body?.message);
        setDisabled(false);
      }
    }
  };

  return (
      <div className="container  text-white  from-scrol">
        <form onSubmit={handleSubmit(handleCreate)}>
          <div className="row d-flex flex-column align-items-center">

             <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
              <label htmlFor="language">{findDisplayName("labelCreateScreenLanguageHeading", "Language")}</label>
              <input
                type="text"
                className="form-control  mt-2 "
                id="language"
                value={language_name}
                readOnly 
              />
            </div>
         
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
              <label htmlFor="LabelName">{findDisplayName("labelCreateScreenLabelNameHeading", "Label Name")}</label>
              <input
                type="text"
                className="form-control  mt-2 "
                id="LabelName"
                {...register("LabelName", {})}
                placeholder={findDisplayName("labelCreateScreenLabelNameInputBox", "Label Name")}
                  onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
              />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
              <label htmlFor="DisplayName">{findDisplayName("labelCreateScreenDisplayNameHeading", "Display Name")}</label>
              <input
                type="text"
                className="form-control  mt-2 "
                id="DisplayName"
                autoComplete="off"
                {...register("DisplayName", {})}
                placeholder={findDisplayName("labelCreateScreenDisplayNameInputBox", "Display Name")}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex gap-2 justify-content-center">
              <button type="submit" className="btn btn-primary" disabled ={disabled} >
                {findDisplayName("labelCreateScreenCreateButton", "Create")}
              </button>
              <button
                className="btn bg-secondary text-light"
                onClick={() => {
                    setActiveComponent("table");
                }}
              >
                {findDisplayName("labelCreateScreenCancelButton", "Cancel")}
              </button>
            </div>
          </div>
        </form>
      </div>
  );
};

export default LanguageCreate;
