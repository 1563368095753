import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/style.css";
import { useDispatch, useSelector } from "react-redux";
import { selectVisitorId } from "../../Store/actions/visitorId/visitorIdSelectors";
import { postTermConditionApi } from "../../apis/apisServies";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import Layout from "../../Layout/Layout";
import { clearVisitorId } from "../../Store/actions/visitorId/visitorIdReducer";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";
import parse from "html-react-parser";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { selectToken } from "../../Store/actions/auth/authSelectors";

const AgreeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [term, setTerm] = useState(false);
  const { showSuccess, showError } = useNotification();
  const visitorId = useSelector(selectVisitorId);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isBackdropOpen, setBackdropOpen] = useState(false);
  const languageId = useSelector(languageid);
  const loginData = useSelector(selectToken);
  const openModal = () => {
    setModalOpen(true);
    setBackdropOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setBackdropOpen(false);
  };
  const handleSubmit = async () => {
    try {
      const dataObj = {
        accepted: term,
        visitorid: visitorId,
        time: "",
        text_message: {
          language_id: languageId,
          organization_id: loginData?.organization_id,
        }      };
      if (!term) {
        // Checkbox is not checked, show an error message and return early
        showError(findDisplayName("termsNotacceptMessage", "User declined to accept the terms"));
      }
      if (navigator.onLine && term) {
        const response = await postTermConditionApi(dataObj);
        if (response.data.statusCode === 200) {
          showSuccess(response?.data?.body?.message);
          dispatch(clearVisitorId());
          navigate("/CheckInPage");
        } else {
          showError(response?.data?.body?.message);
        }
      } else {
        if (term) {
          dataObj.time = new Date().toISOString().toString();
          var visitor_list = JSON.parse(sessionStorage.getItem("visitor"));
          if (visitor_list) {
            visitor_list[visitorId]["terms"] = dataObj;
            sessionStorage.setItem("visitor", JSON.stringify(visitor_list));
            dispatch(clearVisitorId());
            showSuccess(findDisplayName("AgreeScreenAcceptanceSuccessMessage","The user has successfully accepted the conditions."));
            navigate("/CheckInPage");
          }
        }
      }
    } catch (error) {
      showError(error.response.data.body.message);
    }
  };

  //If visitor id not present navigate to Check in page
  useEffect(() => {
    if (visitorId === null) {
      navigate("/CheckInPage");
    }
  }, [null]);

  const agreementScreenCompanyTerms = findDisplayName(
    "agreementScreenCompanyTerms",
    "We are compliant with the General Data Protection Regulation (GDPR). To learn more about how we </br>collect, keep, and process your private information in compliance with GDPR.</br> Please visit our &nbsp;"
  );

  return (
    <Layout>
      <div className="card text-white p-3 col-xxl-6 col-xl-6 col-md-8 col-sm-10 col-xs-10">
        <div className="col-xl-12 col-lg-12 col-md-10 col-sm-10 col-xs-10 text-center">
          <p className="ageryContentTextOne">
            {findDisplayName(
              "agreementScreenInformation",
              "You have been given a visitor card and badge. By checking this box, you confirm to follow the instructions while on the ship."
            )}
          </p>
        </div>

        <div className=" d-flex justify-content-center align-items-center gap-2 col-xl-12 col-lg-12 col-md-12">
          <input
            type="checkbox"
            checked={term}
            onChange={() => setTerm(!term)}
          />
          <span className="aceptContent border border-[#fff] rounded p-1">
            {findDisplayName(
              "agreementScreenCheckBox",
              "I accept and agree to the terms & conditions"
            )}
          </span>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 mt-4">
          <p className="endcontent-text">
            {parse(agreementScreenCompanyTerms)}
            <a
              className="text-white"
              onClick={(e) => {
                openModal();
              }}
            >
              {findDisplayName(
                "agreementScreenPolicyAndTermsUse",
                "Policy and Terms of Use"
              )}
            </a>
          </p>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 mt-4">
          <div className="d-flex  align-items-center justify-content-center ">
            <button
              className="btn btn-primary  btn-block fw-bold"
              onClick={handleSubmit}
            >
              {findDisplayName("agreementScreenConfirmButton", "Confirm")}
              <svg
                className="ms-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16675 10H15.0001M10.8334 5L15.2442 9.41074C15.5696 9.73618 15.5696 10.2638 15.2442 10.5893L10.8334 15"
                  stroke="black"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isBackdropOpen && (
        <div className="modal-backdrop fade show custom-modal-backdrop" />
      )}
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        role="dialog"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {findDisplayName(
                  "agreementScreenPolicyAndTermsUseHeading",
                  "Policy and Terms of Use"
                )}
              </h5>
            </div>
            <div className="modal-body terms-scrol">
              <h2 className="fs-5">
                {parse(findDisplayName("agreementScreenTermsAndConditions", "Terms and conditions"))}
              </h2>

              <hr />
              <div className=" d-flex justify-content-end">
                <button
                  type="button"
                  className=" btn  bg-secondary text-white col-2"
                  onClick={closeModal}
                >
                  {findDisplayName("agreementScreenCloseButton", "Close  ")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AgreeScreen;
