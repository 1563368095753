import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { UpdateRole } from "../../../apis/apisServies";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import {
  findDisplayName,
  isView,
} from "../../../../utils/commonFunctionsAndStatices";

const RoleUpdate = ({
  setActiveComponent,
  rolevalue,
  orgid,
  roleslist,
  rolelistdata,
}) => {
  const { handleSubmit, register } = useForm();
  const loginData = useSelector(selectToken);
  const initialRole = rolevalue?.org_rolename.trim();
  const orgData = useSelector(selectOrgTable);
  const { showSuccess, showError } = useNotification();
  const languageId = useSelector(languageid);
  const initialOrganization = orgData && orgData?.find(
    (item) => item.organizationid === orgid
  )?.name;
  const initialParentRole = rolevalue?.parent_rolename;
  const [loading, setLoading] = useState(false);

  const handleConfirmClick = async (data) => {
    if (!data.role.trim()) {
      showError("Kindly Enter a Role");
      return;
    }
    const changes = {};
    if (data.role.trim() !== initialRole) {
      changes.rolename = data.role.trim();
    }
    const dataObject = {
      userid: loginData?.login_id,
      roleid: rolevalue?.org_roleid,
      ...changes,
      text_message: {
        language_id: languageId,
      },
    };
    setLoading(true);
    const response = await UpdateRole(dataObject);
    if (response.data.statusCode === 200) {
      showSuccess(response?.data?.body?.message);
      roleslist();
      setActiveComponent("table");
      setLoading(false);
    } else if (response?.data?.statusCode === 400) {
      showError(response?.data?.body?.message);
      setLoading(false);
    } else if (response?.data?.statusCode === 404) {
      showError(response?.data?.body?.message);
      setLoading(false);
    } else if (response?.data?.statusCode === 409) {
      showError(response?.data?.body?.message);
      setLoading(false);
    }
  };

  return (
    <div className="container  text-white from-scrol">
      <div className="row d-flex flex-column align-items-center mt-5 ">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-8 "></div>
        {initialOrganization && (
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8">
            {isView("isListOrganization") && (
              <div>
                <label htmlFor="organization">
                  {findDisplayName(
                    "updateRoleOrganizationHeading ",
                    " Organization "
                  )}
                </label>
                <input
                  type="text"
                  className="form-control  mt-2 "
                  id="Organization"
                  placeholder={findDisplayName(
                    "updateRoleOrganizationInputBox ",
                    " Organization "
                  )}
                  defaultValue={initialOrganization}
                  disabled
                />
              </div>
            )}
          </div>
        )}
        {initialOrganization && (
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
            <label htmlFor="parentrole">
              {findDisplayName("updateRoleParentRoleHeading ", "Parent Role")}
            </label>
            <input
              type="text"
              className="form-control  mt-2 "
              value={rolevalue?.parent_rolename}
              disabled
            />
            {/* <select
            className="form-control form-select mt-2"
            id="parentrole"
            placeholder={findDisplayName(
              "updateRoleParentRoleInputBox ",
              "Parent Role"
            )}
            {...register("parentrole", { defaultValue: initialParentRole })}
          >
            {rolelistdata?.map((data, index) => (
              <option key={index} value={data?.org_roleid}>
                {data?.org_rolename}
              </option>
            ))}
          </select> */}
          </div>
        )}
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <label>{findDisplayName("updateRoleRoleHeading", "Role")}</label>
          <input
            type="text"
            className="form-control  mt-2 "
            placeholder={findDisplayName("updateRoleRoleInputBox", "Role")}
            onFocus={(event) => {
              event.target.setAttribute("autoComplete", "off");
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
            }}
            defaultValue={initialRole}
            {...register("role", {})}
          />
        </div>
        <div className="col-lg-6 gap-2 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit((data) => handleConfirmClick(data))}
            disabled={loading}
          >
            {findDisplayName("updateRoleUpdateButtonHeading ", "Update")}
          </button>
          <button
            className="btn bg-secondary text-light"
            onClick={() => {
              setActiveComponent("table");
            }}
          >
            {findDisplayName("updateRoleUpdateCancelButtonHeading ", "Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoleUpdate;
