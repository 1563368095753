// visitorTypeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locationArr: [], // Initialize the location type to []
};

const locationSlice = createSlice({
  name: "locationDataArr",
  initialState,
  reducers: {
    setlocationArray: (state, action) => {
      state.locationArr = action.payload;
    },
    clearLocationArray: (state) => {
      state.locationArr = [];
    },
  },
});

export const { setlocationArray, clearLocationArray } = locationSlice.actions;

export default locationSlice.reducer;
