import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { LanguageCreateAPI } from "../../../apis/apisServies";
import labeldata from "./data.json";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import { findDisplayName } from "../../../../utils/commonFunctionsAndStatices";

const MasterLanguageCreate = ({ setActiveComponent, fetchLanguage }) => {
  const loginData = useSelector(selectToken);
  const { showSuccess, showError } = useNotification();
  const languageId = useSelector(languageid);
  const [disabled, setDisabled] = useState(false);
  const { handleSubmit, register } = useForm();
  const handleConfirmClick = async (data) => {
    if (data.language === "" || !data.language.trim()) {
      showError(
        findDisplayName(
          "languageCreateScreenEmptyValidation",
          "Language field is not filled up"
        )
      );
      return;
    }

    const dataObj = {
      hostuserid: loginData?.login_id,
      languagename: data.language?.trim(),
      is_default: data.is_default,
      text_message: {
        language_id: languageId,
      },
    };

    setDisabled(true);
    const response = await LanguageCreateAPI(dataObj);
    if (response.data.statusCode === 201) {
      showSuccess(response?.data?.body?.message);
      fetchLanguage();
      setActiveComponent("table");
      setDisabled(false);
    } else {
      setDisabled(false);
      showError(response?.data?.body?.message);
    }
  };

  const handleCancel = () => {
    fetchLanguage();
    setActiveComponent("table");
  };

  return (
    <div className="container  text-white from-scrol">
      <form
        onSubmit={handleSubmit(handleConfirmClick)}
        className="row d-flex flex-column align-items-center mt-5 "
      >
        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <label htmlFor="language">
            {findDisplayName("languageCreateScreenLanguageHeading", "Language")}
          </label>
          <input
            type="text"
            className="form-control mt-2"
            id="language"
            placeholder={findDisplayName(
              "languageCreateScreenLanguageInputBox",
              "Language"
            )}
            onFocus={(event) => {
              event.target.setAttribute("autoComplete", "off");
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
            }}
            {...register("language", {})}
          />
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
          <input
            type="checkbox"
            id="is_default"
            className="form-check-input me-2"
            {...register("is_default")}
          />
          <label htmlFor="is_default">
            {findDisplayName(
              "languageCreateScreenDefaultHeading",
              "Default"
            )}
          </label>
        </div>

        <div className="col-lg-6 gap-2 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex justify-content-center">
          <button type="submit" className="btn btn-primary" disabled={disabled}>
            {findDisplayName("languageCreateScreenCreateButton", "Create")}
          </button>
          <button
            type="button"
            className="btn bg-secondary text-light"
            onClick={handleCancel}
          >
            {findDisplayName("languageCreateScreenCancelButton", "Cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MasterLanguageCreate;
