import React, { useEffect, useState } from 'react';
import {
  findDisplayName,
  isUpdate,
} from '../../../../utils/commonFunctionsAndStatices';
import DataNotFound from '../../DataNotFound/DataNotFound';
import { selectToken } from '../../../Store/actions/auth/authSelectors';
import { useSelector } from 'react-redux';
import { languageid } from '../../../Store/actions/languageId/selectLanguageId';
import { OrganizationFeaturesEdit } from '../../../apis/apisServies';
import { useNotification } from '../../../NotificationProvider/NotificationProvider';

const FeaturesTable = ({ featuresList, loading, fetchOrganizationFeatures, orgid }) => {
  const loginData = useSelector(selectToken);
  const languageId = useSelector(languageid);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const { showSuccess, showError } = useNotification  ();
  const [checkedItems, setCheckedItems] = useState({});
  const handleCheckboxChange = (module, permission, isChecked) => {
    setCheckedItems((prevState) => {
      const updatedItems = { ...prevState };
      if (!updatedItems[module]) {
        updatedItems[module] = {};
      }
      updatedItems[module][permission] = isChecked;
      return { ...updatedItems };
    });
    // Update placeholder text for the specific module
    setSelectedPermissions((prevState) => ({
      ...prevState,
      [module]: Object.keys(checkedItems[module]).filter(
        (key) => checkedItems[module][key]
      ),
    }));
  };

  const checkitems = async (Id, moduleName) => {
    const previousData = featuresList.find(item => item.organizationmodule === moduleName).permissions;
    let data = checkedItems[moduleName];
   const isEqual =  compareObjects(data, previousData);
   if (isEqual) {
   showError("No changes detected");
  }
  if(!isEqual){
    const dataObject = {
      userid: loginData?.login_id,
      organization_featureid: Id.organizationfeatureid,
      organizationid: orgid,
      ...data,
      text_message: {
        language_id: languageId,
      },
    }
    if (Id.organizationprivilegeid) {
      dataObject.organization_privilegeid = Id.organizationprivilegeid;
    }

    const response = await OrganizationFeaturesEdit(dataObject);
    if (response.status === 200 || response.status === 201) {
      showSuccess(response?.data?.body?.message);
      fetchOrganizationFeatures(orgid);
    } else {
      showError(response.data.message);
    }
  }
  };

  function compareObjects(obj1, obj2) {
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if all keys and their values are the same
    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    // If all checks pass, the objects are equal
    return true;
}

  // Set initial checkedItems state when the component mounts
  useEffect(() => {
    const initialCheckedItems = {};
    featuresList?.forEach((item) => {
      initialCheckedItems[item.organizationmodule] = { ...item.permissions };
    });
    setCheckedItems(initialCheckedItems);

    const initialSelectedPermissions = {};
    featuresList?.forEach((item) => {
      initialSelectedPermissions[item.organizationmodule] = Object.keys(
        item.permissions
      ).filter((key) => item.permissions[key]);
    });
    setSelectedPermissions(initialSelectedPermissions);
  }, [featuresList]);

  return (
    <div className='container ms-2'>
      <div className='row pe-2'>
        <div className='p-2'>
          <div className='table-scrol'>
            {loading ? (
              <div className='d-flex align-items-center justify-content-center mt-5 pt-5'>
                <div className='d-flex text-white h-100'>
                  <div className='spinner-container'>
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    <span className='ps-2'>
                      {findDisplayName(
                        'labelTabelScreenLoadingHeading',
                        'Loading...'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : featuresList.length === 0 ? (
              <DataNotFound />
            ) : (
              <>
                <table className='table table-striped '>
                  <thead className=' bg-transparent'>
                    <tr>
                      <th scope='col' className='bg-transparent th-color'>
                        {findDisplayName(
                          'organizationFeaturesTableModuleHeading',
                          'Module '
                        )}
                      </th>
                      <th scope='col' className='bg-transparent th-color'>
                        {findDisplayName('organizationFeaturesTableTypeHeading', 'Type ')}
                      </th>
                      <th scope='col' className='bg-transparent th-color'>
                        {findDisplayName(
                          'organizationFeaturesTablePermissionsHeading',
                          'Permissions'
                        )}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {featuresList?.map((item, index) => (
                      <tr key={index}>
                        <td className='bg-transparent text-white col-3'>
                          {item.organizationmodule}
                        </td>
                        <td className='bg-transparent text-white col-2'>
                          {item.type}
                        </td>
                        <td className='bg-transparent text-white col-4'>
                          <div className='dropdown '>
                            <input
                              className='form-control form-select mt-1   w-100'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                              placeholder={
                                selectedPermissions[item.organizationmodule] &&
                                  selectedPermissions[item.organizationmodule].length > 0
                                  ? selectedPermissions[item.organizationmodule]
                                    .map(
                                      (permission) =>
                                        permission
                                          .replace(/^is_/, '')
                                          .charAt(0) + permission.slice(4)
                                    )
                                    .join(', ')
                                  : 'Select'
                              }
                            />

                            <div className='dropdown-menu col-1 dropdown-menu-start  w-100 '>
                              <div
                                className='p-2 '
                                style={{
                                  maxHeight: '25vh',
                                  overflowY: 'auto',
                                  zIndex: '1000',
                                }}
                              >
                                {Object.keys(item.permissions).map(
                                  (permission, i) => (
                                    <div className='form-check' key={i}>
                                      <input
                                        className='form-check-input border border-secondary-subtle'
                                        type='checkbox'
                                        id={`checkbox-${index}-${i}`}
                                        checked={checkedItems[item.organizationmodule] && checkedItems[item.organizationmodule][permission]}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item.organizationmodule,
                                            permission,
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label
                                        className='form-check-label text-dark-emphasis'
                                        htmlFor={`checkbox-${index}-${i}`}
                                      >
                                        {permission
                                          .replace(/^is_/, '')
                                          .charAt(0)
                                          .toUpperCase() + permission.slice(4)}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                              <hr className='my-0 ' />
                              {
                                isUpdate("isDashboardOrganizationFeatures") && (
                              <div
                                className='btn-group d-flex '
                                role='group'
                                aria-label='Basic example'
                              >
                                <button
                                  type='button'
                                  className='btn bg-secondary border-btn w-50'
                                >
                                  {findDisplayName(
                                    'organizationFeaturesTablePermissionsCancelButton',
                                    'Cancel'
                                  )}
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-primary border-btn1 w-50'
                                  onClick={() => checkitems(item, item.organizationmodule)}
                                >
                                  {findDisplayName(
                                    'organizationFeaturesTablePermissionsOkButton',
                                    'Ok'
                                  )}
                                </button>
                              </div>
                                )
                              }
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesTable;
