
// according to utc time zone
export function getCurrentDateAndTime() {
  const now = new Date();
  const dayOfWeek = now.toLocaleDateString("en-US", { weekday: "long" });
  const month = now.toLocaleDateString("en-US", { month: "long" });
  const year = now.getUTCFullYear();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes();
  const date = now.getUTCDate();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const time = `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;

  return { dayOfWeek, month, year, time, date };
}
export function isNonEmptyString(value) {
  return typeof value === "string" && value?.trim() !== "";
}

export function findCountryCodeById(id) {
  var root = JSON.parse(sessionStorage.getItem('persist:root'))
  var countryList = JSON.parse(root.getCountryArray).getCountryArr
  const foundItem = countryList.find(item => item.country_id === id);
  return foundItem ? foundItem.countrycode : null;
}

export function findDisplayName(id, label) {
  var root = JSON.parse(sessionStorage.getItem('persist:root'))
  if (root && root.masterLabel) {
    var masterList = JSON.parse(root.masterLabel).masterLabelData;

    // Check if masterList is an array before using the find method
    if (Array.isArray(masterList)) {
      const foundItem = masterList.find(item => item.LabelName === id);

      // Check if foundItem is defined before accessing its properties
      if (foundItem && foundItem.LabelName) {
        return foundItem.LabelText;
      }
    }
  }

  // Return the original label if masterList is not an array or if the item is not found
  return label;
}


export function getUrlVars(url) {
  var vars = {};
  var hashes = url.split("?")[1];
  if (hashes) {
    var hash = hashes.split("&");

    for (var i = 0; i < hash?.length; i++) {
      var params = hash[i].split("=");
      vars[params[0]] = params[1];
    }
  }
  return vars;
}

// Function to filter data based on the CheckIn date for all organizations
export function filterDataByCheckInForAll(data, timeRange) {
  const currentDate = new Date();
  const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const week = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
  const month = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const year = new Date(currentDate.getFullYear(), 0, 1);
  const lastYear = new Date(currentDate.getFullYear() - 1, 0, 1);
  const filteredEntries = [];

  for (const entries of Object.values(data)) {
    const entriesForTimeRange = entries.filter(entry => {
      const checkInDate = new Date(entry.CheckIn);

      if (timeRange === "Today") {
        return checkInDate >= today;
      } else if (timeRange === "This Week") {
        return checkInDate >= week;
      } else if (timeRange === "This Month") {
        return checkInDate >= month;
      } else if (timeRange === "This Year") {
        return checkInDate >= year;
      } else if (timeRange === "Last Year") {
        return checkInDate >= lastYear && checkInDate < year;
      }

      // Add more time range conditions as needed

      return false;
    });

    filteredEntries.push(...entriesForTimeRange);
  }

  return filteredEntries;
}

// Function to  filtered data by organization name
export function FilteredDataByOrganization(filteredData) {
  const separatedData = {};

  for (const entry of filteredData) {
    const organizationName = entry.organizationid;

    // If the organization name key doesn't exist in separatedData, create an array for it
    if (!separatedData[organizationName]) {
      separatedData[organizationName] = [];
    }

    // Add the entry to the corresponding organization name array
    separatedData[organizationName].push(entry);
  }

  return separatedData;
}

export function findPortNameById(id) {
  var root = JSON.parse(sessionStorage.getItem("persist:root"));
  var portList = JSON.parse(root.portData).data;
  const foundItem = portList && portList?.find((item) => item.port === id);
  return foundItem ? foundItem.port_name : null;
}

// common search function
export const handleCommonSearch = (search, data, field) => {
  const searchValue = search?.toLowerCase();
  const filterData = Array.isArray(data) && data?.filter((item) =>
    item[field] && item[field]?.toLowerCase().includes(searchValue)
  );
  return filterData || [];
};

export const findPermission = (moduleName) => {
  var datalist = JSON.parse(sessionStorage.getItem("persist:root"));
  var privilege = datalist && JSON.parse(datalist.privileges)?.data;
  const permission = privilege && privilege.find(item => item.module === moduleName);
  return permission;
}


export function isView(name) {
  var permission = findPermission(name);
  return permission?.permissions?.is_view;
}

export function isCreate(name) {
  var permission = findPermission(name);
  return permission?.permissions?.is_create;
}

export function isUpdate(name) {
  var permission = findPermission(name);
  return permission?.permissions?.is_update;
}

export function isDelete(name) {
  var permission = findPermission(name);
  return permission?.permissions?.is_delete;
}

export const findUserPermission = (privilege, moduleName) => {
  const permission = privilege && privilege?.find(item => item.module === moduleName);
  return permission;
}


export function isViewUser(privilege, name) {
  var permission = findUserPermission(privilege, name);
  return permission?.permissions?.is_view;
}


export const findFeatursPermission = (moduleName) => {
  var datalist = JSON.parse(sessionStorage.getItem("persist:root"));
  var privilege = datalist && JSON.parse(datalist?.organization)?.features;
  const permission = privilege && privilege.find(item => item.organizationmodule === moduleName);
  return permission;
}


export function isViewOrgFeatures(name) {
  var permission = findFeatursPermission(name);
  return permission?.permissions?.is_view;
}

export function isBatchRead(name) {
  var permission = findFeatursPermission(name);
  return permission?.permissions?.is_read;
}

export function formatNumberWithMask(number, mask) {
  let result = '';
  let numberIndex = 0;
  // Iterate over the mask pattern
  for (let i = 0; i < mask?.length; i++) {
    // If the current character in the mask is '#', replace it with the corresponding digit from the number
    if (mask[i] === '#') {
      if (numberIndex < number?.length) {
        result += number[numberIndex];
        numberIndex++;
      } else {
        // If the number ends before the mask, pad the result with remaining mask characters
        result += ' ';
      }
    } else {
      // If the current character in the mask is not '#', just append it to the result
      result += mask[i];
    }
  }

  return result;
}

export function findVisitorTypeById(id) {
  var root = JSON.parse(sessionStorage.getItem('persist:root'));
  var visitorTypeList = JSON.parse(root.visitorTypeTable).visitortable;
  if (visitorTypeList) {
    const foundItem = visitorTypeList?.find(
      (item) => item.visitortypeid === id
    );
    return foundItem ? capitalizeFirstLetter(foundItem.name) : null;
  } else if (!visitorTypeList) {
    var visitorType = JSON.parse(root.visitorType).visitorArr;
    const foundItem = visitorType?.find((item) => item.visitortypeid === id);
    return foundItem ? capitalizeFirstLetter(foundItem.name) : null;
  }
}


export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function findVesselNameById(id) {
  var root = JSON.parse(sessionStorage.getItem('persist:root'));
  var locationList = JSON.parse(root.locationDataArr).locationArr;
  if (locationList?.length > 0) {
    const foundItem = locationList?.find((item) => item.locationid === id);
    return  foundItem ? capitalizeFirstLetter(foundItem.locationname)  : null;
  } else {
    var locationList = JSON.parse(root.allLocationData).allLocationData;
    const foundItem = locationList.find((item) => item.locationid === id);
    return foundItem ? capitalizeFirstLetter(foundItem.locationname) : null;
  }
}

export const sortData = (data, field, sortDirection) => {
  return [...data].sort((a, b) => {
    // Extract nested fields
    const getNestedPropertyValue = (obj, path) => {
      return path.split('.').reduce((nestedObj, key) => {
        return nestedObj && nestedObj[key] !== undefined ? nestedObj[key] : null;
      }, obj);
    };

    const fieldValueA = getNestedPropertyValue(a, field);
    const fieldValueB = getNestedPropertyValue(b, field);

    // Check if field values are empty or null
    const isEmptyOrNull = (value) => {
      return value === null || value === undefined || value === '';
    };

    // Move empty or null values to the end
    if (isEmptyOrNull(fieldValueA) && !isEmptyOrNull(fieldValueB)) {
      return 1;
    } else if (!isEmptyOrNull(fieldValueA) && isEmptyOrNull(fieldValueB)) {
      return -1;
    }

    // Compare non-empty/non-null values
    if (sortDirection === 'asc') {
      if (fieldValueA < fieldValueB) return -1;
      if (fieldValueA > fieldValueB) return 1;
      return 0;
    } else {
      if (fieldValueA > fieldValueB) return -1;
      if (fieldValueA < fieldValueB) return 1;
      return 0;
    }
  });
};
