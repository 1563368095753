export const checkinClasses =
  "d-flex h-100 justify-content-center align-items-center";
export const sameClasses =
  "d-flex h-100 justify-content-center align-items-center";
export const commonClasses = "";
export const viseoClasses =
  "d-flex h-100 justify-content-center align-items-center p-2";
export const ageryClasses =
  "d-flex justify-content-center align-items-center";
export const formClasses = "d-flex justify-content-center align-items-center";
