import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { useSelector } from "react-redux";
import { utcToZonedTime } from "date-fns-tz";
import DataNotFound from "../../DataNotFound/DataNotFound";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import '../css/style.css'

const DashboardChart8 = ({ data, locationList, visitorTypeList, loading }) => {
  const sortLocationList = locationList && [...locationList].sort((a,b)=> a.locationname.localeCompare(b.locationname));
  const ltnID =
    isView("isListOrganization") ? null : sortLocationList[0]?.locationid;
  const orgData = useSelector(selectOrgTable);
  const [showSelectIcon, setShowSelectIcon] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    isView("isListOrganization") ? orgData[0]?.organizationid : ""
  );
  const [displayName, setDisplayName] = useState(
    isView("isListOrganization") ? orgData[0]?.name : ""
  );
  const [locationId, setLocationId] = useState('');
  const [time, setTime] = useState("This Year");
  const sortOrgData = orgData && [...orgData].sort((a,b)=> a.name.localeCompare(b.name));
  const handleSvgClick = () => {
    setShowSelectIcon((prev) => !prev);
  };

  const handleOptionClick = (organizationId, organizationName) => {
    setDisplayName(organizationName);
    setSelectedOption(organizationId);
    setShowSelectIcon(false);
    setLocationId("");
    setTime("This Year");
    handleChangeTime("This Year");
  };

  const handleChangeLocation = (id) => {
    setLocationId(id);
  };
  const handleChangeTime = (id) => {
    setTime(id);
  };

  const orgLocationData = data.filter(
    (item) => item.organizationid === selectedOption
  );

  const locationData =
    isView("isListOrganization") ? orgLocationData : data;

  const orgLocationList = locationList.filter(
    (item) => item.organizationid === selectedOption
  );

  const sortLocation =  [...orgLocationList].sort((a,b)=> a.locationname.localeCompare(b.locationname));

  if (orgLocationList.length > 0 && locationId === "") {
    setLocationId(sortLocation[0].locationid);
  }

  const unicLocationList = locationId
    ? locationData?.filter((item) => item.locationid === locationId)
    : locationData?.filter((item) => item.locationid === ltnID);

  const separateByVisitorType = (visitorData) => {
    const separatedData = {};

    visitorData.forEach((item) => {
      const visistorId = item.visitortypeid;
      if (!separatedData[visistorId]) {
        separatedData[visistorId] = [];
      }
      separatedData[visistorId].push(item);
    });
    return separatedData;
  };

  function findVisitorTypeById(id) {
    const foundItem = visitorTypeList?.find(
      (item) => item.visitortypeid === id
    );
    return foundItem ? foundItem.name : null;
  }

  const calculateTimeDifferenceInHours = (checkinTime, checkoutTime) => {
    const timeDifferenceInMilliseconds = checkoutTime - checkinTime;
    const timeDifferenceInHours =
      timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
    return timeDifferenceInHours;
  };

  const calculateAverageStayTime = (visitorData) => {
    const totalStayTime = visitorData.reduce((accumulator, item) => {
      try {
        const checkinTime = utcToZonedTime(item.CheckIn, "UTC");
        const checkoutTime = utcToZonedTime(item.CheckOut, "UTC");

        if (isNaN(checkinTime) || isNaN(checkoutTime)) {
          return accumulator; // Skip invalid dates
        }

        const timeDifferenceInHours = calculateTimeDifferenceInHours(
          checkinTime,
          checkoutTime
        );
        return accumulator + timeDifferenceInHours;
      } catch (error) {
        console.error("Error processing date:", error);
        return accumulator; // Skip on error
      }
    }, 0);
    const averageStayTime = totalStayTime / visitorData.length;
    return averageStayTime;
  };

  const filteredDataByTime = () => {
    const currentDate = new Date();
    const endDate = new Date(); // Assuming endDate is current date initially
    let startDate;

    switch (time) {
      case "This Week":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - currentDate.getDay()
        );
        break;
      case "This Month":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        break;
      case "This Year":
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        break;
      case "Today":
      default:
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        endDate.setDate(endDate.getDate() + 1); // Adding one day to include the selected day
        break;
    }

    return unicLocationList.filter((item) => {
      const checkinTime = utcToZonedTime(item.CheckIn, "UTC");
      return checkinTime >= startDate && checkinTime < endDate;
    });
  };

  const orgLocationDataFilteredByTime = filteredDataByTime();

  const dataSet = separateByVisitorType(orgLocationDataFilteredByTime);

  const chartData = Object.keys(dataSet).map((visitorType) => {
    const averageStayTime = calculateAverageStayTime(dataSet[visitorType]);

    return {
      name: findVisitorTypeById(visitorType) || visitorType,
      "Average Stay Time": averageStayTime,
    };
  }) .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="row mt-4">
      <div
        className={` bg-light rounded col-12 p-3 ${
          isView("isListOrganization") ? "pb-lg-5 pb-xxl-2" : ""
        }`}
      >
        <div className="row">
          <div
            className={`  ${
              isView("isListOrganization")
                ? "col-lg-8 col-md-8 col-xxl-8 col-7 "
                : " col-12 "
            }`}
          >
            <strong className="text-dark t-wrap d-xxl-flex align-items-xxl-center">
              {findDisplayName(
                "AvgLengthOfVisitByVisitorTypeHeading",
                "Avg.Length of Visit by Visitor Type"
              )}
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ms-2 d-none d-xxl-block d-lg-block d-xl-block "
              >
                <title>
                  {findDisplayName(
                    "AvgLengthOfVisitByVisitorTypeHeadingTooltip",
                    " To understand the average length of visits for each visitor type."
                  )}
                </title>
                <circle cx="12.3408" cy="12.8436" r="12" fill="#BDBDBD" />
                <rect
                  x="10.3408"
                  y="10.1769"
                  width="4"
                  height="10.6666"
                  rx="2"
                  fill="white"
                />
                <rect
                  x="10.3408"
                  y="4.8436"
                  width="4"
                  height="3.99999"
                  rx="2"
                  fill="white"
                />
              </svg>
            </strong>
          </div>
          {isView("isListOrganization") && (
            <div className="col-lg-4 col-md-4 col-xxl-4 col-5">
              <>
                <div className="d-flex justify-content-end">
                  <span className="bg-dark text-white p-2 rounded selected-text-size text-wrap">
                    {displayName}
                  </span>

                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-2"
                    onClick={handleSvgClick}
                  >
                    <path
                      d="M3.07031 16.5705H17.2589H3.07031Z"
                      fill="#BDBDBD"
                    />
                    <path
                      d="M3.07031 5.33984H17.2589M3.07031 10.9552H17.2589M3.07031 16.5705H17.2589"
                      stroke="#BDBDBD"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>

                {showSelectIcon && (
                  <div className=" row mt-1 position-relative ">
                    <div
                      className=" position-absolute col-12 selected-icon "
                      aria-label="Default select example"
                      style={{ zIndex: 4 }}
                    >
                      <div
                        className="list-group position-relative row"
                        id="list-tab"
                        role="tablist"
                      >
                        {sortOrgData.map((organization) => (
                          <a
                            key={organization.organizationid}
                            className={`list-group-item list-group-item-action col-10 ${
                              organization.organizationid === selectedOption
                                ? "active"
                                : ""
                            }`}
                            data-bs-toggle="list"
                            href={`#${organization.organizationid}`}
                            role="tab"
                            aria-controls={organization.organizationid}
                            onClick={() =>
                              handleOptionClick(
                                organization.organizationid,
                                organization.name
                              )
                            }
                          >
                            {organization.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
          <div
            className={`  ${
              isView("isListOrganization")
                ? "d-flex gap-2  justify-content-end mt-md-4 mt-lg-2 pt-lg-1 pt-xxl-0 mt-xxl-0"
                : " d-flex  justify-content-end col-12 gap-3"
            }`}
          >
            <div className=" mt-1 position-relative">
              <select
                className="form-select"
                value={time}
                onChange={(e) => handleChangeTime(e.target.value)}
              >
                <option value="This Year">
                  {findDisplayName(
                    "AvgLengthOfVisitByVisitorTypeThisYearSelect",
                    "This Year"
                  )}
                </option>
                <option value="This Month">
                  {findDisplayName(
                    "AvgLengthOfVisitByVisitorTypeThisMonthSelect",
                    "This Month"
                  )}
                </option>
                <option value="This Week">
                  {findDisplayName(
                    "AvgLengthOfVisitByVisitorTypeThisWeekSelect",
                    "This Week"
                  )}
                </option>
                <option value="Today">
                  {findDisplayName(
                    "AvgLengthOfVisitByVisitorTypeTodaySelect",
                    "Today"
                  )}
                </option>
              </select>
            </div>
            <div className=" mt-1 position-relative">
              <select
                className="form-select"
                value={locationId}
                onChange={(e) => handleChangeLocation(e.target.value)}
              >
                {isView("isListOrganization")
                  ? sortLocation.map((item) => (
                      <option key={item.locationid} value={item.locationid}>
                        {item.locationname}
                      </option>
                    ))
                  : sortLocationList.map((item) => (
                      <option key={item.locationid} value={item.locationid}>
                        {item.locationname}
                      </option>
                    ))}
              </select>
            </div>
          </div>
        </div>

        <hr
          className={`w-100 text-secondary${
            isView("isListOrganization")
              ? "mt-xxl-3 pb-md-1 pb-lg-0 pt-lg-0 pt-xxl-2"
              : "mt-xxl-4 pb-xxl-4 mt-md-3 pb-md-4 pb-lg-0"
          }`}
        />
        {loading ? (
          <div
            className="d-flex justify-content-center gap-2 text-primary align-items-center"
            style={{
              height: "400px",
            }}
          >
            <span
              className="spinner-grow spinner-grow-sm text-primary pe-1"
              role="status"
              aria-hidden="true"
            ></span>
            {findDisplayName(
              "VisitorsByOrganizationChartLoading",
              "Loading ..."
            )}
          </div>
        ) : chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart width="95%" height={400} data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-25} textAnchor="end" fontSize={10} />
              <YAxis tickFormatter={(value) => `${value} hours`} />
              <Tooltip
                formatter={(value) => `${value.toFixed(2)} hours`} // Adjust the formatting as needed
                contentStyle={{
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  // Add mobile-specific content styles here if needed
                }}
              />
              <Legend
                formatter={(value) => `${value} (hours)`} // Adjust the formatting as needed
              />
              <Line
                type="monotone"
                dataKey="Average Stay Time"
                stroke="#244955"
                strokeWidth={4}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div
            className={` ${
              showSelectIcon
                ? "d-flex justify-content-center align-items-center"
                : ""
            }`}
            style={{
              height: "390px",
             
            }}
          >
            {chartData.length === 0 && !showSelectIcon ? (
              <div className="text-center ">
                <DataNotFound />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardChart8;
