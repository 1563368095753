import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLocationData: [],
};

const allLocationSlice = createSlice({
  name: "allLocationData",
  initialState,
  reducers: {
    setAllLocation: (state, action) => {
      state.allLocationData = action.payload;
    },
    clearAllLocation: (state) => {
      state.allLocationData = [];
    },
  },
});

export const { setAllLocation, clearAllLocation } = allLocationSlice.actions;
export default allLocationSlice.reducer;
