import React from 'react'
import { useSelector } from 'react-redux';
import { selectlanguageArr } from '../../../Store/actions/language/LanguageSelector';

const LanguagesList = ({initialvalue, handleChange}) => {

    const languages = useSelector(selectlanguageArr);
    const sortLanguages = [...languages].sort((a,b)=>a.language_name.localeCompare(b.language_name));

    return (
        <>
            <div className="dropdown">
                <button
                    className="bg-white w-100 form-select text-start text-truncate"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {sortLanguages.find(language => language.language_id === initialvalue)?.language_name || "Select Language"}
                </button>
                <ul className="dropdown-menu w-100 pb-2">
                    {sortLanguages.map((language, index) => (
                        <li key={index}>
                            <a
                                className="dropdown-item text-truncate"
                                onClick={() => handleChange(language.language_id)}
                            >
                                {language.language_name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default LanguagesList
