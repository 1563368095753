import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  filterData: []
};

const portDataReducer = createSlice({
  name: "portData",
  initialState,
  reducers: {
    setPortData: (state, action) => {
      state.data = action.payload;
    },
    setFilterPortData: (state, action) => {
      state.filterData = action.payload;
    },
    clearPortData: (state) => {
      state.data = [];
    },
  },
});

export const { setPortData, clearPortData, setFilterPortData } = portDataReducer.actions;
export default portDataReducer.reducer;
