import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languageData: [],
};

const languageDataSlice = createSlice({
  name: "languageLabelData",
  initialState,
  reducers: {
    setLanguageData: (state, action) => {
      state.languageData = action.payload;
    },
    clearLanguageData: (state) => {
      state.languageData = [];
    },
  },
});

export const { setLanguageData, clearLanguageData } = languageDataSlice.actions;
export default languageDataSlice.reducer;
