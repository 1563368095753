import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { LocationEditAPI } from "../../../apis/apisServies";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";

const LocationUpdate = ({ location, setActiveComponent, fetchLocation, activeButton }) => {
  const form = useForm();
  const [uploadedImageBlob, setUploadedImageBlob] = useState(null);
  const fileInputRef = useRef(null);
  const { register, handleSubmit } = form;
  const loginData = useSelector(selectToken);
  const { showSuccess, showError } = useNotification();
  const initialupload_photo = location.background;
  const initialLocationName = location.locationname;
  const initialimonumber = location.imonumber;
  // const initialvoyagenumber = location.voyagenumber;
  const initialflagstate = location.flagstate;
  const orgData = useSelector(selectOrgTable);
  const [loading, isLoading] = useState(false);
  const initialOrganization = orgData?.find(
    (item) => item.organizationid === location.organizationid
  )?.name;

  const onSubmit = async (formData) => {
    try {
    const { location_name, imonumber, voyagenum, flagstate } = formData;

    if (!location_name.trim()) {
      showError(findDisplayName("locationScreenLocationNameValidation","Kindly provide the name of the location."));
      return;
    }

    const datobj = new FormData();

    const addFormDataIfChanged = (key, value, initialValue, condition = true) => {
      if (condition && value !== initialValue) {
        datobj.append(key, value);
      }
    };

    datobj.append("userid", loginData?.login_id);
    datobj.append("locationid", location?.locationid);
    
    addFormDataIfChanged("imonumber", imonumber, initialimonumber);
    addFormDataIfChanged("locationname", location_name?.trim(), initialLocationName);
    // addFormDataIfChanged("voyagenumber", voyagenum, initialvoyagenumber);
    addFormDataIfChanged("flagstate", flagstate?.trim(), initialflagstate);

    const isCSO = isView("isListOrganization");
    const organizationId = isCSO ? location?.organizationid : loginData?.organization_id;
    datobj.append("organizationid", organizationId);

    if (uploadedImageBlob !== null) {
      datobj.append("background", uploadedImageBlob, "image.jpg");
      datobj.append("locationname", location_name?.trim());
    }

    if (
      Array.from(datobj.keys()).some(
        (key) => !["userid", "locationid", "organizationid"].includes(key)
      )
    ) {
      const response = await LocationEditAPI(datobj);

      if (response.ok) {
        showSuccess( findDisplayName("locationUpdateScreenUpdatedSuccessMessage", "Location was successfully updated."));
        fetchLocation(location?.organizationid, activeButton);
        setActiveComponent("table");
      } else if (response.status === 409) {
        showError(findDisplayName("locationUpdateScreenExistErrorMessage", "Location was existing."));
      }
    } else {
      showError('No changes were made');
    }
  } catch (e) {
    console.error(e);
  }
  finally {
    setTimeout(() => {
      isLoading(false);
    }, 3000);
  }
  };

  function imageToBlob(image) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const blob = new Blob([new Uint8Array(e.target.result)], {
          type: image.type,
        });
        resolve(blob);
      };

      reader.readAsArrayBuffer(image);
    });
  }

  const handleImageChange = async (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    if (selectedImage) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(selectedImage.type)) {
        e.target.value = null;
        showError(
          findDisplayName(
            "locationUpdateImageUploadErrorMessage",
            "The only kinds of files accepted are JPG, JPEG, and PNG."
          )
        );
        return;
      }

        // Check file size
        if (selectedImage.size > maxSize) {
          e.target.value = null;
          showError(findDisplayName("locationUpdateImageExceedSizeErrorMessage", "File size exceeds 2 MB limit. Please choose a smaller file."));
          return;
        }
    
      const blob = await imageToBlob(selectedImage);
      setUploadedImageBlob(blob);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  return (
    <form className="container">
      <div className="col-md-12 col-sm-12 mx-auto p-5 text-white gy-4 from-scrol">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 pt-5 d-flex flex-column">
            <input
              type="file"
              className="d-none"
              id="upload_photo"
              onChange={handleImageChange}
              ref={fileInputRef}
              accept="image/jpeg', 'image/jpg', 'image/png'"
            />
            <img
              src={
                uploadedImageBlob
                  ? URL.createObjectURL(uploadedImageBlob)
                  : initialupload_photo
              }
              alt="Location"
              className="mt-3 img-height"
            />
            <span
              className=" btn bg-primary  btn-sm rounded-pill mt-2 text-white"
              onClick={handleUploadClick}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.50092 4.68158V20.6816M14.1257 12.3065H14.8757M14.1257 13.0565H14.8757M5.70092 20.6816H19.3009C20.421 20.6816 20.9811 20.6816 21.4089 20.4636C21.7852 20.2718 22.0912 19.9659 22.2829 19.5896C22.5009 19.1617 22.5009 18.6017 22.5009 17.4816V7.88158C22.5009 6.76147 22.5009 6.20142 22.2829 5.7736C22.0912 5.39727 21.7852 5.09131 21.4089 4.89957C20.9811 4.68158 20.421 4.68158 19.3009 4.68158H5.70092C4.58081 4.68158 4.02076 4.68158 3.59293 4.89957C3.21661 5.09131 2.91065 5.39727 2.7189 5.7736C2.50092 6.20142 2.50092 6.76147 2.50092 7.88158V17.4816C2.50092 18.6017 2.50092 19.1617 2.7189 19.5896C2.91065 19.9659 3.21661 20.2718 3.59293 20.4636C4.02076 20.6816 4.58081 20.6816 5.70092 20.6816ZM19.5009 12.6816C19.5009 15.443 17.2623 17.6816 14.5009 17.6816C11.7395 17.6816 9.50092 15.443 9.50092 12.6816C9.50092 9.92016 11.7395 7.68158 14.5009 7.68158C17.2623 7.68158 19.5009 9.92016 19.5009 12.6816ZM15.5009 12.6816C15.5009 13.2339 15.0532 13.6816 14.5009 13.6816C13.9486 13.6816 13.5009 13.2339 13.5009 12.6816C13.5009 12.1293 13.9486 11.6816 14.5009 11.6816C15.0532 11.6816 15.5009 12.1293 15.5009 12.6816Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              {findDisplayName(
                "updateLocationUploadPhotoButton",
                "Upload Photo"
              )}
            </span>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-column mt-lg-5 mt-md-5 gy-5 ">
            {isView("isListOrganization") && (
              <div>
                <label htmlFor="LabelName">
                  {findDisplayName(
                    "updateLocationOrganizationHeading",
                    "Organization"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control  mt-2 mb-3 "
                  id="Organization"
                  {...register("Organization", {})}
                  defaultValue={initialOrganization}
                  disabled
                />
              </div>
            )}
            <div
              className={
                isView("isListOrganization") ? "" : "d-flex flex-column gap-2"
              }
            >
              <label htmlFor="location_name">
                {findDisplayName(
                  "updateLocationLocationNameHeading",
                  "Location Name"
                )}
              </label>
              <input
                type="text"
                className="rounded border-0 form-control"
                id="location_name"
                placeholder={findDisplayName(
                  "updateLocationLocationNameHeading",
                  "Location Name"
                )}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                }}
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                {...register("location_name", {})}
                defaultValue={initialLocationName}
              />
            </div>
            {isView("isListOrganization") === false && (
            <div className="d-flex flex-column gap-2 mt-md-0 mt-3">
            <label className="mt-md-3 mt-sm-3" htmlFor="flagstate">
              {findDisplayName(
                "updateLocationFlagStateHeading",
                "Flag State"
              )}
            </label>
            <input
              type="text"
              className="rounded border-0  form-control"
              id="flagstate"
              placeholder={findDisplayName(
                "updateLocationFlagStateHeading",
                "Flag State"
              )}
              onFocus={(event) => {
                event.target.setAttribute("autoComplete", "off");
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
              }}
              {...register("flagstate", {
              })}
              defaultValue={initialflagstate}
            />
          </div>
            )}
            {/* <div className="d-flex flex-column gap-2 ">
              <label className="mt-md-3 mt-sm-3" htmlFor="voyagenum">
                {findDisplayName(
                  "updateLocationVoyageNumberHeading",
                  "Voyage Number"
                )}
              </label>
              <input
                type="text"
                className="rounded border-0  form-control"
                id="voyagenum"
                placeholder={findDisplayName(
                  "updateLocationVoyageNumberHeading",
                  "Voyage Number"
                )}
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                {...register("voyagenum", {
                })}
                defaultValue={initialvoyagenumber}
              />
            </div> */}
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-column mt-lg-5 mt-md-3 mt-3">
            <div className="d-flex flex-column gap-2">
              <label htmlFor="imonumber">
                {findDisplayName(
                  "updateLocationIMONumberHeading",
                  "IMO Number"
                )}
              </label>
              <input
                type="text"
                className="rounded border-0  form-control"
                id="imonumber"
                placeholder={findDisplayName(
                  "updateLocationIMONumberHeading",
                  "IMO Number"
                )}
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                {...register("imonumber", {
                })}
                defaultValue={initialimonumber}
              />
            </div>
            {isView("isListOrganization")  && (

            <div className="d-flex flex-column gap-2 ">
              <label className="mt-md-3 mt-sm-3" htmlFor="flagstate">
                {" "}
                {findDisplayName(
                  "updateLocationFlagStateHeading",
                  "Flag State"
                )}
              </label>
              <input
                type="text"
                className="rounded border-0  form-control"
                id="flagstate"
                placeholder={findDisplayName(
                  "updateLocationFlagStateHeading",
                  "Flag State"
                )}
                onFocus={(event) => {
                  event.target.setAttribute("autoComplete", "off");
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                }}
                {...register("flagstate", {
                })}
                defaultValue={initialflagstate}
              />
            </div>
            )}
          </div>

          <div className="col-lg-12 col-md-10 col-sm-12 d-flex gap-2 mt-4  justify-content-center align-items-center">
            <button
              className="btn btn-primary"
              disabled={loading}
              onClick={() => {
                handleSubmit(onSubmit)();
                isLoading(true);
              }}
            >
              {loading ? (
                "Creating..."
              ) : (
                <> {findDisplayName("updateLocationUpdateButton", "Update")} </>
              )}
            </button>
            <button
              className=" btn bg-secondary text-light "
              onClick={() => {
                setActiveComponent("table");
              }}
            >
              {findDisplayName("updateLocationCancelButton", "Cancel")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LocationUpdate;
