import { useLocation } from "react-router-dom";
import {
  ageryClasses,
  checkinClasses,
  commonClasses,
  formClasses,
  sameClasses,
  viseoClasses,
} from "./classesLayout";

const Layout = ({ children }) => {
  let location = useLocation();
  return (
    <>
      <div
        className={
          location?.pathname === "/ProfileScreen"
            ? commonClasses
            : location?.pathname === "/AgreeScreen"
            ? "container"
            : location?.pathname === "/Formscreen"
            ? "container h"
            :location?.pathname === "/CheckInPage"
            ? "container"
            : " container h-100"
        }
      >
        <div
          className={
            location?.pathname === "/CheckInPage"
              ? checkinClasses
              : location?.pathname === "/"
              ? sameClasses
              : location?.pathname === "/ProfileScreen"
              ? commonClasses
              : location?.pathname === "/Capture"
              ? sameClasses
              : location?.pathname === "/VideoScreen"
              ? viseoClasses
              : location?.pathname === "/AgreeScreen"
              ? ageryClasses
              : location?.pathname === "/Formscreen"
              ? formClasses
              : ""
          }
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
