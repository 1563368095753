import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgEdit: null,
};

const orgUpdateReducer = createSlice({
  name: "orgEditData",
  initialState,
  reducers: {
    setOrgEdit: (state, action) => {
      state.orgEdit = action.payload;
    },
    clearOrgEdit: (state) => {
      state.orgEdit = null;
    },
  },
});

export const { setOrgEdit, clearOrgEdit } = orgUpdateReducer.actions;
export default orgUpdateReducer.reducer;
