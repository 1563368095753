import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languageId: [],
};

const languageSlice = createSlice({
  name: "languageId",
  initialState: initialState,
  reducers: {
    setLanguageId: (state, action) => {
      state.languageId = action.payload;
    },
    clearLanguageId:(state) => {
      state.languageId = [];
    }
  },
});

export const { setLanguageId , clearLanguageId} = languageSlice.actions;
export default languageSlice.reducer;
