import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  findDisplayName,
  getUrlVars,
} from "../../../../utils/commonFunctionsAndStatices";
import formLogo from "../../../../Asset/Img/Form-logo.svg";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import {
  LanguageAPI,
  MasterLabelAPI,
  UserUpdateAPI,
} from "../../../apis/apisServies";
import { useNavigate } from "react-router-dom";
import { setMasterLabel } from "../../../Store/actions/masterLabel/masterLabelReducer";
import { useDispatch, useSelector } from "react-redux";
import { setLanguageId } from "../../../Store/actions/languageId/languageIdReducer";
import { setLanguageArray } from "../../../Store/actions/language/LanguageReducer";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import 'bootstrap-icons/font/bootstrap-icons.css';

const UpdatePassword = () => {
  const navigator = useNavigate();
  const { showSuccess, showError } = useNotification();
  const [dataFetched, setDataFetched] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const dispatch = useDispatch();
  const languageId = useSelector(languageid);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // get the current url and  params
  var url = window.location.href;
  var urlParams = getUrlVars(url);
  var userId = urlParams["userId"];
  var sessionId = urlParams["sessionId"];

  const onSubmit = async (data) => {
    if (!data.password) {
      showError(
        findDisplayName(
          "updatePasswordEnterValidation",
          "Kindly Enter a Password"
        )
      );
      return;
    }
    const passwordPattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,15}$/;

    if (data.password && !passwordPattern.test(data.password)) {
      showError(
        findDisplayName(
          "updatePasswordCharactersValidation",
          "A minimum of one uppercase, one lowercase, one special character, and one number must be included in a password that is between 8 - 15 characters long. -password validation"
        )
      );
      return;
    } else if (data.password && !data.confirmPassword) {
      showError(
        findDisplayName(
          "updatePasswordConfirmValidation",
          "Kindly Enter a confirm password"
        )
      );
      return;
    } else if (data.password && data.password !== data.confirmPassword) {
      showError(
        findDisplayName(
          "updatePasswordConfirmValidation",
          "Password and Confirm Password do not match."
        )
      );
      return;
    } else if (
      data.password &&
      data.confirmPassword &&
      !passwordPattern.test(data.confirmPassword)
    ) {
      showError(
        findDisplayName(
          "updatePasswordConfirmEnterPasswordValidation",
          "Kindly Enter a Valid Confirm Password"
        )
      );
      return;
    }

    const dataObj = {};

    if (sessionId) {
      dataObj.session = {
        user_id: userId,
        session_password: sessionId,
        password: data.password,
        text_message: {
          language_id: languageId,
        },
      };
    } else if (!sessionId) {
      dataObj.forget_password = {
        user_id: userId,
        password: data.password,
        text_message: {
          language_id: languageId,
        },
      };
    }
    try {
      const response = await UserUpdateAPI(dataObj);
      if (response?.data?.statusCode === 200) {
        showSuccess(response?.data?.body?.message);
        navigator("/");
      } else if (response?.data?.statusCode === 401) {
        showError(response?.data?.body?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const language = await LanguageAPI();
        const languageId = language?.data?.body?.data;
        dispatch(setLanguageArray(languageId));
        const languageID = languageId[0]?.language_id;
        dispatch(setLanguageId(languageID));

        if (language.data.statusCode === 200) {
          const dataObj = {
            LanguageId: languageId[0]?.language_id,
          };
          const masterLabel = await MasterLabelAPI(dataObj);
          const data = masterLabel?.data?.body?.data;
          dispatch(setMasterLabel(data));
          setTimeout(() => {
            setDataFetched(false);
          }, 1000);
        }
      } catch (error) {
        console.error(error);
        setDataFetched(false); // Mark data as fetched even in case of an error
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container d-flex justify-content-center align-items-center">
      <div className="card text-white p-3 col-xxl-5 col-xl-5 col-md-7 col-sm-10 col-xs-10">
        <div className="d-flex justify-content-center align-items-center">
          <img src={formLogo} alt="logo" height={65} width={371} />
        </div>
        {dataFetched ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm text-white pe-1"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </div>
        ) : (
          <>
            <span className="p d-flex justify-content-center mt-1 mb-1">
              {findDisplayName("updatePasswordHeading", "Update Your password")}
            </span>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-feild p-4  ">
                <div className="mb-3">
                  <label className="form-label">
                    {findDisplayName("updatePasswordLabelHeading", "Password")}
                  </label>
                  <div className="input-group">
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${
                            errors?.password ? "is-invalid" : ""
                          }`}
                          placeholder={findDisplayName(
                            "updatePasswordScreenPasswordInputBox",
                            "Password"
                          )}
                          autoComplete="off"
                        />
                      )}
                    />
                    <span className="input-group-text">
                      <i
                        className={`bi bi-eye${showPassword ? "" : "-slash"}`}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    {findDisplayName(
                      "updateConfirmPasswordLabelHeading",
                      "Confirm Password"
                    )}
                  </label>
                  <div className="input-group">
                    <Controller
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          type={showPassword1 ? "text" : "password"}
                          className={`form-control ${
                            errors?.confirmPassword ? "is-invalid" : ""
                          }`}
                          placeholder={findDisplayName(
                            "updatePasswordConfrimPasswordInputBox",
                            "Confirm Password"
                          )}
                          autoComplete="off"
                        />
                      )}
                    />
                    <span className="input-group-text">
                      <i
                        className={`bi bi-eye${showPassword1 ? "" : "-slash"}`}
                        onClick={() => setShowPassword1(!showPassword1)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    className="btn btn-primary mt-3 btn-lg w-100"
                  >
                    <b>
                      {findDisplayName("updatePasswordUpdateButton", "Update")}
                    </b>
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default UpdatePassword;
