import React, { useEffect, useState } from "react";
import FeaturesTable from "./FeaturesTable/FeaturesTable";
import FeaturesCreate from "./FeaturesCreate/FeaturesCreate";
import FeaturesUpdate from "./FeaturesUpdate/FeaturesUpdate";
import { FeaturesList, RolesList } from "../../apis/apisServies";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { useSelector, useDispatch } from "react-redux";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { useForm, Controller } from "react-hook-form";
import { setOrganuzationID } from "../../Store/actions/organizationId/organizationIdReducer";
import { selectOrgID } from "../../Store/actions/organizationId/organizationIdSeelct";
import OrganizationList from "../OrganizationList/OrganizationList";
import { selectOrgTable } from "../../Store/actions/Organizationadmin/OrganizationSelector";
import {
  findDisplayName,
  isCreate,
  isUpdate,
  isView,
  handleCommonSearch,
} from "../../../utils/commonFunctionsAndStatices";
import RecreateFeatures from "./RecreateFeature/RecreateFeatures";
const Features = () => {
  const { handleSubmit, register, control } = useForm();

  const orgData = useSelector(selectOrgTable);
  const [activeComponent, setActiveComponent] = useState("table");
  const [data, setData] = useState([]);
  const loginData = useSelector(selectToken);
  const [feature, setFeature] = useState([]);
  const dispatch = useDispatch();
  const languageId = useSelector(languageid);
  const selectOrgId = useSelector(selectOrgID);
  const [roledata, setRoledata] = useState([]);
  const sortOrgData =
    orgData && [...orgData].sort((a, b) => a.name.localeCompare(b.name));
  const organizationid = isView("isListOrganization")
    ? selectOrgId !== ""
      ? selectOrgId
      : sortOrgData[0]?.organizationid
    : loginData?.organization_id;
  const [orgid, setOrgid] = useState(organizationid);
  const [selectedRoleId, setSelectedRoleId] = useState(orgid);
  const [searchTypeBox, setSearchTypeBox] = useState("");
  const [searchModuleNameBox, setSearchModuleNameBox] = useState("");
  const [searchList, setSearchList] = useState(data);
  const [loading, setLoading] = useState(false);

  const handleOrganizationChange = async (organizationid) => {
    setSearchList([]);
    setRoledata([]);
    setOrgid(organizationid);
    dispatch(setOrganuzationID(organizationid));
    await roleslist(organizationid);
  };

  const featurelistdata = async (roleId) => {
    setLoading(true);

    try {
      const dataObj = {
        userid: loginData?.login_id,
        roleid: roleId,
        text_message: {
          language_id: languageId,
        },
      };
      const response = await FeaturesList(dataObj);
      if (response?.data?.statusCode === 200) {
        const datavalue = response?.data?.body?.data;
        setActiveComponent("table");
        setData(datavalue);
        setSearchList(datavalue);
        setSearchModuleNameBox("");
        setLoading(false);
      } else {
        setLoading(false);
        const errorMessage = response?.body?.message;
        setData([]);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in featurelist:", error);
      setData([]);
    }
  };

  const roleslist = async (orgid) => {
    try {
      const dataObj = {
        userid: loginData?.login_id,
        text_message: {
          language_id: languageId,
        },
      };
      if (orgid !== "") {
        dataObj.organizationid = orgid;
      } else {
        dataObj.independent_user = "independent_user";
      }
      const response = await RolesList(dataObj);
      if (response?.data?.statusCode === 200) {
        const datavalue = response?.data?.body?.data;
        setRoledata(datavalue);
        featurelistdata(datavalue[0]?.org_roleid);
        setSearchModuleNameBox("");
      } else if (response?.data?.statusCode === 400) {
        setData([]);
        setSearchList([]);
        setRoledata([]);
      } else {
        setData([]);
        setSearchList([]);
        setRoledata([]);
      }
    } catch (error) {
      console.error("Error in featurelist:", error);
      setData([]);
      setRoledata([]);
    }
  };

  const handleModuleNameSearch = (search) => {
    setSearchModuleNameBox(search);
    const filterData = handleCommonSearch(search, data, "module");
    setSearchList(filterData);
  };
  const types = new Set(data.map((item) => item.type.toLowerCase()));
  const uniqueTypes = [...types];

  const handleTypeSearch = (search) => {
    setSearchTypeBox(search);
    const filterData = handleCommonSearch(search, data, "type");
    setSearchList(filterData);
  };
  const handleRoleChange = (id) => {
    setSelectedRoleId(id);
    featurelistdata(id);
  };

  useEffect(() => {
    // featurelistdata(orgid);
    roleslist(orgid);
  }, []);

  useEffect(() => {
    if (roledata.length > 0) {
      setSelectedRoleId(roledata[0]?.org_roleid);
    }
  }, [roledata]);

  const name =
    selectedRoleId &&
    roledata?.find((item) => item.org_roleid === selectedRoleId)?.org_rolename;

    function capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div>
      <nav className="nav-dashboard">
        <strong className="links">
          {findDisplayName("featuresHeading", "Features ")}
        </strong>
        {/* {(activeComponent === "table" && isCreate("isDashboardFeatures")) && (
          <button
            type="button"
            className="btn btn-primary mb-2"
            id="create-user-button"
            onClick={() => setActiveComponent("create")}
          >
            {findDisplayName("createFeaturesHeading", "Create Features")}
          </button>
        )} */}
        {activeComponent === "table" && (
          <button
            type="button"
            className="btn btn-primary mb-2"
            id="create-user-button"
            onClick={() => setActiveComponent("reCreate")}
          >
            {findDisplayName("cloneFeaturesHeading", "Clone Features")}
          </button>
        )}
      </nav>
      <div className="row p-2 p-lg-0 ms-lg-2">
        {activeComponent === "table" && isView("isListOrganization") && (
          <div className="form-group col-lg-3 col-md-8 col-sm-10 col-xs-10 mt-lg-3 ">
            <label htmlFor="role" className="text-white mb-2">
              {findDisplayName(
                "featuresSelectOrganizationHeading",
                "Organization"
              )}
            </label>
            <OrganizationList
              handleChange={handleOrganizationChange}
              initialvalue={orgid}
              organizatonInUser={true}
            />
          </div>
        )}
        {activeComponent === "table" && (
          <div className="col-lg-3 col-md-8 col-sm-12 col-xs-8 mt-3">
             <label htmlFor="role" className="text-white ">
             {findDisplayName("featuresSelectRoleHeading", "Role")}
            </label>
            <div className="dropdown mt-2">
              {name ? (
                <button
                  className="btn bg-white form-control form-select text-start text-truncate"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {name}
                </button>
              ) : (
                <input
                  className="btn bg-white form-control w-100 form-select text-start text-truncate"
                  type="button"
                  style={{ width: "50px" }}
                />
              )}
              {roledata && roledata.length > 0 && (
                <ul className="dropdown-menu w-100 pb-2">
                  {roledata?.map((data) => (
                    <li key={data.org_roleid}>
                      <a
                        className="dropdown-item text-truncate"
                        onClick={() => handleRoleChange(data.org_roleid)}
                      >
                        {data.org_rolename}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
        {activeComponent === "table" && (
          <>
            <div className="col-12 col-md-8 col-lg-3 mt-2 ">
              <label className="text-white mt-2">
                {findDisplayName(
                  "featuresTableModuleNameHeading",
                  "Module Name"
                )}
              </label>
              <div className="input-group mt-md-2">
                <input
                  type="text"
                  className="form-control"
                  value={searchModuleNameBox}
                  placeholder="Module"
                  onChange={(e) => handleModuleNameSearch(e.target.value)}
                />
                <span className="input-group-text">
                  <svg
                    className="bi bi-search"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                      clipRule="evenodd"
                    />

                    <path
                      fillRule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="col-12 col-lg-2 col-md-8 me-3 mt-3">
              <label className="text-white ">
                {findDisplayName("featureTableSelectTypeHeading", "Type")}
              </label>
              <div>
                <div className="dropdown  mt-2">
                  <button
                    className="btn bg-white form-control form-select text-start"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {searchTypeBox === ""
                      ? findDisplayName(
                          "featureTableSelectAllTypeOption",
                          "All Type"
                        )
                      : capitalizeFirstLetter(searchTypeBox)
                    }
                  </button>

                  {uniqueTypes&& uniqueTypes.length > 0 && (
                    <ul className="dropdown-menu w-100 pb-2">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => handleTypeSearch("")}
                        >
                          {findDisplayName(
                            "featureTableSelectAllTypeOption",
                            "All Type"
                          )}
                        </a>
                      </li>
                      {uniqueTypes?.map((type) => (
                        <li key={type}>
                          <a
                            className="dropdown-item"
                            onClick={() => handleTypeSearch(type)}
                          >
                            {capitalizeFirstLetter(type)}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <hr className="text-white mt-0 mb-0 ms-3 me-1" />
      {activeComponent === "table" && isView("isDashboardFeatures") && (
        <FeaturesTable
          data={searchList}
          setActiveComponent={setActiveComponent}
          featurelistdata={featurelistdata}
          setfeature={setFeature}
          organizationId={orgid}
          selectedRoleId={selectedRoleId}
          loading={loading}
          // searchList={searchList}
          //   activeButton={activeButton}
        />
      )}
      {activeComponent === "create" && isCreate("isDashboardFeatures") && (
        <FeaturesCreate
          setActiveComponent={setActiveComponent}
          featurelistdata={featurelistdata}
          selectedRoleId={selectedRoleId}
          //   fetchdata={fetchdata}
          //   organizationId={orgid}
          //   activeButton={activeButton}
        />
      )}
      {activeComponent === "update" && isUpdate("isDashboardFeatures") && (
        <FeaturesUpdate
          setActiveComponent={setActiveComponent}
          featurelistdata={featurelistdata}
          feature={feature}
          selectedRoleId={selectedRoleId}
          //   organizationId={orgid}
          //   activeButton={activeButton}
        />
      )}
      {activeComponent === "reCreate" && (
        <RecreateFeatures
          setActiveComponent={setActiveComponent}
          sortOrgData={sortOrgData}
          orgid={orgid}
          organizationid={organizationid}
          selectedRoleId={selectedRoleId}
          roledata={roledata}
        />
      )}
    </div>
  );
};

export default Features;
