import { useEffect, useState } from "react";
import React from "react";
import formLogo from "../../../Asset/Img/Form-logo.svg";
import { useNavigate } from "react-router-dom";
import "./css/style.css";
import { useDispatch, useSelector } from "react-redux";
import { selectVisitorArr } from "../../Store/actions/visitorType/visitorTypeSelectors";
import { selectLocationArr } from "../../Store/actions/locationData/locationSelectors";
import { setOrganization } from "../../Store/actions/getorganization/organizationSelector";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { GetAutoBatchNumber, checkInApi } from "../../apis/apisServies";
import { useNotification } from "../../NotificationProvider/NotificationProvider";
import { setVisitorId } from "../../Store/actions/visitorId/visitorIdReducer";
import Layout from "../../Layout/Layout";
import { findDisplayName, isBatchRead, isViewOrgFeatures } from "../../../utils/commonFunctionsAndStatices";
import { selectFieldTable } from "../../Store/actions/FieldTableData/FieldTableSelector";
import OCRProcess from "./OCR/OCRProcess";
import { languageid } from "../../Store/actions/languageId/selectLanguageId";
import { selecetPortID } from "../../Store/actions/portId/portSelect";
import { setVisitorBypass } from "../../Store/actions/visitorType/visitorTypeReducer";

export const FormScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector(selectToken);
  const visitorArr = useSelector(selectVisitorArr);
  const locationArr = useSelector(selectLocationArr);
  const organization = useSelector(setOrganization);
  const list = useSelector(selectFieldTable);
  const fieldData = [...list].sort((a, b) => a.sort_order - b.sort_order);
  const portID = useSelector(selecetPortID);
  const { showSuccess, showError } = useNotification();
  const [visitor, setVisitor] = useState("");
  const [badgenumbers, setBadgenumbers] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [callingon, setCallingon] = useState(locationArr[0]?.locationid);
  const [badgenumbermask, setBadgenumbermask] = useState("");
  const [selectedFieldValues, setSelectedFieldValues] = useState({});
  const [numberFieldValues, setNumberFieldValues] = useState({});
  const [booleanFieldValues, setBooleanFieldValues] = useState({});
  const [textFieldValues, setTextFieldValues] = useState({});
  const [selectedValue, setSelectedValue] = useState("Check-in");
  const [dateFieldValues, setDateFieldValues] = useState({});
  const [timeFieldValue, setTimeFieldValues] = useState({});
  const [radioFieldValues, setRadioFieldValues] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [typeName, setTypeName] = useState(
    findDisplayName("checkInPageSelectVisitorDropdown", "Select Visitor Type")
  );
  const firstNameRegex = organization && organization?.organization_details?.find(item => item.field_name === "First Name Regex");
  const lastNameRegex = organization && organization?.organization_details?.find(item => item.field_name === "Last Name Regex");

  const [locationName, setLocationName] = useState(
    locationArr[0]?.locationname
  );
  const languageId = useSelector(languageid);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  // * visitor select handler * //
  const handleVisitorChange = (id) => {
    setVisitor(id);
    const bypass = visitorArr?.find(
      (item) => item.visitortypeid === id
    ).bypassvideo;
    const name = visitorArr?.find((item) => item.visitortypeid === id).name;
    dispatch(setVisitorBypass(bypass));
    setTypeName(name);
  };

  //* Location select handler *//
  const handleLocationChange = (id) => {
    setCallingon(id);
    const name = locationArr?.find(
      (item) => item.locationid === id
    ).locationname;
    setLocationName(name);
  };

  //* checkin api call
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const items = Array.isArray(fieldData)
        ? fieldData
          .filter((field) => field.display_group === "Check-in")
          .map((field) => {
            switch (field.field_type) {
              case "Select":
                return {
                  key: field.field_name,
                  value:
                    selectedFieldValues[field.organization_field_id] || "",
                };
              case "Number":
                return {
                  key: field.field_name,
                  value: numberFieldValues[field.organization_field_id] || "",
                };
              case "Boolean":
                return {
                  key: field.field_name,
                  value:
                    booleanFieldValues[field.organization_field_id] || false,
                };
              case "Date":
                return {
                  key: field.field_name,
                  value: dateFieldValues[field.organization_field_id] || "", // Change 'false' to an appropriate default value
                };

              case "Time":
                return {
                  key: field.field_name,
                  value: timeFieldValue[field.organization_field_id] || "",
                };

              case "Text":
                return {
                  key: field.field_name,
                  value: (
                    textFieldValues[field.organization_field_id] || ""
                  ).trim(),
                };
              case "Radio":
                return {
                  key: field.field_name,
                  value:
                    radioFieldValues[field.organization_field_id] || false,
                };

              default:
                return null;
            }
          })
        : [];

      const fieldIsValid = (field) => {
        switch (field.field_type) {
          case "Select":
            return (
              selectedFieldValues.hasOwnProperty(field.organization_field_id) &&
              selectedFieldValues[field.organization_field_id] !== ""
            );
          case "Number":
            return (
              numberFieldValues[field.organization_field_id] !== "" &&
              !isNaN(numberFieldValues[field.organization_field_id])
            );
          case "Boolean":
            return (
              booleanFieldValues[field.organization_field_id] !== undefined &&
              booleanFieldValues[field.organization_field_id] !== null &&
              booleanFieldValues[field.organization_field_id] !== false
            );
          case "Radio":
            return (
              radioFieldValues[field.organization_field_id] !== undefined &&
              radioFieldValues[field.organization_field_id] !== null &&
              radioFieldValues[field.organization_field_id] !== false
            );
          case "Date":
            return (
              dateFieldValues[field.organization_field_id] !== undefined &&
              dateFieldValues[field.organization_field_id] !== ""
            );
          case "Time":
            return (
              timeFieldValue[field.organization_field_id] !== undefined &&
              timeFieldValue[field.organization_field_id] !== ""
            );
          case "Text":
            return (
              textFieldValues.hasOwnProperty(field.organization_field_id) &&
              textFieldValues[field.organization_field_id] !== ""
            );
          default:
            return false;
        }
      };

      const checkInFields = fieldData.filter(
        (field) => field.display_group === "Check-in"
      );

      const missingRequiredField = checkInFields.find((field) => {
        const isValid = fieldIsValid(field);
        return field.is_default && !isValid;
      });
      if (!visitor) {
        showError(
          findDisplayName(
            "checkInPageVisitorTypeValidation",
            "Kindly choose a kind of visitor."
          )
        );
        return;
      } else if (!badgenumbers) {
        showError(
          findDisplayName(
            "checkInPageBatchNumberValidation",
            "Kindly Provide Badge Number"
          )
        );
        return;
      } else if (badgenumbers?.length !== badgenumbermask.length) {
        showError(
          findDisplayName(
            "checkInPageBatchNumberLengthValidation",
            "Kindly Provide Valid Badge Number"
          )
        );
        return;
      } else if (fname === "" || !fname.trim()) {
        showError(
          findDisplayName(
            "checkInPageFirstNameValidation",
            "Kindly Provide First Name"
          )
        );
        return;
      } else if (lname === "" || !lname.trim()) {
        showError(
          findDisplayName(
            "checkInPagelastNameValidation",
            "Kindly Provide Last Name"
          )
        );
        return;
      }

      if (missingRequiredField) {
        let data = findDisplayName(
          "checkInPageDynamicFieldValidation",
          "Kindly provide details to this"
        );
        let fieldData = data + " " + missingRequiredField.field_name;
        showError(fieldData);
        return;
      } else {
        const dataObj = {
          organizationid: loginData?.organization_id,
          visitortypeid: visitor,
          locationid: callingon,
          hostuserid: loginData?.login_id,
          badgenumber: badgenumbers.replace(/\s/g, ''),
          FirstName: fname?.trim(),
          LastName: lname?.trim(),
          isAnonymous: true,
          checkin_port: portID,
          items: items.filter((field) => field !== null),
          text_message: {
            language_id: languageId,
            organization_id: loginData?.organization_id,
          },
        };
        if (navigator.onLine) {
          const response = await checkInApi(dataObj);
          if (response.data.statusCode === 201) {
            const videoByPass = visitorArr?.find(
              (item) => item.visitortypeid === visitor
            ).bypassvideo;
            const videoPath = videoByPass ? "/AgreeScreen" : "/VideoScreen"
            const path = isViewOrgFeatures("isTakePicture") ? "/Capture" : videoPath;
            //* Dispatch the setVisitorId action to store the data in Redux
            dispatch(setVisitorId(response?.data?.body?.visitorid));
            showSuccess(response?.data?.body?.message);
            //* navigate on next screen
            navigate(path);
          } else if (response.data.statusCode === 409) {
            showError(response?.data?.body?.message);
          } else if (response.data.statusCode === 500) {
            showError(response?.data?.body?.message);
          }
        } else {
          try {
            var key = "VisitorCounter";
            var currentValue = sessionStorage.getItem(key);
            if (currentValue !== null) {
              // Key exists, increment the value
              currentValue = parseInt(currentValue) + 1;
            } else {
              // Key doesn't exist, set it to 1
              currentValue = 0;
            }
            sessionStorage.setItem(key, currentValue);
            var key = "visitor" + currentValue;
            var visitor_list = JSON.parse(sessionStorage.getItem("visitor"))
              ? JSON.parse(sessionStorage.getItem("visitor"))
              : {};
            visitor_list[key] = { checkin: dataObj };
            sessionStorage.setItem("visitor", JSON.stringify(visitor_list));
            dispatch(setVisitorId(key));
            navigate("/Capture");
            showSuccess("Visitor Created");
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ---------------Badgenumber mask----------------
  function findBadgemaskpattern(inputString) {
    const regex = /[^#]/g;
    let result = [];
    let match;
    while ((match = regex.exec(inputString))) {
      result.push({ symbol: match[0], pos: match.index });
    }
    return result;
  }

  const formatBadgeNumber = (event) => {
    let badgeNumber = event.target.value.replace(/\D/g, "");
    let space = findBadgemaskpattern(badgenumbermask);
    space.map((s) => {
      if (badgeNumber.length >= s.pos) {
        badgeNumber =
          badgeNumber.substring(0, s.pos) +
          s.symbol +
          badgeNumber.substring(s.pos);
      }
      return s;
    });
    setBadgenumbers(badgeNumber);
  };
  const badgenumberBackspace = (event) => {
    if (event.key === "Backspace") {
      // Remove the last character from the Badge number
      setBadgenumbers((prevBadgeNumber) =>
        prevBadgeNumber.replace(/[^\d]/g, "")
      );
    }
  };

  const handleLastNameBlur = (e) => {
    const inputValue = e.target.value;
    if (isViewOrgFeatures("isLastNameRegex")) {
      const regexPattern = lastNameRegex?.field_value.replace(/^\/|\/[gmiy]*$/g, '');
      const regexFlags = lastNameRegex?.field_value.replace(/.*\/([gmiy]*)$/, '$1');
      const regex = new RegExp(regexPattern, regexFlags);
      const alphabeticValue = inputValue.replace(regex, "");
      setLname(alphabeticValue);
    } else {
      const alphabeticValue = isViewOrgFeatures("isLastNameASCIIAccept") ? inputValue : inputValue.replace(/[^A-Za-z ]/g, "");
      setLname(alphabeticValue);
    }
  };

  const handleFirstNameBlur = (e) => {
    const inputValue = e.target.value;
    if (isViewOrgFeatures("isFirstNameRegex")) {
      const regexPattern = firstNameRegex?.field_value.replace(/^\/|\/[gmiy]*$/g, '');
      const regexFlags = firstNameRegex?.field_value.replace(/.*\/([gmiy]*)$/, '$1');
      const regex = new RegExp(regexPattern, regexFlags);
      const alphabeticValue = inputValue.replace(regex, "");
      setFname(alphabeticValue);
    } else {
      // If regex feature is not enabled, use default replacement pattern
      const alphabeticValue = isViewOrgFeatures("isFirstNameASCIIAccept") ? inputValue : inputValue.replace(/[^A-Za-z ]/g, "");
      setFname(alphabeticValue);
    }
  };

  const renderField = (field, index) => {
    let isFieldRequired = field.is_default;
    switch (field.field_type) {
      case "Select":
        return (
          <div key={index} className="col-md-12 col-sm-12 col-xs-8">
            <label className="mt-2">{field.field_name}</label>
            <div className="dropdown mt-1">
              <button
                className=" bg-white w-100 form-select text-start "
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {selectedFieldValues[field.organization_field_id]
                  ? field.items.find(
                    (item) =>
                      item.detail_key ===
                      selectedFieldValues[field.organization_field_id]
                  )?.detail_value
                  : "Select"}
              </button>
              <ul className="dropdown-menu w-100 pb-2">
                {selectedValue === "Check-in" &&
                  field.items.map((item) => (
                    <li key={item.organization_field_details_id}>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleSelectFieldChange(
                            { target: { value: item.detail_key } },
                            field.organization_field_id
                          )
                        }
                      >
                        {item.detail_value}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        );

      case "Number":
        const numberFieldValue =
          numberFieldValues[field.organization_field_id] || "";
        const inputWidth = field?.field_length < 4 ? field?.field_length * 25 : field?.field_length * 16;
        return (
          <div key={index}>
            <label className="mt-2">{field.field_name}</label>
            <br />
            <input
              type="text"
              className="form-control mt-1"
              id={field.organization_field_id}
              // placeholder={field.field_name}
              maxLength={field?.field_length}
              autoComplete="off"
              onChange={(e) =>
                setNumberFieldValues((prevValues) => ({
                  ...prevValues,
                  [field.organization_field_id]: e.target.value.replace(
                    /[^0-9]/g,
                    ""
                  ),
                }))
              }
              value={numberFieldValue}
              required={isFieldRequired ? true : false}
              style={{ width: `${inputWidth}px` }}
            />
          </div>
        );

      case "Boolean":
        const booleanFieldValue =
          booleanFieldValues[field.organization_field_id] || false;
        return (
          <div
            key={index}
            className="col-md-12 col-sm-12 col-xs-8 form-check mt-2"
          >
            <input
              type="checkbox"
              className="form-check-input"
              id={field.organization_field_id}
              autoComplete="off"
              onChange={() =>
                setBooleanFieldValues((prevValues) => ({
                  ...prevValues,
                  [field.organization_field_id]:
                    !prevValues[field.organization_field_id],
                }))
              }
              checked={booleanFieldValue}
              required={isFieldRequired ? true : false}
            />
            <label
              className="form-check-label"
              htmlFor={field.organization_field_id}
            >
              {field.field_name}
            </label>
          </div>
        );
      case "Date":
        const dateFieldValue =
          dateFieldValues[field.organization_field_id] || "";
        return (
          <div key={index} className="col-md-5 col-sm-12 col-xs-8 ">
            <label className="mt-2" htmlFor={field.organization_field_id}>
              {field.field_name}
            </label>
            <input
              type="date"
              className="form-control mt-1"
              id={field.organization_field_id}
              // placeholder={field.field_name}
              onChange={(e) =>
                setDateFieldValues((prevValues) => ({
                  ...prevValues,
                  [field.organization_field_id]: e.target.value,
                }))
              }
              value={dateFieldValue}
              required={isFieldRequired ? true : false}
            />
          </div>
        );

      case "Time":
        const timeFieldValues =
          timeFieldValue[field.organization_field_id] || "";
        return (
          <div key={index} className="col-md-5 col-sm-12 col-xs-8 ">
            <label className="mt-2" htmlFor={field.organization_field_id}>
              {field.field_name}
            </label>
            <input
              type="time"
              className="form-control mt-1"
              id={field.organization_field_id}
              // placeholder={field.field_name}
              onChange={(e) =>
                setTimeFieldValues((prevValues) => ({
                  ...prevValues,
                  [field.organization_field_id]: e.target.value,
                }))
              }
              value={timeFieldValues}
              required={isFieldRequired ? true : false}
            />
          </div>
        );

      case "Text":
        const textFieldValue =
          textFieldValues[field.organization_field_id] || "";
        return (
          <div key={index} className="col-md-12 col-sm-12 col-xs-8">
            <label className="mt-2">{field.field_name}</label>
            <input
              type="text"
              className="form-control mt-1"
              maxLength={field?.field_length}
              id={field.organization_field_id}
              // placeholder={field.field_name}
              autoComplete="off"
              onChange={(e) =>
                setTextFieldValues((prevValues) => ({
                  ...prevValues,
                  [field.organization_field_id]: e.target.value,
                }))
              }
              value={textFieldValue}
              required={isFieldRequired ? true : false}
            />
          </div>
        );

      case "Radio":
        const radioFieldValue =
          radioFieldValues[field.organization_field_id] || "";
        return (
          <div key={index} className="col-md-12 col-sm-12 col-xs-8">
            <label className="mt-2">{field.field_name}</label>
            <div className="mt-1">
              {field.items.map((item, itemIndex) => (
                <div key={itemIndex} className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name={field.organization_field_id}
                    id={`${field.organization_field_id}-${itemIndex}`}
                    value={item.detail_value}
                    checked={radioFieldValue === item.detail_value}
                    onChange={(e) =>
                      setRadioFieldValues((prevValues) => ({
                        ...prevValues,
                        [field.organization_field_id]: e.target.value,
                      }))
                    }
                    required={isFieldRequired ? true : false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${field.organization_field_id}-${itemIndex}`}
                  >
                    {item.detail_value}
                  </label>
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleSelectFieldChange = (e, organizationFieldId) => {
    const { value } = e.target;
    setSelectedFieldValues((prevValues) => ({
      ...prevValues,
      [organizationFieldId]: value,
    }));
  };

  useEffect(() => {
    setBadgenumbermask(organization.badge_number_mask);
  }, [loginData]);

  const fetchBatchNumber = async (id) => {
    const dataObj = {
      userid: loginData?.login_id,
      organizationid: loginData?.organization_id,
      locationid: id,
      text_message: {
        language_id: languageId,
        organization_id: loginData?.organization_id,
      },
    }
    const response = await GetAutoBatchNumber(dataObj);
    if (response?.status === 200) {
      const number = response?.data?.body?.data;
      formatAutoBadgeNumber(number)
    }
  }
  const formatAutoBadgeNumber = (number) => {
    let badgeNumber = number.toString();

    // Define the batch mask pattern
    const batchMask = badgenumbermask;
    // Count the number of "#" characters in the mask pattern
    const maskLength = batchMask.replace(/[^#]/g, "").length;

    // Pad the badgeNumber with leading zeros if its length is less than the mask pattern length
    while (badgeNumber.length < maskLength) {
      badgeNumber = "0" + badgeNumber;
    }

    let maskedNumber = "";
    let numberIndex = 0;

    // Apply the mask to the badgeNumber
    for (let i = 0; i < batchMask.length; i++) {
      if (batchMask[i] === "#") {
        maskedNumber += badgeNumber[numberIndex] || "0";
        numberIndex++;
      } else {
        maskedNumber += batchMask[i];
      }
    }

    // Set the state based on the masked number
    setBadgenumbers(maskedNumber);
  };

  useEffect(() => {
    if (isBatchRead("isAutoBatch")) {
      if (navigator.onLine) {
        fetchBatchNumber(callingon);
      } else {
        formatAutoBadgeNumber(1);
      }
    }

  }, [callingon, badgenumbermask]);

  return (
    <>
      <Layout>
        <div className="card text-white p-3 col-xxl-5 col-xl-5 col-md-7 col-sm-10 col-xs-10">
          <div className="d-flex justify-content-center align-items-center">
            <img src={formLogo} alt="logo" height={60} />
          </div>
          {locationArr.length === 0 && (
            <h5 className="mt-3 text-center">
              {findDisplayName(
                "checkInPageLocationDataValidation",
                "There is no location information available. Please contact Admin or CSO."
              )}{" "}
            </h5>
          )}
          {visitorArr.length === 0 && locationArr.length > 0 && (
            <h5 className="mt-3 text-center">
              {findDisplayName(
                "checkInPageVisitorTypeDataValidation",
                "There is no visitor type available. Please contact Admin or CSO"
              )}
            </h5>
          )}

          {locationArr.length > 0 && visitorArr.length > 0 && (
            <>
              <div className="check-in-form-scrol">
                <p className="pe-3" style={{ textAlign: "justify" }}>
                  {findDisplayName(
                    "checkInPageContent",
                    "This portal is designed to make it easier and more efficient for our visitors to check in and out. We recognize that it is critical for individuals and businesses to be confident that their privacy is protected."
                  )}
                </p>
                <form className=" overflow-x-none">
                  <div className="row my-4 p-2">
                    <div className={isViewOrgFeatures("isVisibleBatch") ? "col-md-6 col-sm-12 col-xs-8" : "col-md-12 col-sm-12 col-xs-8 "}>
                      <label className="mb-1">
                        {findDisplayName(
                          "checkInPageVisitorTypeLabel",
                          "Visitor Type"
                        )}
                      </label>
                      <div className="dropdown">
                        <button
                          className=" bg-white w-100 form-select text-start text-truncate"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {typeName}
                        </button>
                        <ul className="dropdown-menu w-100 pb-2">
                          {visitorArr
                            ?.slice()
                            ?.sort((a, b) => a.sortorder - b.sortorder)
                            ?.map((visitorData, index) => (
                              <li key={index}>
                                <a
                                  className="dropdown-item text-truncate"
                                  onClick={() =>
                                    handleVisitorChange(
                                      visitorData?.visitortypeid
                                    )
                                  }
                                >
                                  {visitorData?.name}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    {
                      isViewOrgFeatures("isVisibleBatch") && (
                        <div className="col-md-6 col-sm-12 col-xs-8">
                          <label className="mt-2 mt-lg-0 mt-md-0">
                            {findDisplayName(
                              "checkInPageBadgeNumberInputBox",
                              "Badge Number"
                            )}
                          </label>
                          <input
                            type="text"
                            value={badgenumbers}
                            className="form-control mt-1"
                            id="organization_id"
                            onKeyDown={badgenumberBackspace}
                            maxLength={badgenumbermask?.length}
                            onInput={(e) => formatBadgeNumber(e)}
                            onFocus={(event) => {
                              event.target.setAttribute("autocomplete", "off");
                            }}
                            required
                            disabled={isViewOrgFeatures("isReadBatch")}
                          />
                        </div>
                      )
                    }

                    <div className="col-md-6 col-sm-12 col-xs-8 ">
                      <label className="mt-2  ">
                        {findDisplayName(
                          "checkInPageFirstNameInputBox",
                          "First Name"
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        id="firstName"
                        value={fname}
                        onChange={handleFirstNameBlur}
                        onFocus={(event) => {
                          event.target.setAttribute("autoComplete", "off");
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-8 ">
                      <label className="mt-2">
                        {findDisplayName(
                          "checkInPageLastNameInputBox",
                          "Last Name"
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        id="lastName"
                        value={lname}
                        onChange={handleLastNameBlur}
                        onFocus={(event) => {
                          event.target.setAttribute("autoComplete", "off");
                        }}
                      />
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-8 mt-2">
                      <label htmlFor="callingOn" className="form-label">
                        {findDisplayName(
                          "checkInPageCallingOnHeading",
                          "Vessel Name"
                        )}
                      </label>
                      {locationArr.length > 1 ? (
                        <div className="dropdown">
                          <button
                            className="bg-white w-100 form-select text-start text-truncate"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {locationName}
                          </button>
                          <ul className="dropdown-menu w-100 pb-2">
                            {locationArr.map((data, index) => (
                              <li key={index}>
                                <a
                                  className="dropdown-item text-truncate"
                                  onClick={() =>
                                    handleLocationChange(data?.locationid)
                                  }
                                >
                                  {data?.locationname}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          value={locationArr[0]?.locationname}
                          readOnly
                        />
                      )}
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-8 mt-2">
                      {fieldData.map(
                        (field, index) =>
                          field.display_group === "Check-in" &&
                          renderField(field, index)
                      )}
                    </div>
                  </div>

                  <div className="mt-4 pe-2 ps-2">
                    <button
                      type="submit"
                      className="btn btn-primary w-100 "
                      onClick={handleSubmit}
                    >
                      {findDisplayName("FormPageSubmitButton", "Submit ")}
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
        {isModalOpen && (
          <div className="modal-backdrop fade show custom-modal-backdrop" />
        )}
        {isModalOpen && (
          <OCRProcess closeModal={closeModal} isModalOpen={isModalOpen} />
        )}
      </Layout>
    </>
  );
};
