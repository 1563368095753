import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roleData: null,
};

const roleCreateSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRoleData: (state, action) => {
      state.roleData = action.payload;
    },
    clearRoleData: (state) => {
      state.roleData = null;
    },
  },
});

export const {setRoleData, clearRoleData} = roleCreateSlice.actions;
export default roleCreateSlice.reducer;