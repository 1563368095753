import React from "react";
import formLogo from "../../../../Asset/Img/Form-logo.svg";
import { useForm } from "react-hook-form";
import { findDisplayName } from "../../../../utils/commonFunctionsAndStatices";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import parse from "html-react-parser";
import { passwordRestMailAPI } from "../../../apis/apisServies";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";
import { useSelector } from "react-redux";

const ForgotPassword = () => {
  const languageId = useSelector(languageid);

  const emailRegex =
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  const sndemail = findDisplayName("forgotPasswordEmailButton", "Reset Password");
  const rsndemail = findDisplayName(
    "forgotPasswordResendEmailButton",
    "Resend Email"
  );
  const DisplayMessage = findDisplayName(
    "forgotPasswordDisplayMessage",
    " The email has been sent to your inbox. <br/>&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Kindly check your mailbox!."
  );
  const [buttonText, setButtonText] = useState(sndemail);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { showSuccess, showError } = useNotification();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  // get the url and set to payload
  var fullURL = window.location.href;
  var url = new URL(fullURL);
  var baseURL = url.origin + "/UpdatePassword";

  const onSubmit = async (data) => {
    if (!data.email) {
      showError(
        findDisplayName(
          "forgotPasswordEmailInputValidation",
          "Kindly Enter your Email"
        )
      );
      return;
    } else if (!emailRegex.test(data.email)) {
      showError(
        findDisplayName(
          "forgotPasswordEmailCorrectValidation",
          "Please enter a valid email address"
        )
      );
      return;
    } else {
      setShowSuccessMessage(true);
      setButtonText(rsndemail);
    }

    const dataObject = {
      login_id: data.email,
      forget_url: baseURL,
      text_message: {
        language_id: languageId,
      }
    };

    const response = await passwordRestMailAPI(dataObject);
    if (response?.data?.statusCode === 200) {
      showSuccess(response?.data?.body?.message);
    } else if (response?.data?.statusCode === 404) {
      showError(response?.data?.body?.message);
      setShowSuccessMessage(false);
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center">
      <div className="card text-white p-3 col-xxl-5 col-xl-5 col-md-7 col-sm-10 col-xs-10">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={formLogo} alt="logo" height={65} width={371} />
          <span className="h4  mt-3 mb-4">
            {findDisplayName("forgotPassowordHeading", "Reset your password")}
          </span>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {showSuccessMessage && <p className="d-flex justify-content-center align-items-center ">{parse(DisplayMessage)}</p>}
            {!showSuccessMessage && (
              <div className="mb-3">
                <label className="form-label">
                  {findDisplayName(
                    "forgotPasswordEmailHeading",
                    "Email address"
                  )}
                </label>

                <input
                  {...register("email")}
                  type="text"
                  className={`form-control ${
                    errors?.email ? "is-invalid" : ""
                  }`}
                  placeholder={findDisplayName(
                    "forgotPasswordEmailInputBox",
                    "Insert your email"
                  )}
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                  }}
                  onInput={(event) => {
                    // Remove spaces from the input value
                    event.target.value = event.target.value.replace(/\s/g, "");
                  }}
                  disabled={showSuccessMessage}
                />
              </div>
            )}
            <div className="mt-4 mb-4 d-flex justify-content-center">
              <button
                className="btn btn-primary me-2 w-100"
                //   onClick={handleSubmit(onSubmit)}
              >
                {buttonText}
              </button>
            </div>
            {showSuccessMessage && (
              <div className="d-flex gap-5 justify-content-center ">
                <Link to="/" className="   text-light">
                  {findDisplayName("forgotPasswordSignInHeading", "Sign in")}
                </Link>
              </div>
            )}
          </form>
        </div>
    </div>
  );
};

export default ForgotPassword;
