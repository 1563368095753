// getCountrySlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getCountryArr: [], // Initialize the getCountry type to []
};

const getCountryTypeSlice = createSlice({
  name: "getCountryArray",
  initialState,
  reducers: {
    setgetCountryArray: (state, action) => {
      state.getCountryArr = action.payload;
    },
    clearCountryArray: (state) => {
      state.getCountryArr = [];
    },
  },
});

export const { setgetCountryArray, clearCountryArray } =
  getCountryTypeSlice.actions;

export default getCountryTypeSlice.reducer;
