import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOrgTable } from "../../Store/actions/Organizationadmin/OrganizationSelector";
import { OrganizationAPI } from "../../apis/apisServies";
import { setOrgTable } from "../../Store/actions/Organizationadmin/OrganizationReducer";
import { selectToken } from "../../Store/actions/auth/authSelectors";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";
import { setOrganuzationID } from "../../Store/actions/organizationId/organizationIdReducer";

const OrganizationList = ({
  initialvalue,
  handleChange,
  organizatonInUser,
}) => {
  const orgData = useSelector(selectOrgTable);
  const loginData = useSelector(selectToken);
  const dispatch = useDispatch();
  const [orgValue, setOrgValue] = useState(initialvalue === undefined || initialvalue === null ? "" : initialvalue);
  const sortOrgData = orgData && [...orgData]?.sort((a, b) => a.name.localeCompare(b.name));
  const getOrganizations = async () => {
    const datobj = {
      userid: loginData?.login_id,
    };
    const res = await OrganizationAPI(datobj);
    const OrganizationData = res?.data?.body?.data;
    dispatch(setOrgTable(OrganizationData));
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const handleOrganizationChange = (id) => {
    setOrgValue(id);
    handleChange(id);
  };

  const isCommon = organizatonInUser === "common";
  const defaultName = isCommon ? "Common" : "Admin";

  const name = orgValue === "" ? defaultName : orgData && orgData?.find((item) => item.organizationid === orgValue)?.name;

  return (
    <div className="dropdown">
      {name ? (
        <button
          className="btn bg-white w-100 form-select text-start text-truncate"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {name}
        </button>
      ) : (
        <input
          className="btn bg-white w-100 form-select text-start"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          type="button"
          style={{ width: "50px" }}
        />
      )}
      <ul className="dropdown-menu w-100 pb-2">
        {organizatonInUser && (
          <li>
            <a
              className="dropdown-item"
              onClick={() => handleOrganizationChange("")}
            >
              {organizatonInUser === true ? findDisplayName("adminSelectionOption", "Admin") : findDisplayName("fieldsCommonSelectionOption", "Common")}
            </a>
          </li>
        )}
        {sortOrgData?.map((orgData) => (
          <li key={orgData.organizationid}>
            <a
              className="dropdown-item text-truncate"
              onClick={() => handleOrganizationChange(orgData.organizationid)}
            >
              {orgData.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrganizationList;
