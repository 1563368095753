import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useState } from "react";
import { VisitorTypeEditAPI } from "../../../apis/apisServies";
import { useNotification } from "../../../NotificationProvider/NotificationProvider";
import { selectToken } from "../../../Store/actions/auth/authSelectors";
import { selectOrgTable } from "../../../Store/actions/Organizationadmin/OrganizationSelector";
import { findDisplayName, isView } from "../../../../utils/commonFunctionsAndStatices";
import { languageid } from "../../../Store/actions/languageId/selectLanguageId";

const VisitorEdit = ({
  Visitor,
  setActiveComponent,
  fetchdata,
  activeButton,
}) => {
  const { handleSubmit, register } = useForm();
  const { showSuccess, showError } = useNotification();
  const loginData = useSelector(selectToken);
  const orgData = useSelector(selectOrgTable);
  const languageId = useSelector(languageid);
  const initialVisitorType = Visitor.name?.trim();
  const initialVideo = Visitor.bypassvideo;
  const [loading, isLoading] = useState(false);
  const initialOrganization =
    Visitor.organizationid === ""
      ? ""
      : orgData?.find((item) => item.organizationid === Visitor.organizationid)
          .name;

  const handleUpdateClick = async (data) => {
    try {
      if (!data.visitortype.trim()) {
        showError(
          findDisplayName(
            "visitorTypeScreenUpdateVisitorTypeValidation",
            "Kindly Enter a visitor type"
          )
        );
        return;
      }

      const changes = {};
      if (data.visitortype?.trim() !== initialVisitorType) {
        changes.name = data.visitortype?.trim();
      }

      if( initialVideo !== data.video){
        changes.bypassvideo = data.video;
      }

      if (Object.keys(changes).length > 0 && !isView("isListOrganization")) {
        changes.organizationid = loginData?.organization_id;
      } else if (
        Object.keys(changes).length > 0 &&
        isView("isListOrganization") ) {
        changes.organizationid = Visitor?.organizationid;
      }

      if (Object.keys(changes).length === 0) {
        showSuccess(
          findDisplayName(
            "languageCreateNoChangeValidation",
            "No changes made "
          )
        );
      } else {
        if (Object.keys(changes).length > 0) {
          const dataObj = {
            userid: loginData?.login_id,
            visitortypeid: Visitor?.visitortypeid,
            ...changes,
            text_message: {
              language_id: languageId,
              ...(loginData?.organization_id
                ? { organization_id: loginData?.organization_id }
                : {}),
            },
          };
          const response = await VisitorTypeEditAPI(dataObj);
          if (response.data.statusCode === 200) {
            showSuccess(response?.data?.body?.message);
            fetchdata(Visitor?.organizationid, activeButton);
            setActiveComponent("table");
          } else if (response.data.statusCode === 400) {
            showError("Data is Empty");
          } else if (response?.data?.statusCode === 409) {
            showError(response?.data?.body?.message);
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTimeout(() => {
        isLoading(false);
      }, 2000);
    }
  };

  return (
    <div>
      <div className="container  text-white  from-scrol">
        <div className="row d-flex flex-column align-items-center mt-5">
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4">
            {isView("isListOrganization") &&  (
              <div>
                <label htmlFor="organization">
                  {findDisplayName(
                    "visitorTypeUpdateOrganizationHeading",
                    "Organization"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control  mt-2 "
                  id="Organization"
                  defaultValue={initialOrganization}
                  {...register("Organization", {})}
                  disabled
                />
              </div>
            )}

            <label htmlFor="visitortype" className="mt-2">
              {findDisplayName(
                "visitorTypeUpdateVisitorTypeHeading",
                "Visitor Type"
              )}
            </label>
            <input
              type="text"
              className="form-control  mt-2 "
              id="visitortype"
              placeholder={findDisplayName(
                "visitorTypeUpdateVisitorTypeInputBox",
                "Visitor Type"
              )}
              onFocus={(event) => {
                event.target.setAttribute("autoComplete", "off");
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
              }}
              {...register("visitortype", {})}
              defaultValue={initialVisitorType}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex gap-2">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                id="bypassVideo"
                defaultChecked={initialVideo}
                {...register("video")}
              />
              <label className="form-check-label ps-2" htmlFor="bypassVideo">
                {findDisplayName(
                  "visitorTypeUpdateBypassVideoHeading",
                  "Bypass Video"
                )}
              </label>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-8 mt-4 d-flex gap-2 justify-content-center mb-3">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                handleSubmit(handleUpdateClick)();
                isLoading(true);
              }}
              disabled={loading}
            >
              {findDisplayName("visitorTypeUpdateUpdateButton", "Update")}
            </button>
            <button
              className="btn bg-secondary text-light"
              onClick={() => {
                setActiveComponent("table");
              }}
            >
              {findDisplayName("visitorTypeUpdateCancelButton", "Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorEdit;
