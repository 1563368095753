import React from "react";
import { useNavigate } from "react-router-dom";
import { findDisplayName } from "../../../utils/commonFunctionsAndStatices";
import DataNot from "../../../Asset/Img/DataNot.svg";
import { clearToken } from "../../Store/actions/auth/authReducer";
import { useDispatch } from "react-redux";

const ErrorPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signInPage = () => {
    sessionStorage.removeItem("persist:root");
    dispatch(clearToken());
    navigate("/");
  };

  // Retrieve error message
  const retrieveMessages = findDisplayName(
    "userAccessNotFound",
    "You don't have permission.Please contact your admin for further assistance."
  );

  // Split the retrieveMessages into an array of sentences
  const messagesArray = retrieveMessages.split(".");

  // Filter out empty strings from the split result
  const filteredMessages = messagesArray.filter(
    (sentence) => sentence.trim() !== ""
  );

  // Join the sentences with a newline character
  const displayMessage = filteredMessages.map((sentence, index) => (
    <React.Fragment key={index}>
      {sentence.trim()}
      <br />
    </React.Fragment>
  ));

  return (
    <>
      <div className="card text-white p-3 col-xxl-4 col-xl-4 col-md-7 col-sm-10 col-xs-10">
        <div className="d-flex justify-content-center align-items-center">
          <img className="w-50" src={DataNot} alt="logo" />
        </div>
        <div className="text-center">
          <div className="mt-2 col-12">
            <h5 className="d-flex justify-content-center p-3">{displayMessage}</h5>
          </div>
          <button
            className="btn btn-primary btn-lg mt-3 p-3"
            onClick={signInPage}
          >
            <span className="h5 font-weight-bold m-4">{findDisplayName("userAccessLogoutButton", "Logout")}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
