// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null, // Initialize the token to null
  loginData: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.loginData = action.payload;
    },
    clearToken: (state) => {
      state.loginData = null;
    },
  },
});

export const { setToken, clearToken } = authSlice.actions;

export default authSlice.reducer;
